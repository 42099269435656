import React, { memo } from "react";
import * as PropTypes from "prop-types";

import { StepperContainer } from "./index.styled";

const Stepper = ({ current, steps }) => {
  return (
    <StepperContainer>
      {steps.map(({ label }, index) => {
        const done = index < current;
        const currentStep = index === current;
        const currentClassName = currentStep ? " stepper__step--current" : "";
        const doneClassName = done ? " stepper__step--done" : "";
        const className = `stepper__step${currentClassName}${doneClassName}`;

        return (
          <li className={className} key={index}>
            <span className="stepper__step__index">
              <span>
                <span>{done ? "✓" : index + 1}</span>
              </span>
            </span>
            <h6 style={{ fontSize: "11px" }} className="stepper__step__label">
              {label}
            </h6>
          </li>
        );
      })}
    </StepperContainer>
  );
};

Stepper.propTypes = {
  current: PropTypes.number,
  steps: PropTypes.array,
};
export default memo(Stepper);
