import React from "react";
import { Helmet } from "react-helmet";

import { useTranslations } from "hooks/use-translations";

import {
  Heading,
  StyledContent,
  StyledLayout,
  Text,
} from "components/common.styled";
import Footer from "components/footer";
import Header from "components/header";

const CookiesInfo = () => {
  const routeName = "cookies-info";
  const translations = useTranslations(routeName);

  return (
    <StyledLayout>
      <Helmet>
        <title>{`${translations.cookies} | ${translations.meta_title_default}`}</title>
        <meta
          id="meta-description"
          name="description"
          content={translations.cookies_info}
        />
      </Helmet>
      <Header translations={translations} isStartPage={false} />

      <StyledContent>
        <Heading>{translations.cookies_info}</Heading>
        <Text>{translations.electronic_com_law}</Text>
        <ul>
          <li>{translations.website_contains_cookies}</li>
          <li>{translations.what_cookies_are_used_for}</li>
          <li>{translations.avoid_cookies}</li>
        </ul>

        <Text>{translations.cookie_explaination_1}</Text>
        <Text>{translations.cookie_explaination_2}</Text>
      </StyledContent>
      <Footer translations={translations} />
    </StyledLayout>
  );
};

export default CookiesInfo;
