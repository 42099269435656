import { useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

import config from "config";
import { useDebounce } from "hooks/use-debounce";

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  const initializeAnalytics = useCallback(() => {
    ReactGA.initialize(config.gaTrackingId);
  }, []);

  const trackPageView = useCallback(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location.pathname, location.search]);

  useEffect(() => {
    if (config.env === "production" && !initialized) {
      initializeAnalytics();
      setInitialized(true);
    }
  }, [initializeAnalytics, initialized]);

  useDebounce(trackPageView, [location.pathname, location.search], 2000);
};

export default usePageTracking;
