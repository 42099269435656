import React, { memo, useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import * as PropTypes from "prop-types";
import { BookingAttendeesService } from "services/booking-attendees-service";
import { BookingsService } from "services/bookings-service";

import config from "config";
import {
  formatDate,
  formatHours,
  mobileValidator,
} from "helpers/helper-functions";
import { useQuery } from "hooks/use-query";
import { useReferralSource } from "hooks/use-referral-source";
import { selectTag } from "redux/app/selectors";
import { authActions } from "redux/auth/actions";
import { caregiversActions } from "redux/caregivers/actions";
import { clinicsActions } from "redux/clinics/actions";

import AuthContent from "../../../auth/auth-content";
import { TransitionStep } from "../../../common.styled";
import AuthLoader from "../../../common/auth-loader";
import { Modal } from "../../../common/modal";
import Stepper from "../../../common/stepper";
import HealthDeclaration from "../../../health-declaration";
import ProfileContent from "../../profile-content";
import ConfirmContent from "../confirm-content";
import ReviewContent from "../review-content";

import { StyledContainer } from "./index.styled";

const BookingModal = ({
  bookingIsTentative,
  bookingRequiresTwoFactorAuthentication,
  clinicId,
  defaultProcedure,
  defaultProcedureId,
  defaultProcedureTitle,
  isSearch,
  modalStep,
  onClose,
  selectedSlot: slot,
  slotsId,
  translations,
}) => {
  const authState = useSelector((state) => state.auth);
  const { selectedSlot, caregiverDataForBooking = {} } =
    useSelector((state) => state.caregivers) || {};
  const locale = useSelector(selectTag);

  const dispatch = useDispatch();
  const query = useQuery();

  const referralSource = useReferralSource();

  const [bookingErrors, setBookingErrors] = useState([]);
  const [bookingExternalBindingId, setBookingExternalBindingId] =
    useState(null);
  const [bookingLoader, setBookingLoader] = useState(false);
  const [clinicAllowsHealthDeclaration, setClinicAllowsHealthDeclaration] =
    useState(false);
  const [showHealthDeclaration, setShowHealthDeclaration] = useState(false);
  const [step, setStep] = useState(0);

  const comment = query.get("comment");
  const goalPageURLCache = sessionStorage.getItem("goal_page_url");

  const steps = [
    { label: `${translations.booking_modal_step_1}` },
    { label: `${translations.booking_modal_step_2}` },
    { label: `${translations.booking_modal_step_3}` },
    { label: `${translations.booking_modal_step_4}` },
  ];

  const location = caregiverDataForBooking;
  const { clinic: currentClinic = {}, caregiver = {} } =
    caregiverDataForBooking || {};
  const isBookingAutoApprove =
    location?.attributes?.auto_approve_patient_booking;
  const isMobile = mobileValidator.test(window.navigator.userAgent);

  useEffect(() => {
    if (bookingRequiresTwoFactorAuthentication) {
      setStep(0);
    }
    if (
      (localStorage.getItem("user_token_pat_frontend") &&
        authState?.authUserPersonalId?.data?.attributes?.e_mail_address &&
        !authState.error?.data) ||
      !bookingRequiresTwoFactorAuthentication
    ) {
      setStep(2);
    } else if (
      (localStorage.getItem("user_token_pat_frontend") &&
        authState.authUserPersonalId &&
        !authState.authUserPersonalId?.data?.attributes?.e_mail_address &&
        !authState.error?.data) ||
      !bookingRequiresTwoFactorAuthentication
    ) {
      setStep(1);
    }
  }, [
    authState.authUserPersonalId,
    authState.authUserPersonalId?.data,
    authState.error?.data,
    bookingRequiresTwoFactorAuthentication,
  ]);

  useEffect(() => {
    if (selectedSlot && selectedSlot?.slot?.id !== slot?.id) {
      dispatch(caregiversActions.getSelectedSlot(slot, slotsId, clinicId));
      if (!bookingRequiresTwoFactorAuthentication) {
        dispatch(clinicsActions.getClinicById(clinicId));
      }
    }
  }, [
    bookingRequiresTwoFactorAuthentication,
    clinicId,
    dispatch,
    selectedSlot,
    slot,
    slotsId,
  ]);

  useEffect(() => {
    return () => dispatch(authActions.authErrorRefresh({}));
  }, [dispatch]);

  const checkStatus = useCallback(
    (bookingIsTentative, isBookingAutoApprove) => {
      if (!bookingIsTentative && isBookingAutoApprove) return "CONFIRMED";
      return "TENTATIVE";
    },
    [],
  );

  const checkStatusSummary = useCallback(
    (bookingIsTentative, isBookingAutoApprove, isSearch) => {
      if (isSearch) {
        if (defaultProcedure.length > 0)
          return `${translations.booking_modal_pat_booking}: ${defaultProcedure[0].title}`;
        if (!bookingIsTentative && isBookingAutoApprove)
          return `${translations.booking_modal_pat_booking}: ${translations.booking_modal_base_examination_dentist}`;
        return `${translations.booking_modal_pat_request}: ${translations.booking_modal_base_examination_dentist}`;
      }

      if (defaultProcedure.title) {
        if (!bookingIsTentative && isBookingAutoApprove)
          return `${translations.booking_modal_pat_booking}: ${defaultProcedure.title}`;
        return `${translations.booking_modal_pat_request}: ${defaultProcedure.title}`;
      }

      if (defaultProcedureTitle) {
        if (!bookingIsTentative && isBookingAutoApprove)
          return `${translations.booking_modal_pat_booking}: ${defaultProcedureTitle}`;
        return `${translations.booking_modal_pat_request}: ${defaultProcedureTitle}`;
      }

      if (!bookingIsTentative && isBookingAutoApprove)
        return `${translations.booking_modal_booking_via_muntra}`;
      return `${translations.booking_modal_preliminar_time_booking_req}`;
    },
    [defaultProcedure, defaultProcedureTitle, translations],
  );

  const checkDescription = useCallback(
    (bookingIsTentative, currentClinic, isBookingAutoApprove) => {
      const descriptionDateNotConfirm = formatDate(
        new Date(selectedSlot?.slot?.attributes?.dtstart),
        "H:mm dd MMMM uuuu",
        locale,
      );
      const descriptionDateConfirmHours = formatDate(
        new Date(selectedSlot?.slot?.attributes?.dtstart),
        "k:mm",
      );
      const descriptionDateConfirmDay = formatDate(
        new Date(selectedSlot?.slot?.attributes?.dtstart),
        "d MMMM uuuu",
        locale,
      );

      if (!bookingIsTentative && isBookingAutoApprove)
        return `
        ${translations.booking_modal_hello} 
        ${authState.authUserPersonalId?.data?.attributes?.first_name},\r\n \r\n
        ${translations.booking_modal_welcome_to} ${currentClinic.attributes?.clinic_name}. 
        ${translations.booking_modal_confirmation_for_time_at}. 
        ${descriptionDateConfirmHours} ${translations.booking_modal_the} 
        ${descriptionDateConfirmDay}.\r\n \r\n${translations.booking_modal_sincerely},\r\n
        ${caregiver.role?.attributes?.name} ${caregiver.attributes?.first_name} 
        ${caregiver.attributes?.last_name}\r\n \r\n
      `;

      return `
      ${translations.booking_modal_hello} 
      ${authState.authUserPersonalId?.data?.attributes?.first_name},\r\n \r\n
      ${translations.booking_modal_thanks_for_your_request_to} 
      ${currentClinic.attributes?.clinic_name}. ${currentClinic.attributes?.clinic_name}. 
      ${translations.booking_modal_we_check_availability_at}. ${descriptionDateNotConfirm} 
      ${descriptionDateNotConfirm} ${translations.booking_modal_and_feedback_to_you_sincerely},\r\n
      ${caregiver.role?.attributes?.name} ${caregiver.attributes?.first_name} 
      ${caregiver.attributes?.last_name}\r\n \r\n
    `;
    },
    [authState, caregiver, locale, selectedSlot, translations],
  );

  const checkReferralSource = (referralSource) => {
    if (referralSource) return referralSource;

    const newReferralSource = sessionStorage.getItem("referral_source");
    return newReferralSource || "";
  };

  const bookSlot = useCallback(
    async ({
      email = "",
      firstName = "",
      lastName = "",
      personalId = "",
      phoneNumber = "",
    }) => {
      try {
        setBookingLoader(true);

        const body = {
          data: {
            attributes: {
              booked_by_patient: true,
              class: "PUBLIC",
              description:
                comment ||
                checkDescription(
                  bookingIsTentative,
                  currentClinic,
                  isBookingAutoApprove,
                ),
              dtend: formatHours(selectedSlot.slot?.attributes?.dtend),
              dtstart: formatHours(selectedSlot.slot?.attributes?.dtstart),
              location: `${currentClinic.attributes?.clinic_address_1}, ${currentClinic.attributes?.clinic_postal_code} ${currentClinic.attributes?.clinic_city}`,
              new_patient: true,
              referral_source: checkReferralSource(referralSource),
              status: checkStatus(bookingIsTentative, isBookingAutoApprove),
              summary: checkStatusSummary(
                bookingIsTentative,
                isBookingAutoApprove,
                isSearch,
              ),
              text: "",
              transp: "OPAQUE",
              uid: null,
              first_name: firstName,
              last_name: lastName,
              e_mail_address: email,
              phone_number_cell: phoneNumber,
            },
            relationships: {
              clinic: {
                data: { id: selectedSlot.clinicId, type: "muntra-clinics" },
              },
              organizer: {
                data: { id: selectedSlot.id, type: "muntra-caregivers" },
              },
              procedure: {
                data: {
                  id:
                    defaultProcedureId ||
                    (isSearch
                      ? defaultProcedure[0]?.id || config.defaultProcedureId
                      : defaultProcedure?.id),
                  type: "muntra-procedure",
                },
              },
            },
            type: "muntra-bookings",
          },
          include: "booking_attendees.patient",
        };

        if (bookingRequiresTwoFactorAuthentication) {
          const { data } = await BookingsService.postBooking(body, {}, true);
          const params = (rsvp, patientData, userData) => ({
            data: {
              attributes: { partstat: "ACCEPTED", rsvp },
              relationships: {
                booking: {
                  data: { type: "muntra-bookings", id: data?.data?.id },
                },
                patient: { data: patientData },
                user: { data: userData },
              },
              type: "muntra-booking-attendees",
            },
          });

          const [userReq, patientReq] = await Promise.all([
            BookingAttendeesService.postBookingAttendees(
              params(false, null, {
                type: "muntra-caregivers",
                id: caregiver.id,
              }),
              true,
            ),
            BookingAttendeesService.postBookingAttendees(
              params(
                true,
                {
                  type: "muntra-patients",
                  id: authState.authUserPersonalId?.data?.id,
                },
                null,
              ),
              true,
            ),
          ]);

          if (userReq?.data && patientReq?.data) setStep(3);
        } else {
          const config = {
            params: {
              patient_personal_id: personalId,
            },
          };
          const response = await BookingsService.postBooking(
            body,
            config,
            true,
          );

          if (response) {
            setStep(3);
            setBookingErrors([]);
            setBookingLoader(false);
            setClinicAllowsHealthDeclaration(
              response.data?.data?.attributes?.patient_declaration_allowed,
            );
            const patientBookingAttendee = response.data?.included?.find(
              (item) =>
                item.type === "muntra_booking_attendee" &&
                item.relationships?.patient?.data?.id,
            );
            setBookingExternalBindingId(patientBookingAttendee?.id);
          }
        }

        if (config.env === "production") {
          ReactGA.pageview("booking-modal");
        }
        setBookingLoader(false);

        if (goalPageURLCache) window.location.replace(goalPageURLCache);
      } catch (error) {
        if (error?.response?.status === 422) {
          setBookingErrors(error.response.data.errors);
        }
        setBookingLoader(false);
      }
    },
    [
      authState.authUserPersonalId?.data?.id,
      bookingIsTentative,
      bookingRequiresTwoFactorAuthentication,
      caregiver.id,
      checkDescription,
      checkStatus,
      checkStatusSummary,
      comment,
      currentClinic,
      defaultProcedure,
      defaultProcedureId,
      goalPageURLCache,
      isBookingAutoApprove,
      isSearch,
      referralSource,
      selectedSlot,
    ],
  );

  const getClassName = useCallback(
    (i) => {
      if (step === i) return "";
      return step > i ? "previous" : "next";
    },
    [step],
  );

  return (
    <>
      <Modal
        isBooking
        justifyContent=""
        onClose={onClose}
        style={{ width: "100%" }}
      >
        {global.window.innerWidth >= 994 &&
          step !== 3 &&
          steps &&
          bookingRequiresTwoFactorAuthentication && (
            <Stepper current={step} steps={steps} />
          )}
        <StyledContainer>
          <TransitionStep className={getClassName(0)}>
            <AuthContent
              clinicId={clinicId}
              defaultProcedure={defaultProcedure}
              isBooking
              selectedSlot={slot}
              slotsId={slotsId}
              translations={translations}
            />
          </TransitionStep>
          <TransitionStep className={getClassName(1)}>
            <ProfileContent isModal translations={translations} />
          </TransitionStep>
          <TransitionStep className={getClassName(2)}>
            <ReviewContent
              bookingErrors={bookingErrors}
              bookingIsTentative={bookingIsTentative && modalStep}
              bookingLoader={bookingLoader}
              bookingRequiresTwoFactorAuthentication={
                bookingRequiresTwoFactorAuthentication
              }
              bookSlot={bookSlot}
              defaultProcedure={defaultProcedure}
              translations={translations}
            />
          </TransitionStep>
          <TransitionStep className={getClassName(3)}>
            {!showHealthDeclaration && (
              <ConfirmContent
                bookingIsTentative={bookingIsTentative}
                clinicAllowsHealthDeclaration={
                  !bookingRequiresTwoFactorAuthentication &&
                  clinicAllowsHealthDeclaration
                }
                isMobile
                onClose={onClose}
                setShowHealthDeclaration={setShowHealthDeclaration}
                translations={translations}
              />
            )}
            {showHealthDeclaration && (
              <HealthDeclaration
                bookingExternalBindingId={bookingExternalBindingId}
                onClose={onClose}
                style={{ display: "flex" }}
                translations={translations}
              />
            )}
          </TransitionStep>
        </StyledContainer>
      </Modal>
      {isMobile && authState.loader && (
        <AuthLoader translations={translations} />
      )}
    </>
  );
};

BookingModal.propTypes = {
  bookingIsTentative: PropTypes.bool,
  bookingRequiresTwoFactorAuthentication: PropTypes.bool,
  clinicId: PropTypes.string,
  defaultProcedure: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  defaultProcedureId: PropTypes.string,
  defaultProcedureTitle: PropTypes.string,
  isSearch: PropTypes.bool,
  modalStep: PropTypes.number,
  onClose: PropTypes.func,
  selectedSlot: PropTypes.object,
  slotsId: PropTypes.string,
  translations: PropTypes.object,
};

BookingModal.defaultProps = {
  bookingIsTentative: false,
  defaultProcedure: {},
};

export default memo(BookingModal);
