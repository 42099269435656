import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as PropTypes from "prop-types";
import { PublicPersonDataService } from "services/public-person-data-service";

import { Notifications } from "helpers/notifications";
import { authActions } from "redux/auth/actions";

import {
  Col,
  Heading,
  InputContainer,
  Row,
  StyledInput,
} from "../../common.styled";

import {
  Divider,
  StyledButton,
  StyledContainer,
  StyledText,
  Switch,
  TextError,
} from "./index.styled";

const emailValidator =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ProfileContent = ({ isModal, translations }) => {
  const [values, setValues] = useState({});

  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const onValuesChange = useCallback(
    ({ target }) => {
      if (target.name !== "social_security_number")
        setValues({ ...values, [target.name]: target.value });
    },
    [values],
  );

  const validateEmail = useCallback(() => {
    let error = "";
    if (values.e_mail_address === null || values.e_mail_address.trim() === "") {
      error = `${translations.profile_content_input_email}`;
      setEmailError(error);
      Notifications.message({ message: error });
    } else if (!emailValidator.test(values.e_mail_address)) {
      error = "Email is not valid";
      setEmailError(error);
      Notifications.message({ message: error });
    } else {
      setEmailError(error);
      return error === "";
    }
  }, [translations.profile_content_input_email, values.e_mail_address]);

  const validateFirstName = useCallback(() => {
    let error = "";
    if (values.first_name === null || values.first_name.trim() === "") {
      error = "The first name field is required.";
      setFirstNameError(error);
      Notifications.message({ message: error });
    } else {
      setFirstNameError(error);
      return error === "";
    }
  }, [values.first_name]);

  const validateLastName = useCallback(() => {
    let error = "";
    if (values.last_name === null || values.last_name.trim() === "") {
      error = "The second name field is required.";
      setLastNameError(error);
      Notifications.message({ message: error });
    } else {
      setLastNameError(error);
      return error === "";
    }
  }, [values.last_name]);

  const validateField = useCallback(
    (name) => {
      if (name === "e_mail_address") return validateEmail();
      if (name === "first_name") return validateFirstName();
      if (name === "last_name") return validateLastName();
    },
    [validateEmail, validateFirstName, validateLastName],
  );

  const fetchProfileData = useCallback(
    () => async (ssn) => {
      const result = await PublicPersonDataService.getData({
        params: {
          social_security_number: ssn,
        },
      });
      if (!result) {
        return;
      }

      const publicData = result.data?.data?.attributes;
      const authData = authState?.authUserPersonalId?.data?.attributes;

      if (authData.social_security_number.split("")[10] % 2 !== 0) {
        setValues({ ...authData, ...publicData, gender: "male" });
      } else {
        setValues({ ...authData, ...publicData, gender: "female" });
      }
    },
    [authState?.authUserPersonalId?.data?.attributes],
  );

  const updateProfile = useCallback(
    async (e) => {
      e.preventDefault();
      let isValid = true;
      const formFields = ["e_mail_address", "first_name", "last_name"];
      formFields.forEach((field) => {
        isValid = validateField(field) && isValid;
      });
      if (isValid) {
        dispatch(
          authActions.updateCurrentUser(
            values,
            translations.profile_content_profile_saved,
          ),
        );
      }
    },
    [
      dispatch,
      translations.profile_content_profile_saved,
      validateField,
      values,
    ],
  );

  useEffect(() => {
    if (authState?.error?.data?.errors?.length) {
      authState.error.data.errors.forEach((el) => {
        Notifications.message({ message: el.detail });
      });
    }
  }, [authState.error.data]);

  useEffect(() => {
    if (!authState.authUserPersonalId?.data?.attributes?.e_mail_address) {
      fetchProfileData(
        authState.authUserPersonalId?.data?.attributes?.social_security_number,
      );
    }
    if (authState?.authUserPersonalId?.data) {
      setValues({ ...authState?.authUserPersonalId?.data?.attributes });
    }
  }, [authState.authUserPersonalId?.data, fetchProfileData]);

  const basicProps = {
    onChange: onValuesChange,
    type: "text",
    autoComplete: "off",
  };

  const basicPropsTel = {
    onChange: onValuesChange,
    type: "tel",
    autoComplete: "off",
  };

  return (
    <>
      {!isModal && (
        <Heading
          style={{
            fontSize: "32px",
            margin: "10px 0 32px 0",
            lineHeight: "34px",
          }}
        >
          {translations.profile_content_your_patient_profile}
        </Heading>
      )}
      {isModal && (
        <Heading
          style={{ fontSize: "28px", lineHeight: "34px", textAlign: "left" }}
        >
          {translations.profile_content_your_patient_profile}
        </Heading>
      )}

      <StyledContainer>
        <form onSubmit={updateProfile}>
          <StyledText>{translations.profile_content_email}</StyledText>
          <InputContainer>
            <StyledInput
              placeholder={translations.profile_content_email_placeholder}
              name="e_mail_address"
              value={values.e_mail_address || ""}
              {...basicProps}
            />
          </InputContainer>
          <TextError> {emailError} </TextError>

          <StyledText>{translations.profile_content_cell}</StyledText>
          <InputContainer>
            <StyledInput
              placeholder={translations.profile_content_cell}
              name="phone_number_cell"
              value={values.phone_number_cell || ""}
              {...basicPropsTel}
            />
          </InputContainer>

          <Divider />

          <StyledText>{translations.profile_content_ssn}</StyledText>
          <InputContainer style={{ padding: 0 }}>
            <StyledInput
              style={{ padding: "14px 12px" }}
              placeholder={translations.profile_content_ssn}
              name="social_security_number"
              value={values.social_security_number || ""}
              {...basicProps}
              disabled
            />
          </InputContainer>

          <StyledText>{translations.profile_content_first_name}</StyledText>
          <InputContainer>
            <StyledInput
              placeholder={translations.profile_content_first_name}
              name="first_name"
              value={values.first_name || ""}
              {...basicProps}
            />
          </InputContainer>
          <TextError> {firstNameError} </TextError>

          <StyledText>{translations.profile_content_last_name}</StyledText>
          <InputContainer>
            <StyledInput
              placeholder={translations.profile_content_last_name}
              name="last_name"
              value={values.last_name || ""}
              {...basicProps}
            />
          </InputContainer>
          <TextError> {lastNameError} </TextError>

          <Divider />

          <StyledText>{translations.profile_content_adress}</StyledText>
          <InputContainer>
            <StyledInput
              name="address_1"
              value={values.address_1 || ""}
              {...basicProps}
            />
          </InputContainer>
          <InputContainer>
            <StyledInput
              name="address_2"
              value={values.address_2 || ""}
              {...basicProps}
            />
          </InputContainer>

          <StyledText>
            {translations.profile_content_postal_number_and_city}
          </StyledText>
          <Row>
            <Col xs={6}>
              <InputContainer>
                <StyledInput
                  placeholder={
                    translations.profile_content_postal_number_placeholder
                  }
                  name="postal_code"
                  value={values.postal_code || ""}
                  {...basicProps}
                />
              </InputContainer>
            </Col>
            <Col xs={18}>
              <InputContainer>
                <StyledInput
                  placeholder={translations.profile_content_city_placeholder}
                  name="city"
                  value={values.city || ""}
                  {...basicProps}
                />
              </InputContainer>
            </Col>
          </Row>

          <Divider />

          <StyledText>{translations.profile_content_gender}</StyledText>
          <Switch>
            <input
              onChange={onValuesChange}
              type="radio"
              className="switch-input"
              name="gender"
              value="female"
              id="female"
              checked={values.gender === "female"}
            />
            <label
              style={{ width: "50%" }}
              htmlFor="female"
              className="switch-label switch-label-off"
            >
              {translations.profile_content_female}
            </label>
            <input
              onChange={onValuesChange}
              type="radio"
              className="switch-input"
              name="gender"
              value="male"
              id="male"
              checked={values.gender === "male"}
            />
            <label
              style={{ width: "50%" }}
              htmlFor="male"
              className="switch-label switch-label-on"
            >
              {translations.profile_content_male}
            </label>
            <span style={{ width: "50%" }} className="switch-selection" />
          </Switch>

          <br />
          <StyledButton type="submit" disabled={authState.loader}>
            <span style={{ margin: "0 auto" }}>
              {translations.profile_content_save}
            </span>
          </StyledButton>
        </form>
      </StyledContainer>
    </>
  );
};

ProfileContent.propTypes = {
  isModal: PropTypes.bool,
  translations: PropTypes.object,
};

export default ProfileContent;
