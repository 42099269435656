import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PatientsClinicCurationsService } from "services/patient-clinic-curations-service";
import { PatientsReviewsService } from "services/patient-reviews-service";

import { mobileValidator, scrollToTop } from "helpers/helper-functions";
import { useQuery } from "hooks/use-query";
import { useTranslations } from "hooks/use-translations";
import { reviewActions } from "redux/review/actions";

import { StyledContent, StyledLayout } from "components/common.styled";
import Header from "components/header/index";

import Step1 from "../components/steps/step1";
import Step2 from "../components/steps/step2";
import Step3 from "../components/steps/step3";
import Step4 from "../components/steps/step4";
import Step5 from "../components/steps/step5";
import Step6 from "../components/steps/step6";
import Step7 from "../components/steps/step7";
import Step8 from "../components/steps/step8";
import Step9 from "../components/steps/step9";
import Step10 from "../components/steps/step10";

const PatientReviewNew = () => {
  const routeName = "patient-review.new";
  const translations = useTranslations(routeName);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();

  const caregiverId = query.get("caregiver_id");
  const clinicId = query.get("clinic_id");
  const externalId = query.get("external_id");
  const stepCount = query.get("step_count");

  const [caregiverReview, setCaregiverReview] = useState({
    comparison_to_expectations: null,
    created_date: "Fri Jun 26 2020 03:21:42 GMT+0300",
    external_id: externalId,
    extra_special_amazing_amenities: false,
    extra_special_excellent_quality: false,
    extra_special_fast_and_painless: false,
    extra_special_no_waiting_time: false,
    extra_special_outstanding_treatment: false,
    extra_special_phenomenal_information: false,
    extra_special_useful_practical_tips: false,
    info_rating_advice_and_tips: null,
    info_rating_follow_up: null,
    info_rating_how_to_find_place: null,
    info_rating_patient_health: null,
    info_rating_patient_treatment: null,
    info_rating_pre_meeting: null,
    info_rating_pricing: null,
    overall_rating: null,
    patient_name: null,
    private_comment_to_caregiver: null,
    rating_bedside_manner: null,
    rating_hygiene: null,
    rating_quality: null,
    rating_reception_on_arrival: null,
    rating_thoroughness: null,
    rating_wait_time: null,
    review_comment: "",
  });

  const [clinicReview, setClinicReview] = useState({
    accepts_children: null,
    accessible_via_elevator: null,
    accessible_via_stairs: null,
    capable_of_handling_fear_of_medical_procedures: null,
    created_date: "Fri Jun 26 2020 04:02:02 GMT+0300",
    external_id: externalId,
    handicap_accessible: null,
    has_drinks: null,
    has_free_parking_directly_outside_of_clinic: null,
    has_free_parking_near_the_clinic: null,
    has_magazines: null,
    has_music: null,
    has_paid_parking_directly_outside_of_clinic: null,
    has_paid_parking_near_the_clinic: null,
    has_restroom: null,
    has_toys: null,
    has_tv: null,
    has_waiting_room: null,
    has_wifi: null,
  });

  const [id, setId] = useState(undefined);
  const [idClinic, setIdClinic] = useState(undefined);
  const [step, setStep] = useState(1);

  const isMobile = mobileValidator.test(window.navigator.userAgent);

  const handleCaregiverReviewUpdate = useCallback((updatedFields) => {
    setCaregiverReview((prev) => ({ ...prev, ...updatedFields }));
  }, []);

  const handleClinicReviewUpdate = useCallback((updatedFields) => {
    setClinicReview((prev) => ({ ...prev, ...updatedFields }));
  }, []);

  const sendCaregiverReview = useCallback(async () => {
    const body = {
      data: {
        attributes: { ...caregiverReview },
        relationships: {
          caregiver: {
            data: { type: "muntra-caregivers", id: caregiverId },
          },
          clinic: {
            data: { type: "muntra-clinics", id: clinicId },
          },
        },
        type: "muntra-patient-reviews",
      },
    };

    if (id) {
      body.data.id = id;
      return PatientsReviewsService.patchReview(id, body);
    }

    const res = await PatientsReviewsService.postReview(body);

    if (res?.data) {
      setCaregiverReview({
        ...caregiverReview,
        patient_name: res.data?.data?.attributes?.patient_name,
      });
      setId(res.data?.data?.id);
      return res;
    }
  }, [caregiverId, caregiverReview, clinicId, id]);

  const sendClinicReview = useCallback(async () => {
    const body = {
      data: {
        attributes: { ...clinicReview },
        relationships: {
          clinic: {
            data: { type: "muntra-clinics", id: clinicId },
          },
        },
        type: "muntra-patient-clinic-curations",
      },
    };

    if (idClinic) {
      body.data.id = idClinic;
      return PatientsClinicCurationsService.patchCurations(idClinic, body);
    }
    const res = await PatientsClinicCurationsService.postCurations(body);
    if (res?.data) {
      setClinicReview({
        ...clinicReview,
        external_id: res.data.data?.attributes?.external_id,
      });
      setIdClinic(res.data.data?.id);
      return res;
    }

    if (id) {
      const result = await PatientsClinicCurationsService.patchCurations(
        id,
        body,
      );

      return result?.data;
    }
  }, [clinicId, clinicReview, id, idClinic]);

  const changeStep = useCallback(
    async (value) => {
      if ([2, 3, 4, 5, 6, 7].includes(value) && value > step) {
        const success = await sendCaregiverReview();
        if (!success) return;
      }

      if ([9, 10, 11].includes(value) && value > step) {
        const success = await sendClinicReview();
        if (!success) return;
      }

      if (value === 11) {
        history.push("/patient-review/confirmation");
        return;
      }
      scrollToTop();
      setStep(value);
      history.push({
        pathname: location.pathname,
        search: `?caregiver_id=${caregiverId}&clinic_id=${clinicId}&external_id=${externalId}&step_count=${value}`,
      });
    },
    [
      caregiverId,
      clinicId,
      externalId,
      history,
      location.pathname,
      sendCaregiverReview,
      sendClinicReview,
      step,
    ],
  );

  useEffect(() => {
    if (stepCount && +stepCount !== +step) setStep(+stepCount);
  }, [step, stepCount]);

  useEffect(() => {
    if (caregiverId) dispatch(reviewActions.reviewFetchCaregiver(caregiverId));
  }, [caregiverId, dispatch]);

  useEffect(() => {
    if (externalId) {
      setCaregiverReview((prev) => ({ ...prev, external_id: externalId }));
      setClinicReview((prev) => ({ ...prev, external_id: externalId }));
    }
  }, [externalId]);

  return (
    <StyledLayout>
      <Helmet>
        <title>{`${translations.meta_title_patient_review} | ${translations.meta_title_default}`}</title>
      </Helmet>
      <Header
        hideMenuOptions
        isMobile={isMobile}
        isReview
        isStartPage
        translations={translations}
      />
      <StyledContent
        isLessMargin
        isPadding={step !== 1}
        style={{
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          marginBottom: "100px",
          padding: "25px",
          position: "relative",
        }}
      >
        {step === 1 && translations?.length && (
          <Step1
            step={step}
            translations={translations}
            changeStep={changeStep}
            caregiverReview={caregiverReview}
            handleCaregiverReviewUpdate={handleCaregiverReviewUpdate}
          />
        )}
        {step === 2 && translations?.length && (
          <Step2
            step={step}
            translations={translations}
            changeStep={changeStep}
            caregiverReview={caregiverReview}
            handleCaregiverReviewUpdate={handleCaregiverReviewUpdate}
          />
        )}
        {step === 3 && translations?.length && (
          <Step3
            step={step}
            translations={translations}
            changeStep={changeStep}
            caregiverReview={caregiverReview}
            handleCaregiverReviewUpdate={handleCaregiverReviewUpdate}
          />
        )}
        {step === 4 && translations?.length && (
          <Step4
            step={step}
            translations={translations}
            changeStep={changeStep}
            caregiverReview={caregiverReview}
            handleCaregiverReviewUpdate={handleCaregiverReviewUpdate}
          />
        )}
        {step === 5 && translations?.length && (
          <Step5
            step={step}
            translations={translations}
            changeStep={changeStep}
            caregiverReview={caregiverReview}
            handleCaregiverReviewUpdate={handleCaregiverReviewUpdate}
          />
        )}
        {step === 6 && translations?.length && (
          <Step6
            step={step}
            translations={translations}
            changeStep={changeStep}
            caregiverReview={caregiverReview}
            handleCaregiverReviewUpdate={handleCaregiverReviewUpdate}
          />
        )}
        {step === 7 && translations?.length && (
          <Step7
            step={step}
            translations={translations}
            changeStep={changeStep}
          />
        )}
        {step === 8 && translations?.length && (
          <Step8
            step={step}
            translations={translations}
            changeStep={changeStep}
            clinicReview={clinicReview}
            handleClinicReviewUpdate={handleClinicReviewUpdate}
          />
        )}
        {step === 9 && translations?.length && (
          <Step9
            step={step}
            translations={translations}
            changeStep={changeStep}
            clinicReview={clinicReview}
            handleClinicReviewUpdate={handleClinicReviewUpdate}
          />
        )}
        {step === 10 && translations?.length && (
          <Step10
            step={step}
            translations={translations}
            changeStep={changeStep}
            clinicReview={clinicReview}
            handleClinicReviewUpdate={handleClinicReviewUpdate}
          />
        )}
      </StyledContent>
    </StyledLayout>
  );
};

export default PatientReviewNew;
