import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import * as PropTypes from "prop-types";

import CloseIcon from "../../../assets/svg-components/CloseIcon";
import LeftArrowSVG from "../../../assets/svg-components/LeftArrow";

import {
  BackButton,
  CloseButton,
  Dialog,
  DialogAvatar,
  DialogBooking,
  DialogClinicImages,
  DialogMobile,
  ModalContainer,
  ModalRoot,
  Overlay,
} from "./index.styled";

export const Context = React.createContext();

export const ModalProvider = ({ children }) => {
  const modalRef = useRef();
  const [context, setContext] = useState();

  useEffect(() => {
    setContext(modalRef.current);
  }, []);

  return (
    <ModalRoot>
      <Context.Provider value={context}>{children}</Context.Provider>
      <ModalContainer ref={modalRef} />
    </ModalRoot>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const Modal = ({
  children,
  isAvatar,
  isBooking,
  isClinicImages,
  isMobileSearchModal,
  justifyContent,
  onClose,
  showHealthDeclaration,
  hideCloseArrow,
  ...props
}) => {
  const modalNode = useContext(Context);

  const outerClick = (e) => {
    if (e.target.id === "overlay") return onClose();
  };

  useEffect(() => {
    let url = window.location.href;
    document.body.addEventListener(
      "click",
      () => {
        requestAnimationFrame(() => {
          if (url !== window.location.href) {
            onClose();
            url = window.location.href;
          }
        });
      },
      true,
    );
  }, [onClose]);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  return modalNode
    ? ReactDOM.createPortal(
        <Overlay id="overlay" onClick={outerClick}>
          {!showHealthDeclaration && !isMobileSearchModal && (
            <CloseButton style={{ zIndex: "101" }} onClick={onClose}>
              <CloseIcon />
            </CloseButton>
          )}

          {!isClinicImages &&
            !isAvatar &&
            !isBooking &&
            global.window.innerWidth >= 994 && (
              <Dialog {...props}>{children}</Dialog>
            )}
          {!isClinicImages &&
            !isAvatar &&
            isBooking &&
            global.window.innerWidth >= 994 && (
              <DialogBooking {...props}>
                <div
                  style={{
                    margin: "0px !important",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent,
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {children}
                </div>
              </DialogBooking>
            )}
          {!isClinicImages && !isAvatar && global.window.innerWidth < 994 && (
            <DialogMobile
              className={`${
                isMobileSearchModal ? "MobileSearchModal NoPadding" : ""
              }${showHealthDeclaration ? "MobileSearchModal NoPadding" : ""}`}
              {...props}
            >
              {children}
              {!isMobileSearchModal && !isBooking && !hideCloseArrow && (
                <BackButton
                  className="LeftArrowSVGMobileSeachModal"
                  onClick={onClose}
                >
                  <LeftArrowSVG />
                </BackButton>
              )}
            </DialogMobile>
          )}
          {!isClinicImages && isAvatar && !isBooking && (
            <DialogAvatar style={{ padding: "10px 10px 5px" }} {...props}>
              {children}
            </DialogAvatar>
          )}
          {isClinicImages && !isAvatar && !isBooking && (
            <DialogClinicImages {...props}>
              {children}
              {!showHealthDeclaration && !isMobileSearchModal && (
                <CloseButton onClick={onClose}>
                  <CloseIcon />
                </CloseButton>
              )}
            </DialogClinicImages>
          )}
        </Overlay>,
        modalNode,
      )
    : null;
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isAvatar: PropTypes.bool,
  isBooking: PropTypes.bool,
  isClinicImages: PropTypes.bool,
  isMobileSearchModal: PropTypes.bool,
  justifyContent: PropTypes.string,
  onClose: PropTypes.func,
  showHealthDeclaration: PropTypes.bool,
  hideCloseArrow: PropTypes.bool,
};

Modal.defaultProps = {
  justifyContent: "center",
};
