import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class BookingAttendeesService extends BaseService {
  constructor(url) {
    super(url);
    this.getBookingAttendees = this.getBookingAttendees.bind(this);
    this.getBookingDetails = this.getBookingDetails.bind(this);
    this.postBookingAttendees = this.postBookingAttendees.bind(this);
    this.confirmBookingAttendees = this.confirmBookingAttendees.bind(this);
    this.rescheduleBookingAttendees =
      this.rescheduleBookingAttendees.bind(this);
  }

  async getBookingAttendees(params) {
    try {
      const response = await this.agent.get("", params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async getBookingDetails(id, params) {
    try {
      const response = await this.agent.get(`/${id}`, params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async postBookingAttendees(data, throwError) {
    try {
      const response = await this.agent.post("", data);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      if (throwError) {
        throw error;
      } else return null;
    }
  }

  async confirmBookingAttendees(data) {
    try {
      const response = await this.agent.post("/confirm", data);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async rescheduleBookingAttendees(id, config) {
    try {
      const response = await this.agent.post(`/${id}/reschedule`, {}, config);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new BookingAttendeesService(
  API_ROUTES.bookingAttendees,
);

export default ServiceInstance;
export { ServiceInstance as BookingAttendeesService };
