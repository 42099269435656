import { all, call, put, takeEvery } from "redux-saga/effects";
import { BookingAttendeesService } from "services/booking-attendees-service";
import { BookingsService } from "services/bookings-service";
import { PatientInvoicesService } from "services/patient-invoices-service";

import { formatDate } from "../../helpers/helper-functions";
import { bookingActions as actions } from "./actions";

function* fetchComingBookings() {
  const dataConfig = {
    params: {
      include:
        "booking_policy,booking_attendees.patient,booking_attendees.user.default_user_image,booking_attendees.user.role,clinic.default_clinic_image",
      order: "asc",
      per_page: 12,
      sort_by: "dtstart",
      status: "CONFIRMED,TENTATIVE",
      from_date: formatDate(new Date()),
    },
  };
  const result = yield call(BookingsService.getBookings, dataConfig);
  if (!result) {
    yield put(actions.fetchComingBookingsListError());
    return;
  }
  yield put(actions.fetchComingBookingsListSuccess(result.data));
}

function* fetchLastBookings({ payload: page }) {
  const dataConfig = {
    params: {
      include:
        "booking_attendees.patient,booking_attendees.user.default_user_image,booking_attendees.user.role,clinic.default_clinic_image",
      order: "desc",
      page,
      per_page: 12,
      sort_by: "dtstart",
      status: "CONFIRMED,TENTATIVE",
      to_date: formatDate(new Date()),
    },
  };
  const result = yield call(BookingsService.getBookings, dataConfig);
  if (!result) {
    yield put(actions.fetchLastBookingsListError());
    return;
  }
  yield put(actions.fetchLastBookingsListSuccess(result.data));
}

function* fetchInvoices({ payload: page }) {
  const dataConfig = {
    params: {
      include: "currency,deductions,patient_invoice_rows.vat_code",
      order: "desc",
      page,
      per_page: 10,
      sort_by: "invoice_date",
    },
  };
  const result = yield call(PatientInvoicesService.getInvoices, dataConfig);
  if (!result) {
    yield put(actions.fetchInvoicesListError());
    return;
  }
  yield put(actions.fetchInvoicesListSuccess(result.data));
}

function* fetchInvoiceById({ payload: id }) {
  const dataConfig = {
    params: {
      include: "currency,deductions,patient_invoice_rows.vat_code",
    },
  };
  const result = yield call(PatientInvoicesService.getInvoice, id, dataConfig);
  if (!result) {
    yield put(actions.fetchInvoiceByIdError());
    return;
  }
  yield put(actions.fetchInvoiceByIdSuccess(result.data));
}

function* fetchBookingPatientResponse({ payload: id }) {
  const dataConfig = {
    data: {
      attributes: {
        external_id: id,
      },
      type: "muntra-booking-attendee-confirms",
    },
  };
  const result = yield call(
    BookingAttendeesService.confirmBookingAttendees,
    dataConfig,
  );
  if (!result) {
    yield put(actions.fetchBookingPatientResponseError());
    return;
  }

  yield put(
    actions.fetchBookingPatientResponseSuccess(result.data?.data?.attributes),
  );
}

function* fetchBookingDetails({ payload: id }) {
  const dataConfig = {
    params: {
      include: "booking.clinic,booking.procedure,booking.booking_policy",
    },
  };
  const result = yield call(
    BookingAttendeesService.getBookingDetails,
    id,
    dataConfig,
  );
  if (!result) {
    yield put(actions.fetchBookingDetailsError());
    return;
  }
  yield put(actions.fetchBookingDetailsSuccess(result.data));
}

function* fetchBookingAttendees({ payload: id }) {
  const dataConfig = {
    params: {
      booking_id: id,
      include: "user.caregiver_locations,user.role",
      only_users: true,
    },
  };
  const result = yield call(
    BookingAttendeesService.getBookingAttendees,
    dataConfig,
  );
  if (!result) {
    yield put(actions.fetchBookingAttendeesError());
    return;
  }

  yield put(actions.fetchBookingAttendeesSuccess(result.data));
}

export default function* bookingSagas() {
  yield all([
    takeEvery(actions.BOOKINGS_COMING_FETCH, fetchComingBookings),
    takeEvery(actions.BOOKINGS_LAST_FETCH, fetchLastBookings),
    takeEvery(actions.BOOKING_INVOICES_FETCH, fetchInvoices),
    takeEvery(actions.BOOKING_INVOICE_FETCH_BY_ID, fetchInvoiceById),
    takeEvery(actions.BOOKING_PATIENT_RESPONSE, fetchBookingPatientResponse),
    takeEvery(actions.BOOKING_DETAILS_FETCH, fetchBookingDetails),
    takeEvery(actions.BOOKING_ATTENDEES_FETCH, fetchBookingAttendees),
  ]);
}
