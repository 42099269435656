import React, { memo, useCallback, useEffect, useState } from "react";
import * as PropTypes from "prop-types";

import { INPUTS_NAMES, REQUIRED_BOOKING_FIELDS } from "constants/booking";

import { InputContainer, StyledInput } from "../../../../common.styled";

import {
  EmailInputTransition,
  NameInputTransition,
  PhoneInputTransition,
} from "./index.styled";

const MissingBookingFields = ({
  bookingErrors,
  missedValues,
  setMissedValues,
  handleBlur,
  callingCode,
  translations,
}) => {
  const [showFields, setShowFields] = useState({});

  useEffect(() => {
    const values = {};
    const fields = {
      fullName: false,
      email: false,
      phoneNumber: false,
    };

    bookingErrors.forEach((item) => {
      const field = item?.source?.pointer?.split("/").pop() || "";
      switch (field) {
        case REQUIRED_BOOKING_FIELDS.email:
          fields.email = true;
          values.email = "";
          break;
        case REQUIRED_BOOKING_FIELDS.fullName:
          fields.fullName = true;
          values.firstName = "";
          values.lastName = "";
          break;

        case REQUIRED_BOOKING_FIELDS.phoneNumber:
          fields.phoneNumber = true;
          values.phoneNumber = "";
          break;
        default:
      }
    });

    setMissedValues(values);
    setShowFields(fields);
  }, [bookingErrors, setMissedValues]);

  const handleChangeValue = useCallback(
    (target, key) => {
      if (key === INPUTS_NAMES.phoneNumber) {
        setMissedValues((state) => ({
          ...state,
          [key]: target.value
            .replace(/[^+|\d]/gm, ``)
            .replace(/^0/gm, `${callingCode}`),
        }));
      } else {
        setMissedValues((state) => ({ ...state, [key]: target.value }));
      }
    },
    [callingCode, setMissedValues],
  );

  const getTransitionClass = useCallback((error) => {
    return error ? "visible" : "invisible";
  }, []);

  return (
    <>
      <NameInputTransition className={getTransitionClass(showFields.fullName)}>
        <InputContainer style={{ maxWidth: "300px" }}>
          <StyledInput
            name="firstName"
            onBlur={handleBlur}
            onChange={({ target }) =>
              handleChangeValue(target, INPUTS_NAMES.firstName)
            }
            placeholder={translations.review_content_simple_first_name}
            type="name"
            value={missedValues.firstName || ""}
          />
        </InputContainer>
        <br />
        <InputContainer style={{ maxWidth: "300px" }}>
          <StyledInput
            name="lastName"
            onBlur={handleBlur}
            onChange={({ target }) =>
              handleChangeValue(target, INPUTS_NAMES.lastName)
            }
            placeholder={translations.review_content_simple_last_name}
            type="name"
            value={missedValues.lastName || ""}
          />
        </InputContainer>
      </NameInputTransition>
      <EmailInputTransition className={getTransitionClass(showFields.email)}>
        <InputContainer style={{ maxWidth: "300px" }}>
          <StyledInput
            name="email"
            onBlur={handleBlur}
            onChange={({ target }) =>
              handleChangeValue(target, INPUTS_NAMES.email)
            }
            placeholder={translations.review_content_simple_email_example}
            type="email"
            value={missedValues.email || ""}
          />
        </InputContainer>
      </EmailInputTransition>
      <PhoneInputTransition
        className={getTransitionClass(showFields.phoneNumber)}
      >
        <InputContainer style={{ maxWidth: "300px" }}>
          <StyledInput
            name="phoneNumber"
            onBlur={handleBlur}
            onChange={({ target }) =>
              handleChangeValue(target, INPUTS_NAMES.phoneNumber)
            }
            placeholder={translations.review_content_simple_cell_num_example}
            type="tel"
            value={missedValues.phoneNumber || ""}
          />
        </InputContainer>
      </PhoneInputTransition>
    </>
  );
};

MissingBookingFields.propTypes = {
  bookingErrors: PropTypes.array,
  callingCode: PropTypes.string,
  handleBlur: PropTypes.func,
  missedValues: PropTypes.object,
  setMissedValues: PropTypes.func,
  translations: PropTypes.object,
};

MissingBookingFields.defaultProps = {
  bookingErrors: [],
  missedValues: {},
};

export default memo(MissingBookingFields);
