import React from "react";
import * as PropTypes from "prop-types";

const Star = ({ onClick, uniqueId, size, offset }) => {
  return (
    <svg
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : "initial" }}
      width={size}
      height={size}
      viewBox="0 0 26 26"
    >
      <defs>
        <linearGradient id={uniqueId} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset={`${offset}%`} stopColor="#F46875" />
          <stop offset="0%" stopColor="lightgrey" />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${uniqueId})`}
        d="M25.326,10.137c-0.117-0.361-0.431-0.625-0.807-0.68l-7.34-1.066l-3.283-6.651 c-0.337-0.683-1.456-0.683-1.793,0L8.82,8.391L1.48,9.457c-0.376,0.055-0.689,0.318-0.807,0.68c-0.117,0.363-0.02,0.76,0.253,1.025 l5.312,5.178l-1.254,7.31c-0.064,0.375,0.09,0.755,0.397,0.978c0.309,0.225,0.717,0.254,1.054,0.076L13,21.252l6.564,3.451 c0.146,0.077,0.307,0.115,0.466,0.115c0.207,0,0.413-0.064,0.588-0.191c0.308-0.223,0.462-0.603,0.397-0.978l-1.254-7.31 l5.312-5.178C25.346,10.896,25.443,10.5,25.326,10.137z"
      />
    </svg>
  );
};

Star.propTypes = {
  onClick: PropTypes.func,
  uniqueId: PropTypes.string,
  size: PropTypes.number,
  offset: PropTypes.number,
};

Star.defaultProps = {
  onClick: () => {},
  uniqueId: "",
  size: 20,
};

export default Star;
