import React, { memo, useContext } from "react";
import ReactDOM from "react-dom";
import * as PropTypes from "prop-types";

import BandIdSvg from "../../../assets/svg-components/BankID";
import { Context } from "../modal";

import { Spinner } from "../spinner/index.styled";
import { Background, ContentWrapper } from "./index.styled";

const AuthLoader = ({ translations }) => {
  const modalNode = useContext(Context);

  return modalNode
    ? ReactDOM.createPortal(
        <Background>
          <ContentWrapper>
            <BandIdSvg style={{ height: 60, marginBottom: 20 }} />
            <h1>{translations.auth_loader_open_auth_software}</h1>
            <p>{translations.auth_loader_how_to_use_auth_software}</p>
            <Spinner />
          </ContentWrapper>
        </Background>,
        modalNode,
      )
    : null;
};

AuthLoader.propTypes = {
  translations: PropTypes.object,
};

export default memo(AuthLoader);
