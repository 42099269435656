const prefix = "[app]";

const TYPES = {
  COUNTRY_REFRESH: `${prefix} COUNTRY_REFRESH`,
  GET_CURRENT_LOCALE: `${prefix} GET_CURRENT_LOCALE`,
  GET_CURRENT_LOCALE_ERROR: `${prefix} GET_CURRENT_LOCALE_ERROR`,
  GET_CURRENT_LOCALE_SUCCESS: `${prefix} GET_CURRENT_LOCALE_SUCCESS`,
  GET_SPECIFIC_LOCALE: `${prefix} GET_SPECIFIC_LOCALE`,
  GET_SPECIFIC_LOCALE_ERROR: `${prefix} GET_SPECIFIC_LOCALE_ERROR`,
  GET_SPECIFIC_LOCALE_SUCCESS: `${prefix} GET_SPECIFIC_LOCALE_SUCCESS`,
  SEND_ERROR_REPORT: `${prefix} SEND_ERROR_REPORT`,
  SEND_ERROR_REPORT_ERROR: `${prefix} SEND_ERROR_REPORT_ERROR`,
  SEND_ERROR_REPORT_SUCCESS: `${prefix} SEND_ERROR_REPORT_SUCCESS`,
  SEND_REVIEW_REQUEST_EMAIL_SETTINGS: `${prefix} SEND_REVIEW_REQUEST_EMAIL_SETTINGS`,
  SEND_REVIEW_REQUEST_EMAIL_SETTINGS_ERROR: `${prefix} SEND_REVIEW_REQUEST_EMAIL_SETTINGS_ERROR`,
  SEND_REVIEW_REQUEST_EMAIL_SETTINGS_SUCCESS: `${prefix} SEND_REVIEW_REQUEST_EMAIL_SETTINGS_SUCCESS`,
};

export const appActions = {
  ...TYPES,
  countryRefresh: (data) => ({
    type: TYPES.COUNTRY_REFRESH,
    payload: data,
  }),
  getCurrentLocale: (data) => ({
    type: TYPES.GET_CURRENT_LOCALE,
    payload: data,
  }),
  getCurrentLocaleSuccess: (locale) => ({
    type: TYPES.GET_CURRENT_LOCALE_SUCCESS,
    payload: locale,
  }),
  getCurrentLocaleError: (error) => ({
    type: TYPES.GET_CURRENT_LOCALE_ERROR,
    payload: error,
  }),
  getSpecificLocale: (data) => ({
    type: TYPES.GET_SPECIFIC_LOCALE,
    payload: data,
  }),
  getSpecificLocaleSuccess: (locale) => ({
    type: TYPES.GET_SPECIFIC_LOCALE_SUCCESS,
    payload: locale,
  }),
  getSpecificLocaleError: (error) => ({
    type: TYPES.GET_SPECIFIC_LOCALE_ERROR,
    payload: error,
  }),
  sendErrorReport: (data) => ({
    type: TYPES.SEND_ERROR_REPORT,
    payload: data,
  }),
  sendErrorReportSuccess: (res) => ({
    type: TYPES.SEND_ERROR_REPORT_SUCCESS,
    payload: res,
  }),
  sendErrorReportError: (error) => ({
    type: TYPES.SEND_ERROR_REPORT_ERROR,
    payload: error,
  }),
  sendReviewRequestEmailSettings: (data) => ({
    type: TYPES.SEND_REVIEW_REQUEST_EMAIL_SETTINGS,
    payload: data,
  }),
  sendReviewRequestEmailSettingsSuccess: (data) => ({
    type: TYPES.SEND_REVIEW_REQUEST_EMAIL_SETTINGS_SUCCESS,
    payload: data,
  }),
  sendReviewRequestEmailSettingsError: (error) => ({
    type: TYPES.SEND_REVIEW_REQUEST_EMAIL_SETTINGS_ERROR,
    payload: error,
  }),
};
