import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as PropTypes from "prop-types";

import config from "config";
import { createQrData } from "helpers/auth";
import { mobileValidator } from "helpers/helper-functions";
import { authActions } from "redux/auth/actions";

import QrCode from "components/qr-code";

import { Heading, StyledRow, ThirdHeading } from "../../common.styled";
import { StretchButton } from "../../common/button";

const AuthContent = ({
  clinicId,
  defaultProcedure,
  isBooking,
  isRegister,
  selectedSlot,
  setIsFormSubmitted,
  slotsId,
  translations,
}) => {
  const [qrCodeText, setQrCodeText] = useState("");
  const [timeCounterInSeconds, setTimeCounterInSeconds] = useState(0);
  const intervalRef = useRef(null);

  const dispatch = useDispatch();

  const authState = useSelector((state) => state.auth);

  const { start_token: startToken, start_secret: startSecret } =
    authState.autoStartToken?.attributes || {};

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const isMobile = mobileValidator.test(window.navigator.userAgent);
      if (!isMobile) {
        const formData = new FormData();
        formData.append("grant_type", "password");
        formData.append("guard", "start");

        dispatch(authActions.userSignInQrCode(formData));
      } else {
        const pathName = window.location.pathname;
        const procedure = {
          id: config.defaultProcedureId,
          title: config.defaultProcedureTitle,
        };
        const defProcedure =
          defaultProcedure?.length !== 0 ? defaultProcedure : procedure;
        dispatch(
          authActions.userSignInMobile({
            clinicId,
            defProcedure,
            isRegister,
            pathName,
            selectedSlot,
            slotsId,
          }),
        );
      }
      if (setIsFormSubmitted) setIsFormSubmitted(true);
    },
    [
      clinicId,
      defaultProcedure,
      dispatch,
      isRegister,
      selectedSlot,
      setIsFormSubmitted,
      slotsId,
    ],
  );

  const clearAndResetQrCode = useCallback(() => {
    clearInterval(intervalRef.current);
    dispatch(authActions.autoStartTokenRefresh({}));
    setTimeCounterInSeconds(0);
    setQrCodeText("");
  }, [dispatch]);

  const createAndStartInterval = useCallback(async () => {
    const initialResult = await createQrData({
      startToken,
      startSecret,
      timeCounterInSeconds,
    });
    setQrCodeText(initialResult.join("."));
  }, [startSecret, startToken, timeCounterInSeconds]);

  useEffect(() => {
    if (!authState.loader) {
      clearAndResetQrCode();
      return;
    }

    if (startToken && startSecret) {
      createAndStartInterval();
      setTimeout(() => setTimeCounterInSeconds(timeCounterInSeconds + 1), 1500);
    }
  }, [
    authState.loader,
    clearAndResetQrCode,
    createAndStartInterval,
    dispatch,
    startSecret,
    startToken,
    timeCounterInSeconds,
  ]);

  if (global.innerWidth >= 994)
    return (
      <>
        {isBooking && (
          <Heading
            style={{
              fontSize: "28px",
              lineHeight: "30px",
              textAlign: "left",
            }}
          >
            {translations.auth_content_login}
          </Heading>
        )}
        <div style={{ maxWidth: "250px", margin: "0 auto", padding: "20px 0" }}>
          {!isBooking && <Heading>{translations.auth_content_login}</Heading>}
          {!!qrCodeText && (
            <>
              {translations.auth_launch_bank_id_to_scan_qr_code}{" "}
              <StyledRow>
                <QrCode text={qrCodeText} />
              </StyledRow>
            </>
          )}
          <br />
          <br />
          {!qrCodeText && (
            <StretchButton
              disabled={authState.loader}
              style={{ marginTop: 0, width: "100%", maxWidth: "250px" }}
              onClick={handleSubmit}
            >
              <span style={{ fontWeight: "700", margin: "0 auto" }}>
                {" "}
                {translations.auth_content_login}{" "}
              </span>{" "}
            </StretchButton>
          )}
        </div>
      </>
    );

  return (
    <div style={{ marginTop: "50px" }}>
      <ThirdHeading style={{ fontSize: "30px" }}>
        <b>{translations.auth_content_login}</b>
      </ThirdHeading>
      <br />
      <br />
      <StretchButton
        disabled={authState.loader}
        style={{ marginTop: 0, display: "flex" }}
        onClick={handleSubmit}
      >
        <span style={{ fontWeight: "700", margin: "0 auto" }}>
          {" "}
          {translations.auth_content_login}{" "}
        </span>
      </StretchButton>
    </div>
  );
};

AuthContent.propTypes = {
  clinicId: PropTypes.string,
  defaultProcedure: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  isBooking: PropTypes.bool,
  isRegister: PropTypes.bool,
  selectedSlot: PropTypes.object,
  setIsFormSubmitted: PropTypes.func,
  slotsId: PropTypes.string,
  translations: PropTypes.object,
};

AuthContent.defaultProps = {
  defaultProcedure: [],
  selectedSlot: {},
};

export default memo(AuthContent);
