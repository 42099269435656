import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class AcceptsReviewEmailService extends BaseService {
  constructor(url) {
    super(url);
    this.sendReview = this.sendReview.bind(this);
  }

  async sendReview(data = {}) {
    try {
      const response = await this.agent.post("", data);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new AcceptsReviewEmailService(
  API_ROUTES.acceptsReviewRequestsEmailSettings,
);

export default ServiceInstance;
export { ServiceInstance as AcceptsReviewEmailService };
