import React, { memo, useCallback } from "react";
import * as PropTypes from "prop-types";

import SmileyHappy from "assets/svg-components/smileys/SmileyHappy";
import SmileyNeutral from "assets/svg-components/smileys/SmileyNeutral";
import SmileySad from "assets/svg-components/smileys/SmileySad";

const HealthStateStep = ({
  healthDeclarationObject,
  noMargin = false,
  setHealthDeclarationObject,
  stepSetter,
  translations,
}) => {
  const setStepAndHealthDeclaration = useCallback(
    (patientHealthEvaluationValue) => {
      setHealthDeclarationObject({
        ...healthDeclarationObject,
        patient_health_evaluation: patientHealthEvaluationValue,
      });
      stepSetter();
    },
    [healthDeclarationObject, setHealthDeclarationObject, stepSetter],
  );

  return (
    <div
      className={`${noMargin ? "noMargin" : ""}`}
      style={{ marginRight: "30px", marginLeft: "30px" }}
    >
      <h2
        className="textCenter bookingHeader"
        style={{ marginBottom: "1.5em" }}
      >
        {translations.health_declaration_patient_health_question}
      </h2>
      <div className="HealthDeclarationSmileySVGContainer flexColStart">
        <div
          className="flexRowCenter HealthDeclarationSmileyDiv"
          onClick={() =>
            setStepAndHealthDeclaration(
              translations.health_declaration_patient_health_bad,
            )
          }
          role="button"
          style={{ marginBottom: "20px" }}
        >
          <SmileySad />
          <label className="HealthDeclarationSmileyLabel">
            {translations.health_declaration_patient_health_bad_label}
          </label>
        </div>
        <div
          className="flexRowCenter HealthDeclarationSmileyDiv"
          onClick={() =>
            setStepAndHealthDeclaration(
              translations.health_declaration_patient_health_average,
            )
          }
          role="button"
          style={{ marginBottom: "20px" }}
        >
          <SmileyNeutral />
          <label className="HealthDeclarationSmileyLabel">
            {translations.health_declaration_patient_health_average_label}
          </label>
        </div>
        <div
          className="flexRowCenter HealthDeclarationSmileyDiv"
          onClick={() =>
            setStepAndHealthDeclaration(
              translations.health_declaration_patient_health_good,
            )
          }
          role="button"
          style={{ marginBottom: "20px" }}
        >
          <SmileyHappy />
          <label className="HealthDeclarationSmileyLabel">
            {translations.health_declaration_patient_health_good_label}
          </label>
        </div>
      </div>
    </div>
  );
};

HealthStateStep.propTypes = {
  healthDeclarationObject: PropTypes.object,
  noMargin: PropTypes.bool,
  setHealthDeclarationObject: PropTypes.func,
  stepSetter: PropTypes.func,
  translations: PropTypes.object,
};

HealthStateStep.defaultProps = {
  noMargin: false,
};

export default memo(HealthStateStep);
