import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectTag } from "redux/app/selectors";
import { translationsActions } from "redux/translations/actions";
import { selectTranslationsByRoute } from "redux/translations/selectors";

export const useTranslations = (routeName) => {
  const dispatch = useDispatch();
  const tag = useSelector(selectTag);
  const prevTag = useRef(tag);
  const routeKey = `${routeName}-${tag}`;
  const translation = useSelector(selectTranslationsByRoute(routeKey));

  useEffect(() => {
    let localeChange = false;

    if (tag && prevTag.current !== tag) {
      prevTag.current = tag;
      localeChange = true;
    }
    if (!translation && tag) {
      dispatch(
        translationsActions.getTranslations({ tag, routeName, localeChange }),
      );
    }
  }, [dispatch, routeName, tag, translation]);

  return new Proxy(translation || {}, {
    get(target, prop) {
      return prop in target ? target[prop] : "\xa0";
    },
  });
};
