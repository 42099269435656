import React from "react";

const SmileyHappy = ({ classProp }) => (
  <svg
    className={`HealthDeclarationSmileySVGSize ${classProp}`}
    viewBox="295.69 88.68 79.96 79.96"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    y="0px"
  >
    <circle className="st0 greenSt0" cx="335.67" cy="128.66" r="37.98" />

    <path
      className="st0 greenSt0"
      d="M315.61,139.13c0.85,1.58,6.84,10.57,18.68,11.07c8.79,0.37,17.13-4.48,21.44-12.45"
    />
    <circle className="st1 greenSt1" cx="322.81" cy="116.68" r="6.16" />
    <circle className="st1 greenSt1" cx="348.54" cy="116.68" r="6.16" />
  </svg>
);

export default SmileyHappy;
