import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class TranslationsService extends BaseService {
  constructor(url) {
    super(url);
    this.getTranslations = this.getTranslations.bind(this);
  }

  async getTranslations(currentLocale, routeName) {
    try {
      const response = await this.agent.get("", {
        params: {
          frontend: "pat-frontend",
          include: "locale",
          ...(currentLocale ? { locale: currentLocale } : { locale: "sv-se" }),
          route_name: routeName,
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new TranslationsService(API_ROUTES.translations);

export default ServiceInstance;
export { ServiceInstance as TranslationsService };
