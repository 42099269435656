import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { addHours, isAfter, isBefore, parseISO } from "date-fns";

import {
  formatHours,
  getItemFields,
  slugifyer,
} from "helpers/helper-functions";
import { Notifications } from "helpers/notifications";
import { readablePhoneNumber } from "helpers/readable-phone-number";
import { useTranslations } from "hooks/use-translations";
import { selectTag } from "redux/app/selectors";
import { bookingActions } from "redux/booking/actions";
import { clinicsActions } from "redux/clinics/actions";

import RescheduleModal from "components/calendar/reschedule/reschedule-modal";
import { MainSpinner, StyledLayout } from "components/common.styled";
import Footer from "components/footer";
import Header from "components/header";

import {
  AppointmentButtons,
  BookingCancelled,
  BookingConfirmContainer,
  BookingDetailsContainer,
  BookingResultContent,
  BookingTimePassedContent,
  BookingTimePassedNote,
  BookingTimePassedTitle,
  ButtonContainer,
  InvalidBookingContainer,
  NoteContainer,
  ResultHeading,
} from "./index.styled";

const BookingConfirm = () => {
  const dispatch = useDispatch();
  const { external_id: bookingId } = useParams();
  const history = useHistory();

  const routeName = "b.n.[external_id]";

  const { loading, bookingDetails, bookingAttendees, bookingConfirmStatus } =
    useSelector((state) => state.booking);
  const { clinic } = useSelector((state) => state.clinics);
  const locale = useSelector(selectTag);

  const mounted = useRef(false);

  const translations = useTranslations(routeName);
  const [confirmBookingClicked, setConfirmBookingClicked] = useState(false);
  const [bookingData, setBookingData] = useState(null);
  const [caregiverData, setCaregiverData] = useState(null);
  const [currentClinic, setCurrentClinic] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [bookingDuration, setBookingDuration] = useState("");
  const [clinicAddress, setClinicAddress] = useState("");
  const [bookingStatus, setBookingStatus] = useState("");
  const [allowBooking, setAllowBooking] = useState(true);
  const [isBookingReschedulableInitial, setIsBookingReschedulableInitial] =
    useState(true);
  const [isBookingReschedulable, setIsBookingReschedulable] = useState(false);
  const [showAcceptButton, setShowAcceptButton] = useState(true);
  const [isInvalidBookingId, setIsInvalidBookingId] = useState(false);
  const [updatedBookingId, setUpdatedBookingId] = useState("");
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  const [isBookingTimePassed, setIsBookingTimePassed] = useState(false);
  const [showBookingTimePassedNote, setShowBookingTimePassedNote] =
    useState(false);

  const caregiverName = useMemo(() => {
    if (caregiverData) {
      const caregiverTitle =
        caregiverData.user.attributes.title ??
        caregiverData.user.role.attributes.name ??
        "";
      const comma = caregiverTitle ? ", " : "";

      return `${caregiverData.user.attributes.first_name} ${caregiverData.user.attributes.last_name}${comma}${caregiverTitle}`;
    }
  }, [caregiverData]);

  const clinicDetails = useMemo(
    () => ({
      phoneNumber: currentClinic?.attributes?.clinic_phone_number || "",
      name: currentClinic?.attributes?.clinic_name || "",
      url: `/${slugifyer(currentClinic?.attributes?.clinic_name || "")}/c/${
        currentClinic?.id || ""
      }`,
    }),
    [
      currentClinic?.attributes?.clinic_name,
      currentClinic?.attributes?.clinic_phone_number,
      currentClinic?.id,
    ],
  );

  const isActiveBookingConfirmQuestion = useMemo(
    () =>
      !isBookingLoading &&
      !isInvalidBookingId &&
      !confirmBookingClicked &&
      !isBookingTimePassed,
    [
      confirmBookingClicked,
      isBookingLoading,
      isBookingTimePassed,
      isInvalidBookingId,
    ],
  );

  const isActiveBookingConfirmSuccess = useMemo(
    () =>
      !isBookingLoading &&
      confirmBookingClicked &&
      bookingConfirmStatus.partstat === "ACCEPTED",
    [bookingConfirmStatus.partstat, confirmBookingClicked, isBookingLoading],
  );

  const isActiveBookingConfirmFailure = useMemo(
    () =>
      !isBookingLoading &&
      confirmBookingClicked &&
      bookingConfirmStatus.partstat !== "ACCEPTED",
    [bookingConfirmStatus.partstat, confirmBookingClicked, isBookingLoading],
  );

  const isActiveInvalidBookingIdComponent = useMemo(
    () => isInvalidBookingId && !isBookingLoading,
    [isBookingLoading, isInvalidBookingId],
  );

  const bookingDetailsTransformed = useMemo(() => {
    return getItemFields(
      bookingDetails?.data?.relationships,
      bookingDetails?.included,
    );
  }, [bookingDetails]);

  const displayBookingData = useCallback(
    (bookingEntry) => {
      const bookingDate = `${formatHours(
        bookingEntry.attributes.dtstart,
        "H:mm",
      )}-${formatHours(
        bookingEntry.attributes.dtend,
        "H:mm EEEE d MMMM yyyy",
        locale,
      )}`;

      setBookingDuration(bookingDate);
      setClinicAddress(bookingEntry.attributes.location);
      setClinicAddress(bookingEntry.attributes.location);

      if (bookingEntry.attributes.reschedulable === false) {
        setIsBookingReschedulableInitial(false);
      }

      if (bookingEntry.attributes.status === "CANCELLED") {
        setAllowBooking(false);
      }
    },
    [locale],
  );

  const displayBookingStatus = useCallback(
    (currentBookingStatus) => {
      switch (currentBookingStatus) {
        case "ACCEPTED":
          setBookingStatus(translations.booking_confirm_status_accepted);
          setShowAcceptButton(false);
          break;
        case "DECLINED":
          setBookingStatus(translations.booking_confirm_status_declined);
          break;
        case "NEEDS-ACTION":
          setBookingStatus(translations.booking_confirm_needs_action);
          break;
        case "TENTATIVE":
        default:
          setBookingStatus(translations.booking_confirm_status_tentative);
          break;
      }
    },
    [translations],
  );

  const getBookingData = useCallback(
    (bookingEntry) => {
      displayBookingStatus(bookingDetails.data?.attributes?.partstat);

      const startDateParsed = parseISO(bookingEntry.attributes?.dtstart);
      const missedBooking = isAfter(new Date(), startDateParsed);

      setIsBookingTimePassed(missedBooking);
      setBookingData(bookingEntry);

      return missedBooking;
    },
    [bookingDetails?.data?.attributes?.partstat, displayBookingStatus],
  );

  const getCaregiverAtLocationDetails = useCallback(
    (reschedulable = true) => {
      if (!reschedulable) {
        setIsBookingReschedulable(false);
        return;
      }
      const lockPeriodInHours =
        bookingDetailsTransformed?.booking?.booking_policy?.attributes
          ?.rescheduling_lock_period_in_hours || 0;
      const lockTime = addHours(new Date(), lockPeriodInHours);
      const startDateParsed = parseISO(bookingData?.attributes?.dtstart);
      const isDtStartBeforeLockTime = isBefore(startDateParsed, lockTime);

      if (reschedulable && isDtStartBeforeLockTime) {
        setIsBookingReschedulable(false);
        return;
      }

      bookingAttendees.included.forEach((includedItem) => {
        if (
          includedItem.type === "muntra_caregiver_at_location" &&
          isBookingReschedulableInitial &&
          includedItem.attributes?.calendar_activated
        ) {
          setIsBookingReschedulable(true);
        }
      });
    },
    [
      bookingAttendees?.included,
      isBookingReschedulableInitial,
      bookingData?.attributes?.dtstart,
      bookingDetailsTransformed,
    ],
  );

  const OnBookingAcceptClick = useCallback(() => {
    dispatch(bookingActions.fetchBookingPatientResponse(updatedBookingId));
    setConfirmBookingClicked(true);
  }, [dispatch, updatedBookingId]);

  const confirmReschedule = useCallback(() => {
    if (bookingDetails && Object.keys(bookingDetails).length !== 0) {
      setIsModalOpened(true);
    } else {
      Notifications.message({
        message: translations.booking_confirm_get_details_error,
      });
    }
  }, [bookingDetails, translations]);

  const onRescheduleModalClose = useCallback(() => {
    if (mounted.current === true) {
      setIsModalOpened(false);
    }
  }, []);

  useEffect(() => {
    if (bookingId) {
      let correctBookingId = bookingId;
      while (correctBookingId[correctBookingId.length - 1] === ".") {
        correctBookingId = correctBookingId.slice(
          0,
          correctBookingId.length - 1,
        );
      }
      setUpdatedBookingId(correctBookingId);
      dispatch(bookingActions.fetchBookingDetails(correctBookingId));
      setIsBookingLoading(true);
    }
  }, [bookingId, dispatch]);

  useEffect(() => {
    if (bookingDetails && Object.keys(bookingDetails).length !== 0) {
      setIsInvalidBookingId(false);

      const missedBooking = getBookingData(bookingDetailsTransformed.booking);
      setCurrentClinic(bookingDetailsTransformed.booking.clinic);
      if (missedBooking) {
        dispatch(
          clinicsActions.getClinicForBookings(
            bookingDetailsTransformed.booking.clinic.id,
            bookingDetailsTransformed.booking.procedure,
          ),
        );
      } else if (isBookingLoading) {
        dispatch(
          bookingActions.fetchBookingAttendees(
            bookingDetails.data?.relationships?.booking?.data?.id,
          ),
        );
      }
      setIsBookingLoading(false);
    } else if (!loading && isBookingLoading) {
      setIsInvalidBookingId(true);
      setIsBookingLoading(false);
    }
  }, [
    bookingDetails,
    dispatch,
    getBookingData,
    isBookingLoading,
    loading,
    bookingDetailsTransformed,
  ]);

  useEffect(() => {
    if (bookingAttendees && Object.keys(bookingAttendees).length !== 0) {
      const isBookingDataValid = !!bookingAttendees.data[0];
      if (isBookingDataValid) {
        const caregiver = getItemFields(
          bookingAttendees.data[0].relationships,
          bookingAttendees.included,
        );
        setCaregiverData(caregiver);
      }
      getCaregiverAtLocationDetails(isBookingDataValid);
    }
  }, [bookingAttendees, getCaregiverAtLocationDetails]);

  useEffect(() => {
    if (clinic && Object.keys(clinic).length !== 0) {
      clinic.caregiver_locations.every((caregiver) => {
        if (caregiver.free_bookable_slots.length > 0) {
          setShowBookingTimePassedNote(true);
          return false;
        }
        return true;
      });
    }
    setCurrentClinic(clinic);
  }, [clinic]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (bookingData) {
      displayBookingData(bookingData);
    }
  }, [bookingData, displayBookingData]);

  return (
    <StyledLayout>
      <Header translations={translations} isStartPage={false} />
      <Helmet>
        <title>{`${translations.meta_title_default}`}</title>
      </Helmet>
      <BookingConfirmContainer>
        {isBookingLoading && <MainSpinner />}
        {isActiveBookingConfirmQuestion && (
          <>
            <BookingDetailsContainer>
              <h1>
                {translations.booking_confirm_clinic} {clinicDetails.name}
              </h1>
              <ul>
                <li>
                  <strong>{translations.booking_confirm_date_time}</strong>
                  <br />
                  {bookingDuration}
                </li>
                <li>
                  <strong>{translations.booking_confirm_place}</strong>
                  <br />
                  {clinicAddress}
                </li>
                {caregiverName !== "" && (
                  <li>
                    <strong>{translations.booking_confirm_caregiver}</strong>
                    <br />
                    {caregiverName}
                  </li>
                )}
                <li>
                  <strong>{translations.booking_confirm_status_label}</strong>
                  <br />
                  {bookingStatus}
                </li>
              </ul>
            </BookingDetailsContainer>
            {allowBooking ? (
              <>
                <ButtonContainer>
                  {showAcceptButton && (
                    <AppointmentButtons onClick={OnBookingAcceptClick}>
                      {translations.booking_confirm_button}
                    </AppointmentButtons>
                  )}
                  {isBookingReschedulable && (
                    <AppointmentButtons secondary onClick={confirmReschedule}>
                      {translations.booking_confirm_reschedule}
                    </AppointmentButtons>
                  )}
                </ButtonContainer>
                <NoteContainer>
                  {isBookingReschedulable ? (
                    <span>
                      {translations.booking_confirm_telephone_cancel}{" "}
                      <nobr>
                        <a href={`tel:${clinicDetails.phoneNumber}`}>
                          {readablePhoneNumber([clinicDetails.phoneNumber])}
                        </a>
                      </nobr>
                    </span>
                  ) : (
                    <span>
                      {translations.booking_confirm_telephone_cancel_and_rebook}{" "}
                      <nobr>
                        <a href={`tel:${clinicDetails.phoneNumber}`}>
                          {readablePhoneNumber([clinicDetails.phoneNumber])}
                        </a>
                      </nobr>
                    </span>
                  )}
                </NoteContainer>
              </>
            ) : (
              <BookingCancelled>
                <p>
                  {translations.booking_confirm_cancelled}{" "}
                  <a href={`tel:${clinicDetails.phoneNumber}`}>
                    {readablePhoneNumber([clinicDetails.phoneNumber])}
                  </a>{" "}
                  {translations.booking_confirm_cancelled_questions}
                </p>
              </BookingCancelled>
            )}
          </>
        )}
        {isActiveBookingConfirmSuccess && (
          <BookingResultContent>
            <ResultHeading>{translations.booking_succ}</ResultHeading>
          </BookingResultContent>
        )}
        {isActiveBookingConfirmFailure && (
          <div>
            <BookingResultContent>
              <ResultHeading>{translations.booking_failed}</ResultHeading>
            </BookingResultContent>
          </div>
        )}
        {isModalOpened && (
          <RescheduleModal
            bookingData={bookingData}
            caregiver={caregiverData}
            onClose={() => onRescheduleModalClose()}
            onComplete={() => {
              history.push("/login");
            }}
            translations={translations}
            authenticateUser={false}
            authenticationId={updatedBookingId}
          />
        )}
        {isActiveInvalidBookingIdComponent && (
          <InvalidBookingContainer>
            {translations.booking_confirm_invalid}
          </InvalidBookingContainer>
        )}
        {isBookingTimePassed && (
          <BookingTimePassedContent>
            <BookingTimePassedTitle>
              {translations.booking_confirm_passed}
            </BookingTimePassedTitle>
            <span>
              {showBookingTimePassedNote && (
                <BookingTimePassedNote>
                  <a href={clinicDetails.url}>
                    {translations.booking_confirm_rebook} {clinicDetails.name}
                  </a>
                </BookingTimePassedNote>
              )}
            </span>
          </BookingTimePassedContent>
        )}
      </BookingConfirmContainer>
      <Footer translations={translations} />
    </StyledLayout>
  );
};

export default BookingConfirm;
