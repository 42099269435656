import styled from "styled-components";

import { Col, Row } from "../common.styled";
import Button from "../common/button";

export const ArrowButton = styled(Button)`
  font-size: 22px;
  padding: 0;
  justify-content: center;

  &[disabled] {
    svg {
      fill: #cecece;
    }
  }
`;

export const DateTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  line-height: 17px;
  font-size: 13px;
  min-height: 32px;
  place-content: center;
  margin-bottom: 5px;
`;

export const DateRow = styled(Row)`
  margin-bottom: 7px;
  flex: 1 1 0;
`;

export const CenterRow = styled(Row)`
  justify-content: center;
  margin-top: 30px;
`;

export const SCol = styled(Col)`
  flex: 1 1 0;
`;

export const HoursButton = styled(Button)`
  box-shadow: 2px 2px 0 #ddd;
  border: 2px solid rgb(24, 144, 255);
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0;
  width: 68.05px;

  @media (max-width: 530px) {
    font-size: 15px;
    padding: 5px 8px;
    line-height: 19px;
    height: auto;
  }

  & svg {
    height: 10px;
    align-self: center;
    margin-left: 7px;
  }

  & span {
    margin-left: 0;
  }

  @media (max-width: 388px) {
    & span.hide-on-mobile {
      display: none;
    }
  }
`;

export const NextButton = styled(Button)`
  margin-bottom: 15px;

  &:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }

  @media (max-width: 740px) {
    font-size: 15px;
    font-weight: 700;
    width: 100%;
    margin-bottom: 14px;
    min-height: 42px;
  }
`;

export const Inner = styled.span`
  @media (max-width: 740px) {
    margin: 0 auto;
    font-size: 15px;
    font-weight: 700;
  }
`;
