import { eventChannel } from "redux-saga";
import { all, call, fork, put, take } from "redux-saga/effects";

import { CUSTOM_EVENTS } from "constants/custom-events";
import { authActions } from "redux/auth/actions";

import { eventEmitter } from "components/common/event-bus";

function createLogoutChannel() {
  return eventChannel((emit) => {
    const logoutHandler = () => {
      emit(authActions.userSignOut());
    };

    eventEmitter.on(CUSTOM_EVENTS.logout, logoutHandler);

    return () => {
      eventEmitter.off(CUSTOM_EVENTS.logout, logoutHandler);
    };
  });
}

function* logoutSaga() {
  const channel = yield call(createLogoutChannel);
  while (true) {
    const action = yield take(channel);

    yield put(action);
  }
}

export default function* socketsSaga() {
  yield all([fork(logoutSaga)]);
}
