import React, { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";

import { formatDate, getItemFields } from "helpers/helper-functions";
import { selectTag } from "redux/app/selectors";

import { StyledText } from "components/common.styled";

import { BoldText, Container } from "./index.styled";

const InvoiceCard = ({ item, invoices }) => {
  const history = useHistory();
  const locale = useSelector(selectTag);

  const data = useMemo(
    () => getItemFields(item.relationships, invoices),
    [invoices, item.relationships],
  );
  const invoiceRow = useMemo(
    () =>
      data?.patient_invoice_rows?.find(
        (item) => item?.attributes?.price_excluding_vat_per_quantity,
      ),
    [data?.patient_invoice_rows],
  );

  const currency = data.currency?.attributes?.code || "SEK";
  const date = formatDate(item.attributes?.invoice_date, "d MMMM uuuu", locale);

  const organizationName = item.attributes?.organization_name;

  const getSum = useCallback((item) => {
    const initialValue = 0;

    return item.reduce(
      (accumulator, currentValue) =>
        accumulator +
        currentValue.attributes.price_excluding_vat_per_quantity *
          (1 + currentValue.vat_code.attributes.vat_percentage),
      initialValue,
    );
  }, []);

  return (
    <Container onClick={() => history.push(`/invoice?invoice_id=${item.id}`)}>
      <StyledText style={{ marginBottom: "20px" }}> {date} </StyledText>
      <div style={{ position: "relative" }}>
        <BoldText> {organizationName} </BoldText>
        <BoldText style={{ position: "absolute", right: "0", top: "0" }}>
          {!invoiceRow &&
            `${new Intl.NumberFormat(locale, {
              style: "currency",
              currency,
            }).format(0)}`}
          {invoiceRow &&
            `${new Intl.NumberFormat(locale, {
              style: "currency",
              currency,
            }).format(
              getSum(data.patient_invoice_rows) +
                item.attributes.generic_dental_subsidy,
            )}`}
        </BoldText>
      </div>
    </Container>
  );
};

InvoiceCard.propTypes = {
  item: PropTypes.object,
  invoices: PropTypes.array,
};

InvoiceCard.defaultProps = {
  item: {},
};

export default memo(InvoiceCard);
