export const LANGUAGES = {
  swedish: "sv",
  norwegian: "no",
  danish: "da",
  english: "en-gb",
};

export const LANGUAGES_TITLE = {
  swedish: "Swedish / Svenska",
  norwegian: "Norwegian / Norsk",
  danish: "Danish / Danska",
  english: "English",
};

export const LOCALES = {
  swedish: "sv-se",
  norwegian: "nb-no",
  danish: "da-dk",
  english: "en-gb",
};
