import { combineReducers } from "redux";

import { appReducer } from "./app/reducer";
import { authReducer } from "./auth/reducer";
import { bookingReducer } from "./booking/reducer";
import { caregiversReducer } from "./caregivers/reducer";
import { clinicsReducer } from "./clinics/reducer";
import { googlePlacesReducer } from "./google-places/reducer";
import { proceduresReducer } from "./procedures/reducer";
import { reviewReducer } from "./review/reducer";
import { rolesReducer } from "./roles/reducer";
import { translationsReducer } from "./translations/reducer";

export const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  roles: rolesReducer,
  googlePlaces: googlePlacesReducer,
  caregivers: caregiversReducer,
  clinics: clinicsReducer,
  procedures: proceduresReducer,
  booking: bookingReducer,
  review: reviewReducer,
  translations: translationsReducer,
});
