import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";

import { Heading, Row } from "components/common.styled";
import Button from "components/common/button";

const Step7 = ({ changeStep, step, translations }) => {
  const { caregiver } = useSelector((state) => state.review);

  const history = useHistory();

  return (
    <>
      {caregiver.attributes && (
        <Heading>
          <b>{`${translations.patient_review_step_7_heading}`}</b>
        </Heading>
      )}
      <br />
      <p>{translations.patient_review_step_7_paragraph}</p>
      <br />
      <Row>
        <Button
          onClick={() => changeStep(step + 1)}
          style={{ marginRight: 20 }}
        >
          {translations.patient_review_step_7_ok}
        </Button>
        <Button onClick={() => history.push("/start")} secondary>
          {translations.patient_review_step_7_not_now}
        </Button>
      </Row>
    </>
  );
};

Step7.propTypes = {
  changeStep: PropTypes.func,
  step: PropTypes.number,
  translations: PropTypes.object,
};

export default memo(Step7);
