import styled, { css } from "styled-components";

export const MainButton = styled.button`
  border: 0;
  display: flex;
  align-items: center;

  background: ${(props) => {
    if (props.opened) return " #00000017";
    return "transparent";
  }};

  height: 100%;
  transition: all 0.3s;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: #00000017;
  }

  & svg {
    height: 12px;
    margin: auto 0;
    width: 14px;
  }
`;

export const MainDivButton = styled.div`
  border: 0;
  display: flex;
  align-items: center;

  height: 100%;
  width: 100%;

  &:focus {
    outline: 0;
  }

  & svg {
    height: 12px;
    margin: auto 0;
    width: 14px;
  }

  ${(props) => {
    if (props.isIncludedMobile)
      return css`
        line-height: 15px;
        display: block;
        & svg {
          height: 100%;
          width: 100%;
          margin: 0;
        }
      `;
  }}
`;

export const DropdownWrapper = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 59px;
  left: -18px;
  width: 175px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 3px 3px;
  z-index: 10;

  ${(props) => {
    if (props.isIncluded)
      return css`
        top: 77px;
        left: -50px;
        width: 150px;
      `;
  }}

  ${(props) => {
    if (props.isIncludedMobile)
      return css`
        top: -100px;
        left: -97px;
        width: 150px;
      `;
  }}

  & button {
    padding: 10px 20px;
  }
`;
