import React, { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";

import defaultUserImage from "assets/images/defaultUserIcon.png";
import { slugifyer } from "helpers/helper-functions";

import Slots from "../calendar/slots/slots";
import SlotsMobile from "../calendar/slots/slots-mobile";
import { Avatar, Col, MobileSection, Row } from "../common.styled";
import Radio from "../common/radio";
import Dropdown from "../common/search-dropdown";

import {
  CaregiverAtLocationContainer,
  ImgCap,
  MainCol,
  MainRow,
  NameTilte,
  StyledDivider,
  StyledRate,
} from "./index.styled";

const CaregiverCard = ({
  caregiversDataForAvatar,
  goToDate,
  handleRadioChange,
  isSearch,
  item,
  selectedProcedures,
  slotsLoadBegin,
  slotsLoadings,
  startDate,
  translations,
}) => {
  const history = useHistory();

  const {
    caregiver = {},
    attributes = {},
    clinic = {},
    procedures,
  } = item || {};
  const fullName = `${caregiver.attributes.first_name} ${caregiver.attributes.last_name}`;

  const { name } = caregiver.role.attributes || {};
  const {
    number_of_reviews: numberOfReviews,
    overall_rating: overallRating,
    hide_reviews: hideReviews,
  } = caregiver.attributes || {};

  const link = `/${slugifyer(name)}/${slugifyer(fullName)}/p/${caregiver.id}`;

  const {
    clinic_address_1: clinicAddress1 = "",
    clinic_city: clinicCity = "",
    clinic_postal_code: clinicPostalCode = "",
  } = clinic.attributes || {};

  const caregiverAvatar = useMemo(
    () =>
      caregiversDataForAvatar?.find(
        (el) => el?.caregiver?.id === item?.caregiver?.id,
      ),
    [caregiversDataForAvatar, item?.caregiver?.id],
  );
  const rate = useMemo(
    () => Math.round((overallRating + Number.EPSILON) * 100) / 100,
    [overallRating],
  );
  const avatarProps = useMemo(
    () => ({
      size: 78,
      alt: `${caregiver.role.attributes.name} ${fullName}`,
      loading: "lazy",
    }),
    [caregiver.role.attributes.name, fullName],
  );
  const latestFeedback = useMemo(
    () =>
      attributes.latest_review_comment?.length > 100
        ? `"${attributes.latest_review_comment.substring(0, 100)}..."`
        : attributes.latest_review_comment || "",
    [attributes.latest_review_comment],
  );
  const selected = useMemo(
    () => selectedProcedures?.map((item) => item.id),
    [selectedProcedures],
  );

  const noSlots =
    global.innerWidth >= 994 ? (
      <>
        {procedures?.length > 6 && (
          <Col style={{ width: "100%" }}>
            <Dropdown
              title={translations.r_search_treatment}
              searchable={[
                translations.r_search_treatment,
                translations.r_search_no_match_treatment,
              ]}
              list={procedures}
              resetThenSet={(item) => handleRadioChange(item, true)}
              defaultProcedure={selectedProcedures}
              isClinic
            />
          </Col>
        )}
        {procedures?.length <= 6 && (
          <Col>
            {procedures?.filter((item) => !selected.includes(item.procedure.id))
              .length > 0 ? (
              procedures
                .filter((item) => !selected.includes(item.procedure.id))
                .map((item) => (
                  <Radio
                    name="procedure"
                    key={item.procedure.id}
                    value={item.procedure.id}
                    onChange={() => handleRadioChange(item.procedure)}
                  >
                    {item.procedure.attributes.name}
                  </Radio>
                ))
            ) : (
              <div style={{ textAlign: "center", margin: "auto" }}>
                {translations.r_no_available_times_to_book}
              </div>
            )}
          </Col>
        )}
      </>
    ) : (
      <Col style={{ width: "100%", padding: "32px 0 9px" }}>
        <Dropdown
          title={translations.r_search_treatment}
          searchable={[
            translations.r_search_treatment,
            translations.r_search_no_match_treatment,
          ]}
          list={procedures}
          resetThenSet={(item) => handleRadioChange(item, true)}
          defaultProcedure={selectedProcedures}
          isClinic
        />
      </Col>
    );

  const caregiverAtLocationObject = {
    id: caregiver.id,
    caregiverAtLocation: item,
    caregiverAtLocationLoadBegin: slotsLoadBegin,
    caregiverAtLocationLoading: slotsLoadings,
    noCaregiverAtLocation: noSlots,
  };

  const dateObject = { startDate, goToDate };

  return (
    <>
      <MobileSection>
        <MainRow>
          {global.innerWidth < 994 && (
            <span style={{ position: "absolute", right: 0, top: 0 }}>
              {`<${Math.ceil(item.attributes.distance)} km`}{" "}
            </span>
          )}
          <MainCol>
            <Row
              onClick={() => history.push(link)}
              style={{ cursor: "pointer", flexWrap: "nowrap", width: "100%" }}
            >
              <div style={{ marginRight: 15 }}>
                {!item?.caregiver.default_user_image &&
                  !caregiverAvatar?.caregiver?.default_user_image && (
                    <Avatar {...avatarProps} src={defaultUserImage} />
                  )}
                {caregiverAvatar?.caregiver?.default_user_image && (
                  <Avatar
                    {...avatarProps}
                    src={`${caregiverAvatar?.caregiver?.default_user_image?.attributes?.original}?h=78&w=78&fit=crop`}
                  />
                )}
                {item?.caregiver.default_user_image &&
                  !caregiverAvatar?.caregiver?.default_user_image && <ImgCap />}
              </div>
              <div>
                <NameTilte
                  onClick={(e) => e.stopPropagation()}
                  style={{ paddingRight: "50px" }}
                >
                  <Link to={`${link}`} style={{ lineHeight: "22px" }}>
                    {fullName}
                  </Link>
                </NameTilte>
                <div style={{ color: "#27272f", fontSize: "15px" }}>
                  <p style={{ marginBottom: "10px" }}>
                    {caregiver?.attributes?.title
                      ? caregiver.attributes.title
                      : name}
                  </p>
                  {!hideReviews && numberOfReviews > 0 && (
                    <Row style={{ marginBottom: "10px" }} alignitems="center">
                      <StyledRate
                        margin="0 4px 0 0"
                        starsCount={1}
                        size={16}
                        defaultValue={rate}
                      />
                      <span
                        style={{
                          fontSize: "14px",
                          lineHeight: "18px",
                          marginBottom: "3px",
                          color: " rgba(0, 0, 0, .65)",
                        }}
                      >
                        <span
                          style={{ color: "rgb(72, 72, 72)", fontWeight: 600 }}
                        >
                          {" "}
                          {`${rate.toFixed(2).replace(".", ",")}`}
                        </span>
                        {` (${numberOfReviews})`}
                      </span>
                    </Row>
                  )}
                  {!caregiver.attributes.hide_reviews &&
                    global.innerWidth >= 994 && <p>{latestFeedback}</p>}
                  {caregiver.attributes.hide_reviews && (
                    <div
                      style={{
                        color: "#000000a6",
                        marginBottom: "10px",
                        fontSize: "13px",
                        lineHeight: "13px",
                      }}
                    >
                      {translations.r_reviews_are_hidden}
                    </div>
                  )}
                  <p>{`${clinicAddress1}, ${clinicPostalCode} ${clinicCity}`}</p>
                </div>
              </div>
            </Row>
          </MainCol>

          {global.innerWidth < 994 && (
            <Col style={{ maxWidth: 700, width: "100%" }}>
              <div>
                <SlotsMobile
                  caregiver={caregiver}
                  defaultProcedure={selectedProcedures}
                  isSearch={isSearch}
                  location={item}
                  translations={translations}
                  caregiverAtLocationObject={caregiverAtLocationObject}
                  clinic={clinic}
                  dateObject={dateObject}
                />
              </div>
            </Col>
          )}

          {global.innerWidth >= 994 && (
            <CaregiverAtLocationContainer>
              <div style={{ width: "8.333333333333332%" }}>&nbsp;</div>
              <div style={{ flex: "1 1 0" }}>
                <Slots
                  caregiver={caregiver}
                  defaultProcedure={selectedProcedures}
                  isSearch={isSearch}
                  location={item}
                  translations={translations}
                  caregiverAtLocationObject={caregiverAtLocationObject}
                  clinic={clinic}
                  dateObject={dateObject}
                />
              </div>
              <div style={{ width: "8.333333333333332%" }}>&nbsp;</div>
            </CaregiverAtLocationContainer>
          )}
        </MainRow>
      </MobileSection>
      <StyledDivider />
    </>
  );
};

CaregiverCard.propTypes = {
  caregiversDataForAvatar: PropTypes.array,
  goToDate: PropTypes.func,
  handleRadioChange: PropTypes.func,
  isSearch: PropTypes.bool,
  item: PropTypes.object,
  selectedProcedures: PropTypes.array,
  slotsLoadBegin: PropTypes.bool,
  slotsLoadings: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  translations: PropTypes.object,
};

export default CaregiverCard;
