import React, { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import * as PropTypes from "prop-types";

import { Heading } from "components/common.styled";
import Rate from "components/common/stars";

import {
  BackButton,
  Container,
  Divider,
  LabelText,
  NextButton,
} from "./index.styled";

const Step3 = ({
  caregiverReview,
  changeStep,
  handleCaregiverReviewUpdate,
  step,
  translations,
}) => {
  const { caregiver } = useSelector((state) => state.review);

  const options = useMemo(
    () => [
      {
        text: `${translations.patient_review_step_3_waiting_time}`,
        selected: +caregiverReview.rating_wait_time,
      },
      {
        text: `${translations.patient_review_step_3_customer_service}`,
        selected: +caregiverReview.rating_reception_on_arrival,
      },
      {
        text: `${translations.patient_review_step_3_quality}`,
        selected: +caregiverReview.rating_quality,
      },
      {
        text: `${translations.patient_review_step_3_experience}`,
        selected: +caregiverReview.rating_bedside_manner,
      },
      {
        text: `${translations.patient_review_step_3_hygiene}`,
        selected: +caregiverReview.rating_hygiene,
      },
      {
        text: `${translations.patient_review_step_3_precision}`,
        selected: +caregiverReview.rating_thoroughness,
      },
    ],
    [
      caregiverReview.rating_bedside_manner,
      caregiverReview.rating_hygiene,
      caregiverReview.rating_quality,
      caregiverReview.rating_reception_on_arrival,
      caregiverReview.rating_thoroughness,
      caregiverReview.rating_wait_time,
      translations,
    ],
  );

  const handleOptionChange = useCallback(
    (value, number) => {
      switch (number) {
        case 0:
          handleCaregiverReviewUpdate({ rating_wait_time: value + 1 });
          break;
        case 1:
          handleCaregiverReviewUpdate({
            rating_reception_on_arrival: value + 1,
          });
          break;
        case 2:
          handleCaregiverReviewUpdate({ rating_quality: value + 1 });
          break;
        case 3:
          handleCaregiverReviewUpdate({ rating_bedside_manner: value + 1 });
          break;
        case 4:
          handleCaregiverReviewUpdate({ rating_hygiene: value + 1 });
          break;
        default:
          handleCaregiverReviewUpdate({ rating_thoroughness: value + 1 });
          break;
      }
    },
    [handleCaregiverReviewUpdate],
  );

  const changeStepBackwards = () => {
    handleCaregiverReviewUpdate({
      rating_reception_on_arrival: null,
      rating_quality: null,
      rating_bedside_manner: null,
      rating_hygiene: null,
      rating_thoroughness: null,
    });
    changeStep(step - 1);
  };

  return (
    <>
      {caregiver.attributes && (
        <Heading>
          <b>{`${translations.patient_review_step_3_let} ${caregiver.attributes.first_name} ${translations.patient_review_step_3_know_about_experience}`}</b>
        </Heading>
      )}
      <p>{translations.patient_review_step_3_this_is_private}</p>
      <br />
      {options.map((item, index) => (
        <Container key={index}>
          <LabelText>{item.text}</LabelText>
          <Rate
            onClick={(value) => handleOptionChange(value, index)}
            size={30}
            defaultValue={item.selected || 0}
          />
        </Container>
      ))}
      <br />
      <Divider />
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <BackButton link onClick={() => changeStepBackwards()}>
          {"<"} {translations.patient_review_step_3_back}
        </BackButton>
        <NextButton onClick={() => changeStep(step + 1)}>
          {translations.patient_review_step_3_next}
        </NextButton>
      </div>
    </>
  );
};

Step3.propTypes = {
  caregiverReview: PropTypes.object,
  changeStep: PropTypes.func,
  handleCaregiverReviewUpdate: PropTypes.func,
  step: PropTypes.number,
  translations: PropTypes.object,
};

Step3.defaultProps = {
  caregiverReview: {},
};

export default memo(Step3);
