import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class ClinicsService extends BaseService {
  constructor(url) {
    super(url);
    this.getClinics = this.getClinics.bind(this);
    this.getClinicById = this.getClinicById.bind(this);
  }

  async getClinics(params) {
    try {
      const response = await this.agent.get("", params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async getClinicById(id, params) {
    try {
      const response = await this.agent.get(`/${id}`, params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new ClinicsService(API_ROUTES.clinics);

export default ServiceInstance;
export { ServiceInstance as ClinicsService };
