import React, { createContext } from "react";
import mitt from "mitt";
import * as PropTypes from "prop-types";

const eventEmitter = mitt();
const BusContext = createContext(null);

function EventProvider({ children }) {
  return (
    <BusContext.Provider value={eventEmitter}>{children}</BusContext.Provider>
  );
}

export default EventProvider;
export { eventEmitter, EventProvider };

EventProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
