import styled from "styled-components";

import {
  LeftSearch,
  RightSearch,
  SearchRow,
  StyledButton,
} from "../../../../components/header/index.styled";

export const Container = styled.div`
  width: 100%;
  height: max-content;
  @media (max-width: 993px) {
    width: 100%;
  }
`;

export const StartPageSearchRow = styled(SearchRow)`
  width: 100%;
  margin-left: 0px !important;
`;

export const StartPageCaregiverSearch = styled(LeftSearch)`
  margin: 0;
  width: 100%;

  &:focus-within {
    width: 100%;
  }
`;

export const StartPageGeoSearch = styled(RightSearch)`
  margin: 0;
  width: 100%;

  &:focus-within {
    width: 100%;
  }
`;

export const StartPageSearchButton = styled(StyledButton)`
  height: 52px !important;
`;
