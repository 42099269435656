import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Layout } from "style/global-styled-components.styled";

import { mobileValidator } from "helpers/helper-functions";
import { useSignInValidation } from "hooks/use-sign-in-validation";
import { useTranslations } from "hooks/use-translations";
import { authActions as actions } from "redux/auth/actions";

import AuthContent from "components/auth/auth-content";
import AuthLoader from "components/common/auth-loader";

import { Content, InnerContainer } from "./index.styled";

const Login = () => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const authState = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();

  const routeName = "login";
  const translations = useTranslations(routeName);

  const isMobile = mobileValidator.test(window.navigator.userAgent);

  useEffect(() => {
    if (localStorage.getItem("user_token_pat_frontend")) {
      history.push("/bookings");
    }
  }, [history]);

  useEffect(() => {
    return () => dispatch(actions.authErrorRefresh({}));
  }, [dispatch]);

  useEffect(() => {
    if (
      isFormSubmitted &&
      authState?.authUserPersonalId?.data &&
      !authState.error?.data
    ) {
      history.push("/bookings");
    }
  }, [
    authState?.authUserPersonalId?.data,
    authState.error?.data,
    history,
    isFormSubmitted,
  ]);

  useSignInValidation();

  return (
    <Layout>
      <Helmet>
        <title>
          {`${translations.meta_title_login} | ${translations.meta_title_default}`}
        </title>
        <meta
          id="meta-description"
          name="description"
          content={translations.login_content}
        />
      </Helmet>
      <Content className="StartPageBackground ">
        <InnerContainer>
          <AuthContent
            translations={translations}
            setIsFormSubmitted={setIsFormSubmitted}
          />
        </InnerContainer>
        {isMobile && authState.loader && (
          <AuthLoader translations={translations} />
        )}
      </Content>
    </Layout>
  );
};

export default Login;
