import React from "react";
import { Link } from "react-router-dom";

import {
  BoldText,
  Col,
  Heading,
  Image,
  Row,
  SecondHeading,
  SmallText,
  Text,
} from "components/common.styled";

const BotoxContent = () => {
  const headings = [
    "Vad är Botox och vad används det till?",
    "Varför får man rynkor i ansiktet?",
    "Hur går botoxbehandlingen till?",
    "Att tänka på inför din botoxbehandling",
    "Att tänka på efter din botoxbehandling",
    "Resultatet från behandlingen med botox",
  ];

  return (
    <Row>
      <Col>
        <Heading>Innan du bokar botoxbehandling</Heading>
        <br />
        <br />
        <Text>Direktlänkar till innehållet i guiden:</Text>
        <ul>
          {headings.map((item, index) => (
            <li key={index}>
              <a href={`#heading${index}`}>{`${index + 1}. ${item}`}</a>
            </li>
          ))}
        </ul>
        <br />
        <br />
        <SecondHeading id="heading0">
          1. Vad är Botox och vad används det till?
        </SecondHeading>
        <Image
          alt="botox"
          loading="lazy"
          src="https://muntra-public.s3.eu-north-1.amazonaws.com/treatmentContent/botox.jpeg"
        />
        <Text>
          Botox ökar alltmer i popularitet och har använts i många typer av
          behandlingar inom sjukvården i decennier.
        </Text>
        <Text>
          Vanligtvis används botox för att motverkar rynkor i ansiktet, för att
          ge en slätare, piggare och fräschare hy.
        </Text>
        <Text>
          Botox kan användas främst för att ta bort rynkor i pannan, ögonbrynen
          och runt ögon samt vid munnen.
        </Text>
        <Text>Men botox används dessutom för att behandla kronisk migrän.</Text>
        <Text>
          Där botox tros blockera smärtsignalerna, vilket förhindrar att migrän
          utvecklas.
        </Text>
        <Text>
          Botox är en förkortning för Botulinumtoxin, som är ett
          muskelavslappnande medel.
        </Text>
        <Text>
          En vanlig orsak till rynkor är muskelsammandragningar. Den
          muskelavslappnande substansen behandlar orsaken till rynkorna och ger
          därför ett effektivt resultat.
        </Text>
        <br />
        <br />

        <SecondHeading id="heading1">
          2. Varför får man rynkor i ansiktet?
        </SecondHeading>
        <Text>
          När man talar om “muskelsammandragning” i detta sammanhang, syftar man
          ofta till vardagliga händelser som t.ex. när vi rynkar pannan.
        </Text>
        <Text>
          Även när vi skrattar sker även sammandragningar på samma sätt som när
          vi ler. Det förefaller därmed naturligt för de flesta att rynkor
          bildas redan i ung ålder.
        </Text>
        <Text>
          Rynkor kan dessutom förekomma av naturliga skäl när huden med tiden
          förlorar sin volym och elasticitet.
        </Text>
        <Text>
          En minskning av volym och elasticitet kan även orsakas av hud som
          exponerats länge och hårt för starkt UV-ljus från solen. Rökning har
          dessutom bevisats som en stor orsak till att hudens elasticitet
          minskar kraftigt.
        </Text>
        <Text>
          Ytterligare en vanlig orsak till rynkor är stress. När vi är stressade
          tenderar vi att bita ihop käkarna och grimasera. Ofta spänner vi eller
          höjer ögonbrynen när vi blir pressade.
        </Text>
        <Text>
          Stress gör att kroppen producerar mycket kortisol vilket innebär en
          minskning av kollagen (en fiberprotein i kroppen). Detta gör i sin tur
          att huden förlorar sin elasticitet och “stressrynkor” bildas.
        </Text>
        <Text>
          Summa summarum uppstår rynkor för den som lever ett aktivt liv, ett
          ohälsosamt liv eller med tidens gång. Vi löper därmed alla en stor
          risk för rynkor! Varför botox ökar alltmer i popularitet.
        </Text>
        <br />
        <br />

        <SecondHeading id="heading2">
          3. Hur går botoxbehandlingen till?
        </SecondHeading>
        <Text>
          En botoxbehandling är nästan helt smärtfri. Behandlaren använder en
          tunn nål som gör att det knappt känns någonting alls.
        </Text>
        <Text>
          Behandlingen går dessutom ofta fort. En botoxbehandling är en
          injektionsbehandling där botox injiceras i utvalda områden.
        </Text>
        <Text>
          Botoxinjektionen sker i en muskel och det muskelavslappnande ämnet gör
          att muskulaturen slappnar av och därmed försvinner rynkorna.
        </Text>
        <Text>
          Behandlingen för att ta bort rynkor i ansiktet syftar främst till
          följande områden och orsaker:
        </Text>
        <ul>
          <li>
            <BoldText>
              Ta bort rynkor mellan ögonbrynen, så kallade “argrynkor” eller
              ”bekymmersrynkor”.
            </BoldText>
            <Text>
              Hur dessa rynkor uppstår avslöjas av namnet. Rynkor mellan
              ögonbrynen uppstår när vi spänner ögonbrynen, t.ex. när vi blir
              arga och ögonbrynen dras samman.
            </Text>
            <Text>
              Behandlingen sker genom injektion med botox på upp till fyra
              punkter mellan ögonbrynen.
            </Text>
          </li>
          <li>
            <BoldText>
              Ta bort pannrynkor (horisontella rynkor i pannan).{" "}
            </BoldText>
            <Text>
              När du höjer på ögonbrynen så bildas det ofta djupa veck när huden
              pressas samman mellan ögonbrynen och hårfästet.
            </Text>
            <Text>
              Pannrynkor orsakas också av ålder, rökning och solljus. Rynkorna
              som bildas i pannan är horisontella och följer ofta konturerna av
              de veck som bildas i pannan när du spänner dig.
            </Text>
            <Text>
              Rynkor i pannan kan bli djupa. Därför är det ibland lämpligt att
              kombinera botox med fillers.
            </Text>
            <Text>
              En konsultation med din vårdgivare ger svar om detta är någonting
              som lämpar sig för dig eller inte. Botoxinjektionerna följer
              linjerna i pannan för att skapa en slätare hy och mer avslappnad
              hud i pannan.
            </Text>
          </li>
          <li>
            <BoldText>
              Ta bort dina “kråksparkar”, rynkorna vid ögonens utkanter.{" "}
            </BoldText>
            <Text>
              Kråksparkar eller skrattrynkor, som de även kallas, är rynkor runt
              ögonens utkanter.
            </Text>
            <Text>
              Eftersom vi använder ögonen för många uttryck är det naturligt att
              huden runt ögonen blir utsatt när vi t.ex. kisar mot solen,
              skrattar eller ler.
            </Text>
            <Text>
              Dessa typer av rynkor förekommer relativt tidigt i livet. För
              många kan de uppstå vid avslappnat tillstånd redan i 30-årsåldern
              (somliga till och med vid 25-årsåldern).
            </Text>
            <Text>
              Även om du använder en ögonkräm runt dina ögon kommer du
              troligtvis att få rynkor. Rynkor är ett tecken på att du lever och
              är enbart naturligt.
            </Text>
            <Text>
              Botox behandlar rynkor vid ögat effektivt och återställer
              elasticiteten och volymen i din hy.
            </Text>
          </li>
          <li>
            <BoldText>
              Ta bort rynkor i käk- och halsområdet (strama halsstråk).{" "}
            </BoldText>
            <Text>
              Botox som syftar till att behandla halsen används för att strama
              upp käklinjen.
            </Text>
            <Text>
              I hakan kan det vara effektivt för att ta bort vecken som ibland
              förekommer på hakan, för somliga tydligare än på andra.
            </Text>
            <Text>
              Önskar du att förstärka dina ansiktsdrag, markera käklinjer och få
              en förstärkt haka kan däremot fillers vara en bättre lösning.
            </Text>
            <Text>
              Återigen, konsultera din behandlare om den bästa lösningen för
              dig.
            </Text>
          </li>
        </ul>
        <br />
        <br />
        <SecondHeading id="heading3">
          4. Att tänka på inför din botoxbehandling
        </SecondHeading>
        <Text>
          Vi rekommenderar alltid att gå på en konsultation inför en behandling.
          Detta för att se att din rynka passar att behandla för botox.
        </Text>
        <ul>
          <li>
            Du ska inte ha något hudutslag eller finnar på den plats du vill
            behandla med Botox.
          </li>
          <li>
            Du ska inte ha någon sjukdomskänsla i kroppen. T.ex. feber,
            inflammation eller snuva.
          </li>
          <li>
            Du ska inte ha någon pågående herpesinfektion i varken kroppen eller
            injektionsområdet.
          </li>
          <li>Du bör heller inte vara gravid eller amma.</li>
        </ul>
        <Text>
          Tänk på att du dessutom enklare kan få mindre blåmärken av
          behandlingen om du ätit värktabletter såsom Alvedon eller Ipren. Vi
          rekommenderar istället att du köper en salva på apoteket, fråga på
          kliniken så att du får hjälp att hitta en produkt som passar för din
          hy.
        </Text>
        <br />
        <br />

        <SecondHeading id="heading4">
          5. Att tänka på efter din botoxbehandling
        </SecondHeading>
        <ul>
          <li>
            Du ska inte utföra någon peeling, massage eller liknande behandling
            på injektionsområdet under samma dag som behandlingen.
          </li>
          <li>
            Du bör undvika att träna eller utsätta dig för hög ansträngning
            under samma dag som behandlingen.
          </li>
          <li>
            Du ska inte bada bastu eller utsätta dig själv för starkt solljus
            under en längre period samma dag som behandlingen.
          </li>
          <li>
            Du ska undvika att applicera smink på injektionsområdet. Helst under
            en hel dag från behandlingen, men absolut minst är 6 timmar.
          </li>
        </ul>
        <Text>
          De första dagarna efter rynkbehandling med botox ser man ofta en lätt
          rodnad och svullnad i injektionsområdet. Det kan också vara lite ömt
          och klia en aning. Detta gäller för både rynkbehandlingar med botox
          och fillers.
        </Text>
        <br />
        <br />

        <SecondHeading id="heading5">
          6. Resultatet från behandlingen med botox
        </SecondHeading>
        <Text>
          Behandlingsresultatet från botox blir synligt från tre till fyra dagar
          efter behandlingen.
        </Text>
        <Text>
          Resultatet varar i genomsnitt upp till tre till fem månader. Många
          väljer därför att upprepa rynkbehandlingen var fjärde månad.
        </Text>
        <Text>
          En vanlig myt är att hud faller ihop eller blir mer rynkig om man
          slutar med behandlingen eller gör ett uppehåll. Det är inte sant.
        </Text>
        <Text>
          Ett uppehåll från behandlingen innebär enbart att din hy fortsätter
          att åldras i den takt den gjorde innan behandlingen påbörjades.
        </Text>
        <Text>
          Det är vanligt att man gör uppehåll i rynkbehandlingar och ingenting
          att vara rädd för.
        </Text>
        <br />
        <Text>
          Vill du boka en botox-behandling? Boka tandläkare eller sjuksköterska
          online på <Link to="/botox-behandling/rp/4">muntra.com</Link>!
        </Text>
        <br />
        <SmallText>
          Muntra tillhandahåller ej medicinsk rådgivning, diagnos eller
          behandling. Vänligen hänvisa medicinska frågor och problem till din
          vårdgivare.
        </SmallText>
      </Col>
    </Row>
  );
};

export default BotoxContent;
