import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class RolesProceduresService extends BaseService {
  constructor(url) {
    super(url);
    this.getRolesProcedures = this.getRolesProcedures.bind(this);
    this.getRoleProcedureById = this.getRoleProcedureById.bind(this);
  }

  async getRolesProcedures(params) {
    try {
      const response = await this.agent.get("", params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async getRoleProcedureById(id, params) {
    try {
      const response = await this.agent.get(`/${id}`, params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new RolesProceduresService(API_ROUTES.rolesProcedures);

export default ServiceInstance;
export { ServiceInstance as RolesProceduresService };
