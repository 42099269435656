import React, { memo } from "react";
import * as PropTypes from "prop-types";

import { MainCol, SearchPageHeader } from "./index.styled";

const SearchRow = ({ children, scrollItemsHeight, scrollFontSize }) => {
  return (
    <MainCol
      style={{
        height: scrollItemsHeight,
        transition: "0.2s",
      }}
    >
      <SearchPageHeader
        style={{
          width: "100%",
          minWidth: "400px",
          overflow: "hidden",
          transition: "0.1s",
          fontSize: scrollFontSize,
        }}
      >
        {children}
      </SearchPageHeader>
    </MainCol>
  );
};

SearchRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  scrollItemsHeight: PropTypes.string,
  scrollFontSize: PropTypes.string,
};

export default memo(SearchRow);
