import React, { memo } from "react";
import * as PropTypes from "prop-types";

import {
  BioTitle,
  MobileSectionForRender,
  P,
  StyledDivider,
} from "../../container/index.styled";

const ProfessionalStatement = ({ professionalStatement, translations }) => (
  <>
    <StyledDivider />
    <MobileSectionForRender>
      <BioTitle>{translations.career_desc}</BioTitle>
      <P itemProp="description">{professionalStatement}</P>
    </MobileSectionForRender>
  </>
);

ProfessionalStatement.propTypes = {
  professionalStatement: PropTypes.string,
  translations: PropTypes.object,
};

ProfessionalStatement.defaultProps = {
  professionalStatement: "",
};

export default memo(ProfessionalStatement);
