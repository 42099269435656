import React, { memo, useCallback, useState } from "react";
import { addDays, subDays } from "date-fns";
import * as PropTypes from "prop-types";

import { Row, Text } from "../common.styled";
import SendBookingModal from "./send-booking-modal";
import Slots from "./slots/slots";
import SlotsMobile from "./slots/slots-mobile";
import Wrapper from "./wrapper";

import { Inner, NextButton } from "./index.styled";

const Calendar = ({
  caregiver,
  clinic,
  location,
  translations,
  defaultProcedure,
  dateObject,
  caregiverAtLocationObject,
}) => {
  const { startDate, goToDate, timeStep } = dateObject || {};

  if (caregiverAtLocationObject?.noCaregiverAtLocation === undefined) {
    caregiverAtLocationObject.noCaregiverAtLocation = (
      <Text>{translations.r_no_available_times_to_book}</Text>
    );
  }

  const { caregiverAtLocation, caregiverAtLocationLoading } =
    caregiverAtLocationObject || {};

  const clinicId = clinic.id;
  const clinicEmail = clinic.attributes?.email;
  const caregiverId = caregiver.id;
  const middleDate = addDays(startDate, 1);
  const endDate = addDays(startDate, 2);

  const [isSendModalOpened, setIsSendModalOpened] = useState(false);

  const newCaregiverAtLocationObject = caregiverAtLocationObject;
  newCaregiverAtLocationObject.id = caregiverId;

  const goBack = useCallback(
    () => goToDate(subDays(startDate, timeStep)),
    [goToDate, startDate, timeStep],
  );
  const goNext = useCallback(
    () => goToDate(addDays(startDate, timeStep)),
    [goToDate, startDate, timeStep],
  );

  const onCloseModal = useCallback(() => {
    setIsSendModalOpened(false);
  }, []);

  const newDateObject = { startDate, goToDate };

  if (
    !location.attributes?.calendar_activated &&
    clinicEmail &&
    caregiverAtLocation?.attributes?.booking_requests_activated
  )
    return (
      <div>
        <Row style={{ justifyContent: "center" }} justify="center">
          <NextButton
            style={{ marginBottom: "15px" }}
            primary
            onClick={() => setIsSendModalOpened(true)}
          >
            <Inner>{translations.r_send_booking_request}</Inner>
          </NextButton>
        </Row>
        {isSendModalOpened && (
          <SendBookingModal
            caregiver={caregiver}
            clinicId={clinicId}
            defaultProcedure={defaultProcedure}
            onClose={onCloseModal}
            slotsId={caregiverId}
            translations={translations}
          />
        )}
      </div>
    );

  if (
    !location?.attributes?.calendar_activated &&
    (!clinicEmail ||
      !caregiverAtLocation?.attributes?.booking_requests_activated)
  )
    return (
      <Row style={{ justifyContent: "center" }}>
        <NextButton disabled secondary>
          <Inner>{translations.r_cant_be_booked}</Inner>{" "}
        </NextButton>
      </Row>
    );

  if (global.innerWidth < 994)
    return (
      <Wrapper
        isMobile
        goBack={goBack}
        startDate={startDate}
        goNext={goNext}
        loading={caregiverAtLocationLoading}
      >
        <SlotsMobile
          caregiver={caregiver}
          defaultProcedure={defaultProcedure}
          id={caregiverId}
          location={location}
          translations={translations}
          caregiverAtLocationObject={newCaregiverAtLocationObject}
          clinic={clinic}
          dateObject={newDateObject}
        />
      </Wrapper>
    );

  return (
    <Wrapper
      goBack={goBack}
      endDate={endDate}
      startDate={startDate}
      middleDate={middleDate}
      goNext={goNext}
      loading={caregiverAtLocationLoading}
    >
      <Slots
        caregiver={caregiver}
        defaultProcedure={defaultProcedure}
        location={location}
        translations={translations}
        caregiverAtLocationObject={newCaregiverAtLocationObject}
        clinic={clinic}
        dateObject={newDateObject}
      />
    </Wrapper>
  );
};

Calendar.propTypes = {
  caregiver: PropTypes.object,
  clinic: PropTypes.object,
  location: PropTypes.object,
  translations: PropTypes.object,
  defaultProcedure: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  dateObject: PropTypes.object,
  caregiverAtLocationObject: PropTypes.object,
};

Calendar.defaultProps = {
  caregiver: {},
  clinic: {},
};

export default memo(Calendar);
