import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useTranslations } from "hooks/use-translations";

import ProfileContent from "components/calendar/profile-content";
import { StyledLayout } from "components/common.styled";
import Footer from "components/footer";
import Header from "components/header";

import { Container } from "./index.styled";

const PatientProfile = () => {
  const authState = useSelector((state) => state.auth);

  const history = useHistory();

  const routeName = "patient-profile";
  const translations = useTranslations(routeName);

  useEffect(() => {
    if (!localStorage.getItem("user_token_pat_frontend"))
      history.push("/login");
  }, [authState, history]);

  return (
    <StyledLayout style={{ backgroundColor: "white" }}>
      <Helmet>
        <title>{`${translations.meta_title_patient_profile} | ${translations.meta_title_default}`}</title>
      </Helmet>
      <Header translations={translations} isStartPage={false} />
      <Container>
        <ProfileContent translations={translations} />
      </Container>
      <Footer translations={translations} />
    </StyledLayout>
  );
};

export default PatientProfile;
