const prefix = "[caregiver]";

const TYPES = {
  CAREGIVER_CLEAN_UP: `${prefix} CAREGIVER_CLEAN_UP`,
  CAREGIVER_CLEAR_BOOKING_DATA: `${prefix} CAREGIVER_CLEAR_BOOKING_DATA`,
  CAREGIVER_CLEAR_ERROR: `${prefix} CAREGIVER_CLEAR_ERROR`,
  CAREGIVER_FETCH_PROCEDURES: `${prefix} CAREGIVER_FETCH_PROCEDURES`,
  CAREGIVER_FETCH_PROCEDURES_SUCCESS: `${prefix} CAREGIVER_FETCH_PROCEDURES_SUCCESS`,
  CAREGIVER_FETCH_STATUS: `${prefix} CAREGIVER_FETCH_STATUS`,
  CAREGIVER_FIRST_NEAREST_SLOTS_FETCH_SUCCESS: `${prefix} CAREGIVER_FIRST_NEAREST_SLOTS_FETCH_SUCCESS`,
  CAREGIVER_FOR_BOOKING_REFRESH: `${prefix} CAREGIVER_FOR_BOOKING_REFRESH`,
  CAREGIVER_GET_BY_ID: `${prefix} CAREGIVER_GET_BY_ID`,
  CAREGIVER_GET_BY_ID_SUCCESS: `${prefix} CAREGIVER_GET_BY_ID_SUCCESS`,
  CAREGIVER_GET_SELECTED_SLOT: `${prefix} CAREGIVER_GET_SELECTED_SLOT`,
  CAREGIVER_INCREMENT_PERIOD: `${prefix} CAREGIVER_INCREMENT_PERIOD`,
  CAREGIVER_NEAREST_SLOTS_FETCH: `${prefix} CAREGIVER_NEAREST_SLOTS_FETCH`,
  CAREGIVER_PATCH_SEARCH_TERMS: `${prefix} CAREGIVER_PATCH_SEARCH_TERMS`,
  CAREGIVER_PATCH_SEARCH_TERMS_ERROR: `${prefix} CAREGIVER_PATCH_SEARCH_TERMS_ERROR`,
  CAREGIVER_PATCH_SEARCH_TERMS_SUCCESS: `${prefix} CAREGIVER_PATCH_SEARCH_TERMS_SUCCESS`,
  CAREGIVER_REVIEWS_CHANGE_PAGE: `${prefix} CAREGIVER_REVIEWS_CHANGE_PAGE`,
  CAREGIVER_REVIEWS_FETCH: `${prefix} CAREGIVER_REVIEWS_FETCH`,
  CAREGIVER_REVIEWS_FETCH_SUCCESS: `${prefix} CAREGIVER_REVIEWS_FETCH_SUCCESS`,
  CAREGIVER_SECOND_NEAREST_SLOTS_FETCH_SUCCESS: `${prefix} CAREGIVER_SECOND_NEAREST_SLOTS_FETCH_SUCCESS`,
  CAREGIVER_SLOTS_FETCH: `${prefix} CAREGIVER_SLOTS_FETCH`,
  CAREGIVER_SLOTS_FETCH_SUCCESS: `${prefix} CAREGIVER_SLOTS_FETCH_SUCCESS`,
  CAREGIVER_SLOTS_SEARCH_CHANGE_PAGE: `${prefix} CAREGIVER_SLOTS_SEARCH_CHANGE_PAGE`,
  CAREGIVER_SLOTS_SEARCH_FETCH: `${prefix} CAREGIVER_SLOTS_SEARCH_FETCH`,
  CAREGIVER_SLOTS_SEARCH_FETCH_SUCCESS: `${prefix} CAREGIVER_SLOTS_SEARCH_FETCH_SUCCESS`,
  CAREGIVERS_CHANGE_PAGE: `${prefix} CAREGIVERS_CHANGE_PAGE`,
  CAREGIVERS_CHANGE_SEARCH: `${prefix} CAREGIVERS_CHANGE_SEARCH`,
  CAREGIVERS_CHANGE_START_PAGE: `${prefix} CAREGIVERS_CHANGE_START_PAGE`,
  CAREGIVERS_FETCH: `${prefix} CAREGIVERS_FETCH`,
  CAREGIVERS_FETCH_ERROR: `${prefix} CAREGIVERS_FETCH_ERROR`,
  CAREGIVERS_FETCH_SUCCESS: `${prefix} CAREGIVERS_FETCH_SUCCESS`,
  CAREGIVERS_GET_FOR_AVATAR_SUCCESS: `${prefix} CAREGIVERS_GET_FOR_AVATAR_SUCCESS`,
  CAREGIVERS_SEARCH: `${prefix} CAREGIVERS_SEARCH`,
  CAREGIVERS_SEARCH_SUCCESS: `${prefix} CAREGIVERS_SEARCH_SUCCESS`,
  CAREGIVERS_SEARCH_TERMS_SUCCESS: `${prefix} CAREGIVERS_SEARCH_TERMS_SUCCESS`,
};

export const caregiversActions = {
  ...TYPES,
  changePageCaregivers: (page) => ({
    type: TYPES.CAREGIVERS_CHANGE_PAGE,
    payload: page,
  }),
  changeStartPageCaregivers: (page) => ({
    type: TYPES.CAREGIVERS_CHANGE_START_PAGE,
    payload: page,
  }),
  fetchCaregivers: (page) => ({
    type: TYPES.CAREGIVERS_FETCH,
    payload: page,
  }),
  fetchCaregiversSuccess: (caregivers) => ({
    type: TYPES.CAREGIVERS_FETCH_SUCCESS,
    payload: caregivers,
  }),
  fetchCaregiversError: (error) => ({
    type: TYPES.CAREGIVERS_FETCH_ERROR,
    payload: error,
  }),
  fetchCaregiverProcedures: (id) => ({
    type: TYPES.CAREGIVER_FETCH_PROCEDURES,
    payload: id,
  }),
  fetchCaregiverProceduresSuccess: (caregiverLocations, defaultProcedure) => ({
    type: TYPES.CAREGIVER_FETCH_PROCEDURES_SUCCESS,
    payload: { caregiverLocations, defaultProcedure },
  }),
  searchCaregivers: (search, page) => ({
    type: TYPES.CAREGIVERS_SEARCH,
    payload: { search, page },
  }),
  searchCaregiverSuccess: (caregivers) => ({
    type: TYPES.CAREGIVERS_SEARCH_SUCCESS,
    payload: caregivers,
  }),
  searchCaregiversChange: (search) => ({
    type: TYPES.CAREGIVERS_CHANGE_SEARCH,
    payload: search,
  }),
  getCaregiverById: (id) => ({
    type: TYPES.CAREGIVER_GET_BY_ID,
    payload: id,
  }),
  getCaregiverByIdSuccess: (caregiver) => ({
    type: TYPES.CAREGIVER_GET_BY_ID_SUCCESS,
    payload: caregiver,
  }),
  fetchCaregiverReviews: ({ id, page }) => ({
    type: TYPES.CAREGIVER_REVIEWS_FETCH,
    payload: { id, page },
  }),
  changePageCaregiverReviews: (page) => ({
    type: TYPES.CAREGIVER_REVIEWS_CHANGE_PAGE,
    payload: page,
  }),
  fetchCaregiverReviewsSuccess: (reviews) => ({
    type: TYPES.CAREGIVER_REVIEWS_FETCH_SUCCESS,
    payload: reviews,
  }),
  fetchCaregiverSlots: (payload) => ({
    type: TYPES.CAREGIVER_SLOTS_FETCH,
    payload,
  }),
  fetchCaregiverSlotsSuccess: (slots) => ({
    type: TYPES.CAREGIVER_SLOTS_FETCH_SUCCESS,
    payload: slots,
  }),
  changePageCaregiverSlotsSearch: (page) => ({
    type: TYPES.CAREGIVER_SLOTS_SEARCH_CHANGE_PAGE,
    payload: page,
  }),
  fetchCaregiverSlotsSearch: (payload) => ({
    type: TYPES.CAREGIVER_SLOTS_SEARCH_FETCH,
    payload,
  }),
  fetchCaregiverSlotsSearchSuccess: (slots) => ({
    type: TYPES.CAREGIVER_SLOTS_SEARCH_FETCH_SUCCESS,
    payload: slots,
  }),
  fetchNearestCaregiverSlots: (payload) => ({
    type: TYPES.CAREGIVER_NEAREST_SLOTS_FETCH,
    payload,
  }),
  fetchFirstNearestCaregiverSlotsSuccess: (slots) => ({
    type: TYPES.CAREGIVER_FIRST_NEAREST_SLOTS_FETCH_SUCCESS,
    payload: slots,
  }),
  fetchSecondNearestCaregiverSlotsSuccess: (slots) => ({
    type: TYPES.CAREGIVER_SECOND_NEAREST_SLOTS_FETCH_SUCCESS,
    payload: slots,
  }),
  getSelectedSlot: (slot, id, clinicId) => ({
    type: TYPES.CAREGIVER_GET_SELECTED_SLOT,
    payload: { slot, id, clinicId },
  }),
  changeFetchCaregiverStatus: (status) => ({
    type: TYPES.CAREGIVER_FETCH_STATUS,
    payload: status,
  }),
  postCaregiversSearchTermsSuccess: (data) => ({
    type: TYPES.CAREGIVERS_SEARCH_TERMS_SUCCESS,
    payload: data,
  }),
  patchSelectedSearchCaregiverTerms: (data) => ({
    type: TYPES.CAREGIVER_PATCH_SEARCH_TERMS,
    payload: data,
  }),
  patchSelectedSearchCaregiverTermsSuccess: (data) => ({
    type: TYPES.CAREGIVER_PATCH_SEARCH_TERMS_SUCCESS,
    payload: data,
  }),
  patchSelectedSearchCaregiverTermsError: (error) => ({
    type: TYPES.CAREGIVER_PATCH_SEARCH_TERMS_ERROR,
    payload: error,
  }),
  getCaregiversForAvatarSuccess: (data) => ({
    type: TYPES.CAREGIVERS_GET_FOR_AVATAR_SUCCESS,
    payload: data,
  }),
  caregiverForBookingRefresh: (data) => ({
    type: TYPES.CAREGIVER_FOR_BOOKING_REFRESH,
    payload: data,
  }),
  clearCaregiverForBooking: () => ({
    type: TYPES.CAREGIVER_CLEAR_BOOKING_DATA,
  }),
  clearCaregiverError: () => ({
    type: TYPES.CAREGIVER_CLEAR_ERROR,
  }),
  cleanUpCaregiver: () => ({
    type: TYPES.CAREGIVER_CLEAN_UP,
  }),
  incrementPeriod: (newValue) => ({
    type: TYPES.CAREGIVER_INCREMENT_PERIOD,
    payload: newValue,
  }),
};
