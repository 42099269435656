import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class PatientsClinicCurationsService extends BaseService {
  constructor(url) {
    super(url);
    this.postCurations = this.postCurations.bind(this);
    this.patchCurations = this.patchCurations.bind(this);
  }

  async postCurations(data) {
    try {
      const response = await this.agent.post("", data);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async patchCurations(id, data) {
    try {
      const response = await this.agent.patch(`/${id}`, data);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new PatientsClinicCurationsService(
  API_ROUTES.patientClinicCurations,
);

export default ServiceInstance;
export { ServiceInstance as PatientsClinicCurationsService };
