import { rolesActions as actions } from "./actions";

export const INIT_STATE = {
  list: [],
  error: "",
  searchLoading: false,
  searchResult: {},
  search: "",
  role: {},
  currentRole: {},
  loading: false,
  selectedRoles: [],
};
export const rolesReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case actions.ROLE_GET: {
      return {
        ...state,
        currentRole: [],
        loading: true,
      };
    }
    case actions.ROLE_SET: {
      return {
        ...state,
        currentRole: action.payload,
        loading: false,
      };
    }
    case actions.ROLE_FETCH:
    case actions.ROLES_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.ROLE_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        role: action.payload,
      };
    }
    case actions.ROLE_CLEAN: {
      return {
        ...state,
        role: {},
      };
    }
    case actions.ROLES_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    }
    case actions.ROLES_FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case actions.ROLES_SEARCH: {
      return {
        ...state,
        searchResult: {},
        searchLoading: true,
      };
    }
    case actions.ROLES_SEARCH_SUCCESS: {
      return {
        ...state,
        searchResult: action.payload,
        searchLoading: false,
      };
    }
    case actions.ROLES_CHANGE_SEARCH: {
      return {
        ...state,
        search: action.payload,
      };
    }
    case actions.ROLES_SET_SELECTED: {
      return {
        ...state,
        selectedRoles: action.payload,
      };
    }
    case actions.ROLES_CLEAN_UP: {
      return {
        ...state,
        currentRole: {},
      };
    }
    default:
      return state;
  }
};
