import styled from "styled-components";

import Button from "components/common/button";

export const ResultHeading = styled.div`
  @media (max-width: 993px) {
    font-size: 6vw;
  }
  @media (min-width: 994px) {
    font-size: 4vh;
    width: 50vw;
  }
  font-weight: 600;
  line-height: normal;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BookingResultContent = styled.div`
  margin: 0 40px;
`;

export const BookingConfirmContainer = styled.div`
  max-width: 700px;
  text-align: center;
  margin: 50px auto 224px auto;
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  @media (max-width: 993px) {
    flex-direction: column;
    gap: 3vw;
    justify-content: flex-start;
    margin: 0 8vw;
  }
`;

export const AppointmentButtons = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 5vh;
  height: 7vh;
  min-height: 60px;

  @media (max-width: 993px) {
    font-size: 5vw;
  }
  @media (min-width: 994px) {
    font-size: 3vh;
  }
  z-index: 10;
`;

export const NoteContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaabad;
  @media (max-width: 993px) {
    font-size: 3vw;
    margin: 5vw 8vw 40vw 8vw;
  }
  @media (min-width: 994px) {
    font-size: 2vh;
    margin: 5vh 0 30vh 0;
  }
  line-height: normal;
  font-weight: 600;
`;

export const BookingDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  @media (max-width: 993px) {
    font-size: 4vw;
    h1 {
      margin-top: 1vw;
    }
    margin: 0 8vw 10vw 8vw;
    ul {
      margin-block-end: 8vw;
      padding-inline-start: 5vw;
    }
    li {
      padding-bottom: 4vw;
    }
  }
  @media (min-width: 994px) {
    font-size: 3vh;
    h1 {
      margin-top: 1vh;
    }
    margin: 4vh 0;
    ul {
      margin-block-end: 4vh;
      padding-inline-start: 5vh;
    }
    li {
      padding-bottom: 4vh;
    }
  }
  line-height: normal;
  margin-top: 0px;
`;

export const BookingCancelled = styled.div`
  display: flex;
  text-align: center;
  @media (max-width: 993px) {
    font-size: 4vw;
    margin: 5vw 8vw 40vw 8vw;
  }
  @media (min-width: 994px) {
    font-size: 3vh;
    margin: 5vh 0 30vh 0;
  }
  line-height: normal;
  margin-top: 0px;
`;

export const InvalidBookingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  line-height: normal;

  @media (max-width: 993px) {
    font-size: 7vw;
  }
  @media (min-width: 994px) {
    font-size: 5.5vh;
  }
`;

export const BookingTimePassedContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  line-height: normal;
  flex-direction: column;
  gap: 10vh;
`;
export const BookingTimePassedTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 993px) {
    font-size: 7vw;
    padding: 0 10vw;
  }
  @media (min-width: 994px) {
    font-size: 5.5vh;
  }
`;

export const BookingTimePassedNote = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 993px) {
    font-size: 4vw;
  }
  @media (min-width: 994px) {
    font-size: 3vh;
  }
`;
