import React, { memo, useCallback, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as PropTypes from "prop-types";

import { Col, MobileSection } from "../../common.styled";
import Pagination from "../pagination";
import ReviewItem from "./review-item";

import { LocalStyledDivider, StyledSpin } from "./index.styled";

const Reviews = ({
  dispatchPage,
  itemReviewed,
  loading,
  pageReviews,
  pathname,
  reviews,
  translations,
}) => {
  const location = useLocation();
  const history = useHistory();

  const reviewsContent = useRef(null);

  const onPageChange = useCallback(
    (e) => {
      history.push(
        {
          pathname: location.pathname,
          query: { page: e },
        },
        {
          pathname,
          query: { page: e },
        },
      );
      if (reviewsContent.current) reviewsContent.current?.scrollIntoView();

      dispatchPage(+e);
    },
    [dispatchPage, history, location.pathname, pathname],
  );

  return (
    <Col ref={reviewsContent}>
      {!loading &&
        reviews?.data?.map(({ attributes }, index) => (
          <ReviewItem
            key={attributes?.id || index}
            translations={translations}
            patientName={attributes?.patient_name || ""}
            itemReviewed={itemReviewed}
            overallRating={attributes?.overall_rating || ""}
            reviewComment={attributes?.review_comment || ""}
            createdDate={attributes?.created_date || ""}
          />
        ))}
      {loading && <StyledSpin style={{ margin: "20px auto" }} />}
      {reviews?.meta?.pagination?.total_pages > 1 && (
        <div>
          <MobileSection>
            <Pagination
              limit={10}
              current={pageReviews}
              total={reviews.meta.pagination.total}
              onPageChanged={onPageChange}
            />
          </MobileSection>
          <LocalStyledDivider />
        </div>
      )}
    </Col>
  );
};

Reviews.propTypes = {
  dispatchPage: PropTypes.func,
  itemReviewed: PropTypes.string,
  loading: PropTypes.bool,
  pageReviews: PropTypes.number,
  pathname: PropTypes.string,
  reviews: PropTypes.object,
  translations: PropTypes.object,
};

export default memo(Reviews);
