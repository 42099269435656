import React, { memo, useState } from "react";
import * as PropTypes from "prop-types";

import { Row } from "../common.styled";

import { BottomMenuContainer, ButtonContainer, Icon } from "./index.styled";

const BottomMenu = ({ menuOptions, translations }) => {
  const [load, setLoad] = useState(true);

  return (
    <BottomMenuContainer onClick={() => setLoad(false)}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "12px 0 7px",
        }}
      >
        {menuOptions?.map((item) => {
          const activeCondition =
            (load && window.location.pathname === item.link) ||
            (load &&
              item.title === `${translations.header_explore_1}` &&
              window.location.pathname !== "/bookings" &&
              window.location.pathname !== "/invoices" &&
              window.location.pathname !== "/patient-profile");

          return (
            <ButtonContainer
              key={item.id}
              linkStatus={!!activeCondition}
              style={{ width: "72px" }}
              onClick={() => item?.action?.()}
            >
              <Row>
                <Icon style={{ margin: "0 auto" }}>{item.icon}</Icon>
              </Row>
              <Row
                style={{
                  textAlign: "center",
                  fontSize: "10px",
                  fontWeight: 600,
                }}
              >
                <span style={{ margin: "0 auto" }}> {item.title} </span>{" "}
              </Row>
            </ButtonContainer>
          );
        })}
      </div>
    </BottomMenuContainer>
  );
};

BottomMenu.propTypes = {
  menuOptions: PropTypes.array,
  translations: PropTypes.object,
};

BottomMenu.defaultProps = {
  menuOptions: [],
};

export default memo(BottomMenu);
