import styled from "styled-components";

import Button from "components/common/button";

export const NextButton = styled(Button)`
  margin-left: auto;
  margin-top: auto;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #d0d0d0;
  margin: 20px 0;
`;

export const BackButton = styled(Button)`
  padding: 0;
`;

export const LabelText = styled.p`
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 20px;
`;

export const SubLabelText = styled.p`
  margin: 0 0 10px 0;
`;

export const InputContainer = styled.textarea`
  border: 1px solid #00000036;
  border-radius: 2px;
  padding: 10px;
  display: flex;
  transition: all 0.3s;
  width: 100%;
  font-family: Arial, sans-serif;

  &:hover {
    border: 1px solid #1890ff;
  }

  &:focus-within {
    box-shadow: 0px 0px 1px 3px rgba(24, 143, 255, 0.31);
    border: 1px solid #1890ff;
  }
`;

export const OptionContainer = styled.div`
  padding: 8px;
  border: 1px solid #484848;
  border-radius: 2px;
  font-weight: bold;
  text-align: center;
  max-width: 150px;
  width: 32%;
  margin-right: 1%;
  cursor: pointer;
  background: ${(p) => (p.selected ? "#484848" : "white")};
  color: ${(p) => (p.selected ? "white" : "#484848")};
`;

export const Container = styled.div`
  padding-bottom: 42px;
`;
