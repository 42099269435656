import React from "react";
import { Helmet } from "react-helmet";

import { useTranslations } from "hooks/use-translations";

import {
  Heading,
  StyledContent,
  StyledLayout,
  Text,
} from "components/common.styled";
import Footer from "components/footer";
import Header from "components/header";

const Terms = () => {
  const routeName = "terms";
  const translations = useTranslations(routeName);

  return (
    <StyledLayout>
      <Helmet>
        <title>{`${translations.meta_title_terms} | ${translations.meta_title_default}`}</title>
        <meta
          id="meta-description"
          name="description"
          content={translations.meta_content_terms}
        />
      </Helmet>
      <Header translations={translations} isStartPage={false} />
      <StyledContent>
        <Heading>{translations.user_terms_heading}</Heading>
        <Text>{translations.user_terms_content_1}</Text>
        <Text>{translations.user_terms_content_2}</Text>
      </StyledContent>

      <Footer translations={translations} />
    </StyledLayout>
  );
};

export default Terms;
