import React, { memo, useCallback, useMemo } from "react";
import * as PropTypes from "prop-types";

import { Heading, Row } from "components/common.styled";

import {
  BackButton,
  Container,
  Divider,
  LabelText,
  NextButton,
  OptionContainer,
} from "./index.styled";

const Step10 = ({
  changeStep,
  clinicReview,
  handleClinicReviewUpdate,
  step,
  translations,
}) => {
  const options = useMemo(
    () => [
      {
        text: `${translations.patient_review_step_10_yes}`,
        value: true,
      },
      {
        text: `${translations.patient_review_step_10_no}`,
        value: false,
      },
      {
        text: `${translations.patient_review_step_10_dont_know}`,
        value: null,
      },
    ],
    [translations],
  );

  const questions = useMemo(
    () => [
      {
        text: `${translations.patient_review_step_10_waiting_room}`,
        selected: clinicReview.has_waiting_room,
      },
      {
        text: `${translations.patient_review_step_10_wifi}`,
        selected: clinicReview.has_wifi,
      },
      {
        text: `${translations.patient_review_step_10_toilet}`,
        selected: clinicReview.has_restroom,
      },
      {
        text: `${translations.patient_review_step_10_drinks}`,
        selected: clinicReview.has_drinks,
      },
      {
        text: `${translations.patient_review_step_10_magazines}`,
        selected: clinicReview.has_magazines,
      },
      {
        text: `${translations.patient_review_step_10_tv}`,
        selected: clinicReview.has_tv,
      },
      {
        text: `${translations.patient_review_step_10_music}`,
        selected: clinicReview.has_music,
      },
      {
        text: `${translations.patient_review_step_10_toys}`,
        selected: clinicReview.has_toys,
      },
    ],
    [
      clinicReview.has_drinks,
      clinicReview.has_magazines,
      clinicReview.has_music,
      clinicReview.has_restroom,
      clinicReview.has_toys,
      clinicReview.has_tv,
      clinicReview.has_waiting_room,
      clinicReview.has_wifi,
      translations,
    ],
  );

  const selectOption = useCallback(
    (number, option) => {
      switch (number) {
        case 0:
          handleClinicReviewUpdate({ has_waiting_room: option.value });
          break;
        case 1:
          handleClinicReviewUpdate({ has_wifi: option.value });
          break;
        case 2:
          handleClinicReviewUpdate({ has_restroom: option.value });
          break;
        case 3:
          handleClinicReviewUpdate({ has_drinks: option.value });
          break;
        case 4:
          handleClinicReviewUpdate({ has_magazines: option.value });
          break;
        case 5:
          handleClinicReviewUpdate({ has_tv: option.value });
          break;
        case 6:
          handleClinicReviewUpdate({ has_music: option.value });
          break;
        default:
          handleClinicReviewUpdate({ has_toys: option.value });
          break;
      }
    },
    [handleClinicReviewUpdate],
  );

  return (
    <>
      <Heading>
        <b>{translations.patient_review_step_10_was_there_waiting_room}</b>
      </Heading>
      <br />
      {questions.map((item, index) => (
        <Container key={index}>
          <LabelText>{item.text}</LabelText>
          <Row>
            {options.map((option, optionIndex) => (
              <OptionContainer
                selected={option.value === item.selected ? 1 : 0}
                onClick={() => selectOption(index, option)}
                key={optionIndex}
              >
                {option.text}
              </OptionContainer>
            ))}
          </Row>
        </Container>
      ))}
      <br />
      <Divider />
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <BackButton link onClick={() => changeStep(step - 1)}>
          {"<"} {`${translations.patient_review_step_10_back}`}
        </BackButton>
        <NextButton onClick={() => changeStep(step + 1)}>
          {`${translations.patient_review_step_10_next}`}
        </NextButton>
      </div>
    </>
  );
};

Step10.propTypes = {
  changeStep: PropTypes.func,
  clinicReview: PropTypes.object,
  handleClinicReviewUpdate: PropTypes.func,
  step: PropTypes.number,
  translations: PropTypes.object,
};

export default memo(Step10);
