import React, { useCallback, useMemo } from "react";
import * as PropTypes from "prop-types";

import { Heading, Row } from "components/common.styled";

import {
  BackButton,
  Container,
  Divider,
  LabelText,
  NextButton,
  OptionContainer,
  SubLabelText,
} from "./index.styled";

const Step9 = ({
  changeStep,
  clinicReview,
  handleClinicReviewUpdate,
  step,
  translations,
}) => {
  const options = useMemo(
    () => [
      {
        text: `${translations.patient_review_step_9_yes}`,
        value: true,
      },
      {
        text: `${translations.patient_review_step_9_no}`,
        value: false,
      },
      {
        text: `${translations.patient_review_step_9_dont_know}`,
        value: null,
      },
    ],
    [translations],
  );

  const questions = useMemo(
    () => [
      {
        text: `${translations.patient_review_step_9_disability_adapted}`,
        selected: clinicReview.handicap_accessible,
      },
      {
        text: `${translations.patient_review_step_9_handle_dental_fear}`,
        selected: clinicReview.capable_of_handling_fear_of_medical_procedures,
      },
    ],
    [
      clinicReview.capable_of_handling_fear_of_medical_procedures,
      clinicReview.handicap_accessible,
      translations,
    ],
  );

  const stairsQuestions = useMemo(
    () => [
      {
        text: `${translations.patient_review_step_9_ground_floor}`,
        selected: clinicReview.accessible_via_stairs,
      },
      {
        text: `${translations.patient_review_step_9_elevator_exists}`,
        selected: clinicReview.accessible_via_elevator,
      },
    ],
    [
      clinicReview.accessible_via_elevator,
      clinicReview.accessible_via_stairs,
      translations,
    ],
  );

  const selectOption = useCallback(
    (number, option) => {
      if (number === 0)
        handleClinicReviewUpdate({ handicap_accessible: option.value });

      if (number === 1)
        handleClinicReviewUpdate({
          capable_of_handling_fear_of_medical_procedures: option.value,
        });
    },
    [handleClinicReviewUpdate],
  );

  const selectStairsOption = useCallback(
    (number, option) => {
      if (number === 0)
        handleClinicReviewUpdate({ accessible_via_stairs: option.value });

      if (number === 1)
        handleClinicReviewUpdate({ accessible_via_elevator: option.value });
    },
    [handleClinicReviewUpdate],
  );

  return (
    <>
      <Heading>
        <b>{translations.patient_review_step_9_special_needs_adaptability}</b>
      </Heading>
      <br />

      <LabelText>{translations.patient_review_step_9_stairs}</LabelText>
      {stairsQuestions.map((item, index) => (
        <Container key={`sub-${index}`}>
          <SubLabelText>{item.text}</SubLabelText>
          <Row>
            {options.map((option, optionIndex) => (
              <OptionContainer
                selected={option.value === item.selected ? 1 : 0}
                onClick={() => selectStairsOption(index, option)}
                key={optionIndex}
              >
                {option.text}
              </OptionContainer>
            ))}
          </Row>
        </Container>
      ))}

      {questions.map((item, index) => (
        <Container key={index}>
          <LabelText>{item.text}</LabelText>
          <Row>
            {options.map((option, optionIndex) => (
              <OptionContainer
                selected={option.value === item.selected ? 1 : 0}
                onClick={() => selectOption(index, option)}
                key={optionIndex}
              >
                {option.text}
              </OptionContainer>
            ))}
          </Row>
        </Container>
      ))}
      <br />
      <Divider />
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <BackButton link onClick={() => changeStep(step - 1)}>
          {"<"} {translations.patient_review_step_9_back}
        </BackButton>
        <NextButton onClick={() => changeStep(step + 1)}>
          {translations.patient_review_step_9_next}
        </NextButton>{" "}
      </div>
    </>
  );
};

Step9.propTypes = {
  changeStep: PropTypes.func,
  clinicReview: PropTypes.object,
  handleClinicReviewUpdate: PropTypes.func,
  step: PropTypes.number,
  translations: PropTypes.object,
};

Step9.defaultProps = {
  clinicReview: {},
};

export default Step9;
