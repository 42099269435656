export const validatePersonalId = ({
  personalId = "",
  personalIdValidator = "//",
  translations = [],
}) => {
  let error = "";
  const regexWithoutFirstSlash = personalIdValidator?.replace(/(^\/)/, "");
  const regexWithoutLastSlash = regexWithoutFirstSlash?.replace(/(\/$)/, "");
  const regexp = new RegExp(regexWithoutLastSlash);

  if (personalId.trim() === "") {
    error = translations.review_content_simple_ssn_required;
  } else if (!regexp.test(personalId)) {
    error = translations.review_content_simple_ssn_invalid;
  }

  return error;
};

export const validatePhoneNumber = (proneNumber = "", translations = []) => {
  let error = "";
  const proneValidator = /^\+\d+$/;

  if (!proneValidator.test(proneNumber)) {
    error = translations.invalid_patient_cell_phone_format;
  }

  return error;
};

export const validateName = (name = "", translations = []) => {
  let error = "";

  if (name.length < 2) {
    error = translations.patient_full_name_required;
  }

  return error;
};

export const validateEmail = (email = "", translations = []) => {
  let error = "";

  const emailValidator =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!emailValidator.test(email)) {
    error = translations.invalid_email_address;
  }

  return error;
};

export const validateBookingField = ({
  field,
  personalIdValidator,
  setError,
  translations,
  value,
}) => {
  switch (field) {
    case "personalId": {
      const personalIdError = validatePersonalId({
        personalId: value,
        personalIdValidator,
        translations,
      });
      setError(personalIdError);
      return personalIdError === "";
    }
    case "firstName": {
      const validatePatFirstName = validateName(value, translations);
      setError(validatePatFirstName);

      return validatePatFirstName === "";
    }
    case "lastName": {
      const validatePatLastName = validateName(value, translations);
      setError(validatePatLastName);

      return validatePatLastName === "";
    }
    case "email": {
      const emailError = validateEmail(value, translations);
      setError(emailError);

      return emailError === "";
    }
    case "phoneNumber": {
      const phoneNumberError = validatePhoneNumber(value, translations);

      setError(phoneNumberError);

      return phoneNumberError === "";
    }
    default: {
      return true;
    }
  }
};
