import { all, call, put, takeEvery } from "redux-saga/effects";
import { GooglePlaceService } from "services/google-place-service";
import { SearchTermsService } from "services/search-terms-service";

import { formatDate, getItemFields } from "../../helpers/helper-functions";
import { getCountryCode } from "../../helpers/internationalization-functions";
import { googlePlacesActions as actions } from "./actions";

function* fetchGooglePlaces({ payload: params }) {
  const dataConfigReq = {
    params: {
      sensor: false,
      reference: params.id,
      language: localStorage.getItem("language"),
    },
  };
  const {
    data: { data: details },
  } = yield call(GooglePlaceService.requestsGooglePlaces, dataConfigReq);

  const dataConfigDetails = {
    params: {
      include: params.include,
    },
  };
  const res = yield call(
    GooglePlaceService.getGooglePlaceDetails,
    params.id,
    dataConfigDetails,
  );
  if (!res) {
    yield put(actions.fetchGooglePlacesError());
  }

  if (res?.data) {
    const {
      data: { relationships, ...rest },
      included,
    } = res.data;

    const results = getItemFields(relationships, included);
    yield put(
      actions.fetchGooglePlacesSuccess({
        info: { ...results, ...rest },
        ...details,
      }),
    );
  } else {
    yield put(actions.fetchGooglePlacesSuccess({ ...details }));
  }
}

function* fetchGooglePlacesMain({ payload: params }) {
  const dataConfig = {
    params: {
      include: params.include,
    },
  };
  const result = yield call(
    GooglePlaceService.getGooglePlaceDetails,
    params.id,
    dataConfig,
  );
  if (!result) {
    yield put(actions.fetchGooglePlacesError());
  }

  const { data: { data: { relationships, ...rest } = {}, included } = {} } =
    result || {};
  const results = getItemFields(relationships, included);
  const places = { ...results, ...rest };
  yield put(actions.fetchGooglePlacesMainSuccess(places));
}

function* searchGooglePlaces({ payload: { search, isAutocomplete, page } }) {
  const dataConfig = {
    params: {
      components: getCountryCode(),
      input: search,
      language: localStorage.getItem("language"),
      sensor: false,
      types: "(regions)",
    },
  };
  const { data: places } = yield call(
    GooglePlaceService.searchGooglePlaces,
    dataConfig,
  );

  if (!isAutocomplete) {
    const dataReq = {
      data: {
        attributes: {
          date: formatDate(new Date()),
          field: "geography",
          number_of_results: places?.data?.length,
          page,
          resulted_in_selection: false,
          search_term: search,
        },
        type: "muntra-search-terms",
      },
    };
    const terms = yield call(SearchTermsService.postSearchTerms, dataReq);
    if (terms?.data?.data) {
      yield put(actions.postGooglePlacesSearchTermsSuccess(terms?.data?.data));
    } else {
      actions.fetchGooglePlacesError();
    }
  }
  yield put(actions.searchGooglePlacesSuccess(places));
}

function* googlePlacePatchSearchTerms({ payload }) {
  const dataReq = {
    data: {
      attributes: {
        date: formatDate(new Date()),
        field: "geography",
        number_of_results: payload.attributes?.number_of_results,
        page: payload.attributes?.page,
        resulted_in_selection: true,
        search_term: payload.attributes?.search_term,
      },
      id: `${payload.id}`,
      type: "muntra-search-terms",
    },
  };
  yield call(SearchTermsService.patchSearchTerms, payload.id, dataReq);
}

export default function* googlePlacesSagas() {
  yield all([
    takeEvery(actions.GOOGLE_PLACES_FETCH, fetchGooglePlaces),
    takeEvery(actions.GOOGLE_PLACES_MAIN_FETCH, fetchGooglePlacesMain),
    takeEvery(actions.GOOGLE_PLACES_SEARCH, searchGooglePlaces),
    takeEvery(
      actions.GOOGLE_PLACES_PATCH_SEARCH_TERMS,
      googlePlacePatchSearchTerms,
    ),
  ]);
}
