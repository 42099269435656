import styled from "styled-components";

import { Col } from "components/common.styled";
import Rate from "components/common/stars";

export const NameTilte = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;

  @media (max-width: 380px) {
    max-width: 160px;
  }

  & a {
    color: #27272f !important;

    &:hover {
      color: #27272f;
      text-decoration: underline;
    }
  }
`;

export const ReviewsLink = styled.div`
  margin-bottom: 10px;
  font-weight: bold;

  & a {
    color: #27272f !important;

    &:hover {
      color: #27272f;
      text-decoration: underline;
    }
  }
`;

export const CalendarCol = styled(Col)`
  border: 1px solid #0000002b;
  padding: 20px 15px;
  border-radius: 2px;
`;

export const StyledDivider = styled.div`
  border-bottom: 1px solid #00000017;
  margin: 15px 0;
  width: 100%;
  @media (max-width: 993px) {
    margin: 0;
  }
`;

export const StyledRate = styled(Rate)``;
