import React, { memo, useState } from "react";
import {
  AdvancedMarker,
  APIProvider,
  InfoWindow,
  Map,
} from "@vis.gl/react-google-maps";
import * as PropTypes from "prop-types";

import { API_KEY, MAP_ID } from "constants/google-map";

const MapContainer = ({ language, lat, lng, placeName, placeTitle }) => {
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);

  const height = global.innerWidth < 994 ? "218px" : "480px";
  const borderRadius = global.innerWidth < 994 ? "12px" : "8px";
  const minWidth = global.innerWidth < 994 ? "100%" : "744px";
  const position = { lat: parseFloat(lat), lng: parseFloat(lng) };

  return (
    <APIProvider
      solutionChannel="GMP_devsite_samples_v3_rgmbasicmap"
      apiKey={API_KEY}
      language={language}
    >
      <Map
        mapId={MAP_ID}
        style={{ borderRadius, minWidth, height }}
        defaultCenter={position}
        defaultZoom={14}
        gestureHandling="greedy"
      >
        <AdvancedMarker
          onClick={() => setShowingInfoWindow(true)}
          position={position}
        />
        {showingInfoWindow && (
          <InfoWindow
            position={position}
            headerContent={placeTitle}
            onClose={() => setShowingInfoWindow(false)}
          >
            {placeName}
          </InfoWindow>
        )}
      </Map>
    </APIProvider>
  );
};

MapContainer.propTypes = {
  language: PropTypes.string,
  lat: PropTypes.string,
  lng: PropTypes.string,
  placeName: PropTypes.object,
  placeTitle: PropTypes.string,
};

export default memo(MapContainer);
