import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";

import CheckMarkIcon from "assets/svg-components/CheckMarIcon";

const CheckBoxesAndTextAreaContent = ({
  checkBoxPropertiesArray,
  header,
  healthDeclarationObject,
  otherText,
  setCheckBoxesUsed,
  setHealthDeclarationObject,
  setOtherText,
  showCheckBoxes,
  textAreaLabel,
  textAreaPlaceholder,
}) => {
  const [currentText, setCurrentText] = useState(otherText);
  const [debouncedText, setDebouncedText] = useState(currentText);

  const handleInputChange = ({ target }) => {
    setCurrentText(target.value);
  };

  const checkBoxPersistance = useCallback(() => {
    Object.entries(healthDeclarationObject).forEach(([key, value]) => {
      if (
        value &&
        document.getElementById(key)?.classList.contains("displayNone")
      ) {
        document.getElementById(key)?.classList.add("displayBlock");
        document
          .getElementById(`${key}CheckBox`)
          .classList.add("HealthDeclarationCheckBoxChecked");
      }
    });
  }, [healthDeclarationObject]);

  const checkBoxChecker = useCallback(
    (checkMarkId, checkBoxId) => {
      if (
        document.getElementById(checkMarkId).classList.contains("displayNone")
      ) {
        document.getElementById(checkMarkId).classList.remove("displayNone");
        document.getElementById(checkMarkId).classList.add("displayBlock");
        document
          .getElementById(checkBoxId)
          .classList.add("HealthDeclarationCheckBoxChecked");
        setHealthDeclarationObject({
          ...healthDeclarationObject,
          [checkMarkId]: true,
        });
        setCheckBoxesUsed(true);
      } else {
        document.getElementById(checkMarkId).classList.remove("displayBlock");
        document
          .getElementById(checkBoxId)
          .classList.remove("HealthDeclarationCheckBoxChecked");
        document.getElementById(checkMarkId).classList.add("displayNone");
        setHealthDeclarationObject({
          ...healthDeclarationObject,
          [checkMarkId]: false,
        });
      }
    },
    [healthDeclarationObject, setCheckBoxesUsed, setHealthDeclarationObject],
  );

  const renderCheckboxes = useMemo(() => {
    return checkBoxPropertiesArray?.map((checkBoxObject) => (
      <div
        className="flexRowCenter HealthDeclarationSmileyDiv"
        key={checkBoxObject.checkBoxText}
        onClick={() =>
          checkBoxChecker(
            checkBoxObject.checkBoxPropertyName,
            `${checkBoxObject.checkBoxPropertyName}CheckBox`,
          )
        }
        role="button"
        style={{ marginBottom: "15px" }}
      >
        <div
          className="HealthDeclarationCheckBox"
          name={checkBoxObject.checkBoxText}
          type="checkbox"
          id={`${checkBoxObject.checkBoxPropertyName}CheckBox`}
        >
          <CheckMarkIcon
            idProp={checkBoxObject.checkBoxPropertyName}
            classProp="displayNone"
          />
        </div>
        <label style={{ marginLeft: "5px" }}>
          {checkBoxObject.checkBoxText}
        </label>
      </div>
    ));
  }, [checkBoxChecker, checkBoxPropertiesArray]);

  useEffect(() => {
    checkBoxPersistance();
  }, [checkBoxPersistance]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedText(currentText);
    }, 350);

    return () => {
      clearTimeout(timerId);
    };
  }, [currentText]);

  useEffect(() => {
    setOtherText(debouncedText);
  }, [debouncedText, setOtherText]);

  return (
    <div
      className={`${showCheckBoxes ? "" : "FlexColJustCenter80vh"}`}
      style={{ width: "300px", height: showCheckBoxes ? "" : "80vh" }}
    >
      <h2
        className="bookingHeader"
        style={{ marginBottom: showCheckBoxes ? "1.5em" : "" }}
      >
        {header}
      </h2>
      {showCheckBoxes && (
        <div className="HealthDeclarationMedicineCheckboxColumn">
          {renderCheckboxes}
        </div>
      )}
      <div
        className="flexColStart"
        style={{
          marginTop: showCheckBoxes ? "5vh" : "0px",
          textAlign: "left",
        }}
      >
        <label>{textAreaLabel}</label>
        <span className="SubLabel">{textAreaPlaceholder}</span>
        <textarea
          className="HealthDeclarationTextArea"
          onChange={handleInputChange}
          value={currentText || ""}
        />
      </div>
    </div>
  );
};

CheckBoxesAndTextAreaContent.propTypes = {
  checkBoxPropertiesArray: PropTypes.array,
  header: PropTypes.string,
  healthDeclarationObject: PropTypes.object,
  otherText: PropTypes.string,
  setCheckBoxesUsed: PropTypes.func,
  setHealthDeclarationObject: PropTypes.func,
  setOtherText: PropTypes.func,
  showCheckBoxes: PropTypes.bool,
  textAreaLabel: PropTypes.string,
  textAreaPlaceholder: PropTypes.string,
};

export default memo(CheckBoxesAndTextAreaContent);
