import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";

import LeftArrowSVG from "assets/svg-components/LeftArrow";
import { arrangeCaregiversOptions } from "helpers/arrange-caregivers-options";
import { useDebounce } from "hooks/use-debounce";
import { useSignInValidation } from "hooks/use-sign-in-validation";
import { caregiversActions } from "redux/caregivers/actions";

import { Modal } from "components/common/modal";
import { LeftSearch } from "components/header/index.styled";

const SearchCaregiversModal = ({ onClose, translations, routeName }) => {
  const caregiversState = useSelector((state) => state.caregivers);

  const dispatch = useDispatch();
  const history = useHistory();

  const [optionsCaregiver, setOptionsCaregiver] = useState([]);

  const handleChangeCaregiversSearch = (value) => {
    dispatch(caregiversActions.searchCaregiversChange(value));
  };

  const searchCaregiversCb = useCallback(() => {
    if (caregiversState?.search?.length >= 3) {
      dispatch(
        caregiversActions.searchCaregivers(caregiversState?.search, routeName),
      );
    }
  }, [caregiversState?.search, dispatch, routeName]);

  useDebounce(searchCaregiversCb, [], 400);

  useSignInValidation();

  useEffect(() => {
    if (caregiversState?.searchResult)
      setOptionsCaregiver(
        arrangeCaregiversOptions(
          caregiversState.searchResult,
          dispatch,
          history,
        ),
      );
  }, [caregiversState?.searchResult, dispatch, history]);

  return (
    <Modal isMobileSearchModal onClose={onClose}>
      <div className="searchModalHeader flexWidth100 MobileModalSearchBar">
        <button className="ghostButton NoPadding" onClick={onClose}>
          <LeftArrowSVG />
        </button>
        <h1 className="MobileSearchModalHeader">
          {translations.start_search_modal}
        </h1>
      </div>
      <LeftSearch
        autoFocus
        className="MobileModalSearchBar"
        leftSearch
        value={caregiversState.search || ""}
        handleSearchChange={handleChangeCaregiversSearch}
        options={optionsCaregiver}
        isCaregiverSearchBar
        isLoading={caregiversState.searchLoading}
        placeholder={translations.start_search_c_placeholder}
        validText={translations.start_search_c_valid_text}
      />
    </Modal>
  );
};

SearchCaregiversModal.propTypes = {
  onClose: PropTypes.func,
  translations: PropTypes.object,
  routeName: PropTypes.string,
};

export default memo(SearchCaregiversModal);
