import styled from "styled-components";

import { Col, Row } from "components/common.styled";
import Button from "components/common/button";

export const ClinicButton = styled(Button)`
  padding: 0;
`;

export const ArrowButton = styled(Button)`
  font-size: 22px;
  padding: 0;
`;

export const LocationHeader = styled.div`
  background: #484848;
  padding: 5px 25px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;

  & h3 {
    color: white;
    margin-bottom: 0;
    font-size: 15px;
  }
`;

export const DateTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  line-height: 17px;
  font-size: 13px;
  min-height: 32px;
`;

export const DateRow = styled(Row)`
  margin-bottom: 7px;
`;

export const ScheduleContainer = styled.div`
  flex-grow: 1;

  display: flex;
  align-items: flex-start;
`;

export const ScheduleContent = styled.div`
  padding: 0px 15px;

  @media (max-width: 993px) {
    padding: 0px;
  }
`;

export const CenterRow = styled(Row)`
  justify-content: center;
  margin-top: 30px;
`;

export const CalendarContainer = styled(Col)`
  max-width: 600px;

  @media (max-width: 900px) {
    margin: 0 auto;
  }
`;

export const SCol = styled(Col)`
  flex: 1 1 0;
`;

export const StickyWrapper = styled.div`
  display: block;
  position: relative;
  bottom: 2em;
  align-self: flex-start;
  max-width: 100%;
  width: 100%;
  min-width: 420px;

  @media (max-width: 993px) {
    padding: 32px 0 0;
    max-width: 100%;
    min-width: 100%;
  }
  @media (min-width: 994px) {
    margin-top: 29px;
  }
`;

export const HoursButton = styled(Button)`
  @media (max-width: 530px) {
    font-size: 13px;
    padding: 3px 5px;
    line-height: 19px;
    height: auto;
  }

  & svg {
    height: 10px;
    align-self: center;
    margin-left: 7px;
  }

  & span {
    margin-left: 0;
  }

  @media (max-width: 388px) {
    & span.hide-on-mobile {
      display: none;
    }
  }
`;
