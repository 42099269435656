import React, { memo } from "react";
import * as PropTypes from "prop-types";

import { Col } from "components/common.styled";

import { ClinicOptionsDivider, ConditionTitle } from "../index.styled";

const Amenities = ({
  waitingRoom,
  wifi,
  restroom,
  magazines,
  music,
  tv,
  toys,
  drinks,
  translations,
}) => {
  if (
    !(
      waitingRoom ||
      wifi ||
      restroom ||
      magazines ||
      music ||
      tv ||
      toys ||
      drinks
    )
  )
    return null;

  return (
    <Col>
      <ConditionTitle>
        <b>{translations.amenities_heading}</b>
      </ConditionTitle>

      {waitingRoom && (
        <>
          <span>{translations.amenities_waiting_room}</span>
          <ClinicOptionsDivider />
        </>
      )}
      {wifi && (
        <>
          <span>{translations.amenities_wifi}</span>
          <ClinicOptionsDivider />
        </>
      )}
      {restroom && (
        <>
          <span>{translations.amenities_rest_room}</span>
          <ClinicOptionsDivider />
        </>
      )}
      {magazines && (
        <>
          <span>{translations.amenities_magazines}</span>
          <ClinicOptionsDivider />
        </>
      )}
      {music && (
        <>
          <span>{translations.amenities_music}</span>
          <ClinicOptionsDivider />
        </>
      )}
      {tv && (
        <>
          <span>{translations.amenities_tv}</span>
          <ClinicOptionsDivider />
        </>
      )}
      {toys && (
        <>
          <span>{translations.amenities_toys}</span>
          <ClinicOptionsDivider />
        </>
      )}
      {drinks && (
        <>
          <span>{translations.amenities_drinks}</span>
          <ClinicOptionsDivider />
        </>
      )}
    </Col>
  );
};

Amenities.propTypes = {
  waitingRoom: PropTypes.bool,
  wifi: PropTypes.bool,
  restroom: PropTypes.bool,
  magazines: PropTypes.bool,
  music: PropTypes.bool,
  tv: PropTypes.bool,
  toys: PropTypes.bool,
  drinks: PropTypes.bool,
  translations: PropTypes.object,
};

export default memo(Amenities);
