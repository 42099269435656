import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin: auto;
  margin-top: 30vh;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 20px;
`;

export const Background = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 102;
  position: fixed;
  background: white;
`;
