import React, { memo } from "react";
import * as PropTypes from "prop-types";

import {
  Album,
  Container,
  DefImg,
  GridContainer,
  GridRow,
  Images,
} from "./index.styled";

const ClinicImagesDesktop = ({
  images,
  toggleModal,
  isLoaded,
  setPickedImage,
}) => {
  const handleClick = (e) => {
    toggleModal();
    setPickedImage(e.target.id);
  };

  return (
    <Container>
      <Album>
        {images?.clinicDefaultImage && (
          <DefImg
            onlyDef={images?.clinicImages?.length === 0}
            isLoaded={isLoaded}
            id={0}
          >
            {images?.clinicDefaultImage?.attributes && (
              <img
                id={0}
                loading="lazy"
                onClick={handleClick}
                src={`${
                  images.clinicDefaultImage.attributes.original
                }?w=${1120}`}
                alt="Clinic default images"
              />
            )}
          </DefImg>
        )}
        {isLoaded &&
          images?.clinicImages.length !== 0 &&
          images?.clinicImages.length < 3 && (
            <GridContainer>
              {images?.clinicImages.map((item, index) => {
                return (
                  <Images
                    style={{
                      paddingBottom:
                        images?.clinicImages.length === 2 && index === 0
                          ? "8px"
                          : 0,
                    }}
                    id={
                      images?.clinicDefaultImage?.attributes ? index + 1 : index
                    }
                    key={
                      images?.clinicDefaultImage?.attributes ? index + 1 : index
                    }
                    count={images?.clinicImages.length}
                  >
                    {item?.attributes?.original && (
                      <img
                        id={index + 1}
                        loading="lazy"
                        onClick={handleClick}
                        src={`${item.attributes.original}?w=${560}`}
                        alt="Clinic images"
                      />
                    )}
                  </Images>
                );
              })}
            </GridContainer>
          )}
        {isLoaded &&
          images?.clinicImages.length !== 0 &&
          images?.clinicImages.length === 3 && (
            <GridContainer>
              <GridRow>
                <Images count={images?.clinicImages.length}>
                  {images?.clinicImages[0]?.attributes?.original && (
                    <img
                      id={1}
                      loading="lazy"
                      onClick={handleClick}
                      src={`${
                        images?.clinicImages[0]?.attributes?.original
                      }?w=${560}`}
                      alt="Clinic images"
                    />
                  )}
                </Images>
                <Images count={images?.clinicImages.length}>
                  {images?.clinicImages[1]?.attributes?.original && (
                    <img
                      id={2}
                      loading="lazy"
                      onClick={handleClick}
                      src={`${
                        images?.clinicImages[1]?.attributes?.original
                      }?w=${560}`}
                      alt="Clinic images"
                    />
                  )}
                </Images>
              </GridRow>
              <Images allWidth count={images?.clinicImages.length}>
                {images?.clinicImages[2]?.attributes?.original && (
                  <img
                    id={3}
                    loading="lazy"
                    onClick={handleClick}
                    src={`${
                      images?.clinicImages[2]?.attributes?.original
                    }?w=${560}`}
                    alt="Clinic images"
                  />
                )}
              </Images>
            </GridContainer>
          )}
        {isLoaded &&
          images?.clinicImages.length !== 0 &&
          images?.clinicImages.length >= 4 && (
            <GridContainer>
              <GridRow style={{ marginBottom: "8px" }}>
                <Images
                  style={{ marginRight: "8px" }}
                  count={images?.clinicImages.length}
                >
                  {images?.clinicImages[0]?.attributes?.original && (
                    <img
                      id={1}
                      loading="lazy"
                      onClick={handleClick}
                      src={`${
                        images?.clinicImages[0]?.attributes?.original
                      }?w=${560}`}
                      alt="Clinic images"
                    />
                  )}
                </Images>
                <Images count={images?.clinicImages.length}>
                  {images?.clinicImages[1]?.attributes?.original && (
                    <img
                      id={2}
                      loading="lazy"
                      onClick={handleClick}
                      src={`${
                        images?.clinicImages[1]?.attributes?.original
                      }?w=${560}`}
                      alt="Clinic images"
                    />
                  )}
                </Images>
              </GridRow>
              <GridRow>
                <Images
                  style={{ marginRight: "8px" }}
                  count={images?.clinicImages.length}
                >
                  {images?.clinicImages[2]?.attributes?.original && (
                    <img
                      id={3}
                      loading="lazy"
                      onClick={handleClick}
                      src={`${
                        images?.clinicImages[2]?.attributes?.original
                      }?w=${560}`}
                      alt="Clinic images"
                    />
                  )}
                </Images>
                <Images count={images?.clinicImages.length}>
                  {images?.clinicImages[3]?.attributes?.original && (
                    <img
                      id={4}
                      loading="lazy"
                      onClick={handleClick}
                      src={`${
                        images?.clinicImages[3]?.attributes?.original
                      }?w=${560}`}
                      alt="Clinic images"
                    />
                  )}
                </Images>
              </GridRow>
            </GridContainer>
          )}
      </Album>
    </Container>
  );
};

ClinicImagesDesktop.propTypes = {
  images: PropTypes.object,
  toggleModal: PropTypes.func,
  isLoaded: PropTypes.bool,
  setPickedImage: PropTypes.func,
};

ClinicImagesDesktop.defaultProps = {
  images: {},
};

export default memo(ClinicImagesDesktop);
