import styled from "styled-components";

export const Container = styled.div`
  margin: 50px 0;
  cursor: pointer;
  min-width: 250px;
  padding-bottom: 32px;
  border-bottom: solid 1px lightgrey;
  @media (max-width: 993px) {
    margin: 32px 0;
  }
`;

export const BoldText = styled.span`
  color: black;
  font-size: 16px;
`;
