import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import config from "config";
import { useTranslations } from "hooks/use-translations";

import { StyledContent, StyledLayout } from "components/common.styled";
import Footer from "components/footer";
import Header from "components/header";

import ToothWhiteningContent from "../components/tandblekning-content";

const ROUTE_NAME = "tandblekning";

const ToothWhitening = () => {
  const location = useLocation();
  const translations = useTranslations(ROUTE_NAME);

  return (
    <StyledLayout>
      <Helmet>
        <title>Tandblekning i praktiken: Allt du behöver veta</title>
        <meta
          id="meta-description"
          name="description"
          content="Konkret och praktisk guide för tandblekning som gör att du sparar pengar, minskar risk för skador och får vitare tänder."
        />
        <link
          id="canonical-link"
          rel="canonical"
          href={config.url + location.pathname.replace(/\?.*$/gm, "")}
        />
      </Helmet>
      <Header translations={translations} isStartPage={false} />
      <StyledContent>
        <ToothWhiteningContent />
      </StyledContent>
      <Footer translations={translations} />
    </StyledLayout>
  );
};

export default ToothWhitening;
