import React, { memo } from "react";
import * as PropTypes from "prop-types";

const NextButton = ({
  buttonText,
  disabled,
  distanceFromScreenBottom,
  noMargin,
  positionFixed,
  stepSetter,
}) => {
  return (
    <div
      className={`HealthDeclarationNextButtonDiv${
        positionFixed ? " positionFixed" : ""
      }`}
      style={{
        bottom: distanceFromScreenBottom || "",
      }}
    >
      <button
        className={`HealthDeclarationNextButton HealthDeclarationButton ${
          noMargin ? "noMargin" : ""
        }`}
        disabled={disabled}
        onClick={stepSetter}
      >
        {buttonText}
      </button>
    </div>
  );
};

NextButton.propTypes = {
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  distanceFromScreenBottom: PropTypes.string,
  noMargin: PropTypes.bool,
  positionFixed: PropTypes.bool,
  stepSetter: PropTypes.func,
};

NextButton.defaultProps = {
  noMargin: false,
};

export default memo(NextButton);
