import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import * as PropTypes from "prop-types";

import UserIcon from "../../../assets/svg-components/user-menu/UserIcon";
import { NavbarDivButton, Row } from "../../common.styled";

import { DropdownWrapper, MainDivButton, OptionsWrapper } from "./index.styled";

const Dropdown = ({ title, options, isIncluded, isIncludedMobile }) => {
  const [isOpened, setIsOpened] = useState(false);
  const dropdownMenuRef = useRef(null);

  const showMenu = useCallback((e) => {
    e.preventDefault();
    setIsOpened(true);
  }, []);

  const closeMenu = useCallback((e) => {
    if (
      dropdownMenuRef?.current &&
      !dropdownMenuRef.current.contains(e.target)
    ) {
      setIsOpened(false);
    }
  }, []);

  useEffect(() => {
    if (isOpened) {
      document.addEventListener("mousedown", closeMenu);
    }

    return () => {
      document.removeEventListener("mousedown", closeMenu);
    };
  }, [isOpened, closeMenu]);

  return (
    <DropdownWrapper>
      {!isIncludedMobile && (
        <MainDivButton opened={isOpened ? 1 : 0} onClick={showMenu}>
          {title}
        </MainDivButton>
      )}
      {isIncludedMobile && (
        <div>
          <MainDivButton
            isIncludedMobile={isIncludedMobile}
            opened={isOpened ? 1 : 0}
            onClick={showMenu}
          >
            <Row>
              <div style={{ margin: "0 auto" }}>
                <UserIcon />
              </div>
            </Row>
            <Row style={{ textAlign: "center", fontSize: "10px" }}>
              <span style={{ margin: "0 auto", textAlign: "center" }}>
                {title}
              </span>
            </Row>
          </MainDivButton>
        </div>
      )}
      {isOpened && (
        <OptionsWrapper
          ref={dropdownMenuRef}
          isIncluded={isIncluded ? 1 : 0}
          isIncludedMobile={isIncludedMobile ? 1 : 0}
        >
          {options.map((item, index) => (
            <NavbarDivButton
              secondary
              key={index}
              onClick={() => item?.action?.()}
            >
              {item.title}
            </NavbarDivButton>
          ))}
        </OptionsWrapper>
      )}
    </DropdownWrapper>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  isIncluded: PropTypes.bool,
  isIncludedMobile: PropTypes.bool,
};

Dropdown.defaultProps = {
  options: [],
};

export default memo(Dropdown);
