import React, { memo, useCallback, useState } from "react";
import { Collapse } from "react-collapse";
import * as PropTypes from "prop-types";

import AngleDown from "../../../assets/svg-components/AngleDown";
import { Col } from "../../common.styled";

import {
  CollapseButton,
  CollapseRow,
  RateRow,
  StyledRate,
} from "./index.styled";

const Rates = ({ item = {}, starSize, starMargin, translations }) => {
  const [isOpened, setIsOpened] = useState(false);

  const showDropdown = useCallback(() => setIsOpened(true), []);

  const hideDropdown = useCallback(() => setIsOpened(false), []);

  return (
    <>
      <CollapseRow>
        <Col xs={24} lg={24} xl={10}>
          <RateRow>
            {translations.rating_wait_time}{" "}
            <StyledRate
              margin={starMargin}
              size={starSize}
              defaultValue={item.attributes?.rating_wait_time}
            />
          </RateRow>
          <RateRow>
            {translations.rating_reception_on_arrival}{" "}
            <StyledRate
              margin={starMargin}
              size={starSize}
              defaultValue={item.attributes?.rating_reception_on_arrival}
            />
          </RateRow>
          <RateRow>
            {translations.rating_quality}{" "}
            <StyledRate
              margin={starMargin}
              size={starSize}
              defaultValue={item.attributes?.rating_quality}
            />
          </RateRow>
        </Col>
        <Col xs={24} lg={24} xl={10}>
          <RateRow>
            {translations.rating_bedside_manner}{" "}
            <StyledRate
              margin={starMargin}
              size={starSize}
              defaultValue={item.attributes?.rating_bedside_manner}
            />
          </RateRow>
          <RateRow>
            {translations.rating_hygiene}{" "}
            <StyledRate
              margin={starMargin}
              size={starSize}
              defaultValue={item.attributes?.rating_hygiene}
            />
          </RateRow>
          <RateRow>
            {translations.rating_thoroughness}{" "}
            <StyledRate
              margin={starMargin}
              size={starSize}
              defaultValue={item.attributes?.rating_thoroughness}
            />
          </RateRow>
        </Col>
      </CollapseRow>
      <Collapse isOpened={isOpened}>
        <CollapseRow>
          <Col xs={24} lg={24} xl={10}>
            <RateRow>
              {translations.info_rating_how_to_find_place}{" "}
              <StyledRate
                margin={starMargin}
                size={starSize}
                defaultValue={item.attributes?.info_rating_how_to_find_place}
              />
            </RateRow>
            <RateRow>
              {translations.info_rating_pre_meeting}{" "}
              <StyledRate
                margin={starMargin}
                size={starSize}
                defaultValue={item.attributes?.info_rating_pre_meeting}
              />
            </RateRow>
            <RateRow>
              {translations.info_rating_patient_health}{" "}
              <StyledRate
                margin={starMargin}
                size={starSize}
                defaultValue={item.attributes?.info_rating_patient_health}
              />
            </RateRow>
            <RateRow>
              {translations.info_rating_patient_treatment}{" "}
              <StyledRate
                margin={starMargin}
                size={starSize}
                defaultValue={item.attributes?.info_rating_patient_treatment}
              />
            </RateRow>
          </Col>
          <Col xs={24} lg={24} xl={10}>
            <RateRow>
              {translations.info_rating_advice_and_tips}{" "}
              <StyledRate
                margin={starMargin}
                size={starSize}
                defaultValue={item.attributes?.info_rating_advice_and_tips}
              />
            </RateRow>
            <RateRow>
              {translations.info_rating_pricing}{" "}
              <StyledRate
                margin={starMargin}
                size={starSize}
                defaultValue={item.attributes?.info_rating_pricing}
              />
            </RateRow>
            <RateRow>
              {translations.info_rating_follow_up}{" "}
              <StyledRate
                margin={starMargin}
                size={starSize}
                defaultValue={item.attributes?.info_rating_follow_up}
              />
            </RateRow>
          </Col>
        </CollapseRow>
      </Collapse>
      {!isOpened && (
        <CollapseButton link onClick={showDropdown}>
          {translations.rating_show} <AngleDown />
        </CollapseButton>
      )}
      {isOpened && (
        <CollapseButton link onClick={hideDropdown}>
          {translations.rating_hide}{" "}
          <AngleDown style={{ transform: "rotate(180deg)" }} />
        </CollapseButton>
      )}
    </>
  );
};

Rates.propTypes = {
  item: PropTypes.object,
  starSize: PropTypes.number,
  starMargin: PropTypes.string,
  translations: PropTypes.object,
};

export default memo(Rates);
