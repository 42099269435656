import styled from "styled-components";

import Button from "components/common/button";

export const ArrowButton = styled(Button)`
  font-size: 22px;
  padding: 0;
  justify-content: center;

  &[disabled] {
    svg {
      fill: #cecece;
    }
  }
`;

export const TransitionStep = styled.div`
  margin: auto;
  &.show {
    max-width: 1080px;
    max-height: 700px;
    & > img {
      max-width: 1180px;
      max-height: 700px;
      object-fit: cover;
    }
  }

  &.hide {
    display: none;
  }
`;

export const MainContainer = styled.div`
  contain: content !important;
  height: 100% !important;
  left: 0px !important;
  position: absolute !important;
  top: 0px !important;
  width: 100% !important;
  min-width: 320px;
`;

export const ImageContainer = styled.div`
  position: relative !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  top: 0px !important;
  height: 100% !important;
  width: 100% !important;
`;

export const ImageInnerContainer = styled.div`
  margin: auto;
  display: flex;
`;

export const Counter = styled.div`
  position: absolute;
  left: 50%;
  top: 50px;
`;
