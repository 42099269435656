import React, { createContext } from "react";
import { Helmet } from "react-helmet";
import * as PropTypes from "prop-types";

import useInternationalization from "hooks/use-internationalization";

export const InternationalizationContext = createContext();

const InternationalizationProvider = ({ children }) => {
  const contextValue = useInternationalization();

  const { getURLWithoutLanguage, languages } = contextValue;

  const url = getURLWithoutLanguage();

  return (
    <InternationalizationContext.Provider value={contextValue}>
      <Helmet>
        {languages.map((languageObject) => (
          <link
            key={languageObject.language}
            rel="alternate"
            href={`${url}`}
            hrefLang={languageObject.language}
          />
        ))}
      </Helmet>
      {children}
    </InternationalizationContext.Provider>
  );
};

export default InternationalizationProvider;

InternationalizationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
