import { COUNTRIES } from "constants/countries";
import { LANGUAGES } from "constants/languages";

export const getCountry = () =>
  localStorage.getItem("country") ?? COUNTRIES.sweden;

export const getCountryCode = () => {
  const selectedCountry = localStorage.getItem("country");
  if (!selectedCountry) {
    return `country:${COUNTRIES.sweden}`;
  }

  return `country:${selectedCountry}`;
};

export const getLanguage = () =>
  localStorage.getItem("language") ?? LANGUAGES.swedish;

export const getLocale = () =>
  localStorage.getItem("locale") ?? navigator.language;
