import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class SearchTermsService extends BaseService {
  constructor(url) {
    super(url);
    this.postSearchTerms = this.postSearchTerms.bind(this);
    this.patchSearchTerms = this.patchSearchTerms.bind(this);
  }

  async postSearchTerms(data) {
    try {
      const response = await this.agent.post("", data);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async patchSearchTerms(id, data) {
    try {
      const response = await this.agent.patch(`/${id}`, data);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new SearchTermsService(API_ROUTES.searchTerms);

export default ServiceInstance;
export { ServiceInstance as SearchTermsService };
