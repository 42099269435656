export const API_ROUTES = {
  acceptsReviewRequestsEmailSettings:
    "/muntra-accepts-review-requests-email-settings",
  bookings: "/muntra-bookings",
  bookingAttendees: "/muntra-booking-attendees",
  caregiverAtLocations: "/muntra-caregiver-at-locations",
  caregivers: "/muntra-caregivers",
  clinics: "/muntra-clinics",
  errorNotifications: "/error-notifications",
  locales: "/locales",
  patientClinicCurations: "/muntra-patient-clinic-curations",
  patients: "/muntra-patients",
  patientInvoices: "/muntra-patient-invoices",
  patientReviews: "/muntra-patient-reviews",
  procedures: "/muntra-procedures",
  publicPersonData: "/muntra-public-person-data",
  roles: "/muntra-roles",
  rolesProcedures: "/muntra-roles-procedures",
  searchResults: "/muntra-search-results",
  searchTerms: "/muntra-search-terms",
  translations: "/translations",
};
