import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Collapse } from "react-collapse";
import { useDispatch, useSelector } from "react-redux";
import { addDays, addHours, startOfDay } from "date-fns";
import * as PropTypes from "prop-types";

import AngleDown from "assets/svg-components/AngleDown";
import { formatDate, formatHours } from "helpers/helper-functions";
import { selectTag } from "redux/app/selectors";
import { caregiversActions } from "redux/caregivers/actions";

import { Row } from "../../common.styled";
import BookingModal from "../booking-modal/booking-modal";
import SendBookingModal from "../send-booking-modal";

import { Spinner } from "../../common/spinner/index.styled";
import {
  CenterRow,
  DateTitle,
  HoursButton,
  Inner,
  NextButton,
  SCol,
} from "../index.styled";

const SHOWN_SIZE = 4;

const Slots = ({
  caregiver,
  defaultProcedure,
  isSearch,
  location,
  translations,
  caregiverAtLocationObject,
  clinic,
  dateObject,
}) => {
  const dispatch = useDispatch();
  const locale = useSelector(selectTag);

  const {
    id,
    caregiverAtLocation,
    caregiverAtLocationLoadBegin,
    caregiverAtLocationLoading,
    noCaregiverAtLocation,
  } = caregiverAtLocationObject || {};

  const { id: clinicId } = clinic;
  const {
    booking_requires_two_factor_authentication:
      bookingRequiresTwoFactorAuthentication,
    email: clinicEmail,
  } = clinic?.attributes || {};
  const { startDate, goToDate } = dateObject || {};

  const [isOpened, setIsOpened] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState("");

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isSendModalOpened, setIsSendModalOpened] = useState(false);

  const middleDate = useMemo(() => addDays(startDate, 1), [startDate]);
  const endDate = useMemo(() => addDays(startDate, 2), [startDate]);

  const emptyRow = (index) => (
    <DateTitle key={index}>
      <span>-</span>
    </DateTitle>
  );

  const onHoursButtonClick = useCallback(
    (slotsData) => {
      setSelectedSlot(slotsData);
      setIsModalOpened(true);
      if (caregiverAtLocation.clinic) {
        dispatch(
          caregiversActions.caregiverForBookingRefresh(caregiverAtLocation),
        );
      } else {
        dispatch(
          caregiversActions.caregiverForBookingRefresh({
            ...caregiverAtLocation,
            clinic,
          }),
        );
      }
    },
    [caregiverAtLocation, clinic, dispatch],
  );

  const getActiveButton = useCallback(
    (hours, slotsData) => (
      <HoursButton onClick={() => onHoursButtonClick(slotsData)} primary>
        {hours}
      </HoursButton>
    ),
    [onHoursButtonClick],
  );

  const renderDateRow = useCallback(() => {
    const sortedArray = [
      ...(caregiverAtLocation?.free_bookable_slots || []),
    ].sort((a, b) => b.attributes.dtstart - a.attributes.dtstart);

    const startDateSlots = sortedArray?.filter(
      (item) =>
        formatDate(item.attributes.dtstart, "dd/MM/uuuu") ===
        formatDate(startDate, "dd/MM/uuuu"),
    );
    const middleDateSlots = sortedArray?.filter(
      (item) =>
        formatDate(item.attributes.dtstart, "dd/MM/uuuu") ===
        formatDate(middleDate, "dd/MM/uuuu"),
    );
    const endDaySlots = sortedArray?.filter(
      (item) =>
        formatDate(item.attributes.dtstart, "dd/MM/uuuu") ===
        formatDate(endDate, "dd/MM/uuuu"),
    );

    const firstColumn = startDateSlots.map((item) => (
      <DateTitle key={item.id}>
        {startDate
          ? getActiveButton(formatHours(item.attributes.dtstart, "H:mm"), item)
          : emptyRow}
      </DateTitle>
    ));

    const secondColumn = middleDateSlots.map((item) => (
      <DateTitle key={item.id}>
        {middleDate
          ? getActiveButton(formatHours(item.attributes.dtstart, "H:mm"), item)
          : emptyRow}
      </DateTitle>
    ));

    const thirdColumn = endDaySlots.map((item) => (
      <DateTitle key={item.id}>
        {endDate
          ? getActiveButton(formatHours(item.attributes.dtstart, "H:mm"), item)
          : emptyRow}
      </DateTitle>
    ));

    const maxLength = Math.max(
      firstColumn.length,
      secondColumn.length,
      thirdColumn.length,
    );
    firstColumn.length = maxLength;

    for (let i = 0; i < maxLength; i += 1) {
      if (!firstColumn[i]) firstColumn[i] = emptyRow(`start-${i}`);
      if (!secondColumn[i]) secondColumn[i] = emptyRow(`middle-${i}`);
      if (!thirdColumn[i]) thirdColumn[i] = emptyRow(`end-${i}`);
    }

    if (maxLength > SHOWN_SIZE) {
      const shownResultFirst = firstColumn.slice(0, SHOWN_SIZE);
      const hiddenResultFirst = firstColumn.slice(SHOWN_SIZE);

      const shownResultSecond = secondColumn.slice(0, SHOWN_SIZE);
      const hiddenResultSecond = secondColumn.slice(SHOWN_SIZE);

      const shownResultThird = thirdColumn.slice(0, SHOWN_SIZE);
      const hiddenResultThird = thirdColumn.slice(SHOWN_SIZE);

      const showMoreButton = (
        <DateTitle>
          <HoursButton secondary onClick={() => setIsOpened(!isOpened)}>
            <span className="hide-on-mobile">{translations.slots_more}</span>
            <AngleDown />
          </HoursButton>
        </DateTitle>
      );

      return (
        <Row>
          <SCol>
            {shownResultFirst}
            <Collapse isOpened={isOpened}>{hiddenResultFirst}</Collapse>
            {!isOpened && startDateSlots.length > SHOWN_SIZE && showMoreButton}
          </SCol>
          <SCol>
            {shownResultSecond}
            <Collapse isOpened={isOpened}>{hiddenResultSecond}</Collapse>
            {!isOpened && middleDateSlots.length > SHOWN_SIZE && showMoreButton}
          </SCol>
          <SCol>
            {shownResultThird}
            <Collapse isOpened={isOpened}>{hiddenResultThird}</Collapse>
            {!isOpened && endDaySlots.length > SHOWN_SIZE && showMoreButton}
          </SCol>
        </Row>
      );
    }
    if (maxLength < SHOWN_SIZE) {
      for (let i = 0; i < SHOWN_SIZE - maxLength; i += 1) {
        firstColumn.push(emptyRow(`start-empty-${i}`));
        secondColumn.push(emptyRow(`middle-empty-${i}`));
        thirdColumn.push(emptyRow(`end-empty-${i}`));
      }
    }

    return (
      <Row style={{ flexWrap: "nowrap" }}>
        <SCol>{firstColumn}</SCol>
        <SCol>{secondColumn}</SCol>
        <SCol>{thirdColumn}</SCol>
      </Row>
    );
  }, [
    caregiverAtLocation?.free_bookable_slots,
    endDate,
    getActiveButton,
    isOpened,
    middleDate,
    startDate,
    translations.slots_more,
  ]);

  useEffect(() => {
    setIsOpened(false);
  }, [caregiverAtLocation]);

  if (
    !location.attributes?.calendar_activated &&
    clinicEmail &&
    caregiverAtLocation?.attributes?.booking_requests_activated
  )
    return (
      <div>
        <CenterRow>
          <NextButton primary onClick={() => setIsSendModalOpened(true)}>
            <Inner>{translations.r_send_booking_request}</Inner>
          </NextButton>
        </CenterRow>
        {isSendModalOpened && (
          <SendBookingModal
            caregiver={caregiver}
            clinicId={clinicId}
            defaultProcedure={defaultProcedure}
            isSearch={isSearch}
            onClose={() => setIsSendModalOpened(false)}
            selectedSlot={selectedSlot}
            slotsId={id}
            translations={translations}
          />
        )}
      </div>
    );

  if (
    !location?.attributes?.calendar_activated &&
    (!clinicEmail ||
      !caregiverAtLocation?.attributes?.booking_requests_activated)
  )
    return (
      <CenterRow style={{ justifyContent: "center" }}>
        <NextButton disabled secondary>
          <Inner>{translations.r_cant_be_booked}</Inner>{" "}
        </NextButton>
      </CenterRow>
    );

  return (
    <>
      {!caregiverAtLocationLoadBegin &&
        caregiverAtLocation?.free_bookable_slots?.length > 0 &&
        renderDateRow()}
      {!caregiverAtLocationLoading &&
        caregiverAtLocation?.free_bookable_slots?.length === 0 &&
        caregiverAtLocation?.next_free_bookable_slot?.attributes && (
          <CenterRow>
            <NextButton
              onClick={() =>
                goToDate(
                  addHours(
                    startOfDay(
                      new Date(
                        caregiverAtLocation?.next_free_bookable_slot?.attributes?.dtstart,
                      ),
                    ),
                    2,
                  ),
                )
              }
            >
              <Inner>
                {`${translations.r_next_available_time}: ${formatDate(
                  caregiverAtLocation?.next_free_bookable_slot?.attributes
                    ?.dtstart,
                  "d MMM",
                  locale,
                ).replace(".", "")}`}
              </Inner>
            </NextButton>
          </CenterRow>
        )}

      {!caregiverAtLocationLoading &&
        (!caregiverAtLocation ||
          (caregiverAtLocation?.free_bookable_slots?.length === 0 &&
            !caregiverAtLocation?.next_free_bookable_slot?.attributes)) && (
          <CenterRow>{noCaregiverAtLocation}</CenterRow>
        )}

      {(caregiverAtLocationLoadBegin ||
        (caregiverAtLocationLoading &&
          caregiverAtLocation?.free_bookable_slots?.length === 0)) && (
        <div>
          <CenterRow>
            <Spinner />
          </CenterRow>
          <CenterRow style={{ margin: "5px 0 5px", fontSize: "13px" }}>
            {translations.r_checking_available_times}
          </CenterRow>
        </div>
      )}
      {isModalOpened && (
        <BookingModal
          bookingRequiresTwoFactorAuthentication={
            bookingRequiresTwoFactorAuthentication
          }
          clinicId={clinicId}
          defaultProcedure={defaultProcedure}
          isSearch={isSearch}
          selectedSlot={selectedSlot}
          slotsId={id}
          onClose={() => setIsModalOpened(false)}
          translations={translations}
        />
      )}
    </>
  );
};

Slots.propTypes = {
  caregiver: PropTypes.object,
  defaultProcedure: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  isSearch: PropTypes.bool,
  location: PropTypes.object,
  translations: PropTypes.object,
  caregiverAtLocationObject: PropTypes.object,
  clinic: PropTypes.object,
  dateObject: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    goToDate: PropTypes.func,
  }),
};

Slots.defaultProps = {
  defaultProcedure: {},
  caregiverAtLocationObject: {},
  clinic: {},
  dateObject: {},
};

export default memo(Slots);
