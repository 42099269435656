import React, { memo } from "react";
import * as PropTypes from "prop-types";

import { Input, Label, Mark, RadioWrapper } from "./index.styled";

const Radio = ({ name, children, value, checkedValue, onChange }) => (
  <RadioWrapper>
    <Label>
      <Input
        name={name}
        type="radio"
        value={value}
        checked={checkedValue === value}
        onChange={onChange}
      />
      <Mark />
      {children}
    </Label>
  </RadioWrapper>
);

Radio.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checkedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default memo(Radio);
