import styled, { css } from "styled-components";

import { StyledText } from "components/common.styled";
import Button from "components/common/button";

export const NameRoleHeader = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 5px 0;
`;

export const RescheduleButton = styled(Button)`
  position: absolute;
  bottom: 0;
  box-shadow: none;
  height: 32px;
  width: 100%;
  min-height: 60px !important;
  border-radius: 0 0 12px 12px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  color: #aaa;

  border-top: 1px solid rgb(221, 221, 221) !important;

  &:hover {
    color: #aaa;
  }

  ${(props) => {
    if (props.activeRescheduling)
      return css`
        color: rgb(24, 144, 255);

        background-color: transparent;
        box-shadow: none;

        &:hover {
          color: rgb(24, 144, 255);
          & a {
            color: rgb(255, 255, 255);
          }
          & svg {
            fill: rgb (247, 247, 247);
          }
          background-color: rgb(247, 247, 247) !important;
        }
      `;
  }}
`;

export const Container = styled.div`
  position: relative;
  border-radius: 12px !important;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12) !important;
  &:hover {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2) !important;
  }
  @media (max-width: 993px) {
    margin: 32px 0;
  }
`;

export const ImageContainer = styled.div`
  position: relative;

  & > div {
    position: absolute;
    bottom: 10px;
    left: 10px;
    & > img {
      border-radius: 50%;
      width: 94px;
      height: 94px;
      border: 1px solid #fff;
      object-fit: cover;
      margin: 0;
    }
  }
`;

export const TextContainer = styled.div`
  padding: 24px 24px 80px;
  @media (max-width: 993px) {
    padding: 24px 10px 80px;
  }

  ${(props) => {
    if (props.isPast)
      return css`
        padding: 24px 10px 24px;
        @media (max-width: 993px) {
          padding: 24px 10px 24px;
        }
      `;
  }}
`;

export const ClinicDefaultImage = styled.img`
  max-width: 100%;
  height: 180px;
  width: 100%;
  object-fit: cover;
  border-radius: 12px 12px 0 0;
`;

export const DateTimeText = styled(StyledText)`
  margin-bottom: 24px;
`;

export const RescheduleText = styled.span`
  margin: auto;
`;
