import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";

import AngleDown from "assets/svg-components/AngleDown";

import { SecondHeading } from "components/common.styled";

import {
  ArrowButton,
  Counter,
  ImageContainer,
  ImageInnerContainer,
  MainContainer,
  TransitionStep,
} from "./index.styled";

const ClinicImagesDesktopContent = ({ images, pickedImage }) => {
  const [activeImg, setActiveImg] = useState(0);

  const goBack = () => {
    setActiveImg(activeImg - 1);
  };

  const goRight = () => {
    setActiveImg(activeImg + 1);
  };

  const getClassName = (isActive) => (isActive ? "show" : "hide");

  useEffect(() => {
    if (pickedImage) setActiveImg(+pickedImage);
  }, [pickedImage]);

  return (
    <MainContainer>
      <Counter>
        <SecondHeading style={{ fontWeight: 500 }}>
          {`${activeImg + 1} / ${1 + (images?.clinicImages?.length || 0)}`}
        </SecondHeading>
      </Counter>

      <ImageContainer style={{ maxWidth: "1280px", display: "flex" }}>
        <ArrowButton
          style={{ position: "absolute", top: "50%", left: "20px" }}
          disabled={activeImg === 0}
          onClick={goBack}
          link
        >
          <AngleDown style={{ transform: "rotate(90deg)" }} />
        </ArrowButton>

        <ImageInnerContainer>
          {images?.clinicDefaultImage?.attributes && (
            <TransitionStep className={getClassName(activeImg === 0)}>
              <img
                loading="lazy"
                style={{ width: "100%", margin: "auto" }}
                id={0}
                src={`${
                  images.clinicDefaultImage.attributes.original
                }?h=${700}&w=${1280}`}
                alt="Clinic default images"
              />{" "}
            </TransitionStep>
          )}
          {images?.clinicImages?.map((item, index) => {
            return (
              <div style={{ margin: "auto" }} key={index + 1}>
                {item?.attributes?.original && (
                  <TransitionStep
                    className={getClassName(activeImg === index + 1)}
                  >
                    <img
                      loading="lazy"
                      id={index + 1}
                      style={{ width: "100%", margin: "auto" }}
                      src={`${item.attributes.original}?h=${700}&w=${1280}`}
                      alt="Clinic default images"
                    />{" "}
                  </TransitionStep>
                )}
              </div>
            );
          })}
        </ImageInnerContainer>

        <ArrowButton
          style={{ position: "absolute", top: "50%", right: "20px" }}
          disabled={images?.clinicImages.length === activeImg}
          onClick={goRight}
          link
        >
          <AngleDown style={{ transform: "rotate(270deg)" }} />
        </ArrowButton>
      </ImageContainer>
    </MainContainer>
  );
};

ClinicImagesDesktopContent.propTypes = {
  images: PropTypes.object,
  pickedImage: PropTypes.number,
};

export default ClinicImagesDesktopContent;
