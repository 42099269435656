import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import * as PropTypes from "prop-types";

import { Heading } from "components/common.styled";
import Rate from "components/common/stars";

import { Divider, NextButton } from "./index.styled";

const Step1 = ({
  caregiverReview,
  changeStep,
  handleCaregiverReviewUpdate,
  step,
  translations,
}) => {
  const { caregiver } = useSelector((state) => state.review);
  const { first_name: firstName = "", last_name: lastName = "" } =
    caregiver?.attributes || {};
  const { name: roleName = "" } = caregiver?.role?.attributes || {};

  const handleRateChange = useCallback(
    (value) => {
      handleCaregiverReviewUpdate({ overall_rating: value + 1 });
    },
    [handleCaregiverReviewUpdate],
  );

  return (
    <>
      {caregiver.attributes && (
        <Heading>
          <b>{`${translations.patient_review_step_1_how_was_visit} ${roleName} ${firstName} ${lastName}?`}</b>
        </Heading>
      )}
      <br />
      <Rate
        onClick={handleRateChange}
        size={35}
        defaultValue={caregiverReview.overall_rating || 0}
      />
      <Divider />
      <NextButton onClick={() => changeStep(step + 1)}>
        {translations.patient_review_step_1_next}
      </NextButton>
    </>
  );
};

Step1.propTypes = {
  caregiverReview: PropTypes.object,
  changeStep: PropTypes.func,
  handleCaregiverReviewUpdate: PropTypes.func,
  step: PropTypes.number,
  translations: PropTypes.object,
};

export default memo(Step1);
