import styled from "styled-components";

import {
  Col,
  MainSpinner,
  Row,
  StyledContent,
  StyledLayout,
} from "../common.styled";
import Rate from "../common/stars";

export const Content = styled(StyledContent)`
  max-width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  width: 100%;
  margin-top: 0;
  padding: 0;
  box-shadow: none;
  padding: 20px 50px;
`;

export const Layout = styled(StyledLayout)`
  background: white;
`;

export const SearchHeader = styled.div`
  background-color: white;
  width: 100%;
  padding: 0 7%;

  @media (max-width: 993px) {
    ${({ isMobile }) => isMobile && `padding: 0`};
  }
`;

export const SearchContent = styled(Content)`
  padding: 0 6%;
  @media (min-width: 994px) {
    padding: 20px 7%;
  }
`;

export const StyledRow = styled(Row)`
  justify-content: space-between;
`;

export const Breadcrumbs = styled.div`
  display: none;
  flex-wrap: wrap;

  & a {
    color: #1890ff;

    :hover {
      color: #40a9ff;
    }
  }

  & span {
    margin: 0px 5px;
  }
  margin-bottom: 0px;
  max-width: 583px;
  width: 100%;
  margin-right: 15%;

  @media (max-width: 1270px) {
    margin-right: 5%;
    max-width: 450px;
  }
`;

export const Spinner = styled(MainSpinner)`
  margin-top: 20vh;
  margin-bottom: 20vh;
  position: fixed;
`;
export const WrapperPosition = styled.div`
  max-width: 39.5%;
  min-width: 400px;
  width: 100%;
  padding: 0 50px;

  @media (max-width: 993px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const CaregiverAtLocationContainer = styled(Col)`
  width: 100%;
  padding: 0 50px;
  min-width: 400px;
  max-width: 39.5%;
  margin: auto 0;
  flex-direction: row;

  @media (max-width: 993px) {
    width: 100%;
  }
`;

export const ImgCap = styled.div`
  width: 78px;
  height: 78px;
  background-color: white;
  border-radius: 50%;
`;

export const StyledDivider = styled.div`
  border-bottom: 1px solid #00000017;
  margin: 15px 0;
  width: 100%;
  @media (max-width: 993px) {
    margin: 0;
  }
`;

export const StyledRate = styled(Rate)``;

export const NameTilte = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;

  & a {
    color: #27272f !important;

    &:hover {
      color: #27272f;
      text-decoration: underline;
    }
  }
`;

export const MainRow = styled(Row)`
  position: relative;
  padding: 0px;

  @media (max-width: 993px) {
    & p {
      margin-bottom: 5px;
    }
  }
`;

export const MainCol = styled(Col)`
  max-width: 39.5%;
  @media (max-width: 993px) {
    max-width: 100%;
  }
`;

export const SearchPageHeader = styled.h1`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 0px !important;
  margin-top: 8px;
  max-width: 39.5%;
  min-width: 400px;

  @media (max-width: 993px) {
    font-size: 12px;
    line-height: 14px;
    width: 100%;
    padding: 0px;
    text-align: center;
    margin-bottom: 8px !important;
    position: inherit !important;
    min-width: 100% !important;
  }
`;
