import React, { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import * as PropTypes from "prop-types";

import { Heading } from "components/common.styled";
import Rate from "components/common/stars";

import {
  BackButton,
  Container,
  Divider,
  LabelText,
  NextButton,
} from "./index.styled";

const Step4 = ({
  caregiverReview = {},
  changeStep,
  handleCaregiverReviewUpdate,
  step,
  translations,
}) => {
  const { caregiver } = useSelector((state) => state.review);
  const { first_name: firstName = "" } = caregiver.attributes || {};

  const options = useMemo(
    () => [
      {
        text: `${translations.patient_review_step_4_find_place}`,
        selected: +caregiverReview.info_rating_how_to_find_place,
      },
      {
        text: `${translations.patient_review_step_4_pre_info}`,
        selected: +caregiverReview.info_rating_pre_meeting,
      },
      {
        text: `${translations.patient_review_step_4_health_info}`,
        selected: +caregiverReview.info_rating_patient_health,
      },
      {
        text: `${translations.patient_review_step_4_care_info}`,
        selected: +caregiverReview.info_rating_patient_treatment,
      },
      {
        text: `${translations.patient_review_step_4_advice}`,
        selected: +caregiverReview.info_rating_advice_and_tips,
      },
      {
        text: `${translations.patient_review_step_4_price_info}`,
        selected: +caregiverReview.info_rating_pricing,
      },
      {
        text: `${translations.patient_review_step_4_post_info}`,
        selected: +caregiverReview.info_rating_follow_up,
      },
    ],
    [
      caregiverReview.info_rating_advice_and_tips,
      caregiverReview.info_rating_follow_up,
      caregiverReview.info_rating_how_to_find_place,
      caregiverReview.info_rating_patient_health,
      caregiverReview.info_rating_patient_treatment,
      caregiverReview.info_rating_pre_meeting,
      caregiverReview.info_rating_pricing,
      translations,
    ],
  );

  const handleOptionChange = useCallback(
    (value, number) => {
      switch (number) {
        case 0:
          handleCaregiverReviewUpdate({
            info_rating_how_to_find_place: value + 1,
          });
          break;
        case 1:
          handleCaregiverReviewUpdate({ info_rating_pre_meeting: value + 1 });
          break;
        case 2:
          handleCaregiverReviewUpdate({
            info_rating_patient_health: value + 1,
          });
          break;
        case 3:
          handleCaregiverReviewUpdate({
            info_rating_patient_treatment: value + 1,
          });
          break;
        case 4:
          handleCaregiverReviewUpdate({
            info_rating_advice_and_tips: value + 1,
          });
          break;
        case 5:
          handleCaregiverReviewUpdate({ info_rating_pricing: value + 1 });
          break;
        default:
          handleCaregiverReviewUpdate({ info_rating_follow_up: value + 1 });
          break;
      }
    },
    [handleCaregiverReviewUpdate],
  );

  return (
    <>
      {caregiver.attributes && (
        <Heading>
          <b>{`${translations.patient_review_step_4_help} ${firstName} ${translations.patient_review_step_4_patients_find_correct_info}`}</b>
        </Heading>
      )}
      <p>{translations.patient_review_step_4_this_is_private}</p>
      <br />

      {options.map((item, index) => (
        <Container key={index}>
          <LabelText>{item.text}</LabelText>
          <Rate
            onClick={(value) => handleOptionChange(value, index)}
            size={30}
            defaultValue={item.selected || 0}
          />
        </Container>
      ))}

      <br />
      <Divider />
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <BackButton link onClick={() => changeStep(step - 1)}>
          {"<"} {translations.patient_review_step_4_back}
        </BackButton>
        <NextButton onClick={() => changeStep(step + 1)}>
          {translations.patient_review_step_4_next}
        </NextButton>{" "}
      </div>
    </>
  );
};

Step4.propTypes = {
  caregiverReview: PropTypes.object,
  changeStep: PropTypes.func,
  handleCaregiverReviewUpdate: PropTypes.func,
  step: PropTypes.number,
  translations: PropTypes.object,
};

Step4.defaultProps = {
  caregiverReview: {},
};

export default memo(Step4);
