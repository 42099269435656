import React, { memo } from "react";
import * as PropTypes from "prop-types";

import {
  BioTitle,
  MobileSectionForRender,
  P,
  StyledDivider,
} from "../../container/index.styled";

const Education = ({ translations, educations = [] }) => (
  <>
    <StyledDivider />
    <MobileSectionForRender>
      <BioTitle>{translations.education_header_pf}</BioTitle>
      {educations?.map((item) => {
        const {
          attributes: { degree, field_of_study: fieldOfStudy, school },
        } = item;

        return (
          <P key={item.id}>{`${school || ""} - ${degree || ""} ${
            fieldOfStudy || ""
          }`}</P>
        );
      })}
    </MobileSectionForRender>
  </>
);

Education.propTypes = {
  translations: PropTypes.object,
  educations: PropTypes.array,
};

Education.defaultProps = {
  educations: [],
};

export default memo(Education);
