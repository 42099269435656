import styled from "styled-components";

export const StepperContainer = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 0;

  position: relative;
  min-height: 40px;

  & .stepper__step {
    width: 100px;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
  }

  & .stepper__step:after {
    content: "";
    background: #dedede;
    width: 120px;
    height: 2px;
    position: absolute;
    right: 60%;
    top: 10px;
    bottom: 0;
    z-index: 10;
  }

  & .stepper__step:first-child:after {
    display: none;
  }

  & .stepper__step__index {
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    background: #dedede;
    color: #999;
    text-align: center;
    margin-bottom: 5px;

    position: absolute;
    top: 0;
    z-index: 11;
  }

  & .stepper__step--done .stepper__step__index {
    background: rgb(24, 144, 255);
    color: #fff;
  }

  & .stepper__step--current .stepper__step__index {
    background: rgb(0, 122, 255);
    color: #fff;
  }

  & .stepper__step--done:after {
    background: rgb(24, 144, 255);
  }

  & .stepper__step--current:after {
    background: rgb(24, 144, 255);
  }

  & .stepper__step__label {
    color: #999;
    position: absolute;
    text-align: center;
    top: 25px;
  }

  & .stepper__step--done .stepper__step__label {
    color: rgb(24, 144, 255);
  }

  & .stepper__step--current .stepper__step__label {
    color: rgb(0, 122, 255);
  }
`;
