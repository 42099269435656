import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import * as PropTypes from "prop-types";

import { Heading } from "components/common.styled";

import {
  BackButton,
  Divider,
  InputContainer,
  NextButton,
} from "./index.styled";

const Step6 = ({
  caregiverReview,
  changeStep,
  handleCaregiverReviewUpdate,
  step,
  translations,
}) => {
  const { caregiver } = useSelector((state) => state.review);
  const { first_name: firstName = "" } = caregiver.attributes || {};

  const handleInputChange = useCallback(
    ({ target }) => {
      handleCaregiverReviewUpdate({ review_comment: target.value });
    },
    [handleCaregiverReviewUpdate],
  );

  return (
    <>
      <Heading>
        <b>{translations.patient_review_step_6_write_public_review}</b>
      </Heading>
      {caregiver.attributes && (
        <p>{`${translations.patient_review_step_6_tell_other_patients} ${firstName}`}</p>
      )}
      <br />
      <InputContainer
        rows="4"
        onChange={handleInputChange}
        value={caregiverReview.review_comment || ""}
        placeholder={translations.patient_review_step_6_describe_experience}
      />
      <br />
      <Divider />
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <BackButton link onClick={() => changeStep(step - 1)}>
          {"<"} {translations.patient_review_step_6_back}
        </BackButton>
        <NextButton onClick={() => changeStep(step + 1)}>
          {translations.patient_review_step_6_next}
        </NextButton>{" "}
      </div>
    </>
  );
};

Step6.propTypes = {
  caregiverReview: PropTypes.object,
  changeStep: PropTypes.func,
  handleCaregiverReviewUpdate: PropTypes.func,
  step: PropTypes.number,
  translations: PropTypes.object,
};

export default memo(Step6);
