import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import { useQuery } from "hooks/use-query";
import { useTranslations } from "hooks/use-translations";
import { appActions as actions } from "redux/app/actions";

import { MainSpinner, StyledLayout } from "components/common.styled";
import Footer from "components/footer";
import Header from "components/header";

import { Container, Content, Heading } from "./index.styled";

const ReviewRequestsEmailSetting = () => {
  const { loading, emailSettingsRes } = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const query = useQuery();

  const routeName = "review-requests-email-setting";
  const translations = useTranslations(routeName);

  const externalId = query.get("external_id");
  const acceptsReviewRequestEmails = query.get("accepts_review_request_emails");

  useEffect(() => {
    if (externalId && acceptsReviewRequestEmails) {
      const currentDate = `${new Date()}`.split("(")[0].trim();

      dispatch(
        actions.sendReviewRequestEmailSettings({
          currentDate,
          external_id: externalId,
          accepts_review_request_emails: acceptsReviewRequestEmails,
        }),
      );
    }
  }, [externalId, acceptsReviewRequestEmails, dispatch]);

  return (
    <StyledLayout style={{ backgroundColor: "white" }}>
      <Header translations={translations} isStartPage={false} />
      <Helmet>
        <title>{`${translations.meta_title_email_setting} | ${translations.meta_title_default}`}</title>
      </Helmet>
      <Container>
        {loading && <MainSpinner />}
        {!loading && !emailSettingsRes?.id && (
          <Content>
            <Heading>{translations.change_not_saved} </Heading>
          </Content>
        )}
        {!loading && emailSettingsRes?.id && (
          <Content>
            <Heading> {translations.change_saved} </Heading>
          </Content>
        )}
      </Container>
      <Footer translations={translations} />
    </StyledLayout>
  );
};

export default ReviewRequestsEmailSetting;
