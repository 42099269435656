import React from "react";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { getLanguage } from "helpers/internationalization-functions";
import initializeStore from "redux/store";
import Routes from "routes/routes";

import ErrorBoundary from "components/common/error-boundary";
import { EventProvider } from "components/common/event-bus";
import { ModalProvider } from "components/common/modal";

import "./index.css";
import "style/global.css";

const theme = { primary: "green" };
const store = initializeStore();

const App = () => {
  return (
    <>
      <Helmet>
        <html lang={getLanguage()} />
      </Helmet>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <EventProvider>
            <ErrorBoundary>
              <ModalProvider>
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </ModalProvider>
            </ErrorBoundary>
          </EventProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
};

export default App;
