import styled from "styled-components";

import Button from "../../common/button";

export const TextError = styled.p`
  color: #e40000;
  margin: 5px 0px;
`;

export const Switch = styled.div`
  position: relative;
  height: 40px;
  width: 100%;
  border: solid 1px #e1e1e4;
  border-radius: 2px;

  & .switch-label {
    position: relative;
    z-index: 2;
    float: left;
    width: 90px;
    line-height: 40px;

    font-size: 15px;
    text-align: center;
    cursor: pointer;
  }

  & .switch-input {
    display: none;
  }

  & .switch-input:checked + .switch-label {
    color: rgba(0, 0, 0, 0.65);
    transition: 0.15s ease-out;
    transition-property: color, text-shadow;
  }

  & .switch-input:checked + .switch-label-on ~ .switch-selection {
    left: 50%;
  }

  & .switch-selection {
    position: absolute;
    z-index: 1;
    top: -1px;
    left: 0px;
    display: block;
    width: 90px;
    height: 40px;
    border-radius: 2px;
    background-color: #e1e1e4;
    transition: left 0.15s ease-out;
  }
`;

export const Text = styled.p`
  margin-top: 10px;
  margin-bottom: 0;
`;

export const Divider = styled.div`
  border-bottom: 1px dashed #c9c9c9;
  margin: 20px 0 24px 0px;
  width: 100%;
`;

export const Container = styled.div`
  width: 60%;
`;

export const StyledText = styled(Text)`
  color: #484848;
  font-weight: 700;
  line-height: 24px;
  font-size: 15px;
  text-align: start;
`;

export const StyledButton = styled(Button)`
  min-height: 45px;
  height: 100%;
  padding: 15px 45px;
  font-weight: 700;
  font-size: 15px;
  text-align: center;
  @media (max-width: 740px) {
    max-width: 720px;
    width: 100%;
  }
`;

export const StyledContainer = styled(Container)`
  max-width: 640px;
  width: 100%;
`;
