import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";

import { mobileValidator } from "helpers/helper-functions";
import { authActions as actions } from "redux/auth/actions";

import AuthLoader from "../../common/auth-loader";
import { Modal } from "../../common/modal";
import AuthContent from "../auth-content";

const RegisterModal = ({ onClose, translations }) => {
  const authState = useSelector((state) => state.auth);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    return () => dispatch(actions.authErrorRefresh({}));
  }, [dispatch]);

  useEffect(() => {
    if (
      isFormSubmitted &&
      authState.authUserPersonalId?.data &&
      !authState.error?.data
    ) {
      onClose();
      history.push("/patient-profile");
    }
  }, [
    authState.authUserPersonalId?.data,
    authState.error?.data,
    history,
    isFormSubmitted,
    onClose,
  ]);

  const isMobile = mobileValidator.test(window.navigator.userAgent);

  return (
    <>
      <Modal onClose={onClose} style={{ maxWidth: 570, width: "100%" }}>
        <AuthContent
          isRegister
          setIsFormSubmitted={setIsFormSubmitted}
          translations={translations}
        />
      </Modal>

      {isMobile && authState.loader && (
        <AuthLoader translations={translations} />
      )}
    </>
  );
};

RegisterModal.propTypes = {
  onClose: PropTypes.func,
  translations: PropTypes.object,
};

RegisterModal.defaultProps = {
  onClose: () => {},
};

export default RegisterModal;
