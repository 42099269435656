import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";

import { useTranslations } from "hooks/use-translations";

import { Heading, StyledContent, StyledLayout } from "components/common.styled";
import Header from "components/header";

const PatientReviewConfirmation = () => {
  const history = useHistory();

  const routeName = "patient-review.confirmation";
  const translations = useTranslations(routeName);

  useEffect(() => {
    setTimeout(() => {
      history.push("/start");
    }, 3000);
  }, [history]);

  return (
    <StyledLayout>
      <Helmet>
        <title>{`${translations.meta_title_patient_review_conf} | ${translations.meta_title_default}`}</title>
      </Helmet>
      <Header translations={translations} isStartPage />
      <StyledContent style={{ boxShadow: "none" }}>
        <Heading>{translations.thanks_for_your_feedback}</Heading>
        <p> {translations.review_saved} </p>
        <p>
          {translations.in_three_sec_redirect}{" "}
          <Link to="/start">{translations.muntra_start_page}</Link>
        </p>
      </StyledContent>
    </StyledLayout>
  );
};

export default PatientReviewConfirmation;
