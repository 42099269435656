import React, { memo } from "react";
import * as PropTypes from "prop-types";

import {
  BioTitle,
  MobileSectionForRender,
  P,
  StyledDivider,
} from "../../container/index.styled";

const Languages = ({ translations, languages = [] }) => (
  <>
    <StyledDivider />
    <MobileSectionForRender>
      <BioTitle>{translations.spoken_languages}</BioTitle>
      {languages?.map((item) => {
        const {
          attributes: { name },
        } = item;
        return <P key={item.id}>{name}</P>;
      })}
    </MobileSectionForRender>
  </>
);

Languages.propTypes = {
  translations: PropTypes.object,
  languages: PropTypes.array,
};

Languages.defaultProps = {
  languages: [],
};

export default memo(Languages);
