import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";

import LeftArrowSVG from "assets/svg-components/LeftArrow";
import { arrangePlacesOptions } from "helpers/arrange-places-options";
import { useDebounce } from "hooks/use-debounce";
import { useSignInValidation } from "hooks/use-sign-in-validation";
import { googlePlacesActions } from "redux/google-places/actions";

import { Modal } from "components/common/modal";
import { RightSearch } from "components/header/index.styled";

const SearchLocationModal = ({ onClose, translations, routeName }) => {
  const placesState = useSelector((state) => state.googlePlaces);

  const dispatch = useDispatch();
  const history = useHistory();

  const [optionsPlaces, setOptionsPlaces] = useState([]);

  const handleChangePlacesSearch = useCallback(
    (value) => {
      dispatch(googlePlacesActions.searchGooglePlacesChange(value));
    },
    [dispatch],
  );

  const searchGooglePlacesCb = useCallback(() => {
    if (placesState?.search?.search?.length >= 3) {
      dispatch(
        googlePlacesActions.searchGooglePlaces(
          placesState?.search?.search,
          placesState?.search?.isAutocomplete,
          routeName,
        ),
      );
    }
  }, [
    dispatch,
    placesState?.search?.isAutocomplete,
    placesState?.search?.search,
    routeName,
  ]);

  useDebounce(searchGooglePlacesCb, [], 400);

  useSignInValidation();

  useEffect(() => {
    if (placesState?.searchResult?.data)
      setOptionsPlaces(
        arrangePlacesOptions(placesState?.searchResult, dispatch, history),
      );
  }, [dispatch, history, placesState?.searchResult]);

  return (
    <Modal isMobileSearchModal onClose={onClose}>
      <div className="searchModalHeader flexWidth100 MobileModalSearchBar">
        <button className="ghostButton NoPadding" onClick={onClose}>
          <LeftArrowSVG />
        </button>
        <h1 className="MobileSearchModalHeader">
          {translations.start_search_modal}
        </h1>
      </div>
      <RightSearch
        autoFocus
        className="MobileModalSearchBar"
        value={placesState.search?.search || ""}
        handleSearchChange={handleChangePlacesSearch}
        options={optionsPlaces}
        isLoading={placesState.searchLoading}
        placeholder={translations.start_search_p_placeholder}
        validText={translations.start_search_p_valid_text}
      />
    </Modal>
  );
};

SearchLocationModal.propTypes = {
  onClose: PropTypes.func,
  translations: PropTypes.object,
  routeName: PropTypes.string,
};

export default memo(SearchLocationModal);
