import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  border-radius: 12px !important;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12) !important;
  &:hover {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2) !important;
  }
  @media (max-width: 993px) {
    margin-bottom: 32px;
  }
`;

export const LocalHeader = styled.h3`
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  margin: 5px 0;
`;

export const ImageContainer = styled.div`
  position: relative;

  & > div {
    position: absolute;
    bottom: 10px;
    left: 10px;
    & > img {
      border-radius: 50%;
      width: 94px;
      height: 94px;
      border: 1px solid #fff;
      object-fit: cover;
      margin: 0;
    }
  }
`;

export const TextContainer = styled.div`
  padding: 24px 24px 24px;
  @media (max-width: 993px) {
    padding: 24px 10px 24px;
  }

  ${(props) => {
    if (props.isPast)
      return css`
        padding: 24px 10px 24px;
        @media (max-width: 993px) {
          padding: 24px 10px 24px;
        }
      `;
  }}
`;

export const ClinicDefaultImage = styled.img`
  max-width: 100%;
  height: 180px;
  width: 100%;
  object-fit: cover;
  border-radius: 12px 12px 0 0;
`;
