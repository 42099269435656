import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import * as PropTypes from "prop-types";

import {
  PageItem,
  PaginationContainer,
  PaginationContent,
} from "./index.styled";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const getRange = (from, to, step = 1) => {
  const range = [];

  for (let i = from; i <= to; i += step) range.push(i);

  return range;
};

const Pagination = ({ total, limit, current, onPageChanged, className }) => {
  const PAGE_NEIGHBORHOOD = 2;

  const [pages, setPages] = useState([]);

  const pageLimit = useMemo(
    () => (typeof limit === "number" ? limit : 30),
    [limit],
  );
  const totalRecords = useMemo(
    () => (typeof total === "number" ? total : 0),
    [total],
  );
  const totalPages = useMemo(
    () => Math.ceil(totalRecords / pageLimit),
    [pageLimit, totalRecords],
  );
  const totalNumbers = useMemo(() => {
    if (global.innerWidth <= 330) return PAGE_NEIGHBORHOOD * 1 + 2;
    if (global.innerWidth > 330 && global.innerWidth <= 375)
      return PAGE_NEIGHBORHOOD * 2 + 2;
    if (global.innerWidth > 375) return PAGE_NEIGHBORHOOD * 2 + 3;
  }, []);

  const handleClick = useCallback(
    (page, e) => {
      e.preventDefault();
      onPageChanged(page);
    },
    [onPageChanged],
  );

  const handleMoveLeft = useCallback(
    (e) => {
      e.preventDefault();
      onPageChanged(current - PAGE_NEIGHBORHOOD * 2 - 1);
    },
    [current, onPageChanged],
  );

  const handleMoveRight = useCallback(
    (e) => {
      e.preventDefault();
      onPageChanged(current + PAGE_NEIGHBORHOOD * 2 + 1);
    },
    [current, onPageChanged],
  );

  const getPageNumbers = useCallback(() => {
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = current - PAGE_NEIGHBORHOOD;
      const rightBound = current + PAGE_NEIGHBORHOOD;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = getRange(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = getRange(
          startPage - singleSpillOffset,
          startPage - 1,
        );
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = getRange(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return getRange(1, totalPages);
  }, [current, totalNumbers, totalPages]);

  useEffect(() => {
    setPages(getPageNumbers());
  }, [getPageNumbers]);

  if (!totalRecords) return null;
  if (totalPages === 1) return null;

  return (
    <PaginationContainer className={className}>
      <PaginationContent>
        {pages.map((page) => {
          if (page === LEFT_PAGE)
            return (
              <PageItem key={page}>
                <Link
                  to={`?page=${current - PAGE_NEIGHBORHOOD * 2 - 1}`}
                  onClick={handleMoveLeft}
                >
                  <span>&laquo;</span>
                </Link>
              </PageItem>
            );
          if (page === RIGHT_PAGE)
            return (
              <PageItem key={page}>
                <a
                  href={`?page=${current + PAGE_NEIGHBORHOOD * 2 + 1}`}
                  onClick={handleMoveRight}
                >
                  <span>&raquo;</span>
                </a>
              </PageItem>
            );

          return (
            <PageItem key={page} active={current === page ? 1 : 0}>
              <Link to={`?page=${page}`} onClick={(e) => handleClick(page, e)}>
                {page}
              </Link>
            </PageItem>
          );
        })}
      </PaginationContent>
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  total: PropTypes.number,
  limit: PropTypes.number,
  current: PropTypes.number,
  onPageChanged: PropTypes.func,
  className: PropTypes.string,
};

Pagination.defaultProps = {
  total: null,
  limit: 10,
  current: 1,
  onPageChanged: (f) => f,
  className: "",
};

export default memo(Pagination);
