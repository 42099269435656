import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import axios from "axios";
import * as queryString from "query-string";

import config from "config";
import { COUNTRIES, COUNTRIES_TITLE } from "constants/countries";
import { LANGUAGES, LANGUAGES_TITLE } from "constants/languages";
import { paramsToQuery } from "helpers/params-to-query";
import { useQuery } from "hooks/use-query";
import { appActions as actions } from "redux/app/actions";

const useInternationalization = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const query = useQuery();
  const history = useHistory();

  const { locale } = useSelector((state) => state.app);

  const gettingNewLocaleRef = useRef(false);

  const currentLocale = useMemo(
    () => query.get("language") || localStorage.getItem("language"),
    [query],
  );

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const countries = useMemo(
    () => [
      {
        country: COUNTRIES.sweden,
        text: COUNTRIES_TITLE.sweden,
      },
      {
        country: COUNTRIES.norway,
        text: COUNTRIES_TITLE.norway,
      },
      {
        country: COUNTRIES.denmark,
        text: COUNTRIES_TITLE.denmark,
      },
    ],
    [],
  );

  const languages = useMemo(
    () => [
      {
        language: LANGUAGES.swedish,
        text: LANGUAGES_TITLE.swedish,
      },
      {
        language: LANGUAGES.norwegian,
        text: LANGUAGES_TITLE.norwegian,
      },
      {
        language: LANGUAGES.danish,
        text: LANGUAGES_TITLE.danish,
      },
      {
        language: LANGUAGES.english,
        text: LANGUAGES_TITLE.english,
      },
    ],
    [],
  );

  const setCountry = (e) => {
    const countrySelected = e.target.value;
    localStorage.setItem("country", countrySelected);
    dispatch(actions.countryRefresh(countrySelected));
  };

  const setNewLanguage = useCallback((languageSelected) => {
    if (languageSelected) {
      localStorage.setItem("language", languageSelected);
    }
  }, []);

  const setLanguageInternal = useCallback(
    (languageSelected) => {
      setNewLanguage(languageSelected);
      dispatch(actions.getSpecificLocale(languageSelected));
    },
    [setNewLanguage, dispatch],
  );

  const setLanguage = (selectEvent) => {
    const languageSelected = selectEvent.target.value;
    if (languageSelected === "default") {
      return;
    }
    setLanguageInternal(languageSelected);
  };

  const getURLWithoutLanguage = () => {
    return config.url + pathname;
  };

  const checkIsLanguageSupported = useCallback(
    (currentLang) => {
      let nextLanguage = null;

      languages.forEach((languageItem) => {
        if (currentLang === languageItem.language) {
          nextLanguage = languageItem.language;
        }
      });

      return nextLanguage;
    },
    [languages],
  );

  useEffect(() => {
    const country = localStorage.getItem("country") ?? COUNTRIES.sweden;
    dispatch(actions.countryRefresh(country));
  }, [dispatch]);

  useEffect(() => {
    if (gettingNewLocaleRef.current) {
      return;
    }
    let currentLanguage = null;

    if (currentLocale) {
      currentLanguage = checkIsLanguageSupported(currentLocale);

      if (currentLanguage !== null) {
        if (
          currentLanguage !== localStorage.getItem("language") &&
          isInitialLoad
        ) {
          setLanguageInternal(currentLanguage);
        } else if (isInitialLoad) {
          dispatch(actions.getSpecificLocale(currentLanguage));
          setIsInitialLoad(false);
        }
      }
    }

    if (currentLanguage === null) {
      if (localStorage.getItem("language") === null) {
        gettingNewLocaleRef.current = true;
        dispatch(actions.getCurrentLocale());
      } else {
        currentLanguage = localStorage.getItem("language");
        dispatch(actions.getSpecificLocale(currentLanguage));
      }
    }
  }, [
    checkIsLanguageSupported,
    currentLocale,
    dispatch,
    isInitialLoad,
    languages,
    setLanguageInternal,
  ]);

  useEffect(() => {
    if (locale[0]?.attributes) {
      localStorage.setItem("locale", locale[0].attributes?.tag);
      axios.defaults.headers.common["Accept-Language"] =
        locale[0].attributes?.tag;
    }

    if (gettingNewLocaleRef.current) {
      let currentLanguage = locale[0]?.language?.attributes?.iso_code;
      const isValidLanguage = !!checkIsLanguageSupported(currentLanguage);

      if (!isValidLanguage && locale[0]) {
        currentLanguage = LANGUAGES.swedish;
      }

      setNewLanguage(currentLanguage);
      gettingNewLocaleRef.current = false;
    }
  }, [checkIsLanguageSupported, setNewLanguage, dispatch, locale]);

  useEffect(() => {
    const queryParams = queryString.parse(search);

    if (
      !queryParams.language ||
      queryParams?.language !== locale[0]?.language?.attributes?.iso_code
    ) {
      const newQueryParams = {
        ...queryParams,
        language: locale[0]?.language?.attributes?.iso_code,
      };
      const newSearch = paramsToQuery(newQueryParams);
      history.replace({ search: newSearch });
    }
  }, [history, locale, search]);

  return {
    countries,
    languages,
    setCountry,
    setLanguage,
    getURLWithoutLanguage,
  };
};

export default useInternationalization;
