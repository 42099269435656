import { BaseService } from "services/base-service";

class GooglePlaceService extends BaseService {
  constructor() {
    super();
    this.getGooglePlaceDetails = this.getGooglePlaceDetails.bind(this);
    this.searchGooglePlaces = this.searchGooglePlaces.bind(this);
    this.requestsGooglePlaces = this.requestsGooglePlaces.bind(this);
  }

  async getGooglePlaceDetails(id, params) {
    try {
      const response = await this.agent.get(
        `/muntra-google-place-details/${id}`,
        params,
      );

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async searchGooglePlaces(params) {
    try {
      const response = await this.agent.get(
        "/muntra-google-place-autocompletes",
        params,
      );

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async requestsGooglePlaces(params) {
    try {
      const response = await this.agent.get(
        "/muntra-google-place-detail-requests",
        params,
      );

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new GooglePlaceService();

export default ServiceInstance;
export { ServiceInstance as GooglePlaceService };
