import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class PatientsService extends BaseService {
  constructor(url) {
    super(url);
    this.getPatient = this.getPatient.bind(this);
    this.updatePatient = this.updatePatient.bind(this);
  }

  async getPatient(formData) {
    try {
      const response = await this.agent.get("/current", formData);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async updatePatient(id, data) {
    try {
      const response = await this.agent.patch(`/${id}`, data);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new PatientsService(API_ROUTES.patients);

export default ServiceInstance;
export { ServiceInstance as PatientsService };
