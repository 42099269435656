import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class CaregiversService extends BaseService {
  constructor(url) {
    super(url);
    this.getCaregivers = this.getCaregivers.bind(this);
    this.getCaregiverById = this.getCaregiverById.bind(this);
  }

  async getCaregivers(params) {
    try {
      const response = await this.agent.get("", params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async getCaregiverById(id, params) {
    try {
      const response = await this.agent.get(`/${id}`, params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new CaregiversService(API_ROUTES.caregivers);

export default ServiceInstance;
export { ServiceInstance as CaregiversService };
