import styled, { css } from "styled-components";

import { Col, Heading, MainSpinner, Row } from "../../common.styled";
import Button, { StretchButton } from "../../common/button";
import { Modal } from "../../common/modal";

export const StyledStretchButton = styled(StretchButton)`
  margin-top: 0;
  font-weight: 700;
  @media (max-width: 993px) {
    width: 100%;
  }

  ${(props) => {
    if (props.secondary)
      return css`
        box-shadow: none;
        margin-right: 10px;

        @media (max-width: 993px) {
          width: 100%;
          margin: 0 0 10px;
        }
      `;
  }}
`;

export const StyledSpinner = styled(MainSpinner)`
  margin: 40px auto;
  @media (max-width: 993px) {
    margin: 100px auto;
  }
`;

export const ButtonConfirmContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 993px) {
    flex-flow: column nowrap;
  }
`;

export const ButtonCompleteContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 30px;
  @media (max-width: 993px) {
    display: block;
    position: static;
    width: 100%;
    margin-top: 28px;
  }
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: 30px;
`;

export const RescheduleErrorContainer = styled.div`
  margin: 30vh auto 30vh;
  text-align: center;
`;

export const RescheduleModalContainer = styled(Modal)`
  max-width: 572px;
  min-height: 350px;
`;

export const CalendarContainer = styled(Col)`
  max-width: 600px;

  @media (max-width: 900px) {
    margin: 0 auto;
  }
`;

export const ScheduleContainer = styled.div`
  flex-grow: 1;

  display: flex;
  align-items: flex-start;
`;

export const ScheduleContent = styled.div`
  padding: 0px 15px;

  @media (max-width: 993px) {
    padding: 0px;
  }
`;

export const StickyWrapper = styled.div`
  display: block;
  position: relative;
  bottom: 2em;
  align-self: flex-start;
  max-width: 100%;
  width: 100%;
  min-width: 420px;

  @media (max-width: 993px) {
    padding: 32px 0 0;
    max-width: 100%;
    min-width: 100%;
  }
  @media (min-width: 994px) {
    margin-top: 29px;
  }
`;

export const CenterRow = styled(Row)`
  justify-content: center;
  margin-top: 30px;
`;

export const SCol = styled(Col)`
  flex: 1 1 0;
`;

export const DateTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  line-height: 17px;
  font-size: 13px;
  min-height: 32px;
  place-content: center;
  margin-bottom: 5px;
`;

export const HoursButton = styled(Button)`
  box-shadow: 2px 2px 0 #ddd;
  border: 2px solid rgb(24, 144, 255);
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0;
  width: 68.05px;

  @media (max-width: 530px) {
    font-size: 15px;
    padding: 5px 8px;
    line-height: 19px;
    height: auto;
  }

  & svg {
    height: 10px;
    align-self: center;
    margin-left: 7px;
  }

  & span {
    margin-left: 0;
  }

  @media (max-width: 388px) {
    & span.hide-on-mobile {
      display: none;
    }
  }
`;

export const Inner = styled.span`
  @media (max-width: 740px) {
    margin: 0 auto;
    font-size: 15px;
    font-weight: 700;
  }
`;

export const NextButton = styled(Button)`
  margin-bottom: 15px;

  @media (max-width: 740px) {
    font-size: 15px;
    font-weight: 700;
    width: 100%;
    margin-bottom: 14px;
    min-height: 42px;
  }
`;
