import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import usePageTracking from "hooks/use-page-tracking";
import useScrollRestoration from "hooks/use-scroll-restoration";
import UseUser from "hooks/use-user";
import InternationalizationProvider from "providers/internationalization-provider";

import ToasterContainer from "components/common/toaster/toaster-container";

import Caregiver from "pages/[first_param]/[second_param]/p/container/[caregiver_id]";
import Clinic from "pages/[first_param]/c/container/[clinic_id]";
import GooglePlace from "pages/[first_param]/g/[google_place_id]";
import Role from "pages/[first_param]/r/[role_id]";
import RoleProcedure from "pages/[first_param]/rp/[role_procedure_id]";
import BookingConfirm from "pages/b/n/[external_id]";
import Bookings from "pages/bookings/container";
import Botox from "pages/botox/container";
import ClinicDirectory from "pages/clinic-directory";
import CookiesInfo from "pages/cookies-info";
import Directory from "pages/directory";
import HealthDeclaration from "pages/health-declaration/container";
import Invoice from "pages/invoice";
import Invoices from "pages/invoices/container";
import Login from "pages/login";
import PatientProfile from "pages/patient-profile";
import PatientReviewConfirmation from "pages/patient-review/confirmation";
import PatientReviewNew from "pages/patient-review/new/container";
import ReviewRequestsEmailSetting from "pages/review-requests-email-setting";
import Search from "pages/search";
import StartPage from "pages/start/container";
import ToothWhitening from "pages/tandblekning/container";
import Terms from "pages/terms";
import TreatmentDirectory from "pages/treatment-directory";

const Routes = () => {
  useScrollRestoration();
  usePageTracking();

  return (
    <>
      <UseUser />
      <ToasterContainer />
      <Switch>
        <Route path="/login" exact>
          <InternationalizationProvider>
            <Login />
          </InternationalizationProvider>
        </Route>
        <Route path="/search" exact>
          <InternationalizationProvider>
            <Search />
          </InternationalizationProvider>
        </Route>
        <Route path="/:role/:name/p/:caregiver_id">
          <InternationalizationProvider>
            <Caregiver />
          </InternationalizationProvider>
        </Route>
        <Route path="/:clinic_name/c/:clinic_id">
          <InternationalizationProvider>
            <Clinic />
          </InternationalizationProvider>
        </Route>
        <Route path="/:google_place_name/g/:google_place_id">
          <InternationalizationProvider>
            <GooglePlace />
          </InternationalizationProvider>
        </Route>
        <Route path="/h/n/:booking_external_binding_id">
          <InternationalizationProvider>
            <HealthDeclaration />
          </InternationalizationProvider>
        </Route>
        <Route path="/:role_name/r/:role_id">
          <InternationalizationProvider>
            <Role />
          </InternationalizationProvider>
        </Route>
        <Route path="/:role_procedure_name/rp/:role_procedure_id">
          <InternationalizationProvider>
            <RoleProcedure />
          </InternationalizationProvider>
        </Route>
        <Route path="/b/n/:external_id">
          <InternationalizationProvider>
            <BookingConfirm />
          </InternationalizationProvider>
        </Route>
        <Route path={["/bookings", "/boka-om"]} exact>
          <InternationalizationProvider>
            <Bookings />
          </InternationalizationProvider>
        </Route>
        <Route path="/clinic-directory" exact>
          <InternationalizationProvider>
            <ClinicDirectory />
          </InternationalizationProvider>
        </Route>
        <Route path="/cookies-info" exact>
          <InternationalizationProvider>
            <CookiesInfo />
          </InternationalizationProvider>
        </Route>
        <Route path="/directory" exact>
          <InternationalizationProvider>
            <Directory />
          </InternationalizationProvider>
        </Route>
        <Route path="/invoice" exact>
          <InternationalizationProvider>
            <Invoice />
          </InternationalizationProvider>
        </Route>
        <Route path="/invoices" exact>
          <InternationalizationProvider>
            <Invoices />
          </InternationalizationProvider>
        </Route>
        <Route path="/patient-profile" exact>
          <InternationalizationProvider>
            <PatientProfile />
          </InternationalizationProvider>
        </Route>
        <Route path="/patient-review/new" exact>
          <InternationalizationProvider>
            <PatientReviewNew />
          </InternationalizationProvider>
        </Route>
        <Route path="/patient-review/confirmation" exact>
          <InternationalizationProvider>
            <PatientReviewConfirmation />
          </InternationalizationProvider>
        </Route>
        <Route path="/review-requests-email-setting" exact>
          <InternationalizationProvider>
            <ReviewRequestsEmailSetting />
          </InternationalizationProvider>
        </Route>
        <Route path="/teeth-whitening" exact>
          <InternationalizationProvider>
            <ToothWhitening />
          </InternationalizationProvider>
        </Route>
        <Route path="/botox" exact>
          <InternationalizationProvider>
            <Botox />
          </InternationalizationProvider>
        </Route>
        <Route path="/terms" exact>
          <InternationalizationProvider>
            <Terms />
          </InternationalizationProvider>
        </Route>
        <Route path="/treatment-directory" exact>
          <InternationalizationProvider>
            <TreatmentDirectory />
          </InternationalizationProvider>
        </Route>
        <Route path={["/start", ""]} exact>
          <InternationalizationProvider>
            <StartPage />
          </InternationalizationProvider>
        </Route>
        <Redirect to="/start" />
      </Switch>
    </>
  );
};

export default Routes;
