import { clinicsActions as actions } from "./actions";

export const INIT_STATE = {
  data: {},
  error: "",
  page: 1,
  loading: false,
  loadingReviews: false,
  loadingCaregivers: false,
  clinic: {},
  reviews: {},
  pageReviews: 1,
  slotsLoading: false,
  slotsLoadBegin: false,
  slots: [],
  defaultClinicProcedure: {},
  fetchClinicDataStatus: false,
  clinicImages: {},
};

export const clinicsReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case actions.CLINICS_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.CLINICS_CHANGE_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    case actions.CLINICS_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    }
    case actions.CLINICS_FETCH_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case actions.CLINIC_GET_BY_ID:
    case actions.CLINIC_GET_PROCEDURE_BY_CLINIC_ID: {
      return {
        ...state,
        loading: true,
        id: action.payload,
      };
    }
    case actions.CLINIC_GET_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        clinic: action.payload,
      };
    }
    case actions.CLINIC_GET_CAREGIVERS_BY_CLINIC_ID:
      return {
        ...state,
        loadingCaregivers: true,
      };
    case actions.CLINIC_GET_PROCEDURE_BY_CLINIC_ID_SUCCESS: {
      const images = {
        clinicDefaultImage: action.payload.default_clinic_image,
        clinicImages: action.payload.clinic_images,
      };
      return {
        ...state,
        loading: false,
        clinic: {
          ...action.payload,
          caregiver_locations: state.clinic.caregiver_locations || [],
        },
        clinicImages: images,
      };
    }
    case actions.CLINIC_GET_CAREGIVERS_BY_CLINIC_ID_SUCCESS: {
      const resProcedure = action.payload[0]?.default_procedure?.procedure;
      const procedure = {
        type: resProcedure?.type,
        id: resProcedure?.id,
        title: resProcedure?.attributes?.name,
        selected: false,
        isLoaded: true,
      };
      return {
        ...state,
        loadingCaregivers: false,
        defaultClinicProcedure: procedure,
        clinic: { ...state.clinic, caregiver_locations: action.payload },
      };
    }
    case actions.CLINIC_GET_CAREGIVERS_FETCH_ERROR: {
      return {
        ...state,
        loadingCaregivers: false,
      };
    }
    case actions.CLINIC_GET_BY_ID_FULL: {
      return {
        ...state,
        slotsLoading: true,
        slotsLoadBegin: true,
        id: action.payload,
      };
    }
    case actions.CLINIC_GET_BY_ID_FULL_SUCCESS: {
      if (action.payload.loadComplete)
        return {
          ...state,
          slotsLoading: false,
          clinic: action.payload,
        };
      return {
        ...state,
        slotsLoadBegin: false,
        clinic: action.payload,
      };
    }
    case actions.CLINIC_REVIEWS_CHANGE_PAGE: {
      return {
        ...state,
        pageReviews: action.payload,
      };
    }
    case actions.CLINIC_REVIEWS_FETCH: {
      return {
        ...state,
        loadingReviews: true,
      };
    }
    case actions.CLINIC_REVIEWS_FETCH_SUCCESS: {
      return {
        ...state,
        loadingReviews: false,
        reviews: action.payload,
      };
    }
    case actions.CLINIC_SLOTS_FETCH: {
      return {
        ...state,
        slotsLoading: true,
      };
    }
    case actions.CLINIC_SLOTS_FETCH_SUCCESS: {
      return {
        ...state,
        slotsLoading: false,
        slots: action.payload,
      };
    }
    case actions.CLINIC_FETCH_DATA_COMPLETED: {
      return {
        ...state,
        fetchClinicDataStatus: action.payload,
      };
    }
    default:
      return state;
  }
};
