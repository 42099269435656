import { translationsActions as actions } from "./actions";

export const INIT_STATE = {
  translations: {},
  error: "",
};

export const translationsReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case actions.TRANSLATIONS_REFRESH: {
      return {
        ...state,
        translations: action.payload,
      };
    }
    case actions.GET_TRANSLATIONS_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};
