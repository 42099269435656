import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class ProceduresService extends BaseService {
  constructor(url) {
    super(url);
    this.getProcedures = this.getProcedures.bind(this);
  }

  async getProcedures(params) {
    try {
      const response = await this.agent.get("", params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new ProceduresService(API_ROUTES.procedures);

export default ServiceInstance;
export { ServiceInstance as ProceduresService };
