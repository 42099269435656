import React from "react";

const SmileyNeutral = ({ classProp }) => (
  <svg
    className={`HealthDeclarationSmileySVGSize ${classProp}`}
    viewBox="178.26 87.65 79.96 79.96"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    y="0px"
  >
    <circle className="st0 yellowSt0" cx="218.24" cy="127.63" r="37.98" />

    <path
      className="st0 yellowSt0"
      d="M198.12,139.53c3.74,0.11,8.24,0.21,20.06,0.24c8.79,0.02,9.81,0.12,20.19,0.12"
    />
    <circle className="st1 yellowSt1" cx="205.38" cy="115.65" r="6.16" />
    <circle className="st1 yellowSt1" cx="231.1" cy="115.65" r="6.16" />
  </svg>
);

export default SmileyNeutral;
