import React, { memo, useCallback, useMemo } from "react";
import * as PropTypes from "prop-types";

import { Heading, Row } from "components/common.styled";

import {
  BackButton,
  Container,
  Divider,
  LabelText,
  NextButton,
  OptionContainer,
} from "./index.styled";

const Step8 = ({
  changeStep,
  clinicReview,
  handleClinicReviewUpdate,
  step,
  translations,
}) => {
  const options = useMemo(
    () => [
      {
        text: `${translations.patient_review_step_8_yes}`,
        value: true,
      },
      {
        text: `${translations.patient_review_step_8_no}`,
        value: false,
      },
      {
        text: `${translations.patient_review_step_8_dont_know}`,
        value: null,
      },
    ],
    [translations],
  );

  const questions = [
    {
      text: `${translations.patient_review_step_8_free_parking}`,
      selected: clinicReview.has_free_parking_directly_outside_of_clinic,
    },
    {
      text: `${translations.patient_review_step_8_paid_parking}`,
      selected: clinicReview.has_paid_parking_directly_outside_of_clinic,
    },
    {
      text: `${translations.patient_review_step_8_vicinity_free_parking}`,
      selected: clinicReview.has_free_parking_near_the_clinic,
    },
    {
      text: `${translations.patient_review_step_8_vicinity_paid_parking}`,
      selected: clinicReview.has_paid_parking_near_the_clinic,
    },
  ];

  const selectOption = useCallback(
    (number, option) => {
      switch (number) {
        case 0:
          handleClinicReviewUpdate({
            has_free_parking_directly_outside_of_clinic: option.value,
          });
          break;
        case 1:
          handleClinicReviewUpdate({
            has_paid_parking_directly_outside_of_clinic: option.value,
          });
          break;
        case 2:
          handleClinicReviewUpdate({
            has_free_parking_near_the_clinic: option.value,
          });
          break;

        default:
          handleClinicReviewUpdate({
            has_paid_parking_near_the_clinic: option.value,
          });
          break;
      }
    },
    [handleClinicReviewUpdate],
  );

  return (
    <>
      <Heading>
        <b>{translations.patient_review_step_8_heading}</b>
      </Heading>
      <br />
      {questions.map((item, index) => (
        <Container key={index}>
          <LabelText>{item.text}</LabelText>
          <Row>
            {options.map((option, optionIndex) => (
              <OptionContainer
                selected={option.value === item.selected ? 1 : 0}
                onClick={() => selectOption(index, option)}
                key={optionIndex}
              >
                {option.text}
              </OptionContainer>
            ))}
          </Row>
        </Container>
      ))}
      <br />
      <Divider />
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <BackButton link onClick={() => changeStep(step - 1)}>
          {"<"} {translations.patient_review_step_8_back}
        </BackButton>
        <NextButton onClick={() => changeStep(step + 1)}>
          {translations.patient_review_step_8_next}
        </NextButton>{" "}
      </div>
    </>
  );
};

Step8.propTypes = {
  changeStep: PropTypes.func,
  clinicReview: PropTypes.object,
  handleClinicReviewUpdate: PropTypes.func,
  step: PropTypes.number,
  translations: PropTypes.object,
};

Step8.defaultProps = {
  clinicReview: {},
};

export default memo(Step8);
