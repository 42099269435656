import styled from "styled-components";

export const Heading = styled.h1`
  font-size: 30px;
  line-height: 40px;
  margin: 0 40px;
`;

export const Content = styled.div`
  margin: 0 40px;
`;

export const Container = styled.div`
  max-width: 700px;
  text-align: center;
  margin: 50px auto;
`;
