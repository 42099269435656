import React from "react";

import { proceduresActions } from "redux/procedures/actions";

import { Col } from "components/common.styled";
import {
  SearchResultName,
  StyledOptionContainer,
} from "components/header/index.styled";

import { slugifyer } from "./helper-functions";

const renderPlace = ({ item, isActive }) => {
  return (
    <StyledOptionContainer active={isActive}>
      <Col xs={24}>
        <SearchResultName>{item.attributes.description}</SearchResultName>
      </Col>
    </StyledOptionContainer>
  );
};

export const arrangePlacesOptions = (searchResult, dispatch, history) => {
  return searchResult?.data?.map((item) => ({
    item,
    value: item.attributes.description,
    link: `/${slugifyer(item.attributes.description)}/g/${item.id}`,
    action: (link) => {
      dispatch(proceduresActions.setIsProcedure(false));
      history.push(link);
    },
    label: renderPlace,
  }));
};
