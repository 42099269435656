import { createSelector } from "reselect";

const translations = ({ translations }) => translations.translations;

export const selectTranslations = createSelector(
  [translations],
  (translations) => translations,
);

export const selectTranslationsByRoute = (route) =>
  createSelector([translations], (translations) => translations[route]);
