import React, { memo } from "react";
import * as PropTypes from "prop-types";

import {
  CheckboxContainer,
  HiddenCheckbox,
  Icon,
  StyledCheckbox,
} from "./index.styled";

const Checkbox = ({ className, checked, label, ...props }) => (
  <label style={{ display: "flex", alignItems: "center" }}>
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
    <span style={{ marginLeft: 8 }}>{label}</span>
  </label>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  label: PropTypes.string,
};

export default memo(Checkbox);
