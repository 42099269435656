import React from "react";

const UserIcon = () => (
  <svg
    style={{ margin: "auto", width: "24px", height: "24px" }}
    height="512pt"
    viewBox="0 0 512 512"
    width="512pt"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M437.02 74.98C388.668 26.63 324.379 0 256 0 187.617 0 123.332 26.629 74.98 74.98 26.63 123.332 0 187.617 0 256c0 68.379 26.629 132.668 74.98 181.02C123.332 485.37 187.617 512 256 512c68.379 0 132.668-26.629 181.02-74.98C485.37 388.668 512 324.379 512 256c0-68.383-26.629-132.668-74.98-181.02zM128.34 442.387c10.707-61.649 64.129-107.121 127.66-107.121 63.535 0 116.953 45.472 127.66 107.12C347.312 467.36 303.336 482 256 482s-91.313-14.64-127.66-39.613zm46.262-218.52c0-44.887 36.515-81.398 81.398-81.398s81.398 36.515 81.398 81.398c0 44.883-36.515 81.399-81.398 81.399s-81.398-36.516-81.398-81.399zm235.043 197.711c-8.075-28.7-24.11-54.738-46.586-75.078a159.444 159.444 0 00-46.36-29.27c30.5-19.894 50.703-54.312 50.703-93.363 0-61.426-49.976-111.398-111.402-111.398S144.602 162.44 144.602 223.867c0 39.051 20.203 73.469 50.699 93.363a159.483 159.483 0 00-46.36 29.266c-22.472 20.34-38.511 46.379-46.586 75.078C57.883 380.274 30 321.336 30 256 30 131.383 131.383 30 256 30s226 101.383 226 226c0 65.34-27.883 124.277-72.355 165.578zm0 0" />
  </svg>
);

export default UserIcon;
