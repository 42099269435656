import React from "react";
import * as PropTypes from "prop-types";

import { Col } from "components/common.styled";

import { ClinicOptionsDivider, ConditionTitle } from "../index.styled";

const Parking = ({
  freeOutside,
  paidOutside,
  freeNear,
  paidNear,
  translations,
}) => {
  if (!(freeOutside || paidOutside || freeNear || paidNear)) return null;

  return (
    <Col>
      <ConditionTitle>
        <b>{translations.parking_heading}</b>
      </ConditionTitle>

      {freeOutside && paidOutside ? (
        <>
          <span>{translations.parking_outside_free_and_paid}</span>
          <ClinicOptionsDivider />
        </>
      ) : null}
      {freeOutside && !paidOutside ? (
        <>
          <span>{translations.parking_outside_free}</span>
          <ClinicOptionsDivider />
        </>
      ) : null}
      {!freeOutside && paidOutside ? (
        <>
          <span>{translations.parking_outside_paid}</span>
          <ClinicOptionsDivider />
        </>
      ) : null}
      {freeOutside !== null &&
      paidOutside !== null &&
      !freeOutside &&
      !paidOutside ? (
        <>
          <span>{translations.parking_outside_no_parking}</span>
          <ClinicOptionsDivider />
        </>
      ) : null}

      {freeNear && paidNear ? (
        <>
          <span>{translations.parking_near_free_and_paid}</span>
          <ClinicOptionsDivider />
        </>
      ) : null}
      {freeNear && !paidNear ? (
        <>
          <span>{translations.parking_near_free}</span>
          <ClinicOptionsDivider />
        </>
      ) : null}
      {!freeNear && paidNear ? (
        <>
          <span>{translations.parking_near_paid}</span>
          <ClinicOptionsDivider />
        </>
      ) : null}
      {freeNear !== null && paidNear !== null && !freeNear && !paidNear ? (
        <>
          <span>{translations.parking_near_no_parking}</span>
          <ClinicOptionsDivider />
        </>
      ) : null}
    </Col>
  );
};

Parking.propTypes = {
  freeOutside: PropTypes.bool,
  paidOutside: PropTypes.bool,
  freeNear: PropTypes.bool,
  paidNear: PropTypes.bool,
  translations: PropTypes.object,
};

export default Parking;
