import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class PublicPersonDataService extends BaseService {
  constructor(url) {
    super(url);
    this.getData = this.getData.bind(this);
  }

  async getData(params) {
    try {
      const response = await this.agent.get("", params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new PublicPersonDataService(
  API_ROUTES.publicPersonData,
);

export default ServiceInstance;
export { ServiceInstance as PublicPersonDataService };
