import styled from "styled-components";

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 18px;
  color: #00000094;
  transition: color 0.3s;
  padding: 7px 15px;

  &:focus {
    outline: 0;
  }

  &:hover {
    color: black;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: white;
  top: 0;
  z-index: 500;
  overflow-x: hidden;
  overflow-y: hidden;
`;
