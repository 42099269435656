import React, { memo, useMemo } from "react";
import * as PropTypes from "prop-types";

import Star from "assets/svg-components/star";

const Rate = ({
  defaultValue,
  starsCount,
  className,
  size,
  onClick,
  margin,
}) => {
  const newStars = useMemo(() => {
    let rate = +defaultValue;
    const starsArray = [];
    const uniqueId = `star-${Math.random().toString(36).substr(2, 9)}-`;

    for (let i = 0; i < starsCount; i += 1) {
      if (rate > 1) {
        starsArray.push([100, uniqueId + i, i]);
        rate -= 1;
      } else {
        const y = Math.asin(2 * rate - 1) / Math.PI + 0.5;
        starsArray.push([y * 100, uniqueId + i, i]);
        rate = 0;
      }
    }

    return starsArray;
  }, [defaultValue, starsCount]);

  return (
    <div className={className}>
      {newStars.map(([offset, uniqueId, index]) => {
        return (
          <div key={uniqueId} style={{ display: "inline", margin }}>
            <Star
              onClick={onClick ? () => onClick(index) : null}
              uniqueId={uniqueId}
              size={size}
              offset={offset}
            />
          </div>
        );
      })}
    </div>
  );
};

Rate.propTypes = {
  defaultValue: PropTypes.number,
  starsCount: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
  margin: PropTypes.string,
};

Rate.defaultProps = {
  starsCount: 5,
  size: 22,
};

export default memo(Rate);
