import { BaseService } from "services/base-service";

import config from "config";
import { API_ROUTES } from "constants/routes";

class ErrorNotificationsService extends BaseService {
  constructor(url, api) {
    super(url, api);
    this.sendErrorReport = this.sendErrorReport.bind(this);
  }

  async sendErrorReport(data = {}) {
    try {
      const response = await this.agent.post("", data, {
        headers: {
          "MPMS-Token": config.mpmsToken,
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new ErrorNotificationsService(
  API_ROUTES.errorNotifications,
  config.errorNotificationApi,
);

export default ServiceInstance;
export { ServiceInstance as ErrorNotificationsService };
