import React, { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import * as PropTypes from "prop-types";

import AngleDown from "assets/svg-components/AngleDown";
import { formatDate } from "helpers/helper-functions";
import { selectTag } from "redux/app/selectors";

import { Col, Row } from "../../common.styled";

import { ArrowButton, DateRow, DateTitle, SCol } from "../index.styled";

const Wrapper = ({
  children,
  endDate,
  goBack,
  goNext,
  isMobile,
  middleDate,
  loading,
  startDate,
}) => {
  const locale = useSelector(selectTag);

  const isActive = useMemo(
    () =>
      formatDate(new Date(startDate), "dd M") ===
      formatDate(new Date(), "dd M"),
    [startDate],
  );

  const dayOfMonth = useCallback(
    (date) => {
      return formatDate(date, "d MMM", locale).replace(".", "");
    },
    [locale],
  );

  const disableButton = global.innerWidth >= 994 ? loading : false;

  return (
    <Row
      style={{
        flex: "1 1 0",
        justifyContent: "center",
      }}
      justify="center"
    >
      <Col xs={3} md={2} style={{ display: "flex" }}>
        <ArrowButton
          style={{ justifyContent: "flex-start" }}
          disabled={isActive || disableButton}
          onClick={goBack}
          link
        >
          <AngleDown style={{ transform: "rotate(90deg)" }} />
        </ArrowButton>
      </Col>
      <div
        style={{
          display: "flex",
          flex: "1 1 0",
          flexDirection: "column",
        }}
      >
        {endDate && middleDate && (
          <DateRow>
            <SCol>
              <DateTitle>
                <b>
                  <span>{`${formatDate(startDate, "EEE", locale)}`}</span>
                </b>
                <span>{dayOfMonth(startDate)}</span>
              </DateTitle>
            </SCol>
            <SCol>
              <DateTitle>
                <b>
                  <span>{formatDate(middleDate, "EEE", locale)}</span>
                </b>
                <span>{dayOfMonth(middleDate)}</span>
              </DateTitle>
            </SCol>
            <SCol>
              <DateTitle>
                <b>
                  <span>{formatDate(endDate, "EEE", locale)}</span>
                </b>
                <span>{dayOfMonth(endDate)}</span>
              </DateTitle>
            </SCol>
          </DateRow>
        )}

        {!endDate && !middleDate && (
          <DateRow>
            <SCol>
              <DateTitle>
                <b>
                  <span>{formatDate(startDate, "EEE", locale)}</span>
                </b>
                <span>{dayOfMonth(startDate)}</span>
              </DateTitle>
            </SCol>
          </DateRow>
        )}
        {!isMobile && <div>{children}</div>}
      </div>

      <Col
        xs={3}
        md={2}
        style={{
          display: "flex",
        }}
      >
        <ArrowButton
          disabled={disableButton}
          style={{ display: "flex", justifyContent: "flex-end" }}
          onClick={goNext}
          link
        >
          <AngleDown style={{ transform: "rotate(270deg)" }} />
        </ArrowButton>
      </Col>
      {isMobile && <div style={{ width: "100%" }}>{children}</div>}
    </Row>
  );
};

Wrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  endDate: PropTypes.instanceOf(Date),
  goBack: PropTypes.func,
  goNext: PropTypes.func,
  isMobile: PropTypes.bool,
  middleDate: PropTypes.instanceOf(Date),
  loading: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
};

Wrapper.defaultProps = {
  endDate: null,
  middleDate: null,
};
export default memo(Wrapper);
