import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import config from "config";
import { useTranslations } from "hooks/use-translations";
import { caregiversActions } from "redux/caregivers/actions";
import { proceduresActions } from "redux/procedures/actions";

import Footer from "components/footer";
import SearchContent from "components/search";
import { Layout } from "components/search/index.styled";

const Search = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const routeName = "search";
  const translations = useTranslations(routeName);

  const onPageChange = useCallback(
    (e) => {
      history.push({
        pathname: location.pathname,
        query: {
          page: e,
        },
      });
      dispatch(caregiversActions.changePageCaregiverSlotsSearch(+e));
    },
    [dispatch, history, location.pathname],
  );

  useEffect(() => {
    dispatch(caregiversActions.changeFetchCaregiverStatus(false));

    return () => {
      dispatch(proceduresActions.setIsProcedure(false));
    };
  }, [dispatch]);

  return (
    <Layout>
      <Helmet>
        <title>{`${translations.meta_title_default}`}</title>
        <meta
          id="meta-description"
          name="description"
          content={translations.meta_content_role_search}
        />
        <link
          id="canonical-link"
          rel="canonical"
          href={config.url + location.pathname.replace(/\?.*$/gm, "")}
        />
      </Helmet>
      <SearchContent onPageChange={onPageChange} translations={translations} />
      <Footer translations={translations} />
    </Layout>
  );
};

export default Search;
