import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { CaregiversAtLocationsService } from "services/caregivers-at-locations-service";
import { CaregiversService } from "services/caregivers-service";
import { PatientsReviewsService } from "services/patient-reviews-service";
import { SearchResultsService } from "services/search-results-service";
import { SearchTermsService } from "services/search-terms-service";

import config from "config";
import {
  formatDate,
  getItemFields,
  removeUndefinedValues,
} from "helpers/helper-functions";

import { caregiversActions as actions } from "./actions";

const { defaultProcedureId, defaultProcedureTitle } = config;

const defProc = {
  id: `${defaultProcedureId}`,
  attributes: {
    name: defaultProcedureTitle,
  },
};

function* fetchCaregivers({ payload: page }) {
  const dataConfig = {
    params: {
      include: "caregiver_locations.clinics,role",
      sort_by: "last_name",
      per_page: 20,
      page,
    },
  };
  const result = yield call(CaregiversService.getCaregivers, dataConfig);
  if (!result) {
    yield put(actions.fetchCaregiversError());
  }
  yield put(actions.fetchCaregiversSuccess(result.data));
}

function* searchCaregivers({ payload: { search, page } }) {
  const dataConfig = {
    params: {
      include: "search_result_caregiver,search_result_role_procedure",
      query: search,
    },
  };
  const result = yield call(SearchResultsService.getSearchResult, dataConfig);
  if (!result) {
    yield put(actions.fetchCaregiversError());
    return;
  }

  const {
    data: { data, included },
  } = result;
  const results = data.map((item) => {
    const { relationships, ...rest } = item;
    const result = getItemFields(relationships, included);
    return { ...result, ...rest };
  });

  const dataRequest = {
    data: {
      attributes: {
        date: formatDate(new Date()),
        field: "procedure",
        number_of_results: results?.length,
        page,
        resulted_in_selection: false,
        search_term: search,
      },
      type: "muntra-search-terms",
    },
  };

  const terms = yield call(SearchTermsService.postSearchTerms, dataRequest);
  if (terms.data.data) {
    yield put(actions.postCaregiversSearchTermsSuccess(terms.data.data));
    yield put(actions.searchCaregiverSuccess(results));
  } else {
    yield put(actions.fetchCaregiversError());
  }
}

function* caregiverPatchSearchTerms({ payload }) {
  const dataReq = {
    data: {
      attributes: {
        date: formatDate(new Date()),
        field: "procedure",
        number_of_results: payload.attributes?.number_of_results,
        page: payload.attributes?.page,
        resulted_in_selection: true,
        search_term: payload?.attributes?.search_term,
      },
      id: `${payload.id}`,
      type: "muntra-search-terms",
    },
  };
  yield call(SearchTermsService.patchSearchTerms, payload.id, dataReq);
}

function* getCaregiverById({ payload: id }) {
  const dataConfig = {
    params: {
      new_patient: true,
      include: "default_user_image,clinics,educations,language,role",
    },
  };
  const data = yield call(CaregiversService.getCaregiverById, id, dataConfig);
  if (data?.data?.data?.relationships) {
    const {
      data: {
        data: { relationships, ...rest },
        included,
      },
    } = data;
    const results = getItemFields(relationships, included);
    const caregiver = { ...results, ...rest };
    yield put(actions.getCaregiverByIdSuccess(caregiver));
  } else {
    yield put(actions.fetchCaregiversError());
  }
}

function* fetchCaregiverReviews({ payload: { id, page } }) {
  const dataConfig = {
    params: {
      order: "desc",
      page,
      per_page: 10,
      sort_by: "has_review_comment,created_date",
      caregiver_id: id,
    },
  };
  const result = yield call(PatientsReviewsService.getReviews, dataConfig);
  if (!result) {
    yield put(actions.fetchCaregiversError());
    return;
  }

  yield put(actions.fetchCaregiverReviewsSuccess(result.data));
}

function* fetchCaregiverProcedures({ payload: id }) {
  const dataConfig = {
    params: {
      include: "default_procedure.procedure,procedures.procedure",
      caregiver_id: id,
    },
  };
  const procedureData = yield call(
    CaregiversAtLocationsService.getCaregiver,
    dataConfig,
  );
  if (!procedureData) {
    yield put(actions.fetchCaregiversError());
    return;
  }

  const results = procedureData?.data?.data?.map((item) => {
    const { relationships, ...rest } = item;
    const result = getItemFields(relationships, procedureData?.data?.included);
    return { ...result, ...rest };
  });
  const defaultProcedure =
    results?.[0]?.default_procedure?.procedure || defProc;

  yield put(actions.fetchCaregiverProceduresSuccess(results, defaultProcedure));
}

function* fetchCaregiverSlots({ payload }) {
  const { nextSlotPeriod } = yield select((state) => state.caregivers);
  const dataConfig = {
    params: {
      caregiver_id: payload.id,
      clinic_id: payload.clinicId || "",
      new_patient: payload.isNewPatient,
      procedure_ids: payload.procedureId,
      next_free_slot_period_in_months: nextSlotPeriod,
      include_caregiver_locations_without_procedure_id_matches: true,
      dtend: formatDate(new Date(payload.dateEnd)),
      dtstart: formatDate(new Date(payload.dateStart)),
      duration_in_minutes: payload.durationInMinutes || "",
      include:
        "free_bookable_slots,next_free_bookable_slot,procedures.procedure,next_free_slot_period_in_months",
    },
  };
  const result = yield call(
    CaregiversAtLocationsService.getCaregiver,
    dataConfig,
  );
  if (!result) {
    yield put(actions.fetchCaregiversError());
    return;
  }

  const {
    data: { data, included },
  } = result;
  if (data?.length) {
    const results = data.map((item) => {
      const { relationships, ...rest } = item;
      const result = getItemFields(relationships, included);
      return { ...result, ...rest };
    });
    yield put(actions.fetchCaregiverSlotsSuccess(results));
  } else if (data) {
    const { relationships, ...rest } = data;
    const result = getItemFields(relationships, included);
    const finalResult = { ...result, ...rest };
    yield put(actions.fetchCaregiverSlotsSuccess(finalResult));
  } else {
    yield put(actions.fetchCaregiverSlotsSuccess([]));
  }
}

const getResults = (arr, included) =>
  arr.map((item) => {
    const { relationships, ...rest } = item;
    const result = getItemFields(relationships, included);
    return { ...result, ...rest };
  });

function* fetchCaregiversWithSlots({ payload }) {
  const filteredParams = removeUndefinedValues({
    radius: payload.radius,
    dtend: formatDate(new Date(payload.dateEnd)),
    dtstart: formatDate(new Date(payload.dateStart)),
    include: [
      "caregiver.default_user_image",
      "clinic",
      "default_procedure.procedure",
      "free_bookable_slots",
      "procedures.procedure",
    ].join(","),
    new_patient: true,
    lat: payload.lat,
    lng: payload.lng,
    per_page: 3,
    page: payload.page,
    procedure_ids: payload.procedureIds,
    role_ids: payload.roleIds,
    viewport_northeast_lat: payload.vNLat,
    viewport_northeast_lng: payload.vNLng,
    viewport_southwest_lat: payload.vSLat,
    viewport_southwest_lng: payload.vSLng,
  });

  const dataConfig = {
    params: filteredParams,
  };

  if (payload.page === 1) {
    const res1 = yield call(
      CaregiversAtLocationsService.getCaregiver,
      dataConfig,
    );
    if (!res1) {
      yield put(actions.fetchCaregiversError());
    }

    if (res1?.data?.included) {
      const results1 = getResults(res1.data?.data, res1.data.included);
      yield put(
        actions.fetchCaregiverSlotsSearchSuccess({
          results: results1,
          meta: res1.data?.meta,
        }),
      );
    }
  }

  dataConfig.params.per_page = 10;
  const res2 = yield call(
    CaregiversAtLocationsService.getCaregiver,
    dataConfig,
  );
  if (!res2) {
    yield put(actions.fetchCaregiversError());
  }
  if (res2?.data?.included) {
    const results2 = getResults(res2.data?.data, res2.data.included);
    yield put(actions.getCaregiversForAvatarSuccess(results2));
    yield put(
      actions.fetchCaregiverSlotsSearchSuccess({
        results: results2,
        meta: res2.data.meta,
        secondLoadComplete: true,
      }),
    );
  }

  const { nextSlotPeriod } = yield select((state) => state.caregivers);
  dataConfig.params.next_free_slot_period_in_months = nextSlotPeriod;
  dataConfig.params.include = `${dataConfig.params.include},next_free_bookable_slot`;

  const res3 = yield call(
    CaregiversAtLocationsService.getCaregiver,
    dataConfig,
  );
  if (!res3) {
    yield put(actions.fetchCaregiversError());
  }
  if (res3?.data?.included) {
    const results3 = getResults(res3.data.data, res3.data.included);
    yield put(
      actions.fetchCaregiverSlotsSearchSuccess({
        results: results3,
        meta: res3.data.meta,
        loadComplete: true,
      }),
    );
  }

  yield put(actions.changeFetchCaregiverStatus(true));
}

function* fetchNearestCaregiverSlots({ payload }) {
  const getParams = ({ include = "", perPage, isFirst, nextSlotPeriod }) => ({
    params: removeUndefinedValues({
      radius: payload.radius,
      dtend: formatDate(new Date(payload.dateEnd)),
      dtstart: formatDate(new Date(payload.dateStart)),
      include: [
        "caregiver.default_user_image",
        "clinic",
        "free_bookable_slots",
        "clinic.default_clinic_image",
        include,
      ].join(","),
      new_patient: true,
      lat: payload.lat,
      lng: payload.lng,
      has_default_user_image: payload.onlyWithImg,
      has_free_slot: payload.hasFreeSlot,
      next_free_slot_period_in_months: nextSlotPeriod,
      per_page: perPage,
      page: payload.page,
      procedure_ids: isFirst
        ? payload.procedureIds[0]
        : payload.procedureIds[1],
      role_ids: payload.roleIds,
    }),
  });

  const { nextSlotPeriod } = yield select((state) => state.caregivers);

  const firstProcArgs = [
    getParams({ perPage: 3, isFirst: true }),
    getParams({
      include: "next_free_bookable_slot",
      perPage: 3,
      isFirst: true,
      nextSlotPeriod,
    }),
  ];
  const secondProcArgs = [
    getParams({ perPage: 3, isFirst: false }),
    getParams({
      include: "next_free_bookable_slot",
      perPage: 3,
      isFirst: false,
      nextSlotPeriod,
    }),
  ];

  const [firstProcReq, secProcReq] = yield Promise.all([
    CaregiversAtLocationsService.getCaregiver(firstProcArgs[0]),
    CaregiversAtLocationsService.getCaregiver(secondProcArgs[0]),
  ]);
  if (!firstProcReq || !secProcReq) {
    yield put(actions.fetchCaregiversError());
  }

  if (firstProcReq.data.included) {
    const firstProcReqResult = getResults(
      firstProcReq.data.data,
      firstProcReq.data.included,
    );
    yield put(
      actions.fetchFirstNearestCaregiverSlotsSuccess({
        results: firstProcReqResult,
        meta: firstProcReq.data.meta,
      }),
    );
  }

  if (firstProcReq.data.included) {
    const secProcReqResults = getResults(
      secProcReq.data.data,
      secProcReq.data.included,
    );
    yield put(
      actions.fetchSecondNearestCaregiverSlotsSuccess({
        results: secProcReqResults,
        meta: secProcReq.data.meta,
        secondLoadComplete: true,
      }),
    );
  }

  const [firstProcReq2, secProcReq2] = yield Promise.all([
    CaregiversAtLocationsService.getCaregiver(firstProcArgs[1]),
    CaregiversAtLocationsService.getCaregiver(secondProcArgs[1]),
  ]);
  if (!firstProcReq2 || !secProcReq2) {
    yield put(actions.fetchCaregiversError());
  }

  if (firstProcReq2.data.included) {
    const firstProcReqResult2 = getResults(
      firstProcReq2.data.data,
      firstProcReq2.data.included,
    );
    yield put(
      actions.fetchFirstNearestCaregiverSlotsSuccess({
        results: firstProcReqResult2,
        meta: firstProcReq2.data.meta,
      }),
    );
  }

  if (secProcReq2.data.included) {
    const secProcReqResult2 = getResults(
      secProcReq2.data.data,
      secProcReq2.data.included,
    );
    yield put(
      actions.fetchSecondNearestCaregiverSlotsSuccess({
        results: secProcReqResult2,
        meta: secProcReq2.data.meta,
        loadComplete: true,
      }),
    );
  }
}

export default function* caregiversSagas() {
  yield all([
    takeEvery(actions.CAREGIVERS_FETCH, fetchCaregivers),
    takeEvery(actions.CAREGIVERS_SEARCH, searchCaregivers),
    takeEvery(actions.CAREGIVER_GET_BY_ID, getCaregiverById),
    takeEvery(actions.CAREGIVER_FETCH_PROCEDURES, fetchCaregiverProcedures),
    takeEvery(actions.CAREGIVER_REVIEWS_FETCH, fetchCaregiverReviews),
    takeEvery(actions.CAREGIVER_SLOTS_FETCH, fetchCaregiverSlots),
    takeLatest(actions.CAREGIVER_SLOTS_SEARCH_FETCH, fetchCaregiversWithSlots),
    takeEvery(actions.CAREGIVER_PATCH_SEARCH_TERMS, caregiverPatchSearchTerms),
    takeEvery(
      actions.CAREGIVER_NEAREST_SLOTS_FETCH,
      fetchNearestCaregiverSlots,
    ),
  ]);
}
