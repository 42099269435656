import React from "react";
import * as PropTypes from "prop-types";
import styled, { css } from "styled-components";

const StyledButton = styled.button`
  border-radius: 5px;
  font-size: 14px;
  line-height: 14px;
  outline: none;
  border: none;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  box-shadow: 2px 2px 0 #ddd;

  align-items: center;

  padding: ${(props) => {
    if (props.circle) return "10px";
    return "0px 15px";
  }};

  border-radius: ${(props) => {
    if (props.circle) return "16px";
    return "2px";
  }};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

  color: rgb(255, 255, 255);
  & a {
    color: rgb(255, 255, 255);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }
  & svg {
    fill: rgb(255, 255, 255);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }

  background-color: rgb(24, 144, 255);
  border-color: rgb(24, 144, 255);

  &:hover {
    color: rgb(255, 255, 255);
    & a {
      color: rgb(255, 255, 255);
    }
    & svg {
      fill: rgb(255, 255, 255);
    }

    background-color: rgb(0, 122, 255);
    border-color: rgb(0, 122, 255);
  }

  ${(props) => {
    if (props.secondary)
      return css`
        box-shadow: none;
        color: rgba(0, 0, 0, 0.65);
        & svg {
          fill: rgba(0, 0, 0, 0.65);
        }
        & a {
          color: rgba(0, 0, 0, 0.65);
        }

        background-color: rgb(255, 255, 255);
        border-color: rgb(217, 217, 217);

        &:hover {
          background: rgb(255, 255, 255);
          color: rgb(0, 122, 255);
          & svg {
            fill: rgb(0, 122, 255);
          }
          & a {
            color: rgb(0, 122, 255);
          }

          border-color: rgb(0, 122, 255);
        }
      `;
  }}

  ${(props) => {
    if (props.link)
      return css`
        border: 0;
        color: rgb(24, 144, 255);
        & svg {
          fill: rgb(24, 144, 255);
        }
        & a {
          color: rgb(24, 144, 255);
        }

        background-color: transparent;
        box-shadow: none;

        &:hover {
          background: transparent;
          color: rgb(0, 122, 255);
          & svg {
            fill: rgb(0, 122, 255);
          }
          & a {
            color: rgb(0, 122, 255);
          }
        }
      `;
  }}

  height: 32px;
  display: flex;
  align-items: center;

  & svg {
    height: 100%;
    width: auto;
  }
`;

const Button = ({ secondary, primary, circle, ...props }) => {
  return (
    <StyledButton
      secondary={secondary}
      primary={primary}
      circle={circle}
      {...props}
    />
  );
};

export const NavbarButton = styled(Button)`
  border: 0;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  color: #484848;
  font-weight: 600;
  height: 100%;
  margin: 0;
`;

export const StretchButton = styled(Button)`
  width: fit-content;
  font-size: 15px;
  height: 42px;
  padding: 0 25px;
  box-shadow: 2px 2px 0 #ddd;
  margin-top: 20px;
  @media (max-width: 740px) {
    font-weight: 700;
    width: 100%;
    height: 42px;
  }
`;

Button.propTypes = {
  secondary: PropTypes.bool,
  primary: PropTypes.bool,
  circle: PropTypes.bool,
};

export default Button;
