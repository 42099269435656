import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";

import defaultUserImage from "assets/images/defaultUserIcon.png";
import { slugifyer } from "helpers/helper-functions";

import Calendar from "components/calendar";
import { Avatar, Col, MobileSection, Row } from "components/common.styled";
import Radio from "components/common/radio";
import Dropdown from "components/common/search-dropdown";

import { RoleText } from "../index.styled";
import {
  CalendarCol,
  NameTilte,
  ReviewsLink,
  StyledDivider,
  StyledRate,
} from "./index.styled";

const CaregiverCard = ({
  clinic,
  defaultProcedure,
  handleProcedureChange,
  handleRadioChange,
  caregiverAtLocationDetails,
  procedures,
  translations,
  dateObject,
}) => {
  const { slotsLoadBegin, slotsLoading } = useSelector(
    (state) => state.clinics,
  );

  const caregiver = caregiverAtLocationDetails?.caregiver || {};

  const history = useHistory();

  const fullName =
    caregiver &&
    `${caregiver.attributes.first_name} ${caregiver.attributes.last_name}`;
  const { name } = caregiver.role?.attributes || {};
  const link =
    caregiver && `/${slugifyer(name)}/${slugifyer(fullName)}/p/${caregiver.id}`;
  const rate =
    caregiver &&
    Math.round((caregiver.attributes.overall_rating + Number.EPSILON) * 100) /
      100;

  const noSlots = useMemo(
    () =>
      caregiverAtLocationDetails?.procedures?.length > 3 ? (
        <Col>
          <Dropdown
            isClinic
            defaultProcedure={defaultProcedure}
            list={caregiverAtLocationDetails?.procedures?.filter(
              (item) => item.procedure?.id !== defaultProcedure.id,
            )}
            resetThenSet={(item) =>
              handleProcedureChange(
                procedures?.find((el) => el.id === item.procedure.id),
                { changeItemsOrder: false },
              )
            }
            searchable={["", "Ingen match"]}
            title=""
          />
        </Col>
      ) : (
        <Col>
          {caregiverAtLocationDetails?.procedures?.map((item) => (
            <Radio
              name="procedure"
              key={item.procedure.id}
              value={item.procedure.id}
              onChange={(e) =>
                handleRadioChange(e, { changeItemsOrder: false })
              }
              checkedValue={defaultProcedure.id}
            >
              {item.procedure.attributes.name}
            </Radio>
          ))}
        </Col>
      ),
    [
      caregiverAtLocationDetails.procedures,
      defaultProcedure,
      handleProcedureChange,
      handleRadioChange,
      procedures,
    ],
  );

  const avatarProps = {
    size: 100,
    alt: `${caregiver.role.attributes.name} ${fullName}`,
    loading: "lazy",
  };

  const caregiverAtLocationObject = useMemo(
    () => ({
      caregiverAtLocation: caregiverAtLocationDetails,
      caregiverAtLocationBegin: slotsLoadBegin,
      caregiverAtLocationLoading: slotsLoading,
      noCaregiverAtLocation: noSlots,
    }),
    [caregiverAtLocationDetails, noSlots, slotsLoadBegin, slotsLoading],
  );

  return (
    <div>
      {caregiverAtLocationDetails && (
        <>
          <MobileSection>
            <Row>
              <Col xs={24} md={12}>
                <Row
                  noWrap
                  onClick={() => history.push(link)}
                  style={{ cursor: "pointer" }}
                >
                  <div style={{ marginRight: 15 }}>
                    {!caregiver.default_user_image && (
                      <Avatar {...avatarProps} src={defaultUserImage} />
                    )}
                    {caregiver.default_user_image && (
                      <Avatar
                        {...avatarProps}
                        src={`${caregiver.default_user_image?.attributes?.original}?h=100&w=100&fit=crop`}
                      />
                    )}
                  </div>
                  <div>
                    <NameTilte onClick={(e) => e.stopPropagation()}>
                      <Link to={`${link}`}>{fullName}</Link>
                    </NameTilte>
                    {caregiver.attributes?.number_of_reviews > 0 &&
                      !caregiver.attributes?.hide_reviews && (
                        <>
                          {" "}
                          <Row alignitems="center">
                            <StyledRate defaultValue={rate} />
                            <span style={{ marginLeft: 10 }}>{rate}</span>
                          </Row>
                          {global.innerWidth >= 994 && (
                            <ReviewsLink onClick={(e) => e.stopPropagation()}>
                              <Link to={`${link}`}>
                                {translations.caregiver_card_read_reviews}
                              </Link>
                            </ReviewsLink>
                          )}
                        </>
                      )}
                    {caregiver.attributes.hide_reviews && (
                      <span
                        style={{
                          color: "#000000a6",
                          fontSize: "13px",
                          lineHeight: "13px",
                        }}
                      >
                        {translations.caregiver_card_reviews_hidden}
                      </span>
                    )}
                    <RoleText>
                      {caregiver?.attributes?.title
                        ? caregiver.attributes.title
                        : name}
                    </RoleText>
                  </div>
                </Row>
              </Col>
              <CalendarCol xs={24} md={12}>
                <Calendar
                  caregiver={caregiver}
                  clinic={clinic}
                  location={caregiverAtLocationDetails}
                  defaultProcedure={defaultProcedure}
                  translations={translations}
                  dateObject={dateObject}
                  caregiverAtLocationObject={caregiverAtLocationObject}
                />
              </CalendarCol>
            </Row>
          </MobileSection>
          <StyledDivider />
        </>
      )}
    </div>
  );
};

CaregiverCard.propTypes = {
  clinic: PropTypes.object,
  defaultProcedure: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  handleProcedureChange: PropTypes.func,
  handleRadioChange: PropTypes.func,
  caregiverAtLocationDetails: PropTypes.object,
  procedures: PropTypes.array,
  translations: PropTypes.object,
  dateObject: PropTypes.object,
};

CaregiverCard.defaultProps = {
  defaultProcedure: {},
};

export default memo(CaregiverCard);
