export const REQUIRED_BOOKING_FIELDS = {
  fullName: "full_name",
  email: "e_mail_address",
  phoneNumber: "phone_number_cell",
};

export const FIELD_ERROR_EXCEPTIONS = [
  REQUIRED_BOOKING_FIELDS.fullName,
  REQUIRED_BOOKING_FIELDS.email,
  REQUIRED_BOOKING_FIELDS.phoneNumber,
];

export const INPUTS_NAMES = {
  email: "email",
  firstName: "firstName",
  lastName: "lastName",
  personalId: "personalId",
  phoneNumber: "phoneNumber",
};
