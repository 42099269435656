import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class PatientsReviewsService extends BaseService {
  constructor(url) {
    super(url);
    this.getReviews = this.getReviews.bind(this);
    this.postReview = this.postReview.bind(this);
    this.patchReview = this.patchReview.bind(this);
  }

  async getReviews(params) {
    try {
      const response = await this.agent.get("", params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async postReview(data) {
    try {
      const response = await this.agent.post("", data);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async patchReview(id, data) {
    try {
      const response = await this.agent.patch(`/${id}`, data);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new PatientsReviewsService(API_ROUTES.patientReviews);

export default ServiceInstance;
export { ServiceInstance as PatientsReviewsService };
