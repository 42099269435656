import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { RolesProceduresService } from "services/roles-procedures-service";

import config from "config";
import { useTranslations } from "hooks/use-translations";
import { caregiversActions } from "redux/caregivers/actions";
import { proceduresActions } from "redux/procedures/actions";
import { rolesActions } from "redux/roles/actions";

import Footer from "components/footer";
import Search from "components/search";
import { Layout } from "components/search/index.styled";

const RoleProcedure = () => {
  const [roleProcedureDataIsFetched, setRoleProcedureDataIsFetched] =
    useState(false);

  const [roleProcedureName, setRoleProcedureName] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const {
    role_procedure_name: paramsRoleProcedureName,
    role_procedure_id: paramsRoleProcedureId,
  } = useParams();

  const routeName = "[first_param].rp.[role_procedure_id]";
  const translations = useTranslations(routeName);

  const onPageChange = (e) => {
    history.push(
      {
        pathname: location.pathname,
        search: `?page=${e}`,
      },
      {
        pathname: `/${paramsRoleProcedureName}/rp/${paramsRoleProcedureId}`,
        search: `?page=${e}`,
      },
    );
    dispatch(caregiversActions.changePageCaregiverSlotsSearch(+e));
  };

  const fetchRoleProcedureData = useCallback(async () => {
    if (paramsRoleProcedureId !== "") {
      const response = await RolesProceduresService.getRoleProcedureById(
        paramsRoleProcedureId,
        {
          params: {
            include: "roles,procedures",
          },
        },
      );
      if (!response) {
        history.push("/start");
        return;
      }

      setRoleProcedureName(response.data?.data?.attributes?.term);

      const roles = [];
      const procedures = [];

      response.data?.included?.forEach(({ type, id, attributes }) => {
        if (type === "muntra_role") {
          roles.push({ id, title: attributes.name });
          return;
        }
        if (type === "muntra_procedure") {
          procedures.push({ id, title: attributes.name });
        }
      });

      dispatch(rolesActions.setSelectedRole(roles));
      dispatch(proceduresActions.setSelectedProcedure(procedures));

      setRoleProcedureDataIsFetched(true);
    }
  }, [dispatch, history, paramsRoleProcedureId]);

  useEffect(() => {
    fetchRoleProcedureData();
  }, [fetchRoleProcedureData]);

  useEffect(() => {
    return () => dispatch(rolesActions.cleanRole());
  }, [dispatch]);

  return (
    <Layout>
      <Helmet>
        <title>{`${roleProcedureName} | ${translations.meta_title_booking}`}</title>
        <meta
          id="meta-description"
          name="description"
          content={translations.meta_content_role_search}
        />
        <link
          id="canonical-link"
          rel="canonical"
          href={config.url + location.pathname.replace(/\?.*$/gm, "")}
        />
      </Helmet>
      {roleProcedureDataIsFetched && (
        <Search
          firstParam={paramsRoleProcedureName}
          onPageChange={onPageChange}
          roleProcedureName={roleProcedureName}
          translations={translations}
        />
      )}
      <Footer translations={translations} />
    </Layout>
  );
};

export default RoleProcedure;
