import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { authActions } from "redux/auth/actions";

import { useQuery } from "./use-query";

export const useSignInValidation = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();

  const autostarttoken = query.get("autostarttoken");
  const isRegister = query.get("isRegister");
  const bookingCaregiverId = query.get("caregiver_id");
  const bookingClinicId = query.get("clinic_id");
  const bookingDateEnd = query.get("dtend");
  const bookingDateStart = query.get("dtstart");
  const authState = useSelector((state) => state.auth);

  const inBookingProcess = useMemo(
    () =>
      bookingCaregiverId &&
      bookingClinicId &&
      bookingDateEnd &&
      bookingDateStart,
    [bookingCaregiverId, bookingClinicId, bookingDateEnd, bookingDateStart],
  );

  useEffect(() => {
    if (autostarttoken && !authState?.authUserPersonalId?.data) {
      const formData = new FormData();
      formData.append("autostarttoken", autostarttoken);
      formData.append("grant_type", "password");
      formData.append("guard", "start");
      dispatch(authActions.getUserTokenAndSignIn(formData));
    }
  }, [authState?.authUserPersonalId?.data, autostarttoken, dispatch]);

  useEffect(() => {
    if (
      autostarttoken &&
      authState?.authUserPersonalId?.data &&
      !inBookingProcess
    ) {
      if (isRegister) {
        history.push("/patient-profile");
      } else {
        history.push("/bookings");
      }
    }
  }, [
    authState?.authUserPersonalId?.data,
    autostarttoken,
    history,
    inBookingProcess,
    isRegister,
  ]);
};
