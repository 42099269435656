import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`from { opacity: 0; }`;

export const ModalRoot = styled.div`
  position: relative;
  z-index: 0;
`;

export const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
`;

export const CloseButton = styled.button`
  background: transparent;
  border: 0;
  color: #00000094;
  cursor: pointer;
  font-size: 18px;
  margin: 21px 25px 0px 25px;
  padding: 0px !important;
  position: absolute;
  right: 0;
  top: 0;
  transition: color 0.3s;
  z-index: 1000;

  &:focus {
    outline: 0;
  }

  &:hover {
    color: black;
  }
`;

export const BackButton = styled(CloseButton)`
  display: flex;
`;

export const Overlay = styled.div`
  animation: ${fadeIn} 200ms ease-out;
  top: 0;
  left: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  z-index: 101;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Dialog = styled.div`
  top: 100px;
  margin-bottom: 100px;
  background: white;
  border-radius: 5px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 100;
  padding: 25px 30px 20px 30px;
  @media (max-height: 670px) {
    overflow-x: hidden;
    overflow-y: hidden;
  }
`;

export const DialogBooking = styled.div`
  padding: 100px;
  height: 100vh;
  background: white;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 100;
  padding: 25px 30px 20px 30px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const DialogAvatar = styled.div`
  background: white;
  border-radius: 5px;
  position: fixed;
  top: 15vh;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 1;
  padding: 25px 30px 20px 30px;
`;

export const DialogClinicImages = styled.div`
  height: 100vh;
  background: white;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 100;
  padding: 35px 0 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const DialogMobile = styled.div`
  height: 100vh;
  background: white;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 100;
  padding: 25px 30px 80px 30px;
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-height: 670px) {
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;
