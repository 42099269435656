import React from "react";
import toast from "react-hot-toast";

class Notifications {
  static message({ message = "" }) {
    if (!message.trim()) {
      return;
    }

    const id = toast.success(
      <div
        style={{ width: "100%", cursor: "pointer" }}
        onClick={() => toast.dismiss(id)}
      >
        {message}
      </div>,
    );
  }
}

export default Notifications;
export { Notifications };
