import React, { memo, useCallback, useEffect, useState } from "react";
import { Collapse } from "react-collapse";
import { useSelector } from "react-redux";
import { addDays, addHours, startOfDay } from "date-fns";
import * as PropTypes from "prop-types";

import AngleDown from "assets/svg-components/AngleDown";
import { formatDate, formatHours } from "helpers/helper-functions";
import { selectTag } from "redux/app/selectors";

import { Row, Text } from "../../common.styled";
import {
  CenterRow,
  DateTitle,
  HoursButton,
  Inner,
  NextButton,
  SCol,
} from "./reschedule.styled";

import { Spinner } from "../../common/spinner/index.styled";

const RescheduleSlots = ({
  goToDate,
  translations,
  setStep,
  setSelectedSlot,
  slots,
  slotsLoading,
  startDate,
}) => {
  const locale = useSelector(selectTag);
  const [isOpened, setIsOpened] = useState(false);

  const middleDate = addDays(startDate, 1);
  const endDate = addDays(startDate, 2);

  const emptyRow = (index) => (
    <DateTitle key={index}>
      <span>-</span>
    </DateTitle>
  );

  const getActiveButton = useCallback(
    (hours, slotsData) => (
      <HoursButton
        onClick={() => {
          setSelectedSlot(slotsData);
          setStep(1);
        }}
        primary
      >
        {hours}
      </HoursButton>
    ),
    [setSelectedSlot, setStep],
  );

  const renderDateRow = useCallback(() => {
    const sortedArray = [...(slots?.free_bookable_slots || [])].sort(
      (a, b) => b.attributes.dtstart - a.attributes.dtstart,
    );

    const startDateSlots = sortedArray?.filter(
      (item) =>
        formatDate(item.attributes.dtstart, "dd/MM/uuuu") ===
        formatDate(startDate, "dd/MM/uuuu"),
    );
    const middleDateSlots = sortedArray?.filter(
      (item) =>
        formatDate(item.attributes.dtstart, "dd/MM/uuuu") ===
        formatDate(middleDate, "dd/MM/uuuu"),
    );
    const endDaySlots = sortedArray?.filter(
      (item) =>
        formatDate(item.attributes.dtstart, "dd/MM/uuuu") ===
        formatDate(endDate, "dd/MM/uuuu"),
    );

    const firstColumn = startDateSlots.map((item) => (
      <DateTitle key={item.id}>
        {startDate
          ? getActiveButton(formatHours(item.attributes.dtstart, "H:mm"), item)
          : emptyRow}
      </DateTitle>
    ));

    const secondColumn = middleDateSlots.map((item) => (
      <DateTitle key={item.id}>
        {middleDate
          ? getActiveButton(formatHours(item.attributes.dtstart, "H:mm"), item)
          : emptyRow}
      </DateTitle>
    ));

    const thirdColumn = endDaySlots.map((item) => (
      <DateTitle key={item.id}>
        {endDate
          ? getActiveButton(formatHours(item.attributes.dtstart, "H:mm"), item)
          : emptyRow}
      </DateTitle>
    ));

    const maxLength = Math.max(
      firstColumn.length,
      secondColumn.length,
      thirdColumn.length,
    );
    firstColumn.length = maxLength;

    for (let i = 0; i < maxLength; i += 1) {
      if (!firstColumn[i]) firstColumn[i] = emptyRow(`start-${i}`);
      if (!secondColumn[i]) secondColumn[i] = emptyRow(`middle-${i}`);
      if (!thirdColumn[i]) thirdColumn[i] = emptyRow(`end-${i}`);
    }

    const shownSize = 4;

    if (maxLength > shownSize) {
      const shownResultFirst = firstColumn.slice(0, shownSize);
      const hiddenResultFirst = firstColumn.slice(shownSize);

      const shownResultSecond = secondColumn.slice(0, shownSize);
      const hiddenResultSecond = secondColumn.slice(shownSize);

      const shownResultThird = thirdColumn.slice(0, shownSize);
      const hiddenResultThird = thirdColumn.slice(shownSize);

      const showMoreButtton = (
        <DateTitle>
          <HoursButton secondary onClick={() => setIsOpened(!isOpened)}>
            <span className="hide-on-mobile">{translations.slots_more}</span>
            <AngleDown />
          </HoursButton>
        </DateTitle>
      );

      return (
        <Row>
          <SCol>
            {shownResultFirst}
            <Collapse isOpened={isOpened}>{hiddenResultFirst}</Collapse>
            {!isOpened && startDateSlots.length > shownSize && showMoreButtton}
          </SCol>
          <SCol>
            {shownResultSecond}
            <Collapse isOpened={isOpened}>{hiddenResultSecond}</Collapse>
            {!isOpened && middleDateSlots.length > shownSize && showMoreButtton}
          </SCol>
          <SCol>
            {shownResultThird}
            <Collapse isOpened={isOpened}>{hiddenResultThird}</Collapse>
            {!isOpened && endDaySlots.length > shownSize && showMoreButtton}
          </SCol>
        </Row>
      );
    }
    if (maxLength < shownSize) {
      for (let i = 0; i < shownSize - maxLength; i += 1) {
        firstColumn.push(emptyRow(`start-empty-${i}`));
        secondColumn.push(emptyRow(`middle-empty-${i}`));
        thirdColumn.push(emptyRow(`end-empty-${i}`));
      }
    }

    return (
      <Row style={{ flexWrap: "nowrap" }}>
        <SCol>{firstColumn}</SCol>
        <SCol>{secondColumn}</SCol>
        <SCol>{thirdColumn}</SCol>
      </Row>
    );
  }, [
    endDate,
    getActiveButton,
    isOpened,
    middleDate,
    slots?.free_bookable_slots,
    startDate,
    translations.slots_more,
  ]);

  useEffect(() => {
    setIsOpened(false);
  }, [slots]);

  return (
    <>
      {!slotsLoading &&
        slots?.free_bookable_slots?.length > 0 &&
        renderDateRow()}
      {!slotsLoading &&
        slots?.free_bookable_slots?.length === 0 &&
        slots?.next_free_bookable_slot?.attributes && (
          <CenterRow>
            <NextButton
              onClick={() =>
                goToDate(
                  addHours(
                    startOfDay(
                      new Date(
                        slots?.next_free_bookable_slot?.attributes?.dtstart,
                      ),
                    ),
                    2,
                  ),
                )
              }
            >
              <Inner>
                {`${translations.r_next_available_time}: ${formatDate(
                  slots?.next_free_bookable_slot?.attributes?.dtstart,
                  "d MMM",
                  locale,
                ).replace(".", "")}`}
              </Inner>
            </NextButton>
          </CenterRow>
        )}

      {!slotsLoading &&
        (!slots ||
          (slots?.free_bookable_slots?.length === 0 &&
            !slots?.next_free_bookable_slot?.attributes)) && (
          <CenterRow>
            <Text>No available times to book</Text>
          </CenterRow>
        )}

      {slotsLoading && (
        <div>
          <CenterRow>
            <Spinner />{" "}
          </CenterRow>
          <CenterRow style={{ margin: "5px 0 5px", fontSize: "13px" }}>
            {translations.r_checking_available_times}
          </CenterRow>
        </div>
      )}
    </>
  );
};

RescheduleSlots.propTypes = {
  goToDate: PropTypes.func,
  translations: PropTypes.object,
  setSelectedSlot: PropTypes.func,
  setStep: PropTypes.func,
  slots: PropTypes.object,
  slotsLoading: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
};

export default memo(RescheduleSlots);
