import React from "react";

const SmileySad = ({ classProp }) => (
  <svg
    className={`HealthDeclarationSmileySVGSize ${classProp}`}
    viewBox="58.49 90.74 79.96 79.96"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    y="0px"
  >
    <circle className="st0 orangeSt0" cx="98.47" cy="130.72" r="37.98" />
    <path
      className="st0 orangeSt0"
      d="M118.53,150.9c-0.85-1.58-6.84-10.57-18.68-11.07c-8.79-0.37-17.13,4.48-21.44,12.45"
    />
    <circle className="st1 orangeSt1" cx="85.61" cy="118.74" r="6.16" />
    <circle className="st1 orangeSt1" cx="111.33" cy="118.74" r="6.16" />
  </svg>
);

export default SmileySad;
