import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as PropTypes from "prop-types";

import { bookingActions } from "redux/booking/actions";

import { ContentContainer, MainSpinner } from "components/common.styled";
import Pagination from "components/common/pagination";

import BookingCard from "../booking-card";

import {
  BookingHeader,
  BookingsGrid,
  BookingsInnerContainer,
  PastBookingsGrid,
} from "./index.styled";

const BookingsContent = ({ translations }) => {
  const dispatch = useDispatch();
  const { lastBookings, comingBookings, lastBookingsPage, loading } =
    useSelector((state) => state.booking);

  const onPageChange = (e) => {
    dispatch(bookingActions.changeLastBookingsPage(e));
  };

  useEffect(() => {
    dispatch(bookingActions.fetchComingBookingsList());

    return () => {
      dispatch(bookingActions.changeLastBookingsPage(1));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(bookingActions.fetchLastBookingsList(lastBookingsPage));
  }, [dispatch, lastBookingsPage]);

  return (
    <ContentContainer>
      <BookingsInnerContainer>
        {loading ? (
          <MainSpinner />
        ) : (
          <>
            <BookingHeader>
              {translations.bookings_coming_booking}
            </BookingHeader>
            <BookingsGrid>
              {comingBookings?.data?.length === 0 && (
                <div>{translations.bookings_you_have_no_bookings}</div>
              )}
              {!!comingBookings?.data?.length &&
                comingBookings?.data?.map((item) => (
                  <BookingCard
                    isComing
                    bookings={comingBookings.included}
                    item={item}
                    key={item?.id}
                    translations={translations}
                  />
                ))}
            </BookingsGrid>
            <br />
            <BookingHeader>
              {translations.bookings_where_treated_before}
            </BookingHeader>
            <PastBookingsGrid>
              {!lastBookings?.data?.length && (
                <div style={{ padding: "0 32px" }}>
                  {translations.bookings_you_have_no_past_bookings}
                </div>
              )}
              {!!lastBookings?.data?.length &&
                lastBookings?.data?.map((item) => (
                  <BookingCard
                    bookings={lastBookings.included}
                    item={item}
                    key={item?.id}
                    translations={translations}
                  />
                ))}
            </PastBookingsGrid>
            {lastBookings?.meta && (
              <Pagination
                limit={12}
                current={lastBookingsPage}
                total={lastBookings.meta.pagination.total}
                onPageChanged={onPageChange}
              />
            )}
          </>
        )}
      </BookingsInnerContainer>
    </ContentContainer>
  );
};

BookingsContent.propTypes = {
  translations: PropTypes.object,
};

export default BookingsContent;
