import { BaseService } from "../base-service";

class AuthService extends BaseService {
  constructor() {
    super();
    this.userSignIn = this.userSignIn.bind(this);
    this.getAutoStartToken = this.getAutoStartToken.bind(this);
  }

  async userSignIn(formData) {
    try {
      const response = await this.agent.post("/oauth/token", formData);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      throw error;
    }
  }

  async getAutoStartToken() {
    try {
      const response = await this.agent.post(
        "/autostarttokens",
        {
          data: { type: "autostarttokens" },
        },
        {
          params: { guard: "patient" },
        },
      );

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      throw error;
    }
  }
}

const ServiceInstance = new AuthService();

export default ServiceInstance;
export { ServiceInstance as AuthService };
