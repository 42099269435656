import React, { memo, useCallback } from "react";
import * as PropTypes from "prop-types";

const TwoAlternativesButtons = ({
  leftButtonText,
  leftOnClickValue,
  onClickLeft,
  onClickRight,
  rightButtonText,
  containerClass,
}) => {
  const handleClickLeft = useCallback(() => {
    onClickLeft(leftOnClickValue);
  }, [onClickLeft, leftOnClickValue]);

  return (
    <div className={containerClass}>
      <button
        style={{ marginRight: "7px" }}
        className="HealthDeclarationButton HealthDeclarationTwoAlternativeButton"
        onClick={handleClickLeft}
      >
        {leftButtonText}
      </button>
      <button
        className="HealthDeclarationButton HealthDeclarationTwoAlternativeButton"
        onClick={onClickRight}
      >
        {rightButtonText}
      </button>
    </div>
  );
};

TwoAlternativesButtons.propTypes = {
  leftButtonText: PropTypes.string,
  leftOnClickValue: PropTypes.bool,
  onClickLeft: PropTypes.func,
  onClickRight: PropTypes.func,
  rightButtonText: PropTypes.string,
  containerClass: PropTypes.string,
};

TwoAlternativesButtons.defaultProps = {
  containerClass: "HealthDeclarationTwoAlternativeButtonDiv",
};

export default memo(TwoAlternativesButtons);
