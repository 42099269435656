import React, { useEffect, useState } from "react";
import { addDays, subDays } from "date-fns";
import * as PropTypes from "prop-types";

import { toDate } from "helpers/helper-functions";

import Wrapper from "components/calendar/wrapper";
import Header from "components/header";
import { SearchHeader } from "components/search/index.styled";

import { TransitionWrapper } from "../../container/index.styled";

const HeaderBlock = ({
  translations,
  referralSourceEqualStatus,
  loading,
  startDate,
  setStartDate,
  mounted,
  timeStep,
}) => {
  const [scrolledDown, setScrolledDown] = useState(false);

  const goToDate = (date) => {
    toDate(date, setStartDate);
  };

  const goBack = () => goToDate(subDays(startDate, timeStep));
  const goNext = () => goToDate(addDays(startDate, timeStep));

  const scrollEventFunction = () => {
    let ticking = false;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        if (window.scrollY >= 100) setScrolledDown(true);
        else setScrolledDown(false);
        ticking = false;
      });
      ticking = true;
    }
  };

  useEffect(() => {
    if (mounted) document.addEventListener("scroll", scrollEventFunction);

    return () => {
      document.removeEventListener("scroll", scrollEventFunction);
    };
  }, [mounted]);

  if (global.innerWidth >= 994)
    return <Header showSearchBar translations={translations} />;

  return (
    <Header
      addPaddingBottom
      showSearchBar
      referralSourceEqualStatus={referralSourceEqualStatus}
      translations={translations}
      withoutBorder
      hideSearchBarOnScroll
    >
      <TransitionWrapper
        style={{
          backgroundColor: "white",
          height: "60px",
          width: "100%",
        }}
        className={`${scrolledDown ? "" : "hided"} dateSelectorHeaderDiv`}
        referralSourceEqualStatus={referralSourceEqualStatus}
      >
        {referralSourceEqualStatus && (
          <div
            style={{
              backgroundColor: "white",
              height: "20px",
              width: "100%",
            }}
          >
            {" "}
          </div>
        )}
        <SearchHeader isMobile>
          <div
            style={{
              width: "100%",
              marginTop: "10px",
            }}
          >
            <Wrapper
              goBack={goBack}
              goNext={goNext}
              loading={loading}
              startDate={startDate}
            />
          </div>
        </SearchHeader>
      </TransitionWrapper>
    </Header>
  );
};

HeaderBlock.propTypes = {
  translations: PropTypes.object,
  referralSourceEqualStatus: PropTypes.bool,
  loading: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func,
  mounted: PropTypes.bool,
  timeStep: PropTypes.number,
};

export default HeaderBlock;
