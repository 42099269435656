import {
  getMinutes,
  isBefore,
  isSameDay,
  setMinutes,
  setSeconds,
  startOfDay,
} from "date-fns";
import da from "date-fns/locale/da";
import en from "date-fns/locale/en-GB";
import nb from "date-fns/locale/nb";
import sv from "date-fns/locale/sv";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

import { DEFAULT_ERROR_MESSAGE } from "constants/defaults";
import { LOCALES } from "constants/languages";

export const mobileValidator = /(phone|mobile|mobi)/i;

export const proxyGetInterceptor = (target, returnValue = " ") => {
  return new Proxy(target, {
    get(target, prop) {
      if (prop in target) {
        return target[prop];
      }
      return returnValue;
    },
  });
};

export const getAdaptedLanguages = (translations = []) => {
  const adaptedTranslations = {};
  translations.forEach((translation) => {
    if (translation?.attributes?.key) {
      adaptedTranslations[translation.attributes.key] =
        translation.attributes.value;
    }
  });

  return proxyGetInterceptor(adaptedTranslations);
};

export const slugifyer = (text) => {
  const mapObj = {
    å: "a",
    ä: "a",
    ö: "o",
    é: "e",
    ü: "u",
    ç: "c",
    è: "e",
    ê: "e",
    ë: "e",
    í: "i",
    ì: "i",
    î: "i",
    ï: "i",
    ñ: "n",
    ø: "o",
    ó: "o",
    ò: "o",
    ô: "o",
    õ: "o",
    ú: "u",
    ù: "u",
    û: "u",
    ý: "y",
    ÿ: "y",
    æ: "ae",
    œ: "oe",
  };
  if (text) {
    return text
      .toLowerCase()
      .trim()
      .replace(
        /(?:å|ä|ö|é|ü|ç|è|ê|ë|í|ì|ï|ñ|ø|ó|ò|ô|õ|ú|ù|û|ý|ÿ|æ|œcatch)/gm,
        (matched) => mapObj[matched],
      )
      .replace(/\s+|-{2,}|\//gm, "-")
      .replace(/\s+|-{2,}|\//gm, "-")
      .replace(/[^a-z0-9\s-]+|^-+|-+$/gm, ``);
  }
  return null;
};

export const getBookingTitle = (title) => {
  const appName = "Boka via Muntra";
  return title ? `${title} | ${appName}` : appName;
};

export const getDefaultTitle = (title) => {
  const appName = "Boka tandvård online | Muntra";
  return title ? `${title} | ${appName}` : appName;
};

export const getIncludedItem = (included, search) =>
  included?.find((item) => item.type === search.type && item.id === search.id);

const isObjectCondition = (item) => item?.data && typeof item.data === "object";
const isArrayCondition = (item) => item?.data && Array.isArray(item.data);

const operateObject = ({ result, key, included, relationships }) => {
  const includedItem = getIncludedItem(included, relationships[key].data);

  if (includedItem?.relationships) {
    const params = {
      result: includedItem,
      included,
      relationships: includedItem.relationships,
    };

    for (const key2 in includedItem.relationships) {
      if (isArrayCondition(includedItem.relationships[key2])) {
        // * this is ok, needed for recursion
        // eslint-disable-next-line no-use-before-define
        operateArray({ ...params, key: key2 });
      } else if (isObjectCondition(includedItem.relationships[key2])) {
        operateObject({ ...params, key: key2 });
      } else includedItem[key2] = undefined;
    }
  }

  result[key] = includedItem;
};

const operateArray = ({ result, key, included, relationships }) => {
  result[key] = relationships[key]?.data?.map((item) => {
    const includedItem = getIncludedItem(included, item);
    if (includedItem.relationships) {
      const params = {
        result: includedItem,
        included,
        relationships: includedItem.relationships,
      };

      for (const key2 in includedItem.relationships) {
        if (isArrayCondition(includedItem.relationships[key2])) {
          operateArray({ ...params, key: key2 });
        } else if (isObjectCondition(includedItem.relationships[key2])) {
          operateObject({ ...params, key: key2 });
        } else includedItem[key2] = undefined;
      }
    }
    return includedItem;
  });
};

export const getItemFields = (relationships, included) => {
  try {
    const result = {};
    const params = { result, included, relationships };

    for (const key in relationships) {
      if (isArrayCondition(relationships[key])) {
        operateArray({ ...params, key });
      } else if (isObjectCondition(relationships[key])) {
        operateObject({ ...params, key });
      } else result[key] = undefined;
    }

    return result;
  } catch (err) {
    throw new Error("Something went wrong with items parsing", err);
  }
};

export const isoFormatDate = (date) =>
  format(date, "yyyy-MM-dd'T'HH:mm:ssXXX", {
    timeZone: "Europe/Stockholm",
    locale: sv,
  });

const locales = {
  [LOCALES.swedish]: sv,
  [LOCALES.norwegian]: nb,
  [LOCALES.danish]: da,
  [LOCALES.english]: en,
};

export const formatDate = (date, dateFormat, locale = LOCALES.swedish) => {
  const constructDate = new Date(date);
  const newTimeZone = "Europe/Stockholm";

  const newDate = utcToZonedTime(constructDate, newTimeZone);

  if (!formatDate) return "";

  if (!dateFormat) {
    const newDateWithoutFormat = utcToZonedTime(date, newTimeZone);

    return format(newDateWithoutFormat, "yyyy-MM-dd'T'HH:mm:ssXXX", {
      timeZone: "Europe/Stockholm",
      locale: locales[locale],
    });
  }

  return format(newDate, dateFormat, {
    timeZone: "Europe/Stockholm",
    locale: locales[locale],
  });
};

export const toDate = (date, setDate) => {
  if (isSameDay(date, new Date()) || isBefore(date, new Date()))
    return setDate(new Date());
  const dayStartZoned = startOfDay(date);
  const dayStart = zonedTimeToUtc(dayStartZoned, "Europe/Stockholm");
  return setDate(dayStart);
};

export const scrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

export const getCaregiverFullName = (caregiver) => {
  const firstName = caregiver?.user.attributes.first_name;
  const lastName = caregiver?.user.attributes.last_name;
  return `${firstName} ${lastName}`;
};

export const formatHours = (date, format, locale = LOCALES.swedish) => {
  const interval = 5;
  const roundedMinutes =
    Math.ceil(getMinutes(new Date(date)) / interval) * interval;
  const dateWithRoundMinutes = setMinutes(
    setSeconds(new Date(date), 0),
    roundedMinutes,
  );

  if (format) return formatDate(dateWithRoundMinutes, format, locale);

  return formatDate(dateWithRoundMinutes);
};

export const replaceVariables = (text = "", variables = []) => {
  const replacedEscapeLineBreaks = text.replace(/\\r\\n/g, "\r\n");
  // example of string with vars: 'Variable #1: $1; Variable #2: $2; Variable #3: $3;'
  return replacedEscapeLineBreaks.replace(/\$([1-9])/g, (_, position) => {
    return variables[+position - 1] || "";
  });
};

export const handleResponseError = (error) =>
  error?.response?.data?.errors?.[0]?.detail ||
  error?.message ||
  DEFAULT_ERROR_MESSAGE;

export const removeUndefinedValues = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value !== undefined),
  );
};
