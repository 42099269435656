import styled from "styled-components";

export const FooterLogo = styled.img`
  margin: 0 auto 20px auto;
  max-width: 100px;
`;

export const BottomFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #484848;
  padding: 15px 20px;
  font-size: 12px;

  & a {
    color: #fff04b;
  }
`;

export const VersionContainer = styled.span`
  color: grey;
`;

export const InternationalizationFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-right: 15px;
`;
