export const createPatientData = (id, values) => ({
  data: {
    id,
    attributes: {
      ...values,
      accepts_review_request_emails: true,
      phone_number_cell:
        values.phone_number_cell === null
          ? null
          : values.phone_number_cell.trim().replace(/^0|^46/g, "+46"),
    },
    type: "muntra-patients",
  },
});
