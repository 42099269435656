import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addHours, startOfDay } from "date-fns";
import * as PropTypes from "prop-types";

import { formatDate, formatHours } from "helpers/helper-functions";
import { selectTag } from "redux/app/selectors";
import { caregiversActions } from "redux/caregivers/actions";

import { Row } from "../../common.styled";
import BookingModal from "../booking-modal/booking-modal";
import SendBookingModal from "../send-booking-modal";

import { Spinner } from "../../common/spinner/index.styled";
import {
  CenterRow,
  DateTitle,
  HoursButton,
  Inner,
  NextButton,
} from "../index.styled";

const SlotsMobile = ({
  caregiver,
  defaultProcedure,
  isSearch,
  location,
  translations,
  caregiverAtLocationObject,
  clinic = {},
  dateObject,
}) => {
  const dispatch = useDispatch();
  const locale = useSelector(selectTag);

  const {
    id,
    caregiverAtLocation,
    caregiverAtLocationLoadBegin,
    caregiverAtLocationLoading,
    noCaregiverAtLocation,
  } = caregiverAtLocationObject || {};

  const { id: clinicId } = clinic || {};
  const {
    booking_requires_two_factor_authentication:
      bookingRequiresTwoFactorAuthentication,
    email: clinicEmail,
  } = clinic?.attributes || {};
  const { startDate, goToDate } = dateObject || {};

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isSendModalOpened, setIsSendModalOpened] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState("");

  const sortedArray = useMemo(
    () =>
      [...(caregiverAtLocation?.free_bookable_slots || [])].sort(
        (a, b) => b.attributes.dtstart - a.attributes.dtstart,
      ),
    [caregiverAtLocation?.free_bookable_slots],
  );

  const startDateSlots = useMemo(
    () =>
      sortedArray?.filter(
        (item) =>
          formatDate(item.attributes.dtstart, "dd/MM/uuuu") ===
          formatDate(startDate, "dd/MM/uuuu"),
      ),
    [sortedArray, startDate],
  );

  const onHoursButtonClick = useCallback(
    (slotsData) => {
      setSelectedSlot(slotsData);
      setIsModalOpened(true);
      if (caregiverAtLocation.clinic) {
        dispatch(
          caregiversActions.caregiverForBookingRefresh(caregiverAtLocation),
        );
      } else {
        dispatch(
          caregiversActions.caregiverForBookingRefresh({
            ...caregiverAtLocation,
            clinic,
          }),
        );
      }
    },
    [caregiverAtLocation, clinic, dispatch],
  );

  const getActiveButton = useCallback(
    (hours, slotsData) => (
      <span>
        <HoursButton
          style={{ display: "inline" }}
          onClick={() => onHoursButtonClick(slotsData)}
          primary
        >
          {hours}
        </HoursButton>
      </span>
    ),
    [onHoursButtonClick],
  );

  const renderDateRow = useCallback(() => {
    const dateRow = startDateSlots.map((item) => (
      <DateTitle style={{ padding: "0 5px", margin: 0 }} key={item.id}>
        {startDate
          ? getActiveButton(formatHours(item.attributes.dtstart, "H:mm"), item)
          : ""}
      </DateTitle>
    ));

    return (
      <Row
        style={{
          flexWrap: "nowrap",
          overflow: "auto",
          height: "105px",
          width: "100%",
        }}
      >
        {dateRow}
      </Row>
    );
  }, [getActiveButton, startDate, startDateSlots]);

  if (
    !location.attributes?.calendar_activated &&
    clinicEmail &&
    caregiverAtLocation?.attributes?.booking_requests_activated
  )
    return (
      <div style={{ height: "105px", width: "100%" }}>
        <Row style={{ justifyContent: "center" }} justify="center">
          <NextButton
            style={{ margin: "32px 32px 0px 32px" }}
            primary
            onClick={() => setIsSendModalOpened(true)}
          >
            <Inner>{translations.r_send_booking_request}</Inner>
          </NextButton>
        </Row>
        {isSendModalOpened && (
          <SendBookingModal
            caregiver={caregiver}
            clinicId={clinicId}
            defaultProcedure={defaultProcedure}
            isSearch={isSearch}
            onClose={() => setIsSendModalOpened(false)}
            selectedSlot={selectedSlot}
            slotsId={id}
            translations={translations}
          />
        )}
      </div>
    );

  if (
    !location?.attributes?.calendar_activated &&
    (!clinicEmail ||
      !caregiverAtLocation?.attributes?.booking_requests_activated)
  )
    return (
      <div style={{ height: "105px", width: "100%" }}>
        <Row style={{ justifyContent: "center" }}>
          <NextButton
            style={{ margin: "32px 32px 0px 32px" }}
            disabled
            secondary
          >
            <Inner>{translations.r_cant_be_booked}</Inner>{" "}
          </NextButton>
        </Row>
      </div>
    );

  return (
    <div style={{ position: "relative", height: "105px", width: "100%" }}>
      {!caregiverAtLocationLoadBegin &&
        startDateSlots.length > 0 &&
        renderDateRow()}
      {!caregiverAtLocationLoadBegin &&
        startDateSlots?.length === 0 &&
        !caregiverAtLocation?.next_free_bookable_slot && (
          <div
            style={{ textAlign: "center", margin: "auto", paddingTop: "32px" }}
          >
            {translations.r_no_available_times_to_book}
          </div>
        )}
      {!caregiverAtLocationLoading &&
        startDateSlots?.length === 0 &&
        caregiverAtLocation?.next_free_bookable_slot && (
          <CenterRow style={{ margin: 0 }}>
            <NextButton
              style={{ margin: "32px 32px 0px 32px" }}
              onClick={() =>
                goToDate(
                  addHours(
                    startOfDay(
                      new Date(
                        caregiverAtLocation?.next_free_bookable_slot?.attributes?.dtstart,
                      ),
                    ),
                    2,
                  ),
                )
              }
            >
              <Inner style={{ margin: "auto" }}>
                {`${translations.r_next_available_time}: ${formatDate(
                  caregiverAtLocation.next_free_bookable_slot?.attributes
                    ?.dtstart,
                  "d MMM",
                  locale,
                ).replace(".", "")}`}
              </Inner>
            </NextButton>
          </CenterRow>
        )}

      {!caregiverAtLocationLoading && !caregiverAtLocation && (
        <CenterRow style={{ margin: 0 }}>{noCaregiverAtLocation}</CenterRow>
      )}

      {(caregiverAtLocationLoadBegin ||
        (caregiverAtLocationLoading &&
          caregiverAtLocation?.free_bookable_slots?.length === 0)) && (
        <div style={{ width: "100%" }}>
          <CenterRow style={{ margin: 0 }}>
            <Spinner />{" "}
          </CenterRow>
          <CenterRow style={{ marginTop: "19.5px", fontSize: "13px" }}>
            {translations.r_checking_available_times}
          </CenterRow>
        </div>
      )}
      {isModalOpened && (
        <BookingModal
          bookingRequiresTwoFactorAuthentication={
            bookingRequiresTwoFactorAuthentication
          }
          clinicId={clinicId}
          defaultProcedure={defaultProcedure}
          isSearch={isSearch}
          onClose={() => setIsModalOpened(false)}
          selectedSlot={selectedSlot}
          slotsId={id}
          translations={translations}
        />
      )}
    </div>
  );
};

SlotsMobile.propTypes = {
  caregiver: PropTypes.object,
  defaultProcedure: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  isSearch: PropTypes.bool,
  location: PropTypes.object,
  translations: PropTypes.object,
  caregiverAtLocationObject: PropTypes.object,
  clinic: PropTypes.object,
  dateObject: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    goToDate: PropTypes.func,
  }),
};

SlotsMobile.defaultProps = {
  defaultProcedure: {},
  caregiverAtLocationObject: {},
  clinic: {},
  dateObject: {},
};

export default memo(SlotsMobile);
