import styled from "styled-components";

import { ThirdHeading } from "../../common.styled";

export const StyledTextArea = styled.textarea`
  border: 1px solid #00000036;
  border-radius: 2px;
  padding: 2px 10px;
  display: flex;
  transition: all 0.3s;
  width: 100%;
  min-height: 45px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  &:hover {
    border: 1px solid #1890ff;
    outline: 0;
    & svg {
      fill: #1890ff;
    }
  }

  &:focus-within {
    box-shadow: 0px 0px 1px 3px rgba(24, 143, 255, 0.31);
    border: 1px solid #1890ff;
    outline: 0;
  }

  & input {
    border: 0;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 20px;
  }
`;

export const StyledThirdHeading = styled(ThirdHeading)`
  margin-top: 24px;
`;

export const ErrorText = styled.p`
  color: #dc0e0e;
  font-size: 12px;
  text-align: left;
  margin: 5px 0px 0px 0px;
`;
