import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import * as PropTypes from "prop-types";

import { caregiversActions } from "redux/caregivers/actions";
import { googlePlacesActions } from "redux/google-places/actions";

import GeoLocationSvg from "../../../assets/svg-components/GeoLocation";
import SearchSvg from "../../../assets/svg-components/Search";

import {
  LeftSearchInputContainer,
  NoResults,
  OptionsContainer,
  SearchContainer,
  SearchInputContainer,
  SearchSpin,
  StartPageSearchLeftInputContainer,
  StartPageSearchRightInputContainer,
} from "./index.styled";

const Search = ({
  autoFocus,
  className,
  handleSearchChange,
  isCaregiverSearchBar,
  isLoading,
  isStartPage,
  leftSearch,
  options,
  onClose,
  placeholder,
  validText,
  value = "",
}) => {
  const dispatch = useDispatch();
  const { caregiversTerms, error } = useSelector((state) => state.caregivers);
  const { placesTerms, placesError } = useSelector(
    (state) => state.googlePlaces,
  );

  const dropdownMenu = useRef(null);

  const [activeOption, setActiveOption] = useState(0);
  const [showOptions, setShowOptions] = useState(0);

  const onChange = ({ target }) => {
    setShowOptions(1);
    handleSearchChange(target.value);
  };

  const handleClick = useCallback(
    ({ action, link }) => {
      if (error) dispatch(caregiversActions.clearCaregiverError());
      if (placesError) dispatch(googlePlacesActions.clearPlacesError());
      if (leftSearch && caregiversTerms)
        dispatch(
          caregiversActions.patchSelectedSearchCaregiverTerms(caregiversTerms),
        );
      if (!leftSearch && placesTerms)
        dispatch(
          googlePlacesActions.patchSelectedSearchGooglePlaceTerms(placesTerms),
        );

      handleSearchChange("");
      action(link);
      setShowOptions(0);

      if (onClose) {
        onClose();
      }
    },
    [
      caregiversTerms,
      dispatch,
      error,
      handleSearchChange,
      leftSearch,
      onClose,
      placesError,
      placesTerms,
    ],
  );

  const onKeyDown = useCallback(
    ({ keyCode }) => {
      if (keyCode === 13) {
        const result = options[activeOption];
        if (result) {
          if (error) dispatch(caregiversActions.clearCaregiverError());
          if (placesError) dispatch(googlePlacesActions.clearPlacesError());
          if (leftSearch && caregiversTerms)
            dispatch(
              caregiversActions.patchSelectedSearchCaregiverTerms(
                caregiversTerms,
              ),
            );
          if (!leftSearch && placesTerms)
            dispatch(
              googlePlacesActions.patchSelectedSearchGooglePlaceTerms(
                placesTerms,
              ),
            );

          handleSearchChange("");
          result.action(result.link);
          setActiveOption(0);
          setShowOptions(0);
        }
      } else if (keyCode === 38) {
        if (activeOption === 0) return;
        setActiveOption(activeOption - 1);
      } else if (keyCode === 40) {
        if (activeOption === options.length - 1) return;
        setActiveOption(activeOption + 1);
      }
    },
    [
      activeOption,
      caregiversTerms,
      dispatch,
      error,
      handleSearchChange,
      leftSearch,
      options,
      placesError,
      placesTerms,
    ],
  );

  const closeMenu = useCallback((e) => {
    if (!dropdownMenu?.current?.contains(e.target)) {
      document.removeEventListener("click", closeMenu);
      setShowOptions(0);
    }
  }, []);

  const showMenu = useCallback(
    (e) => {
      e.preventDefault();
      document.addEventListener("click", closeMenu);
      setShowOptions(1);
    },
    [closeMenu],
  );

  useEffect(() => {
    return () => document.removeEventListener("click", closeMenu);
  }, [closeMenu]);

  const optionsList = useMemo(() => {
    if (!isLoading && options && value) {
      if (options.length > 0 && value?.length >= 3) {
        return (
          <OptionsContainer options={showOptions}>
            {options.map(
              ({ item, label, value, action, link, ...rest }, index) => {
                const isActive = activeOption === index ? 1 : 0;
                return (
                  <div
                    key={item.id}
                    onClick={() => handleClick({ value, action, link })}
                    value={item.id}
                  >
                    {label({ ...rest, isActive, item })}
                  </div>
                );
              },
            )}
          </OptionsContainer>
        );
      }

      if (value?.length < 3) {
        return (
          <OptionsContainer options={showOptions}>
            <NoResults>{validText}</NoResults>
          </OptionsContainer>
        );
      }

      return (
        <OptionsContainer options={showOptions}>
          <NoResults>
            Inga resultat för sökningen. Kontrollera att orden är rättstavade
            eller använd färre eller andra sökord
          </NoResults>
        </OptionsContainer>
      );
    }

    if (isLoading && value)
      return (
        <OptionsContainer options={showOptions}>
          <SearchSpin />
        </OptionsContainer>
      );
  }, [
    activeOption,
    handleClick,
    isLoading,
    options,
    showOptions,
    validText,
    value,
  ]);

  return (
    <SearchContainer className={className} ref={dropdownMenu}>
      {!isStartPage && !isCaregiverSearchBar && (
        <SearchInputContainer>
          <div className="SVGContainer">
            <GeoLocationSvg stylingPropClassName="SVG16px" />
          </div>
          <input
            autoFocus={autoFocus}
            id="noStartPageLocationSearchInput"
            onChange={onChange}
            onFocus={showMenu}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            style={{ fontSize: "15px" }}
            type="search"
            value={value}
          />
        </SearchInputContainer>
      )}
      {!isStartPage && isCaregiverSearchBar && (
        <LeftSearchInputContainer>
          <div className="SVGContainer">
            <SearchSvg stylingPropClassName="SVG16px" />
          </div>
          <input
            autoFocus={autoFocus}
            id="noStartPageCaregiverSearchInput"
            onChange={onChange}
            onFocus={showMenu}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            style={{ fontSize: "15px" }}
            type="search"
            value={value}
          />
        </LeftSearchInputContainer>
      )}
      {isStartPage && !isCaregiverSearchBar && (
        <StartPageSearchRightInputContainer>
          <div className="SVGContainer">
            <GeoLocationSvg stylingPropClassName="SVG16px" />
          </div>
          <input
            autoFocus={autoFocus}
            id="StartPageLocationSearchInput"
            onChange={onChange}
            onFocus={showMenu}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            style={{ fontSize: "15px" }}
            type="search"
            value={value}
          />
        </StartPageSearchRightInputContainer>
      )}
      {isStartPage && isCaregiverSearchBar && (
        <StartPageSearchLeftInputContainer>
          <div className="SVGContainer">
            <SearchSvg stylingPropClassName="SVG16px" />
          </div>
          <input
            autoFocus={autoFocus}
            id="StartPageCaregiverSearchInput"
            onChange={onChange}
            onFocus={showMenu}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            style={{ fontSize: "15px" }}
            type="search"
            value={value}
          />
        </StartPageSearchLeftInputContainer>
      )}
      {optionsList}
    </SearchContainer>
  );
};

Search.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  handleSearchChange: PropTypes.func,
  isCaregiverSearchBar: PropTypes.bool,
  isLoading: PropTypes.bool,
  isStartPage: PropTypes.bool,
  leftSearch: PropTypes.bool,
  options: PropTypes.array,
  onClose: PropTypes.func,
  placeholder: PropTypes.string,
  validText: PropTypes.string,
  value: PropTypes.string,
};

export default memo(Search);
