import axios from "axios";
import { addMinutes, isBefore } from "date-fns";

import config from "config";
import { CUSTOM_EVENTS } from "constants/custom-events";
import { formatDate, handleResponseError } from "helpers/helper-functions";
import { getLocale } from "helpers/internationalization-functions";
import { Notifications } from "helpers/notifications";

import { eventEmitter } from "components/common/event-bus";

export class BaseService {
  constructor(url = "", api = "") {
    this.baseServiceURL = api ? `${api}${url}` : `${config.api}${url}`;
    this.processError = this.processError.bind(this);
  }

  get baseURL() {
    return this.baseServiceURL;
  }

  get agent() {
    const headers = {};
    const accessToken = localStorage.getItem("user_token_pat_frontend");
    if (accessToken) {
      headers.authorization = `Bearer ${accessToken}`;
    }
    axios.defaults.headers.common["Accept-Language"] = getLocale();

    axios.defaults.paramsSerializer = (params) => {
      let str = "";
      Object.entries(params).forEach(([key, value], index) => {
        str += `${key}=${encodeURIComponent(value)}`;
        if (index !== Object.entries(params).length - 1) str += "&";
      });
      return str;
    };

    const axiosInstance = axios.create({
      baseURL: this.baseURL,
      headers,
    });

    axiosInstance.interceptors.response.use(
      (response) => {
        localStorage.setItem(
          "expired_time",
          formatDate(addMinutes(new Date(), 15)),
        );
        return response;
      },
      (error) => {
        if (+(error?.response?.status || "") === 401) {
          eventEmitter.emit(CUSTOM_EVENTS.logout);
          throw error;
        } else throw error;
      },
    );

    axiosInstance.interceptors.request.use(
      (config) => {
        const expTime = localStorage.getItem("expired_time");
        const token = localStorage.getItem("user_token_pat_frontend");
        const isTimeExpired = isBefore(new Date(expTime), new Date());

        if (expTime && token && isTimeExpired) {
          eventEmitter.emit(CUSTOM_EVENTS.logout);
          return Promise.reject(new Error("The session has expired."));
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    return axiosInstance;
  }

  // eslint-disable-next-line class-methods-use-this
  processError(error) {
    Notifications.message({ message: handleResponseError(error) });
  }
}
