import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import * as PropTypes from "prop-types";

import { formatHours, replaceVariables } from "helpers/helper-functions";
import { selectTag } from "redux/app/selectors";

import { Heading } from "../../common.styled";
import { StretchButton } from "../../common/button";

const ConfirmContent = ({
  bookingIsTentative,
  clinicAllowsHealthDeclaration,
  isMobile,
  onClose,
  setShowHealthDeclaration,
  translations,
}) => {
  const { selectedSlot, caregiverDataForBooking = {} } = useSelector(
    (state) => state.caregivers,
  );
  const locale = useSelector(selectTag);

  const location = caregiverDataForBooking;
  const { caregiver, clinic } = caregiverDataForBooking || {};

  const { slot = {} } = selectedSlot || {};

  const handleClick = useCallback(() => {
    onClose();

    const newHref = window.location.href.split("?")[0];
    window.location.href = newHref;
  }, [onClose]);

  if (
    location?.attributes?.auto_approve_patient_booking &&
    caregiver.caregiver_locations &&
    !bookingIsTentative
  ) {
    const replacedVariables = [
      clinic.attributes?.clinic_name,
      formatHours(slot.attributes?.dtstart, "H:mm"),
      formatHours(slot.attributes?.dtend, "H:mm EEEE d MMMM", locale),
    ];

    return (
      <div style={{ textAlign: "start" }}>
        <Heading
          style={{ fontSize: "28px", lineHeight: "30px", textAlign: "left" }}
        >
          {translations.confirm_content_time_booked}
        </Heading>
        <br />
        <p>
          {replaceVariables(
            translations.confirm_content_description,
            replacedVariables,
          )}
        </p>
        <p>{translations.confirm_content_calendar_invite_sent}</p>
        {!clinicAllowsHealthDeclaration && (
          <StretchButton onClick={handleClick}>
            <span style={{ margin: "0 auto" }}>
              {translations.confirm_content_close}
            </span>
          </StretchButton>
        )}
        {clinicAllowsHealthDeclaration && setShowHealthDeclaration && (
          <StretchButton onClick={() => setShowHealthDeclaration(true)}>
            <span style={{ margin: "0 auto" }}>
              {translations.confirm_content_simple_fill_health_declaration}
            </span>
          </StretchButton>
        )}
      </div>
    );
  }

  return (
    <div
      className={isMobile ? "FlexColJustCenter90vh" : ""}
      style={{ textAlign: "start" }}
    >
      <Heading
        style={{ fontSize: "28px", lineHeight: "30px", textAlign: "left" }}
      >
        {translations.confirm_content_time_booked}
      </Heading>
      <br />
      <p>{translations.confirm_content_confirmation_mail_coming}</p>
      <p>{translations.confirm_content_calendar_invite_req}</p>
      <StretchButton onClick={handleClick}>
        <span style={{ margin: "0 auto" }}>
          {translations.confirm_content_close}
        </span>
      </StretchButton>
    </div>
  );
};

ConfirmContent.propTypes = {
  bookingIsTentative: PropTypes.bool,
  clinicAllowsHealthDeclaration: PropTypes.bool,
  isMobile: PropTypes.bool,
  onClose: PropTypes.func,
  setShowHealthDeclaration: PropTypes.func,
  translations: PropTypes.object,
};

ConfirmContent.defaultProps = {
  bookingIsTentative: false,
};

export default memo(ConfirmContent);
