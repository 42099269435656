import React, { memo } from "react";
import { Link } from "react-router-dom";
import * as PropTypes from "prop-types";

import { slugifyer } from "helpers/helper-functions";

import {
  CategoryContainer,
  CategoryImg,
  ImageContainer,
} from "../../container/index.styled";

const CaregiverTypeCard = ({ role, roleId }) => (
  <CategoryContainer key={roleId}>
    <Link to={`/${slugifyer(role)}/r/${roleId}`}>
      <ImageContainer>
        <CategoryImg
          alt={role}
          loading="lazy"
          src={`https://muntra-public.s3.eu-north-1.amazonaws.com/roles/${roleId}.jpeg`}
        />
      </ImageContainer>
      <p>{role}</p>
    </Link>
  </CategoryContainer>
);

CaregiverTypeCard.propTypes = {
  role: PropTypes.string,
  roleId: PropTypes.string,
};

export default memo(CaregiverTypeCard);
