import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import * as PropTypes from "prop-types";

import { Heading } from "components/common.styled";

import {
  BackButton,
  Divider,
  InputContainer,
  NextButton,
} from "./index.styled";

const Step5 = ({
  caregiverReview,
  changeStep,
  handleCaregiverReviewUpdate,
  step,
  translations,
}) => {
  const { caregiver } = useSelector((state) => state.review);
  const { first_name: firstName = "" } = caregiver.attributes || {};

  const handleInputChange = useCallback(
    ({ target }) => {
      handleCaregiverReviewUpdate({
        private_comment_to_caregiver: target.value,
      });
    },
    [handleCaregiverReviewUpdate],
  );

  return (
    <>
      {caregiver.attributes && (
        <Heading>
          <b>{`${translations.patient_review_step_5_add_private_note_to} ${firstName}`}</b>
        </Heading>
      )}
      <p>{translations.patient_review_step_5_give_suggestion_or_thanks}</p>
      <br />
      <InputContainer
        rows="4"
        onChange={handleInputChange}
        value={caregiverReview.private_comment_to_caregiver || ""}
        placeholder="Privat anteckning"
      />
      <br />
      <Divider />
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <BackButton link onClick={() => changeStep(step - 1)}>
          {"<"} {translations.patient_review_step_5_back}
        </BackButton>
        <NextButton onClick={() => changeStep(step + 1)}>
          {translations.patient_review_step_5_next}
        </NextButton>{" "}
      </div>
    </>
  );
};

Step5.propTypes = {
  caregiverReview: PropTypes.object,
  changeStep: PropTypes.func,
  handleCaregiverReviewUpdate: PropTypes.func,
  step: PropTypes.number,
  translations: PropTypes.object,
};

export default memo(Step5);
