import styled from "styled-components";

import { StyledText } from "../../../common.styled";

export const CaregiverCardStyled = styled.div`
  border: solid 1px lightgrey;
  padding: 20px;
  cursor: pointer;
`;

export const CaregiverCardMobileStyled = styled.div`
  padding: 10px 10px 32px;
  cursor: pointer;
  display: flex;
  border-bottom: 1px solid lightgrey;
  margin: 10px 0 32px 0;
  padding-left: 20px;
`;

export const LocalHeader = styled(StyledText)`
  font-size: 13px;
  margin-bottom: 5px;
`;

export const TextStyled = styled.p`
  color: black;
  margin-bottom: 2em;
`;
