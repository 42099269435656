import React, { memo, useCallback, useMemo } from "react";
import * as PropTypes from "prop-types";

import { Heading } from "components/common.styled";
import Radio from "components/common/radio";

import { BackButton, Divider, NextButton } from "./index.styled";

const Step2 = ({
  caregiverReview,
  changeStep,
  handleCaregiverReviewUpdate,
  step,
  translations,
}) => {
  const radioValues = useMemo(
    () => [
      {
        title: `${translations.patient_review_step_2_rating_5}`,
        value: "significantly-exceeds",
      },
      {
        title: `${translations.patient_review_step_2_rating_4}`,
        value: "exceeds",
      },
      {
        title: `${translations.patient_review_step_2_rating_3}`,
        value: "meets",
      },
      {
        title: `${translations.patient_review_step_2_rating_2}`,
        value: "below",
      },
      {
        title: `${translations.patient_review_step_2_rating_1}`,
        value: "significantly-below",
      },
    ],
    [translations],
  );

  const handleRadioChange = useCallback(
    ({ target }) => {
      handleCaregiverReviewUpdate({ comparison_to_expectations: target.value });
    },
    [handleCaregiverReviewUpdate],
  );

  return (
    <>
      <Heading>
        <b>{translations.patient_review_step_2_heading}</b>
      </Heading>
      <br />

      {radioValues.map((item, index) => (
        <div key={index}>
          <Radio
            value={item.value}
            name="review"
            onChange={handleRadioChange}
            checkedValue={
              caregiverReview.comparison_to_expectations || undefined
            }
          >
            {item.title}
          </Radio>
          {index !== radioValues.length - 1 && <Divider />}
        </div>
      ))}

      <br />
      <Divider />
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <BackButton link onClick={() => changeStep(step - 1)}>
          {"<"} {translations.patient_review_step_2_back}
        </BackButton>
        <NextButton onClick={() => changeStep(step + 1)}>
          {translations.patient_review_step_2_next}
        </NextButton>
      </div>
    </>
  );
};

Step2.propTypes = {
  caregiverReview: PropTypes.object,
  changeStep: PropTypes.func,
  handleCaregiverReviewUpdate: PropTypes.func,
  step: PropTypes.number,
  translations: PropTypes.object,
};

export default memo(Step2);
