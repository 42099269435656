const signHmacKey = async ({ encoder, params }, hmacKey) => {
  const data = encoder.encode(params[2]);
  const signature = await crypto.subtle.sign("HMAC", hmacKey, data);
  params.push(
    Array.from(new Uint8Array(signature))
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join(""),
  );
  return params;
};

const createHmacKey = async ({ encoder, startSecret }) => {
  return crypto.subtle.importKey(
    "raw",
    encoder.encode(startSecret),
    { name: "HMAC", hash: { name: "SHA-256" } },
    false,
    ["sign"],
  );
};

export const createQrData = async ({
  startSecret,
  startToken,
  timeCounterInSeconds = 0,
}) => {
  const params = ["bankid", startToken, timeCounterInSeconds];
  const encoder = new TextEncoder();
  const hmacKey = await createHmacKey({ encoder, startSecret });
  return signHmacKey({ encoder, params }, hmacKey);
};
