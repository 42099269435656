import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { decode } from "he";
import * as PropTypes from "prop-types";

import { selectTag } from "redux/app/selectors";

import { PositionText, StyledText } from "../../index.styled";

const InvoiceRow = ({ amount, currency, label }) => {
  const locale = useSelector(selectTag);

  const decodedLabel = useMemo(() => {
    try {
      return decodeURIComponent(label);
    } catch (e) {
      try {
        return decode(label);
      } catch (heError) {
        return label;
      }
    }
  }, [label]);

  return (
    <div style={{ position: "relative" }}>
      <StyledText
        style={{ marginBottom: "50px", paddingRight: "80px" }}
        dangerouslySetInnerHTML={{ __html: decodedLabel }}
      />
      <PositionText>
        {`${new Intl.NumberFormat(locale, {
          style: "currency",
          currency,
        }).format(amount)}`}
      </PositionText>
    </div>
  );
};

InvoiceRow.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.string,
  label: PropTypes.string,
};

InvoiceRow.defaultProps = {
  amount: 0,
  currency: "SEK",
  label: "",
};
export default memo(InvoiceRow);
