import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { addDays, endOfDay } from "date-fns";

import config from "config";
import { formatDate, slugifyer, toDate } from "helpers/helper-functions";
import { readablePhoneNumber } from "helpers/readable-phone-number";
import { useDebounce } from "hooks/use-debounce";
import { useQuery } from "hooks/use-query";
import { useReferralSource } from "hooks/use-referral-source";
import { useTranslations } from "hooks/use-translations";
import { clinicsActions } from "redux/clinics/actions";

import {
  Col,
  ContentContainer,
  MainSpinner,
  MobileSection,
  Row,
  StyledLayout,
} from "components/common.styled";
import Checkbox from "components/common/checkbox";
import Radio from "components/common/radio";
import Rates from "components/common/rates";
import Reviews from "components/common/reviews";
import Dropdown from "components/common/search-dropdown";
import Footer from "components/footer";

import Accessibility from "../components/accessibility";
import AggregateRatingMeta from "../components/aggregate-rating-meta";
import Amenities from "../components/amenities";
import Breadcrumbs from "../components/breadcrumbs";
import CaregiverCard from "../components/caregiver-card";
import ClinicImagesDesktop from "../components/clinic-images-desktop";
import ClinicImagesMobile from "../components/clinic-images-mobile";
import ClinicImagesModal from "../components/clinic-images-modal";
import ErrorMessage from "../components/error-message";
import GoogleMap from "../components/google-map";
import HeaderBlock from "../components/header";
import Parking from "../components/parking";

import {
  ClinicName,
  Content,
  MainRate,
  RateTitleCommments,
  StyledDivider,
} from "../components/index.styled";
import {
  ColMobile,
  InnerContainer,
  RatingRow,
  StyledMapCol,
} from "./index.styled";

const Clinic = () => {
  const {
    clinic = {},
    clinicImages,
    defaultClinicProcedure,
    error,
    fetchClinicDataStatus,
    loading,
    loadingReviews,
    loadingCaregivers,
    pageReviews,
    reviews,
  } = useSelector((state) => state.clinics);
  const language = useSelector((state) => state.app.data);

  const dispatch = useDispatch();
  const location = useLocation();
  const { clinic_name: clinicName, clinic_id: clinicId } = useParams();
  const query = useQuery();

  const referralSource = useReferralSource();
  const goalPageUrl = query.get("goal_page_url");

  const routeName = "[first_param].c.[clinic_id]";

  const timeStep = global.innerWidth < 994 ? 1 : 3;
  const isMobile = global.innerWidth < 994;

  const endDateInterval = global.innerWidth < 994 ? 0 : 2;

  const translations = useTranslations(routeName);

  const [caregiverArr, setCaregiverArr] = useState([]);
  const [defaultProcedure, setDefaultProcedure] = useState({});
  const [defaultProcedureStatus, setDefaultProcedureStatus] = useState(true);
  const [isNewPatient, setIsNewPatient] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [pickedImage, setPickedImage] = useState(0);
  const [procedureForSort, setProcedureForSort] = useState({});
  const [procedures, setProcedures] = useState([]);
  const [referralSourceEqualStatus, setReferralSourceEqualStatus] =
    useState(false);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [debouncedStartDate, setDebouncedStartDate] = useState(startDate);

  const getEndDate = useCallback(
    () => addDays(endOfDay(debouncedStartDate), endDateInterval),
    [endDateInterval, debouncedStartDate],
  );

  const goToDate = (date) => {
    toDate(date, setStartDate);
  };

  const onChangeCheckBox = ({ target }) => setIsNewPatient(target.checked);
  const toggleModal = () => setIsOpenModal(!isOpenModal);

  const handleRadioChange = (event, { changeItemsOrder }) => {
    const procedure = procedures?.find(
      (item) => item.id === event.target.value,
    );
    if (changeItemsOrder) {
      setDefaultProcedure(procedure);
      setProcedureForSort(procedure);
    } else {
      setDefaultProcedure(procedure);
    }
  };

  const handleProcedureChange = (newProcedure, { changeItemsOrder }) => {
    const procedure = procedures?.find((item) => item.id === newProcedure.id);
    if (changeItemsOrder) {
      setDefaultProcedure(procedure);
      setProcedureForSort(procedure);
    } else {
      setDefaultProcedure(procedure);
    }
  };

  const sortByProcedures = (aProcedure, bProcedure) => {
    if (aProcedure && !bProcedure) return -1;
    if (!aProcedure && bProcedure) return 1;
    if (!aProcedure && !bProcedure) return 0;
  };

  const sortByFreeBookableSlots = (a, b) => {
    if (a.free_bookable_slots?.length && !b.free_bookable_slots?.length)
      return -1;
    if (!a.free_bookable_slots?.length && b.free_bookable_slots?.length)
      return 1;
    if (a.free_bookable_slots?.length && b.free_bookable_slots?.length)
      return 0;
  };

  const sortByNextFreeBookableSlots = (a, b) => {
    if (a.next_free_bookable_slot && !b.next_free_bookable_slot) return -1;
    if (!a.next_free_bookable_slot && b.next_free_bookable_slot) return 1;
  };

  const getSortedCaregivers = useCallback((arrData, id) => {
    const arrayCopy = JSON.parse(JSON.stringify(arrData));

    return (
      arrayCopy?.sort((a, b) => {
        const aProcedure = a?.procedures?.find(
          (item) => item.procedure.id === id,
        );
        const bProcedure = b?.procedures?.find(
          (item) => item.procedure.id === id,
        );

        sortByProcedures(aProcedure, bProcedure);
        sortByFreeBookableSlots(a, b);
        sortByNextFreeBookableSlots(a, b);

        return 0;
      }) || []
    );
  }, []);

  const getProcedures = useCallback(() => {
    const procedures = [];

    if (caregiverArr)
      caregiverArr.forEach((item) => {
        if (item.procedures)
          item.procedures?.forEach((procedure) => {
            if (!procedures?.find((el) => el.id === procedure?.procedure?.id))
              procedures.push({
                id: procedure.procedure?.id,
                title: procedure.procedure?.attributes?.name,
                selected: false,
                key: `procedure-${procedure.procedure?.id}`,
              });
          });
      });

    return procedures;
  }, [caregiverArr]);

  const debounceDateCb = useCallback(() => {
    if (startDate !== debouncedStartDate) {
      setDebouncedStartDate(startDate);
    }
  }, [debouncedStartDate, startDate]);

  useDebounce(debounceDateCb, [], 400);

  useEffect(() => {
    setMounted(true);

    return () => {
      dispatch(clinicsActions.changePageClinicReviews(1));
    };
  }, [dispatch]);

  useEffect(() => {
    if (clinic.attributes?.website_address && referralSource) {
      const URL = clinic.attributes?.website_address;
      const parserURL = document?.createElement("a");
      parserURL.href = URL;
      const parserReferralSource = document?.createElement("a");
      parserReferralSource.href = referralSource;

      if (
        parserURL.hostname.includes(referralSource) ||
        parserURL.hostname.includes(parserReferralSource.hostname) ||
        referralSource.includes(parserURL.hostname) ||
        parserReferralSource.hostname.includes(parserURL.hostname) ||
        URL.includes(referralSource) ||
        referralSource.includes(URL)
      ) {
        if (goalPageUrl) sessionStorage.setItem("goal_page_url", goalPageUrl);
        setReferralSourceEqualStatus(true);
      }
    }
  }, [clinic.attributes?.website_address, goalPageUrl, referralSource]);

  useEffect(() => {
    if (clinicId) {
      dispatch(clinicsActions.getClinicProcedureById(clinicId));
      dispatch(clinicsActions.getClinicCaregiversById(clinicId));
    }
  }, [clinicId, dispatch]);

  useEffect(() => {
    let isMounted = true;
    if (defaultClinicProcedure.title && defaultProcedureStatus) {
      setProcedureForSort(defaultClinicProcedure);
      setDefaultProcedure(defaultClinicProcedure);
    } else if (defaultClinicProcedure.isLoaded && defaultProcedureStatus) {
      const defProcedure = {
        id: config.defaultProcedureId,
        title: config.defaultProcedureTitle,
      };
      if (isMounted) {
        setProcedureForSort(defProcedure);
        setDefaultProcedure(defProcedure);
      }
    }
    if (defaultClinicProcedure.isLoaded) setDefaultProcedureStatus(false);

    return () => {
      isMounted = false;
    };
  }, [defaultClinicProcedure, defaultProcedureStatus]);

  useEffect(() => {
    if (clinicId && defaultProcedure?.id)
      dispatch(
        clinicsActions.getClinicByIdFull({
          clinicId,
          dateEnd: formatDate(getEndDate()),
          dateStart: formatDate(debouncedStartDate),
          defaultProcedure,
        }),
      );
  }, [clinicId, debouncedStartDate, getEndDate, defaultProcedure, dispatch]);

  useEffect(() => {
    if (procedureForSort?.id && defaultProcedure?.id === procedureForSort?.id) {
      const sortedCaregiverArray = getSortedCaregivers(
        clinic?.caregiver_locations,
        procedureForSort?.id,
      );
      setCaregiverArr(sortedCaregiverArray);
      return;
    }

    setCaregiverArr((prev) =>
      prev.map((item) => {
        return clinic?.caregiver_locations.find(
          (caregiver) => caregiver.id === item.id,
        );
      }),
    );
  }, [
    clinic?.caregiver_locations,
    defaultProcedure?.id,
    getSortedCaregivers,
    procedureForSort?.id,
  ]);

  useEffect(() => {
    if (query.get("page") && +query.get("page") !== +pageReviews)
      dispatch(clinicsActions.changePageClinicReviews(+query.get("page")));
  }, [dispatch, pageReviews, query]);

  useEffect(() => {
    setIsDescriptionVisible(
      clinic.attributes?.clinic_phone_number ||
        clinic.attributes?.website_address ||
        clinic.attributes?.clinic_email_address,
    );

    if (clinic.attributes) {
      const results = getProcedures();
      setProcedures(results);
    }
  }, [clinic.attributes, getProcedures]);

  useEffect(() => {
    if (clinicId && fetchClinicDataStatus) {
      dispatch(
        clinicsActions.fetchClinicReviews({ id: clinicId, page: pageReviews }),
      );
    }
  }, [pageReviews, clinicId, fetchClinicDataStatus, dispatch]);

  return (
    <StyledLayout>
      <Helmet>
        <title>{`${clinic.attributes?.clinic_name} i ${clinic.attributes?.clinic_city} | ${translations.meta_title_booking}`}</title>
        <meta
          id="meta-description"
          name="description"
          content={`${translations.book_time_now_with_short} ${clinic.attributes?.clinic_name} ${translations.in} ${clinic.attributes?.clinic_city}${translations.meta_content_clinic}`}
        />
        <link
          id="canonical-link"
          rel="canonical"
          href={config.url + location.pathname.replace(/\?.*$/gm, "")}
        />
      </Helmet>
      <HeaderBlock
        translations={translations}
        referralSourceEqualStatus={referralSourceEqualStatus}
        loading={loading}
        startDate={startDate}
        setStartDate={setStartDate}
        mounted={mounted}
        timeStep={timeStep}
      />
      {global.innerWidth < 994 &&
        clinicImages?.clinicDefaultImage?.attributes && (
          <ClinicImagesMobile
            images={clinicImages}
            isLoaded={!!reviews?.meta}
            referralSourceEqualStatus={referralSourceEqualStatus}
            toggleModal={toggleModal}
          />
        )}
      <ContentContainer isStyledPage isMobile={isMobile}>
        <InnerContainer>
          <Content style={{ padding: 0 }}>
            <Col>
              <RatingRow
                padding={!clinicImages?.clinicDefaultImage?.attributes}
              >
                <MobileSection
                  style={{
                    margin: "auto 0",
                    padding: isMobile ? "32px 0 12px" : 0,
                    width: "100%",
                  }}
                >
                  {global.innerWidth >= 994 && (
                    <>
                      <Row style={{ justifyContent: "space-between" }}>
                        <ClinicName style={{ margin: 0 }}>
                          {clinic.attributes?.clinic_name}
                        </ClinicName>
                        {clinic.logotype && (
                          <img
                            style={{
                              margin: "auto 0",
                              maxWidth: "200px",
                              maxHeight: "50px",
                              width: "auto",
                            }}
                            src={`${clinic.logotype.attributes.original}?w=200&fit=crop`}
                            alt={clinic.attributes?.clinic_name}
                          />
                        )}
                      </Row>
                      <div style={{ display: "flex" }}>
                        <Row style={{ margin: "auto 0", height: "58px" }}>
                          <Row style={{ margin: "auto 0" }}>
                            {clinic.attributes?.number_of_reviews > 0 && (
                              <>
                                <MainRate
                                  margin="0 4px 0 0"
                                  size={16}
                                  starsCount={1}
                                  defaultValue={
                                    clinic.attributes?.overall_rating
                                  }
                                />
                                <span>
                                  <span
                                    style={{
                                      color: "rgb(72, 72, 72)",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {" "}
                                    {`${clinic.attributes?.overall_rating
                                      .toFixed(2)
                                      .replace(".", ",")}`}
                                  </span>
                                  {` (${clinic.attributes?.number_of_reviews})`}
                                </span>
                              </>
                            )}
                          </Row>

                          {!referralSourceEqualStatus && (
                            <span style={{ margin: "auto 8px" }}> · </span>
                          )}
                          {clinic.attributes && !referralSourceEqualStatus && (
                            <Breadcrumbs
                              clinic={clinic}
                              translations={translations}
                            />
                          )}
                        </Row>
                      </div>
                    </>
                  )}
                  {global.innerWidth < 994 && (
                    <>
                      <Row>
                        <div
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <ClinicName
                            style={{
                              fontSize: "20px",
                              margin: "3px 10px 3px 0",
                              lineHeight: "24px",
                            }}
                          >
                            {clinic.attributes?.clinic_name}
                          </ClinicName>
                          {clinic.logotype && (
                            <img
                              width="100"
                              style={{
                                margin: "auto",
                                maxHeight: "30px",
                                maxWidth: "100px",
                                width: "auto",
                              }}
                              src={`${clinic.logotype.attributes.original}?w=100&fit=crop`}
                              alt={clinic.attributes?.clinic_name}
                            />
                          )}
                        </div>
                      </Row>
                      <Row>
                        {clinic.attributes?.number_of_reviews > 0 && (
                          <Row style={{ margin: "auto 0" }}>
                            <MainRate
                              margin="0 4px 0 0"
                              size={16}
                              starsCount={1}
                              defaultValue={clinic.attributes?.overall_rating}
                            />
                            <span>
                              <span
                                style={{
                                  color: "rgb(72, 72, 72)",
                                  fontWeight: 600,
                                }}
                              >
                                {" "}
                                {`${clinic.attributes?.overall_rating
                                  .toFixed(2)
                                  .replace(".", ",")}`}
                              </span>
                              {` (${clinic.attributes?.number_of_reviews})`}
                            </span>
                          </Row>
                        )}

                        {!referralSourceEqualStatus && (
                          <span style={{ margin: "auto 8px" }}> · </span>
                        )}
                        {clinic?.attributes && !referralSourceEqualStatus && (
                          <Breadcrumbs
                            clinic={clinic}
                            translations={translations}
                          />
                        )}
                      </Row>
                    </>
                  )}
                  {reviews?.data?.length > 0 && (
                    <AggregateRatingMeta clinic={clinic} reviews={reviews} />
                  )}
                </MobileSection>
              </RatingRow>

              {global.innerWidth >= 994 &&
                clinicImages?.clinicDefaultImage?.attributes && (
                  <ClinicImagesDesktop
                    images={clinicImages}
                    isLoaded={!!reviews?.meta}
                    setPickedImage={setPickedImage}
                    toggleModal={toggleModal}
                  />
                )}

              <StyledDivider />

              <MobileSection>
                <Row>
                  <Col xs={24} md={12}>
                    <h2>
                      <b>{translations.book_a_time}</b>
                    </h2>
                  </Col>

                  {defaultProcedure && (
                    <Col xs={24} md={12}>
                      <h4>
                        <b>{translations.selected_treatment}</b>
                      </h4>
                      {global.innerWidth >= 994 && (
                        <>
                          {procedures?.length <= 6 &&
                            procedures?.map((item) => (
                              <Radio
                                name="procedure"
                                checkedValue={defaultProcedure.id}
                                value={item.id}
                                key={item.id}
                                onChange={(e) =>
                                  handleRadioChange(e, {
                                    changeItemsOrder: true,
                                  })
                                }
                              >
                                {item.title}
                              </Radio>
                            ))}
                          {procedures?.length > 6 && (
                            <Dropdown
                              title=""
                              searchable={[
                                "",
                                `${translations.no_procedure_match}`,
                              ]}
                              list={procedures}
                              resetThenSet={(item) =>
                                handleProcedureChange(item, {
                                  changeItemsOrder: true,
                                })
                              }
                              defaultProcedure={defaultProcedure}
                            />
                          )}
                        </>
                      )}
                      {global.innerWidth < 994 && procedures && (
                        <Dropdown
                          title=""
                          searchable={[
                            "",
                            `${translations.no_procedure_match}`,
                          ]}
                          list={procedures}
                          resetThenSet={(item) =>
                            handleProcedureChange(item, {
                              changeItemsOrder: true,
                            })
                          }
                          defaultProcedure={defaultProcedure}
                        />
                      )}
                      {!loading && (
                        <Checkbox
                          checked={isNewPatient}
                          onChange={onChangeCheckBox}
                          label={translations.i_am_new_patient}
                        />
                      )}
                    </Col>
                  )}
                </Row>
              </MobileSection>

              <StyledDivider />
              {loadingCaregivers && (
                <MainSpinner style={{ margin: "20px auto" }} />
              )}
              {!!caregiverArr?.length &&
                caregiverArr.map((caregiverAtLocationDetails) => {
                  return (
                    <CaregiverCard
                      key={caregiverAtLocationDetails.id}
                      clinic={clinic}
                      defaultProcedure={defaultProcedure}
                      handleProcedureChange={handleProcedureChange}
                      handleRadioChange={handleRadioChange}
                      caregiverAtLocationDetails={caregiverAtLocationDetails}
                      procedures={procedures}
                      translations={translations}
                      dateObject={{ startDate, timeStep, goToDate }}
                    />
                  );
                })}

              <Row>
                {isDescriptionVisible && (
                  <>
                    <Col xs={24} md={14}>
                      <MobileSection>
                        <h2>
                          <b>{translations.description}</b>
                        </h2>
                        <p>
                          {clinic.attributes?.clinic_professional_statement}
                        </p>
                        {global.innerWidth >= 994 && (
                          <>
                            <Row>
                              {clinic.attributes?.clinic_phone_number && (
                                <Col xs={12}>
                                  <h4>
                                    <b>{translations.phone}</b>
                                  </h4>
                                  <a
                                    href={`${translations.short_phone}:${clinic.attributes?.clinic_phone_number}`}
                                  >
                                    {readablePhoneNumber([
                                      clinic.attributes?.clinic_phone_number,
                                    ])}
                                  </a>
                                </Col>
                              )}
                              {clinic.attributes?.website_address && (
                                <Col xs={12}>
                                  <h4>
                                    <b>{translations.home_page}</b>
                                  </h4>
                                  <a
                                    rel="nofollow"
                                    href={clinic.attributes?.website_address}
                                  >
                                    {clinic.attributes?.website_address}
                                  </a>
                                </Col>
                              )}
                            </Row>
                            <Row style={{ marginTop: "20px" }}>
                              {clinic.attributes?.clinic_email_address && (
                                <Col xs={12}>
                                  <h4>
                                    <b>{translations.email_adress}</b>
                                  </h4>
                                  <a
                                    href={`mailto:${clinic.attributes?.clinic_email_address}`}
                                  >
                                    {clinic.attributes?.clinic_email_address}
                                  </a>
                                </Col>
                              )}
                            </Row>
                          </>
                        )}
                        {global.innerWidth < 994 && (
                          <>
                            {clinic.attributes?.clinic_phone_number && (
                              <ColMobile>
                                <h4>
                                  <b>{translations.phone}</b>
                                </h4>
                                <a
                                  href={`tel:${clinic.attributes?.clinic_phone_number}`}
                                >
                                  {readablePhoneNumber([
                                    clinic.attributes?.clinic_phone_number,
                                  ])}
                                </a>
                              </ColMobile>
                            )}
                            {clinic.attributes?.website_address && (
                              <ColMobile>
                                <h4>
                                  <b>{translations.home_page}</b>
                                </h4>
                                <a
                                  rel="nofollow"
                                  href={clinic.attributes?.website_address}
                                >
                                  {clinic.attributes?.website_address}
                                </a>
                              </ColMobile>
                            )}
                            {clinic.attributes?.clinic_email_address && (
                              <ColMobile style={{ margin: 0 }}>
                                <h4>
                                  <b>{translations.email_adress}</b>
                                </h4>
                                <a
                                  href={`mailto:${clinic.attributes?.clinic_email_address}`}
                                >
                                  {clinic.attributes?.clinic_email_address}
                                </a>
                              </ColMobile>
                            )}
                          </>
                        )}
                        <br />
                      </MobileSection>
                    </Col>

                    <StyledDivider />
                  </>
                )}
                <Col>
                  <MobileSection>
                    <h2>
                      <b> {translations.place}</b>
                    </h2>
                    <Col xs={12}>
                      {clinic.attributes && (
                        <>
                          {clinic.attributes?.clinic_address_1 && (
                            <p style={{ margin: "0 0 5px 0" }}>
                              {clinic.attributes?.clinic_address_1 || ""}
                            </p>
                          )}
                          <p style={{ marginBottom: 0 }}>{`${
                            clinic.attributes?.clinic_postal_code || ""
                          }, ${clinic.attributes?.clinic_city || ""}`}</p>
                        </>
                      )}
                    </Col>
                    <StyledMapCol>
                      {clinic.google_place_detail && (
                        <GoogleMap
                          language={language}
                          placeName={
                            <div>
                              <p style={{ marginBottom: 0 }}>
                                {clinic.attributes?.clinic_name}
                              </p>
                              {clinic.attributes && (
                                <>
                                  {clinic.attributes?.clinic_address_1 && (
                                    <p style={{ margin: "0 0 5px 0" }}>
                                      {clinic.attributes?.clinic_address_1 ||
                                        ""}
                                    </p>
                                  )}
                                  <p style={{ marginBottom: 0 }}>{`${
                                    clinic.attributes?.clinic_postal_code || ""
                                  }, ${
                                    clinic.attributes?.clinic_city || ""
                                  }`}</p>
                                </>
                              )}
                            </div>
                          }
                          lat={
                            clinic.google_place_detail.attributes.location_lat
                          }
                          lng={
                            clinic.google_place_detail.attributes.location_lng
                          }
                        />
                      )}
                    </StyledMapCol>
                  </MobileSection>
                </Col>
                <StyledDivider />
                <Col
                  style={{ paddingTop: isMobile ? "32px" : 0 }}
                  xs={24}
                  md={14}
                >
                  <Accessibility
                    elevator={clinic.attributes?.accessible_via_elevator}
                    handicap={clinic.attributes?.handicap_accessible}
                    stairs={clinic.attributes?.accessible_via_stairs}
                    translations={translations}
                  />

                  <Parking
                    freeOutside={
                      clinic.attributes
                        ?.has_free_parking_directly_outside_of_clinic
                    }
                    paidOutside={
                      clinic.attributes
                        ?.has_paid_parking_directly_outside_of_clinic
                    }
                    freeNear={
                      clinic.attributes?.has_free_parking_near_the_clinic
                    }
                    paidNear={
                      clinic.attributes?.has_paid_parking_near_the_clinic
                    }
                    translations={translations}
                  />
                  <Amenities
                    drinks={clinic.attributes?.has_drinks}
                    magazines={clinic.attributes?.has_magazines}
                    music={clinic.attributes?.has_music}
                    restroom={clinic.attributes?.has_restroom}
                    toys={clinic.attributes?.has_toys}
                    tv={clinic.attributes?.has_tv}
                    waitingRoom={clinic.attributes?.has_waiting_room}
                    wifi={clinic.attributes?.has_wifi}
                    translations={translations}
                  />
                  {!loadingReviews && reviews?.data?.length > 0 && (
                    <>
                      <Col>
                        <MobileSection>
                          <Row alignitems="center">
                            <RateTitleCommments>{`${clinic.attributes?.number_of_reviews} ${translations.reviews_from_confirmed_patients}`}</RateTitleCommments>
                            <MainRate
                              defaultValue={clinic.attributes?.overall_rating}
                            />
                          </Row>
                          <br />
                          <p>{translations.all_reviews_are_genuine}</p>
                        </MobileSection>
                        <StyledDivider />
                        <MobileSection>
                          <Rates item={clinic} translations={translations} />
                        </MobileSection>
                      </Col>
                      <StyledDivider style={{ marginBottom: "15px" }} />
                      <Reviews
                        loading={loadingReviews}
                        reviews={reviews}
                        pageReviews={pageReviews}
                        dispatchPage={(page) =>
                          dispatch(clinicsActions.changePageClinicReviews(page))
                        }
                        pathname={`/${slugifyer(clinicName)}/c/${clinicId}`}
                        itemReviewed={
                          clinic.attributes && clinic.attributes?.clinic_name
                        }
                        translations={translations}
                      />
                    </>
                  )}
                  {loadingReviews && !error && (
                    <MainSpinner style={{ margin: "20px auto" }} />
                  )}
                </Col>
              </Row>
            </Col>
          </Content>
          {error && !loading && (
            <ErrorMessage error={error} translations={translations} />
          )}
          {isOpenModal && (
            <ClinicImagesModal
              images={clinicImages}
              onClose={() => setIsOpenModal(false)}
              pickedImage={pickedImage}
            />
          )}
        </InnerContainer>
      </ContentContainer>
      <Footer
        referralSourceEqualStatus={referralSourceEqualStatus}
        translations={translations}
      />
    </StyledLayout>
  );
};

export default Clinic;
