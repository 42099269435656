import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useLocation } from "react-router-dom";
import { RolesProceduresService } from "services/roles-procedures-service";

import config from "config";
import { slugifyer } from "helpers/helper-functions";
import { useQuery } from "hooks/use-query";
import { useTranslations } from "hooks/use-translations";

import {
  Content,
  Heading,
  Layout,
  StyledCol,
  StyledPagination,
  StyledRow,
  StyledSpin,
} from "components/common.styled";
import Footer from "components/footer";
import Header from "components/header";

const TreatmentDirectory = () => {
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();

  const routeNames = "treatment-directory";
  const translations = useTranslations(routeNames);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(null);
  const [roleProcedures, setRoleProcedures] = useState({});

  const onPageChange = (e) => {
    history.push({
      pathname: location.pathname,
      search: `?page=${e}`,
    });
    setPage(+e);
  };

  const fetchRoleProcedureList = useCallback(async () => {
    setLoading(true);
    const data = await RolesProceduresService.getRolesProcedures({
      params: {
        page: page || 1,
        per_page: 20,
      },
    });
    setRoleProcedures(data);
    setLoading(false);
  }, [page]);

  useEffect(() => {
    if (query.get("page") && +query.get("page") !== +page)
      setPage(+query.get("page"));
  }, [query, page]);

  useEffect(() => {
    fetchRoleProcedureList();
  }, [fetchRoleProcedureList]);

  const renderPagination = () => {
    const {
      meta: { pagination },
    } = roleProcedures.data;

    return (
      <StyledPagination
        current={page}
        limit={20}
        onPageChanged={onPageChange}
        total={pagination.total}
      />
    );
  };

  return (
    <Layout>
      <Helmet>
        <title>{`${translations.meta_title_treatment_dir} | ${translations.meta_title_default}`}</title>
        <meta
          id="meta-description"
          name="description"
          content={translations.meta_content_clinic_dir}
        />
        <link
          id="canonical-link"
          rel="canonical"
          href={config.url + location.pathname.replace(/\?.*$/gm, "")}
        />
      </Helmet>
      <Header translations={translations} isStartPage={false} />

      <Content>
        <Heading>{translations.treatment_dir_header}</Heading>
        {roleProcedures?.data?.meta && renderPagination()}
        <StyledRow>
          {!loading &&
            !!roleProcedures?.data?.data?.length &&
            roleProcedures.data.data.map((item) => {
              const roleProcedureName = item.attributes.term;
              return (
                <StyledCol xs={24} md={4} key={`role-procedure-${item?.id}`}>
                  <Link
                    to={`/${slugifyer(roleProcedureName)}/rp/${item.id}`}
                  >{`${roleProcedureName}`}</Link>
                </StyledCol>
              );
            })}
          {loading && <StyledSpin size="large" />}
        </StyledRow>
        {roleProcedures?.data?.meta && renderPagination()}
      </Content>
      <Footer translations={translations} />
    </Layout>
  );
};

export default TreatmentDirectory;
