import React, { memo } from "react";
import * as PropTypes from "prop-types";

import { Col } from "../../common.styled";
import { CalendarContainer } from "./reschedule.styled";
import RescheduleCalendar from "./reschedule-calendar";

const RescheduleItem = ({
  goToDate,
  id,
  setSelectedSlot,
  setStep,
  slots,
  slotsLoading,
  startDate,
  timeStep,
  translations,
}) => {
  return (
    <Col>
      <CalendarContainer>
        <RescheduleCalendar
          goToDate={goToDate}
          id={id}
          setSelectedSlot={setSelectedSlot}
          setStep={setStep}
          slots={slots}
          slotsLoading={slotsLoading}
          startDate={startDate}
          timeStep={timeStep}
          translations={translations}
        />
      </CalendarContainer>
    </Col>
  );
};

RescheduleItem.propTypes = {
  goToDate: PropTypes.func,
  id: PropTypes.string,
  setSelectedSlot: PropTypes.func,
  setStep: PropTypes.func,
  slots: PropTypes.object,
  slotsLoading: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  timeStep: PropTypes.number,
  translations: PropTypes.object,
};

export default memo(RescheduleItem);
