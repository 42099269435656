import React, { Component } from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";

import packageJson from "../../../../package.json";
import { formatDate } from "../../../helpers/helper-functions";
import { appActions as actions } from "../../../redux/app/actions";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { sendReport } = this.props;

    sendReport({
      date: formatDate(new Date()),
      message: error.message,
      name: error.name,
      stack: errorInfo.componentStack,
      url: document.location.href,
      version: `${packageJson.name}: ${packageJson.version}`,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError)
      return (
        <p style={{ marginTop: 40, marginLeft: 40 }}>Something went wrong.</p>
      );
    return children;
  }
}

ErrorBoundary.propTypes = {
  sendReport: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const mapDispatchToProps = (dispatch) => {
  return { sendReport: (data) => dispatch(actions.sendErrorReport(data)) };
};

export default connect(() => ({}), mapDispatchToProps)(ErrorBoundary);
