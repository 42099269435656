import React, { memo } from "react";
import { Link, useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";

import { slugifyer } from "helpers/helper-functions";

import { BreadcrumbsContainer } from "../index.styled";

const Breadcrumbs = ({ clinic, translations }) => {
  const history = useHistory();

  const restructurePlace = (place, places) => {
    if (place?.attributes?.include_in_sitemap) places.push(place);
    if (place?.parent_place) {
      restructurePlace(place.parent_place, places);
    }
  };

  const placesBreadcrumbs = () => {
    const places = [];

    if (clinic?.google_place_detail)
      restructurePlace(clinic.google_place_detail, places);

    return places;
  };

  const places =
    window.innerWidth >= 994
      ? placesBreadcrumbs().reverse().splice(1, 3)
      : placesBreadcrumbs().reverse().splice(2, 2);

  if (window.innerWidth >= 994)
    return (
      <BreadcrumbsContainer style={{ padding: "20px 0 0" }}>
        <div key="start">
          <span style={{ margin: "0 10px 0 0" }}>
            <Link to="/start">{translations.start_page}</Link>
          </span>
          {">"}
        </div>
        {places.map((item) => {
          const { name } = item.attributes;
          return (
            <div key={item.id}>
              <span>
                <Link
                  to={`/${slugifyer(name)}/g/${item.id}`}
                  onClick={() =>
                    history.push(`/${slugifyer(name)}/g/${item.id}`)
                  }
                >
                  {name}
                </Link>
              </span>
              {">"}
            </div>
          );
        })}
        <span>{clinic.attributes?.clinic_name || "-"}</span>
      </BreadcrumbsContainer>
    );

  return (
    <BreadcrumbsContainer style={{ margin: 0 }}>
      {places.map((item, index) => {
        const { name } = item.attributes || {};
        const margin = index === 0 ? 0 : 10;
        return (
          <div style={{ padding: "15px 0", margin: "auto 0" }} key={item.id}>
            <span style={{ marginLeft: `${margin}` }}>
              <Link
                to={`/${slugifyer(name)}/g/${item.id}`}
                onClick={() => history.push(`/${slugifyer(name)}/g/${item.id}`)}
              >
                {name}
              </Link>
            </span>
            {index === 0 && ">"}
          </div>
        );
      })}
    </BreadcrumbsContainer>
  );
};

Breadcrumbs.propTypes = {
  clinic: PropTypes.object,
  translations: PropTypes.object,
};

Breadcrumbs.defaultProps = {
  clinic: {},
};

export default memo(Breadcrumbs);
