import styled from "styled-components";

import { Row } from "../../common.styled";
import Button from "../button";
import Rate from "../stars";

export const RateRow = styled(Row)`
  justify-content: space-between;
  align-items: center;

  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

export const CollapseRow = styled(Row)`
  justify-content: space-between;
`;

export const StyledRate = styled(Rate)``;

export const CollapseButton = styled(Button)`
  padding: 0;
  margin-top: 10px;

  & svg {
    height: 10px;
    align-self: center;
    margin-left: 7px;
  }
`;
