const prefix = "[auth]";

const TYPES = {
  AUTH_ERROR_REFRESH: `${prefix} AUTH_ERROR_REFRESH`,
  AUTH_USER_REFRESH: `${prefix} AUTH_USER_REFRESH`,
  AUTO_START_TOKEN_REFRESH: `${prefix} AUTO_START_TOKEN_REFRESH`,
  GET_CURRENT_USER: `${prefix} GET_CURRENT_USER`,
  UPDATE_CURRENT_USER: `${prefix} UPDATE_CURRENT_USER`,
  UPDATE_CURRENT_USER_ERROR: `${prefix} UPDATE_CURRENT_USER_ERROR`,
  GET_USER_TOKEN_AND_SIGN_IN: `${prefix} GET_USER_TOKEN_AND_SIGN_IN`,
  HIDE_MESSAGE: `${prefix} HIDE_MESSAGE`,
  SHOW_MESSAGE: `${prefix} SHOW_MESSAGE`,
  SIGNIN_MOBILE: `${prefix} SIGNIN_MOBILE`,
  SIGNIN_MOBILE_ERROR: `${prefix} SIGNIN_MOBILE_ERROR`,
  SIGNIN_MOBILE_SUCCESS: `${prefix} SIGNIN_MOBILE_SUCCESS`,
  SIGNIN_USER: `${prefix} SIGNIN_USER`,
  SIGNIN_USER_QR_CODE: `${prefix} SIGNIN_USER_QR_CODE`,
  SIGNIN_USER_SUCCESS: `${prefix} SIGNIN_USER_SUCCESS`,
  SIGNOUT_USER: `${prefix} SIGNOUT_USER`,
  SIGNOUT_USER_SUCCESS: `${prefix} SIGNOUT_USER_SUCCESS`,
  SIGNUP_USER: `${prefix} SIGNUP_USER`,
  SIGNUP_USER_SUCCESS: `${prefix} SIGNUP_USER_SUCCESS`,
};

export const authActions = {
  ...TYPES,
  authErrorRefresh: (error) => ({
    type: TYPES.AUTH_ERROR_REFRESH,
    payload: error,
  }),
  authUserRefresh: (authUser) => ({
    type: TYPES.AUTH_USER_REFRESH,
    payload: authUser,
  }),
  autoStartTokenRefresh: (data) => ({
    type: TYPES.AUTO_START_TOKEN_REFRESH,
    payload: data,
  }),
  updateCurrentUser: (values, message) => ({
    type: TYPES.UPDATE_CURRENT_USER,
    payload: { values, message },
  }),
  updateCurrentUserError: () => ({
    type: TYPES.UPDATE_CURRENT_USER_ERROR,
  }),
  getCurrentUser: () => ({
    type: TYPES.GET_CURRENT_USER,
  }),
  getUserTokenAndSignIn: (formData) => ({
    type: TYPES.GET_USER_TOKEN_AND_SIGN_IN,
    payload: formData,
  }),
  userSignUp: (user) => ({
    type: TYPES.SIGNUP_USER,
    payload: user,
  }),
  userSignIn: (user) => ({
    type: TYPES.SIGNIN_USER,
    payload: user,
  }),
  userSignInQrCode: (formData) => ({
    type: TYPES.SIGNIN_USER_QR_CODE,
    payload: formData,
  }),
  userSignOut: () => ({
    type: TYPES.SIGNOUT_USER,
  }),
  userSignUpSuccess: (authUser) => ({
    type: TYPES.SIGNUP_USER_SUCCESS,
    payload: authUser,
  }),
  userSignInSuccess: (authUser) => ({
    type: TYPES.SIGNIN_USER_SUCCESS,
    payload: authUser,
  }),
  userSignOutSuccess: () => ({
    type: TYPES.SIGNOUT_USER_SUCCESS,
  }),
  showAuthMessage: (message) => ({
    type: TYPES.SHOW_MESSAGE,
    payload: message,
  }),
  hideAuthMessage: () => ({
    type: TYPES.HIDE_MESSAGE,
  }),
  userSignInMobile: ({
    bookingIsTentative,
    clinicId,
    comment,
    defProcedure,
    isRegister,
    pathName,
    selectedSlot,
    slotsId,
  }) => ({
    type: TYPES.SIGNIN_MOBILE,
    payload: {
      bookingIsTentative,
      clinicId,
      comment,
      defProcedure,
      isRegister,
      pathName,
      selectedSlot,
      slotsId,
    },
  }),
  userSignInMobileSuccess: (data) => ({
    type: TYPES.SIGNIN_MOBILE_SUCCESS,
    payload: data,
  }),
  userSignInMobileError: (error) => ({
    type: TYPES.SIGNIN_MOBILE_ERROR,
    payload: error,
  }),
};
