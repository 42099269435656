import React from "react";
import * as PropTypes from "prop-types";

const CelebrationIcon = ({ classProp }) => (
  <svg
    className={classProp}
    height="375"
    version="1.0"
    width="375"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="a">
        <path d="M81.402 168H215v133.23H81.402Zm0 0" />
      </clipPath>
      <clipPath id="b">
        <path d="M258 215h35.652v35H258Zm0 0" />
      </clipPath>
      <clipPath id="c">
        <path d="M133 84.48h39V123h-39Zm0 0" />
      </clipPath>
    </defs>
    <path
      fill="#E6FDFF"
      d="M187.5 89.758c-56.953 0-103.125 46.172-103.125 103.125S130.547 296.008 187.5 296.008s103.125-46.172 103.125-103.125S244.453 89.758 187.5 89.758Zm0 0"
    />
    <path
      fill="#277E88"
      d="M198.496 98.582h4.89v4.887h-4.89ZM232.418 115.004h4.89v4.89h-4.89ZM236.945 202.824h4.89v4.89h-4.89ZM252.121 165.945h4.89v4.887h-4.89Zm0 0"
    />
    <g clipPath="url(#a)">
      <path
        fill="#277E88"
        d="M202.758 231.633c5.902 9.847 6.87 16.05 5.277 17.644a1.747 1.747 0 0 1-.445.309l-.5.187c-1.992.563-6.578-.02-14.899-4.718-9.242-5.215-19.996-13.75-30.285-24.035-10.289-10.286-18.832-21.036-24.05-30.274-4.145-7.332-5.086-11.766-4.864-14.074l.672-1.79c1.277-1.195 6.352-1.421 17.504 5.231l2.848-4.773c-11.746-7.008-20.157-8.516-24.317-4.36-1.176 1.18-1.906 2.672-2.191 4.47L82.023 295.847a3.987 3.987 0 0 0 .907 4.218 3.983 3.983 0 0 0 2.82 1.164c.473 0 .95-.082 1.406-.253l120.364-45.422 1.476-.559c.223-.07.438-.156.652-.246l1.059-.398-.043-.114a7.32 7.32 0 0 0 1.305-1.031c4.176-4.172 2.636-12.621-4.442-24.434Zm-5.696 21.926-74.753 28.207c-10.848-1.325-19.704-5.243-25.727-8.723l6.797-17.992c7.922 4.738 20.941 10.558 37.105 10.558 13.762 0 29.801-4.222 46.907-16.918 3.41 2.047 6.668 3.704 9.672 4.868Zm-91.683-3.801 6.582-17.426c5.621 3.89 14.75 8.473 26.594 8.473 8.062 0 17.382-2.13 27.695-8.04 5.367 4.778 10.852 9.126 16.113 12.704-34.582 24.547-64.074 12.265-76.984 4.289Zm56.558-20.957c-23.125 12.441-40.25 3.847-47.94-1.852l5.417-14.34c3.785 2.528 9.117 4.887 15.86 4.887 3.91 0 8.296-.797 13.12-2.816a196.384 196.384 0 0 0 9.579 10.27 204.553 204.553 0 0 0 3.964 3.85Zm-28.925-35.32c2.957 5.23 6.918 10.906 11.61 16.695-10.817 3.879-18.685.387-23.18-2.938l7.976-21.11c.902 2.255 2.098 4.704 3.594 7.352Zm-38.418 84.828c4.61 2.59 10.629 5.336 17.781 7.207l-23.914 9.023Zm0 0"
      />
    </g>
    <path
      fill="#277E88"
      d="m248.148 126.691 6.204 1.926 1.93 6.207a2.824 2.824 0 0 0 2.273 1.961 2.82 2.82 0 0 0 2.766-1.168l3.75-5.3 6.495.081a2.848 2.848 0 0 0 2.57-1.55 2.824 2.824 0 0 0-.253-2.996L270 120.648l2.086-6.148v-.004a2.824 2.824 0 0 0-.684-2.918 2.82 2.82 0 0 0-2.925-.683l-6.153 2.085-5.203-3.882a2.831 2.831 0 0 0-2.996-.258 2.832 2.832 0 0 0-1.555 2.57l.086 6.492-5.304 3.75a2.827 2.827 0 0 0-1.168 2.77 2.824 2.824 0 0 0 1.964 2.27Zm8.895-5.082a2.856 2.856 0 0 0 1.191-2.351l-.03-2.418 1.933 1.441a2.856 2.856 0 0 0 2.62.422l2.294-.777-.781 2.297c-.297.882-.141 1.859.418 2.609l1.449 1.938-2.43-.032a2.842 2.842 0 0 0-2.352 1.203l-1.398 1.977-.715-2.305a2.839 2.839 0 0 0-1.875-1.879l-2.312-.718Zm0 0"
    />
    <g clipPath="url(#b)">
      <path
        fill="#277E88"
        d="M293.11 225.184c-.497-.98-1.493-1.567-2.587-1.563l-8.644.11-4.992-7.055a2.847 2.847 0 0 0-2.785-1.176 2.844 2.844 0 0 0-2.286 1.98l-2.566 8.25-8.254 2.563a2.845 2.845 0 0 0-1.976 2.285 2.85 2.85 0 0 0 1.171 2.785l7.059 4.989-.11 8.636a2.864 2.864 0 0 0 2.867 2.902 2.84 2.84 0 0 0 1.708-.57l6.93-5.164 8.183 2.774a2.847 2.847 0 0 0 2.945-.688 2.84 2.84 0 0 0 .688-2.941l-2.777-8.184 5.168-6.922a2.842 2.842 0 0 0 .257-3.011Zm-11.15 10.375 1.478 4.347-4.344-1.472a2.874 2.874 0 0 0-2.64.414l-3.684 2.75.058-4.582a2.851 2.851 0 0 0-1.207-2.383l-3.754-2.653 4.395-1.367a2.86 2.86 0 0 0 1.879-1.879l1.363-4.386 2.652 3.742a2.85 2.85 0 0 0 2.375 1.215l4.594-.059-2.742 3.676a2.852 2.852 0 0 0-.422 2.637Zm0 0"
      />
    </g>
    <g clipPath="url(#c)">
      <path
        fill="#277E88"
        d="m134.906 103.977 7.996 5.648-.125 9.79a2.855 2.855 0 0 0 1.567 2.593c.418.21.863.316 1.308.316.606 0 1.207-.195 1.715-.574l7.848-5.852 9.273 3.141a2.852 2.852 0 0 0 2.953-.687 2.854 2.854 0 0 0 .692-2.954l-3.145-9.27 5.856-7.839c.66-.883.758-2.039.258-3.023a2.862 2.862 0 0 0-2.598-1.567l-9.79.125-5.651-7.988a2.856 2.856 0 0 0-2.797-1.184 2.858 2.858 0 0 0-2.297 1.985l-2.906 9.347-9.352 2.903a2.859 2.859 0 0 0-1.984 2.297 2.866 2.866 0 0 0 1.18 2.793Zm13.164-3.106a2.874 2.874 0 0 0 1.887-1.887l1.707-5.496 3.324 4.696a2.883 2.883 0 0 0 2.364 1.214h.02l5.757-.07-3.441 4.61a2.867 2.867 0 0 0-.418 2.64l1.847 5.45-5.449-1.848a2.875 2.875 0 0 0-2.645.418l-4.613 3.441.074-5.75a2.88 2.88 0 0 0-1.214-2.387l-4.704-3.32Zm0 0"
      />
    </g>
    <path
      fill="#277E88"
      d="m230.574 187.016.946 5.48c12.039-2.074 24.37-1.125 35.664 2.738l1.8-5.261c-12.171-4.164-25.453-5.188-38.41-2.957ZM179.492 216.719l3.934 3.93 6.453-6.45c9.223-9.219 20.625-16.023 32.973-19.68l-1.579-5.332c-13.238 3.922-25.453 11.211-35.324 21.079ZM195.656 123.594a76.288 76.288 0 0 0-2.574-9.489l-5.262 1.801a71.613 71.613 0 0 1 2.387 8.793ZM196.598 129.348l-5.516.691c2.387 19.102-3.121 39.137-15.113 54.965l4.433 3.355c12.86-16.972 18.766-38.484 16.196-59.011ZM168.844 193.172l-6.453 6.453 3.933 3.93 6.453-6.453a80.19 80.19 0 0 0 2.7-2.825l-4.11-3.742a76.495 76.495 0 0 1-2.523 2.637ZM241.145 222.191c4.199 1.278 8.433 2.973 12.582 5.036l2.48-4.973c-4.426-2.203-8.95-4.016-13.441-5.383ZM188.629 226.61l-4.41 4.41 3.933 3.93 4.41-4.41c9.555-9.552 24.458-13.282 40.887-10.235l1.016-5.47c-18.262-3.382-34.965.911-45.836 11.774ZM160.672 126.766l-4.977 2.48c2.094 4.2 3.805 8.488 5.09 12.742l5.324-1.605c-1.375-4.555-3.203-9.137-5.437-13.617ZM147.969 194.785l3.933 3.934 4.41-4.41c10.868-10.864 15.16-27.559 11.778-45.809l-5.469 1.016c3.047 16.418-.687 31.312-10.242 40.863ZM248.586 130.45l3.934 3.929-38.438 38.418-3.934-3.93ZM208.54 178.336l-25.59 25.582-3.93-3.934 25.59-25.578ZM167.371 219.488l-3.934-3.93 11.184-11.18 3.934 3.93Zm0 0"
    />
  </svg>
);

CelebrationIcon.propTypes = {
  classProp: PropTypes.string,
};

CelebrationIcon.defaultProps = {
  classProp: "",
};

export default CelebrationIcon;
