import React, { useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { addMinutes, getMinutes, setMinutes, setSeconds } from "date-fns";
import * as PropTypes from "prop-types";
import { BookingAttendeesService } from "services/booking-attendees-service";
import { BookingsService } from "services/bookings-service";

import defaultUserImage from "assets/images/defaultUserIcon.png";
import BandIdSvg from "assets/svg-components/BankID";
import config from "config";
import { COUNTRY_CODES } from "constants/countries";
import { networkError } from "constants/errors-messages";
import {
  formatDate,
  isoFormatDate,
  mobileValidator,
} from "helpers/helper-functions";
import { Notifications } from "helpers/notifications";
import { validatePersonalId } from "helpers/validators";
import { useReferralSource } from "hooks/use-referral-source";
import { authActions } from "redux/auth/actions";
import { clinicsActions } from "redux/clinics/actions";

import {
  Avatar,
  Heading,
  InputContainer,
  StyledInput,
  StyledText,
  ThirdHeading,
  VerticalRadio,
} from "../../common.styled";
import AuthLoader from "../../common/auth-loader";
import { StretchButton } from "../../common/button";
import ProfileContent from "../profile-content";

import { Spinner } from "../../common/spinner/index.styled";
import { ErrorText, StyledTextArea, StyledThirdHeading } from "./index.styled";

const SendBookingContent = ({
  caregiver,
  clinicId,
  defaultProcedure,
  isSearch,
  onClose,
  slotsId,
  translations,
}) => {
  const authState = useSelector((state) => state.auth);
  const { clinic, loading } = useSelector((state) => state.clinics);
  const {
    code: countryCode,
    personal_id_placeholder: personalIdPlaceholder,
    personal_id_regex: personalIdValidator,
  } = clinic.country?.attributes || {};

  const dispatch = useDispatch();

  const referralSource = useReferralSource();

  const [authData, setAuthData] = useState({});
  const [bookingComplete, setBookingComplete] = useState(false);
  const [bookingError, setBookingError] = useState("");
  const [bookingLoader, setBookingLoader] = useState(false);
  const [comment, setComment] = useState("");
  const [date, setDate] = useState(formatDate(new Date(), "uuuu-MM-dd"));
  const [formIsSubmited, setFormIsSubmited] = useState(false);
  const [hours, setHours] = useState(formatDate(new Date(), "HH:mm"));
  const [personalId, setPersonalId] = useState("");
  const [personalIdError, setPersonalIdError] = useState("");

  const checkStatusSummary = useCallback(
    (defaultProcedure) => {
      if (isSearch) {
        if (defaultProcedure?.length > 0 && defaultProcedure[0]?.title) {
          return `${translations.send_modal_booking_request}: ${defaultProcedure[0]?.title}`;
        }
        return `${translations.send_modal_preliminar_booking_req}`;
      }

      if (defaultProcedure?.title) {
        return `${translations.send_modal_toast_error}: ${defaultProcedure.title}`;
      }
      return `${translations.send_modal_preliminar_booking_req}`;
    },
    [isSearch, translations],
  );

  const formatHours = useCallback((date, end) => {
    const interval = 5;
    const roundedMinutes =
      Math.ceil(getMinutes(new Date(date)) / interval) * interval;

    if (end)
      return isoFormatDate(
        addMinutes(
          setMinutes(setSeconds(new Date(date), 0), roundedMinutes),
          30,
        ),
      );
    return isoFormatDate(
      setMinutes(setSeconds(new Date(date), 0), roundedMinutes),
    );
  }, []);

  const bookSlot = useCallback(async () => {
    try {
      const dtstart = `${date}T${hours}:00Z`;
      const selectedSlot = {
        attributes: {
          dtend: formatHours(dtstart, true),
          dtstart: formatHours(dtstart),
        },
      };
      setBookingLoader(true);
      const currentClinic = clinic;

      const body = {
        data: {
          attributes: {
            booked_by_patient: true,
            class: "PUBLIC",
            description: comment,
            dtend: selectedSlot.attributes.dtend,
            dtstart: selectedSlot.attributes.dtstart,
            location: `${currentClinic.attributes.clinic_address_1}, ${currentClinic.attributes.clinic_postal_code} ${currentClinic.attributes.clinic_city}`,
            new_patient: true,
            referral_source:
              sessionStorage.getItem("referral_source") || referralSource,
            status: "TENTATIVE",
            summary: checkStatusSummary(defaultProcedure),
            text: "",
            transp: "OPAQUE",
            uid: null,
          },
          relationships: {
            clinic: {
              data: { id: clinicId, type: "muntra-clinics" },
            },
            organizer: {
              data: { id: slotsId, type: "muntra-caregivers" },
            },
            procedure: {
              data: {
                id: isSearch
                  ? defaultProcedure[0]?.id || config.defaultProcedureId
                  : defaultProcedure?.id || config.defaultProcedureId,
                type: "muntra-procedure",
              },
            },
          },
          type: "muntra-bookings",
        },
      };

      const { data } = await BookingsService.postBooking(body, {}, true);

      const params = (rsvp, patientData, userData) => ({
        data: {
          attributes: { partstat: "ACCEPTED", rsvp },
          relationships: {
            booking: { data: { type: "muntra-bookings", id: data?.data?.id } },
            patient: { data: patientData },
            user: { data: userData },
          },
          type: "muntra-booking-attendees",
        },
      });

      const [userReq, patientReq] = await Promise.all([
        BookingAttendeesService.postBookingAttendees(
          params(false, null, { type: "muntra-caregivers", id: caregiver.id }),
          true,
        ),
        BookingAttendeesService.postBookingAttendees(
          params(
            true,
            {
              type: "muntra-patients",
              id: authState.authUserPersonalId?.data?.id,
            },
            null,
          ),
          true,
        ),
      ]);

      setBookingLoader(false);
      if (userReq?.data && patientReq?.data) setBookingComplete(true);

      if (config.env === "production") {
        ReactGA.pageview("send-booking-content-modal");
      }
    } catch (err) {
      setBookingError(err);
    }
  }, [
    authState.authUserPersonalId?.data?.id,
    caregiver.id,
    checkStatusSummary,
    clinic,
    clinicId,
    comment,
    date,
    defaultProcedure,
    formatHours,
    hours,
    isSearch,
    referralSource,
    slotsId,
  ]);

  const renderError = useCallback((error) => {
    if (error?.response?.data?.errors[0]?.detail)
      return (
        <div style={{ margin: "100px auto", textAlign: "center" }}>
          {" "}
          <b> {error?.response?.data?.errors[0]?.detail} </b>{" "}
        </div>
      );
    return (
      <div style={{ margin: "100px auto", textAlign: "center" }}>
        {" "}
        <b> {networkError} </b>{" "}
      </div>
    );
  }, []);

  const renderSendBooking = () => {
    const firstName = caregiver.attributes?.first_name;
    const lastName = caregiver.attributes?.last_name;
    const fullName = `${firstName} ${lastName}`;
    const caregiverRole = `${caregiver.role?.attributes?.name}`;

    const avatarProps = {
      size: global.innerWidth < 994 ? 44 : 64,
      alt: fullName,
    };

    const handleSubmit = (event) => {
      const validateField = (name) => {
        if (name === "personalId") {
          const personalIdValidationError = validatePersonalId({
            personalId,
            personalIdValidator,
            setError: setPersonalIdError,
            translations,
          });
          setPersonalIdError(personalIdValidationError);
          return personalIdValidationError === "";
        }
      };

      setFormIsSubmited(true);
      event.preventDefault();
      const formFileds = ["personalId"];
      let isValid = true;
      formFileds.forEach((field) => {
        isValid = validateField(field) && isValid;
      });
      const dtstart = `${date}T${hours}:00Z`;
      const selectedSlot = {
        attributes: {
          dtend: formatHours(dtstart, true),
          dtstart: formatHours(dtstart),
        },
      };
      const isMobile = mobileValidator.test(window.navigator.userAgent);
      if (
        isValid &&
        authState.authUserPersonalId &&
        !authState.authUserPersonalId?.data
      ) {
        if (!isMobile) {
          const formData = new FormData();
          formData.append("grant_type", "password");
          formData.append("guard", "start");
          dispatch(authActions.userSignInQrCode(formData));
        } else {
          const bookingIsTentative = true;
          const procedure = {
            id: config.defaultProcedureId,
          };
          const defProcedure =
            defaultProcedure.length !== 0 ? defaultProcedure : procedure;
          const pathName = window.location.pathname;
          dispatch(
            authActions.userSignInMobile({
              bookingIsTentative,
              clinicId,
              comment,
              defProcedure,
              pathName,
              personalId,
              selectedSlot,
              slotsId,
            }),
          );
        }
      }
    };

    const currentYear = new Date().getFullYear().toString();
    const pastCenturyYear = new Date().getFullYear() - 100;

    const handleChangePersonalId = (e) => {
      const { value } = e.target;
      const valueStartsWithRelevantCurrentCenturyDecadeDigit =
        value[0] <= currentYear.charAt(2);
      const valueStartsWithRelevantMilleniumDigit =
        value[0] === pastCenturyYear.toString().charAt(0) ||
        value[0] === currentYear.charAt(0);

      setPersonalId(value.replace(/\D/g, ``));
      if (
        !valueStartsWithRelevantMilleniumDigit &&
        countryCode === COUNTRY_CODES.sweden
      ) {
        if (valueStartsWithRelevantCurrentCenturyDecadeDigit) {
          setPersonalId(currentYear.charAt(0) + currentYear.charAt(1) + value);
        } else if (value !== "") {
          setPersonalId(
            pastCenturyYear.toString().charAt(0) +
              pastCenturyYear.toString().charAt(1) +
              value,
          );
        }
      }
    };

    const onChangeRadio = () => {};

    return (
      <>
        <Heading style={{ lineHeight: "25px" }}>
          {translations.send_modal_send_booking_req}
        </Heading>
        <StyledText style={{ marginBottom: "20px" }}>
          {translations.send_modal_booking_req_details}
        </StyledText>
        <form id="formElem" onSubmit={handleSubmit}>
          <StyledThirdHeading>
            {translations.send_modal_caregiver}
          </StyledThirdHeading>
          <div style={{ display: "flex" }}>
            <Avatar
              src={
                caregiver.default_user_image
                  ? `${caregiver.default_user_image.attributes.original}?h=64&w=64&fit=crop`
                  : defaultUserImage
              }
              {...avatarProps}
            />
            <div style={{ marginLeft: "16px" }}>
              <ThirdHeading style={{ marginBottom: "0" }}>
                {" "}
                {fullName}{" "}
              </ThirdHeading>
              <StyledText> {caregiverRole} </StyledText>
            </div>
          </div>
          <StyledThirdHeading>
            {translations.send_modal_treatment}
          </StyledThirdHeading>

          {(defaultProcedure?.title ||
            (defaultProcedure?.length > 0 && defaultProcedure[0]?.title)) && (
            <div>
              <VerticalRadio
                checkedValue={defaultProcedure.id || defaultProcedure[0].id}
                key={defaultProcedure.id || defaultProcedure[0].id}
                name="procedure"
                onChange={onChangeRadio}
                value={defaultProcedure.id || defaultProcedure[0].id}
              >
                {defaultProcedure.title || defaultProcedure[0].title}
              </VerticalRadio>
            </div>
          )}

          <StyledThirdHeading>
            {translations.send_modal_wanted_treatment_time}
          </StyledThirdHeading>
          <div style={{ display: "flex" }}>
            <InputContainer style={{ maxWidth: "146px" }}>
              <StyledInput
                onChange={({ target }) => setDate(target.value)}
                style={{
                  fontSize: "14px",
                  fontFamily: "Arial,Helvetica,sans-serif",
                  color: "#484848",
                }}
                type="date"
                value={date}
              />
            </InputContainer>
            <InputContainer style={{ maxWidth: "100px" }}>
              <StyledInput
                style={{
                  fontSize: "14px",
                  fontFamily: "Arial,Helvetica,sans-serif",
                  color: "#484848",
                }}
                type="time"
                value={hours}
                onChange={({ target }) => setHours(target.value)}
              />
            </InputContainer>
          </div>
          <StyledThirdHeading>
            {translations.send_modal_message_optional}
          </StyledThirdHeading>
          <StyledTextArea
            onChange={({ target }) => setComment(target.value)}
            placeholder={translations.send_modal_other_wishes}
            type="textarea"
            value={comment}
          />
          {authState.authUserPersonalId &&
            !authState?.authUserPersonalId?.data && (
              <div>
                <StyledThirdHeading>
                  {translations.send_modal_ssn}
                </StyledThirdHeading>
                <InputContainer>
                  <StyledInput
                    disabled={
                      authState.loader || authData.social_security_number
                    }
                    name={translations.send_modal_ssn_name}
                    onChange={handleChangePersonalId}
                    placeholder={personalIdPlaceholder}
                    type="number"
                    value={authData.social_security_number || personalId}
                  />
                  <BandIdSvg
                    style={{ margin: "auto 0", width: 20, height: 30 }}
                  />
                </InputContainer>
              </div>
            )}

          {personalIdError && <ErrorText>{personalIdError}</ErrorText>}
          <StretchButton primary>
            <span style={{ margin: "0 auto" }}>
              {translations.send_modal_next}
            </span>
          </StretchButton>
        </form>
      </>
    );
  };

  useEffect(() => {
    if (clinicId) {
      dispatch(clinicsActions.getClinicById(clinicId, defaultProcedure));
    }
  }, [clinicId, defaultProcedure, dispatch]);

  useEffect(() => {
    if (authState.authUserPersonalId?.data) {
      setAuthData(authState.authUserPersonalId?.data?.attributes);
    }
  }, [authState]);

  useEffect(() => {
    if (authData?.social_security_number) {
      setPersonalId(authData.social_security_number);
    }
  }, [authData]);

  useEffect(() => {
    if (
      authData?.social_security_number &&
      authData?.e_mail_address &&
      formIsSubmited &&
      !bookingError
    ) {
      bookSlot();
    }
  }, [
    authData?.e_mail_address,
    authData?.social_security_number,
    bookSlot,
    bookingError,
    formIsSubmited,
  ]);

  useEffect(() => {
    if (authState.error?.data?.errors?.length) {
      setPersonalIdError(authState.error.data.errors[0].detail);
    } else if (authState.error?.message) {
      if (authState.error.message !== "Network Error")
        setPersonalIdError(authState.error.message);
      else setPersonalIdError(`${translations.send_modal_ssn_error}`);

      Notifications.message({
        message: `${translations.send_modal_toast_error}`,
      });
    }
  }, [authState?.error?.data?.errors, authState?.error?.message, translations]);

  return (
    <div>
      {bookingError && renderError(bookingError)}
      {!bookingError && loading && <Spinner style={{ margin: "100px auto" }} />}
      {!bookingError && bookingLoader && (
        <Spinner style={{ margin: "100px auto" }} />
      )}
      {!bookingError && authState.loader && (
        <AuthLoader translations={translations} />
      )}
      {!bookingError &&
        !loading &&
        !bookingLoader &&
        bookingComplete &&
        authState?.authUserPersonalId?.data?.attributes?.e_mail_address && (
          <div>
            <ThirdHeading>
              {translations.send_modal_booking_req_sent}
            </ThirdHeading>
            <br />
            <p>{translations.send_modal_mail_confirmation_incoming}</p>
            <p>{translations.send_modal_calendar_invite_sent}</p>
            <StretchButton onClick={() => onClose()}>
              <span style={{ margin: "0 auto" }}>
                {translations.send_modal_close}
              </span>
            </StretchButton>
          </div>
        )}
      {!bookingError &&
        !loading &&
        !bookingLoader &&
        !bookingComplete &&
        !authState.loader &&
        authState.authUserPersonalId?.data?.attributes?.e_mail_address &&
        renderSendBooking()}
      {!bookingError &&
        !loading &&
        !bookingLoader &&
        !authState.loader &&
        !authState.authUserPersonalId?.data?.attributes?.e_mail_address && (
          <ProfileContent isModal translations={translations} />
        )}
      {!bookingError &&
        !loading &&
        !bookingLoader &&
        !bookingComplete &&
        !authState.loader &&
        !authState.authUserPersonalId?.data &&
        renderSendBooking()}
    </div>
  );
};

SendBookingContent.propTypes = {
  caregiver: PropTypes.object,
  clinicId: PropTypes.string,
  defaultProcedure: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  isSearch: PropTypes.bool,
  onClose: PropTypes.func,
  slotsId: PropTypes.string,
  translations: PropTypes.object,
};

SendBookingContent.defaultProps = {
  caregiver: {},
  defaultProcedure: {},
  isSearch: false,
  slotsId: "",
};

export default SendBookingContent;
