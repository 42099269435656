import styled from "styled-components";

import { Row, Text } from "../../common.styled";
import Rate from "../stars";

import { Spinner } from "../spinner/index.styled";

export const StyledDivider = styled.div`
  border-bottom: 1px solid #00000017;
  margin: 15px 0;
  width: 100%;
  @media (max-width: 993px) {
    margin: 0;
  }
`;

export const LocalStyledDivider = styled(StyledDivider)`
  @media (max-width: 993px) {
    margin: 0;
  }
`;

export const P = styled(Text)`
  margin-bottom: 0;
`;

export const RateTitle = styled(Text)`
  margin-bottom: 0;
  margin-right: 15px;
  font-weight: bold;
`;

export const CommentRateRow = styled(Row)`
  align-items: center;
  margin-bottom: 5px;
`;

export const CommentFooter = styled(Text)`
  display: flex;
  margin-top: 10px;
  color: #484848;
  font-size: 12px;
  margin-bottom: 0;

  & span {
    margin: 0 10px;
  }
`;

export const StyledSpin = styled(Spinner)`
  margin: 0px 47% 20px 47%;
`;

export const StyledRate = styled(Rate)``;
