import React, { Component } from "react";
import * as PropTypes from "prop-types";

import AngleDown from "../../../assets/svg-components/AngleDown";

import {
  Input,
  ItemButtonFit,
  List,
  MainButton,
  ScrollList,
} from "./index.styled";

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listOpen: false,
      keyword: "",
    };

    this.searchField = React.createRef();
    this.close = this.close.bind(this);
  }

  componentDidUpdate() {
    const { listOpen } = this.state;

    setTimeout(() => {
      if (listOpen) {
        window.addEventListener("click", this.close);
      } else {
        window.removeEventListener("click", this.close);
      }
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.close);
  }

  close() {
    this.setState({ listOpen: false });
  }

  selectItem(item) {
    const { resetThenSet } = this.props;

    this.setState({ listOpen: false }, resetThenSet(item));
  }

  toggleList() {
    const { listOpen } = this.state;

    this.setState(
      (prevState) => ({
        listOpen: !prevState.listOpen,
        keyword: "",
      }),
      () => {
        if (listOpen && this.searchField.current) {
          this.searchField.current.focus();
          this.setState({
            keyword: "",
          });
        }
      },
    );
  }

  filterList(e) {
    this.setState({
      keyword: e.target.value.toLowerCase(),
    });
  }

  listItems() {
    const { list, searchable, defaultProcedure, isClinic } = this.props;
    const { keyword } = this.state;

    let tempList = list;

    if (keyword.length && isClinic) {
      tempList = list
        ?.filter((item) =>
          item?.title?.procedure?.attributes?.name
            ?.toLowerCase()
            .slice(0, keyword.length)
            .includes(keyword),
        )
        .sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        });
    }

    if (keyword.length && !isClinic) {
      tempList = list
        ?.filter((item) =>
          item.title?.toLowerCase().slice(0, keyword.length).includes(keyword),
        )
        .sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        });
    }

    if (tempList.length && !isClinic) {
      return tempList.map((item) => (
        <ItemButtonFit
          style={{
            textAlign: "start",
            fontWeight: item.id === defaultProcedure.id ? "bold" : "normal",
          }}
          secondary
          key={item.id}
          onClick={() => this.selectItem(item)}
        >
          {item.title}
        </ItemButtonFit>
      ));
    }

    if (tempList.length && isClinic) {
      return tempList.map((item) => (
        <ItemButtonFit
          style={{
            textAlign: "start",
            fontWeight:
              item.procedure.id === defaultProcedure.id ? "bold" : "normal",
          }}
          secondary
          key={item.procedure.id}
          onClick={() => this.selectItem(item)}
        >
          {item.procedure.attributes.name}
        </ItemButtonFit>
      ));
    }

    return <div className="dd-list-item no-result">{searchable[1]}</div>;
  }

  render() {
    const { searchable, defaultProcedure } = this.props;
    const { listOpen } = this.state;

    if (!defaultProcedure) return null;

    return (
      <div style={{ marginBottom: 15, position: "relative" }}>
        <MainButton onClick={() => this.toggleList()} secondary>
          <div>{defaultProcedure.title}</div>
          {listOpen ? (
            <AngleDown style={{ transform: "rotate(180deg)" }} />
          ) : (
            <AngleDown />
          )}
        </MainButton>

        {listOpen && (
          <List onClick={(e) => e.stopPropagation()}>
            <Input
              ref={this.searchField}
              placeholder={searchable[0]}
              onChange={(e) => this.filterList(e)}
            />
            <ScrollList>{this.listItems()}</ScrollList>
          </List>
        )}
      </div>
    );
  }
}

Dropdown.propTypes = {
  resetThenSet: PropTypes.func,
  list: PropTypes.array,
  searchable: PropTypes.array,
  defaultProcedure: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  isClinic: PropTypes.bool,
};

export default Dropdown;
