import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class LocalesService extends BaseService {
  constructor(url) {
    super(url);
    this.getLocale = this.getLocale.bind(this);
    this.getSpecificLocale = this.getSpecificLocale.bind(this);
  }

  async getLocale() {
    try {
      const response = await this.agent.get("/get-by-accept-language-header", {
        params: {
          include: "language",
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async getSpecificLocale(params) {
    try {
      const response = await this.agent.get("", params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new LocalesService(API_ROUTES.locales);

export default ServiceInstance;
export { ServiceInstance as LocalesService };
