import { all, call, put, takeEvery } from "redux-saga/effects";
import { ProceduresService } from "services/procedures-service";

import { proceduresActions as actions } from "./actions";

function* searchProcedures({ payload: search }) {
  const dataConfig = {
    params: { query: search },
  };
  const result = yield call(ProceduresService.getProcedures, dataConfig);
  if (!result) {
    yield put(actions.fetchProceduresError());
    return;
  }
  yield put(actions.searchProceduresSuccess(result.data));
}

function* getProcedure({ payload: params }) {
  const dataConfig = {
    params: { "filter[ids]": params },
  };
  const result = yield call(ProceduresService.getProcedures, dataConfig);
  if (!result) {
    yield put(actions.fetchProceduresError());
    return;
  }
  yield put(actions.setProcedure(result.data?.data));
}

export default function* proceduresSagas() {
  yield all([
    takeEvery(actions.PROCEDURES_SEARCH, searchProcedures),
    takeEvery(actions.PROCEDURE_GET, getProcedure),
  ]);
}
