import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class BookingsService extends BaseService {
  constructor(url) {
    super(url);
    this.getBookings = this.getBookings.bind(this);
    this.postBooking = this.postBooking.bind(this);
    this.rescheduleBooking = this.rescheduleBooking.bind(this);
  }

  async getBookings(params) {
    try {
      const response = await this.agent.get("", params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async postBooking(data, config, throwError) {
    try {
      const response = await this.agent.post("", data, config);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      if (throwError) {
        throw error;
      } else return null;
    }
  }

  async rescheduleBooking(id, config) {
    try {
      const response = await this.agent.post(`/${id}/reschedule`, {}, config);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new BookingsService(API_ROUTES.bookings);

export default ServiceInstance;
export { ServiceInstance as BookingsService };
