import { authActions as actions } from "./actions";

export const INIT_STATE = {
  autoStartToken: {},
  loader: false,
  notification: "",
  showNotification: false,
  authUser: typeof window !== "undefined" && localStorage.getItem("user_id"),
  authUserPersonalId: {},
  error: {},
};

export const authReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case actions.AUTO_START_TOKEN_REFRESH: {
      return {
        ...state,
        autoStartToken: action.payload,
      };
    }
    case actions.GET_CURRENT_USER: {
      return {
        ...state,
      };
    }
    case actions.SIGNUP_USER_SUCCESS:
    case actions.SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case actions.GET_USER_TOKEN_AND_SIGN_IN:
    case actions.SIGNIN_USER_QR_CODE: {
      return {
        ...state,
        loader: true,
        authUserPersonalId: {},
      };
    }
    case actions.AUTH_USER_REFRESH: {
      return {
        ...state,
        loader: false,
        authUserPersonalId: action.payload.data,
        error: "",
      };
    }
    case actions.AUTH_ERROR_REFRESH:
    case actions.SIGNIN_MOBILE_ERROR: {
      return {
        ...state,
        loader: false,
        error: action.payload,
      };
    }
    case actions.SIGNOUT_USER: {
      return {
        ...state,
        authUserPersonalId: {},
        loader: false,
      };
    }
    case actions.SHOW_MESSAGE: {
      return {
        ...state,
        notification: action.payload,
        showNotification: true,
        loader: false,
      };
    }
    case actions.HIDE_MESSAGE: {
      return {
        ...state,
        notification: "",
        showNotification: false,
        loader: false,
      };
    }
    case actions.UPDATE_CURRENT_USER:
    case actions.SIGNIN_MOBILE: {
      return {
        ...state,
        loader: true,
      };
    }
    case actions.UPDATE_CURRENT_USER_ERROR:
    case actions.SIGNIN_MOBILE_SUCCESS: {
      return {
        ...state,
        loader: false,
      };
    }
    default:
      return state;
  }
};
