import styled from "styled-components";

export const NameInputTransition = styled.div`
  &.visible {
    overflow: hidden;
    height: 0;
    height: 126px;
    transition: height 300ms;
  }

  &.invisible {
    overflow: hidden;
    height: 0;
    transition: height 300ms;
  }
`;

export const EmailInputTransition = styled.div`
  &.visible {
    overflow: hidden;
    height: 0;
    height: 65px;
    transition: height 300ms;
  }

  &.invisible {
    overflow: hidden;
    height: 0;
    transition: height 300ms;
  }
`;

export const PhoneInputTransition = styled.div`
  &.visible {
    overflow: hidden;
    height: 0;
    height: 80px;
    transition: height 300ms;
  }

  &.invisible {
    overflow: hidden;
    height: 0;
    transition: height 300ms;
  }
`;
