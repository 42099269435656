import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { TranslationsService } from "services/translations-service";

import { getAdaptedLanguages } from "helpers/helper-functions";

import { translationsActions as actions } from "./actions";
import { selectTranslations } from "./selectors";

function* getTranslations({ payload }) {
  const { tag, routeName, localeChange } = payload;
  const routeKey = `${routeName}-${tag}`;
  if (!tag) return;

  const translations = yield select(selectTranslations);
  const response = yield call(
    TranslationsService.getTranslations,
    tag,
    routeName,
  );
  if (response?.data?.data.length > 0) {
    const adaptedResult = getAdaptedLanguages(response.data.data);
    yield put(
      actions.translationsRefresh({
        ...(localeChange ? {} : translations),
        [routeKey]: adaptedResult,
      }),
    );
  } else {
    const swedishResponse = yield call(
      TranslationsService.getTranslations,
      "sv-se",
      routeName,
    );
    if (swedishResponse?.data?.data.length > 0) {
      const adaptedResult = getAdaptedLanguages(swedishResponse.data.data);
      yield put(
        actions.translationsRefresh({
          ...(localeChange ? {} : translations),
          [routeKey]: adaptedResult,
        }),
      );
    } else {
      actions.getTranslationsError();
    }
  }
}

export default function* translationsSaga() {
  yield all([takeEvery(actions.GET_TRANSLATIONS, getTranslations)]);
}
