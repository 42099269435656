import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { Notifications } from "helpers/notifications";
import { authActions } from "redux/auth/actions";

import { useQuery } from "./use-query";
import { useReferralSource } from "./use-referral-source";

const UseUser = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const query = useQuery();

  const facebookPixelId = query.get("facebook_pixel_id");
  const referralSource = useReferralSource();

  useEffect(() => {
    if (localStorage.getItem("user_token_pat_frontend"))
      dispatch(authActions.getCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (pathname === "/") {
      history.push("/start");
    }
  }, [pathname, history]);

  useEffect(() => {
    try {
      if (referralSource) {
        sessionStorage.setItem("referral_source", referralSource);
      }
      if (facebookPixelId)
        sessionStorage.setItem("facebook_pixel_id", facebookPixelId);
    } catch (err) {
      Notifications.message({
        message:
          'Din webbläsare stöder inte lagrings funktionen "lokal" eller "session".',
      });
    }
  }, [referralSource, facebookPixelId]);

  useEffect(() => {
    try {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            localStorage.setItem("muntra-geolocation-status", true);
            localStorage.setItem("muntra-latitude", position.coords.latitude);
            localStorage.setItem("muntra-longitude", position.coords.longitude);
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED)
              localStorage.removeItem("muntra-geolocation-status");
            localStorage.removeItem("muntra-latitude");
            localStorage.removeItem("muntra-longitude");
          },
        );
      } else {
        localStorage.removeItem("muntra-geolocation-status");
        localStorage.removeItem("muntra-latitude");
        localStorage.removeItem("muntra-longitude");
      }
    } catch (err) {
      if (err?.response?.data?.errors?.length) {
        err.response.data.errors.forEach((el) => {
          Notifications.message({ message: el.detail });
        });
      } else Notifications.message({ message: err.message });
    }
  }, []);

  return null;
};

export default UseUser;
