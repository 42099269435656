import React, { memo } from "react";
import * as PropTypes from "prop-types";

import { Row } from "components/common.styled";

const AggregateRatingMeta = ({ clinic, reviews }) => {
  return (
    <div>
      <Row
        alignitems="center"
        itemProp="aggregateRating"
        itemScope
        itemType="https://schema.org/AggregateRating"
      >
        {clinic?.attributes?.clinic_name && (
          <div
            itemProp="itemReviewed"
            itemScope
            itemType="https://schema.org/MedicalOrganization"
          >
            <meta content={clinic?.attributes?.clinic_name} itemProp="name" />
          </div>
        )}

        <meta
          content={clinic?.attributes?.overall_rating}
          itemProp="ratingValue"
        />
        <meta content="1" itemProp="worstRating" />
        <meta content="5" itemProp="bestRating" />
        <meta content={reviews?.data?.length} itemProp="reviewCount" />
      </Row>
    </div>
  );
};

AggregateRatingMeta.propTypes = {
  clinic: PropTypes.object,
  reviews: PropTypes.object,
};

AggregateRatingMeta.defaultProps = {
  clinic: {},
  reviews: {},
};

export default memo(AggregateRatingMeta);
