import React, { Component } from "react";
import * as PropTypes from "prop-types";

import AngleDown from "../../../assets/svg-components/AngleDown";
import { Row } from "../../common.styled";

import {
  CloseButton,
  Input,
  ItemButton,
  List,
  MainInput,
  NoResult,
  OptionBadge,
  ScrollList,
  StyledSpin,
} from "./index.styled";

class DropdownMultiple extends Component {
  constructor(props) {
    super(props);
    this.state = { listOpen: false };
    this.searchField = React.createRef();
    this.close = this.close.bind(this);
  }

  componentDidUpdate() {
    const { listOpen } = this.state;
    setTimeout(() => {
      if (listOpen) {
        window.addEventListener("click", this.close);
      } else {
        window.removeEventListener("click", this.close);
      }
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.close);
  }

  close() {
    this.setState({ listOpen: false });
  }

  toggleList(disable) {
    const { listOpen } = this.state;

    if (!disable)
      this.setState(
        (prevState) => ({
          listOpen: !prevState.listOpen,
        }),
        () => {
          if (listOpen && this.searchField.current) {
            this.searchField.current.focus();
          }
        },
      );
  }

  filterList({ target }) {
    const { onSearch } = this.props;

    onSearch(target.value);
  }

  listItems() {
    const { list, toggleItem, searchable, selectedItems, isLoading, value } =
      this.props;

    if (list.length) {
      return list.map((item) => (
        <ItemButton
          secondary
          style={{
            fontWeight: selectedItems?.find(
              (selected) => selected.id === item.id,
            )
              ? "bold"
              : "normal",
          }}
          key={item.id}
          onClick={() => toggleItem(item)}
        >
          {item.title}
        </ItemButton>
      ));
    }

    if (isLoading)
      return (
        <div>
          <StyledSpin />
        </div>
      );

    if (value) return <NoResult>{searchable[1]}</NoResult>;
  }

  render() {
    const {
      searchable,
      selectedItems,
      value = "",
      toggleItem,
      disable,
    } = this.props;
    const { listOpen } = this.state;
    return (
      <div style={{ marginBottom: 15, position: "relative", width: `100%` }}>
        <MainInput disable={disable} onClick={() => this.toggleList(disable)}>
          {selectedItems.length > 0 && (
            <Row>
              {selectedItems.map((item) => (
                <OptionBadge key={item.id} disable={disable}>
                  <CloseButton
                    onClick={() => {
                      if (!disable) toggleItem(item);
                    }}
                  >
                    x
                  </CloseButton>
                  {item.title}
                </OptionBadge>
              ))}
            </Row>
          )}

          {selectedItems.length === 0 && <div>{searchable[0]}</div>}

          {listOpen ? (
            <AngleDown style={{ transform: "rotate(180deg)" }} />
          ) : (
            <AngleDown />
          )}
        </MainInput>

        {listOpen && (
          <List onClick={(e) => e.stopPropagation()}>
            {searchable && (
              <Input
                ref={this.searchField}
                placeholder={searchable[0]}
                value={value}
                onChange={(e) => this.filterList(e)}
              />
            )}
            <ScrollList>{this.listItems()}</ScrollList>
          </List>
        )}
      </div>
    );
  }
}

DropdownMultiple.propTypes = {
  onSearch: PropTypes.func,
  list: PropTypes.array,
  toggleItem: PropTypes.func,
  searchable: PropTypes.array,
  selectedItems: PropTypes.array,
  isLoading: PropTypes.bool,
  value: PropTypes.string,
  disable: PropTypes.bool,
};

export default DropdownMultiple;
