import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 2rem 12rem 2rem;
  font-family: Arial, sans-serif;
  @media (max-width: 993px) {
    margin-bottom: 60px;
  }
`;
