import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import config from "config";
import { slugifyer } from "helpers/helper-functions";
import { useQuery } from "hooks/use-query";
import { useTranslations } from "hooks/use-translations";
import { clinicsActions } from "redux/clinics/actions";

import {
  Content,
  Heading,
  Layout,
  StyledCol,
  StyledPagination,
  StyledRow,
  StyledSpin,
  StyledText,
} from "components/common.styled";
import Footer from "components/footer";
import Header from "components/header";

const ClinicDirectory = () => {
  const routeName = "clinic-directory";

  const clinicsState = useSelector((state) => state.clinics);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();

  const translations = useTranslations(routeName);

  const { data, loading, page } = clinicsState;

  const onPageChange = useCallback(
    (e) => {
      history.push({
        pathname: location.pathname,
        search: `?page=${e}`,
      });
      dispatch(clinicsActions.changePageClinics(+e));
    },
    [dispatch, history, location.pathname],
  );

  useEffect(() => {
    if (query.get("page") && +query.get("page") !== +page)
      dispatch(clinicsActions.changePageClinics(+query.get("page")));
  }, [dispatch, page, query]);

  useEffect(() => {
    dispatch(clinicsActions.fetchClinics(page));
  }, [dispatch, page]);

  const renderPagination = useCallback(() => {
    const {
      meta: { pagination },
    } = data;

    return (
      <StyledPagination
        current={page}
        limit={20}
        onPageChanged={onPageChange}
        total={pagination.total}
      />
    );
  }, [data, onPageChange, page]);

  return (
    <Layout>
      <Helmet>
        <title>{`${translations.find_dental_caregivers_by_clinic} | ${translations.meta_title_default}`}</title>
        <meta
          id="meta-description"
          name="description"
          content={translations.meta_content_clinic_dir}
        />
        <link
          id="canonical-link"
          rel="canonical"
          href={config.url + location.pathname.replace(/\?.*$/gm, "")}
        />
      </Helmet>

      <Header translations={translations} isStartPage={false} />
      <Content>
        {data?.meta && renderPagination()}
        <Heading>
          {translations.find_dental_caregivers_by_clinicouteName}
        </Heading>
        <StyledRow>
          {!loading &&
            (data?.data || []).map((item) => {
              const {
                clinic_name: clinicName,
                clinic_city: clinicCity,
                clinic_address_1: clinicAddress1,
                clinic_postal_code: clinicPostalCode,
              } = item.attributes;

              return (
                <StyledCol xs={24} md={5} key={item.id}>
                  <Link
                    to={`/${slugifyer(clinicName)}/c/${item.id}`}
                  >{`${clinicName}`}</Link>
                  {clinicAddress1 && (
                    <StyledText>{`${clinicAddress1}`}</StyledText>
                  )}
                  <StyledText>{`${clinicCity} ${clinicPostalCode}`}</StyledText>
                </StyledCol>
              );
            })}
          {loading && <StyledSpin size="large" />}
        </StyledRow>
        {data?.meta && renderPagination()}
      </Content>
      <Footer translations={translations} />
    </Layout>
  );
};

export default ClinicDirectory;
