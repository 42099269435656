import styled from "styled-components";

export const ImgStyled = styled.img`
  @media (max-width: 993px) {
    max-width: 168px;
    max-height: 168px;
  }
  @media (min-width: 995px) {
    max-width: 600px;
  }
`;
