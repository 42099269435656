import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class CaregiversAtLocationsService extends BaseService {
  constructor(url) {
    super(url);
    this.getCaregiver = this.getCaregiver.bind(this);
  }

  async getCaregiver(params) {
    try {
      const response = await this.agent.get("", params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new CaregiversAtLocationsService(
  API_ROUTES.caregiverAtLocations,
);

export default ServiceInstance;
export { ServiceInstance as CaregiversAtLocationsService };
