import React, { useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { PatientInvoicesService } from "services/patient-invoices-service";

import { COUNTRIES } from "constants/countries";
import { formatDate, getItemFields } from "helpers/helper-functions";
import { useQuery } from "hooks/use-query";
import { useTranslations } from "hooks/use-translations";
import { selectTag } from "redux/app/selectors";
import { bookingActions } from "redux/booking/actions";

import { MainSpinner } from "components/common.styled";
import { StretchButton } from "components/common/button";
import Footer from "components/footer";
import Header from "components/header";
import { Layout } from "components/search/index.styled";

import InvoiceRow from "./components/invoice-row";

import {
  Container,
  Content,
  FirstHeader,
  PositionText,
  SecondHeader,
  StyledText,
} from "./index.styled";

const Invoice = () => {
  const routeName = "invoice";
  const { invoice, loading } = useSelector((state) => state.booking);
  const { country } = useSelector((state) => state.app);
  const locale = useSelector(selectTag);

  const dispatch = useDispatch();
  const query = useQuery();
  const invoiceId = query.get("invoice_id");

  const translations = useTranslations(routeName);

  const {
    account_number: accountNumber = "",
    bankgiro_number: bankgiroNumber = "",
    clearing_number: clearingNumber = "",
    generic_dental_subsidy: genericDentalSubsidy = 0,
    invoice_date: invoiceDate = 0,
    invoice_fee: invoiceFee = 0,
    ocr_number: ocrNumber = "",
    organization_name: organizationName = "",
    specific_dental_subsidy: specificDentalSubsidy = 0,
    state_dental_subsidy: stateDentalSubsidy = 0,
  } = invoice?.data?.attributes || {};

  const bankAccountNumber = useMemo(
    () => `${clearingNumber} ${accountNumber}`.trim(),
    [accountNumber, clearingNumber],
  );
  const date = useMemo(
    () => formatDate(invoiceDate, "d MMMM uuuu"),
    [invoiceDate],
  );
  const invoiceData = useMemo(
    () => getItemFields(invoice?.data?.relationships, invoice?.included),
    [invoice?.data?.relationships, invoice?.included],
  );
  const invoiceRow = useMemo(
    () =>
      (invoiceData?.patient_invoice_rows || []).find(
        (item) => !!item?.attributes?.price_excluding_vat_per_quantity,
      ),
    [invoiceData?.patient_invoice_rows],
  );
  const currency = invoiceData.currency?.attributes?.code || "SEK";
  const isCountrySweden = country === COUNTRIES.sweden;

  const totalSum = useMemo(() => {
    const invoiceSum = (invoiceData?.patient_invoice_rows || []).reduce(
      (accumulator, currentValue) =>
        accumulator +
        currentValue.attributes.price_excluding_vat_per_quantity *
          (1 + currentValue.vat_code.attributes.vat_percentage),
      0,
    );
    const deductionsSum = (invoiceData?.deductions || []).reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.attributes.amount,
      0,
    );

    return (
      invoiceSum +
      specificDentalSubsidy +
      stateDentalSubsidy +
      invoiceFee +
      deductionsSum
    );
  }, [
    invoiceData?.deductions,
    invoiceData?.patient_invoice_rows,
    invoiceFee,
    specificDentalSubsidy,
    stateDentalSubsidy,
  ]);

  const totalSumFormatted = useMemo(
    () =>
      new Intl.NumberFormat(locale, {
        style: "currency",
        currency,
      }).format(invoiceRow ? totalSum + genericDentalSubsidy : 0),
    [currency, genericDentalSubsidy, invoiceRow, locale, totalSum],
  );

  const handleDownloadPdfClick = useCallback(async () => {
    try {
      const result = await PatientInvoicesService.getInvoicePdf(invoiceId);
      if (result?.data) {
        const blob = new Blob([result.data], {
          type: "application/pdf",
        });
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.target = "_self";
        a.download = "invoice.pdf";
        document.body.appendChild(a);
        a.click();

        setTimeout(() => {
          a.remove();
          window.URL.revokeObjectURL(url);
        }, 200);
      }
    } catch (err) {
      if (err?.response?.data?.errors?.length)
        err.response.data.errors.forEach((el) => {
          toast.error(el.detail);
        });
      else toast.error(err.message);
    }
  }, [invoiceId]);

  useEffect(() => {
    if (invoiceId) dispatch(bookingActions.fetchInvoiceById(invoiceId));
  }, [dispatch, invoiceId]);

  return (
    <Layout style={{ backgroundColor: "white" }}>
      <Helmet>
        <title>{`${translations.meta_title_invoice} | ${translations.meta_title_default}`}</title>
      </Helmet>
      <Header translations={translations} isStartPage={false} />
      {loading && <MainSpinner />}
      {!loading && (
        <Container>
          {invoice?.data && (
            <Content>
              <FirstHeader style={{ marginBottom: "10px" }}>
                {totalSumFormatted}
              </FirstHeader>
              <StyledText> {organizationName} </StyledText>
              <SecondHeader>
                {translations.invoice_treatments_header}{" "}
              </SecondHeader>
              {!!invoiceData.patient_invoice_rows?.length &&
                invoiceData.patient_invoice_rows.map((item) => {
                  const {
                    price_excluding_vat_per_quantity:
                      priceExcludingVatPerQuantity = 0,
                    vat_percentage: vatPercentage = 0,
                  } = item.attributes || {};
                  const amount =
                    priceExcludingVatPerQuantity * (1 + vatPercentage);
                  return (
                    <InvoiceRow
                      amount={amount}
                      currency={currency}
                      key={item.id}
                      label={item.attributes?.product_name}
                    />
                  );
                })}
              {!!invoiceData.deductions?.length &&
                invoiceData.deductions?.map((item) => (
                  <InvoiceRow
                    amount={item.attributes?.amount}
                    currency={currency}
                    key={item.id}
                    label={item.attributes?.label}
                  />
                ))}
              <div
                style={{
                  borderBottom: "solid lightgrey 1px",
                  paddingBottom: "32px",
                  marginBottom: "32px",
                }}
              >
                <SecondHeader>
                  {translations.invoice_more_info_header}&nbsp;
                </SecondHeader>
                <div style={{ position: "relative" }}>
                  <StyledText>{translations.buy_date}&nbsp;</StyledText>
                  <PositionText>&nbsp;{date}</PositionText>
                </div>
              </div>
              {bankgiroNumber && (
                <div style={{ position: "relative", margin: "20px 0" }}>
                  <StyledText>{translations.bankgiro_number}&nbsp;</StyledText>
                  <PositionText>&nbsp;{bankgiroNumber}</PositionText>
                </div>
              )}
              {isCountrySweden && (
                <div style={{ position: "relative", margin: "20px 0" }}>
                  <StyledText>{translations.invoice_ocr}&nbsp;</StyledText>
                  <PositionText>&nbsp;{ocrNumber}</PositionText>
                </div>
              )}
              {(!bankgiroNumber || !isCountrySweden) && bankAccountNumber && (
                <div style={{ position: "relative", margin: "20px 0" }}>
                  <StyledText>
                    {translations.bank_account_number}&nbsp;
                  </StyledText>
                  <PositionText>&nbsp;{bankAccountNumber}</PositionText>
                </div>
              )}
              <StretchButton onClick={handleDownloadPdfClick}>
                <span style={{ margin: "0 auto" }}>
                  {translations.download_pdf}
                </span>
              </StretchButton>
            </Content>
          )}
        </Container>
      )}
      <Footer translations={translations} />
    </Layout>
  );
};

export default Invoice;
