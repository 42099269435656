import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";

import SearchSvg from "assets/svg-components/Search";
import { arrangeCaregiversOptions } from "helpers/arrange-caregivers-options";
import { arrangePlacesOptions } from "helpers/arrange-places-options";
import { useDebounce } from "hooks/use-debounce";
import { useSignInValidation } from "hooks/use-sign-in-validation";
import { caregiversActions } from "redux/caregivers/actions";
import { googlePlacesActions } from "redux/google-places/actions";

import {
  Container,
  StartPageCaregiverSearch,
  StartPageGeoSearch,
  StartPageSearchButton,
  StartPageSearchRow,
} from "./index.styled";

const SearchBar = ({ places, isStartPage, translations, routeName }) => {
  const caregiversState = useSelector((state) => state.caregivers);
  const placesState = useSelector((state) => state.googlePlaces);

  const dispatch = useDispatch();
  const history = useHistory();

  const [optionsCaregiver, setOptionsCaregiver] = useState([]);
  const [optionsPlaces, setOptionsPlaces] = useState([]);

  const handleChangeCaregiversSearch = (value) => {
    dispatch(caregiversActions.searchCaregiversChange(value));
  };
  const handleChangePlacesSearch = (value) => {
    dispatch(googlePlacesActions.searchGooglePlacesChange(value));
  };

  const searchCaregiversCb = useCallback(() => {
    if (caregiversState?.search?.length >= 3) {
      dispatch(
        caregiversActions.searchCaregivers(caregiversState?.search, routeName),
      );
    }
  }, [caregiversState?.search, dispatch, routeName]);

  const searchGooglePlacesCb = useCallback(() => {
    if (placesState?.search?.search?.length >= 3) {
      dispatch(
        googlePlacesActions.searchGooglePlaces(
          placesState?.search?.search,
          placesState?.search?.isAutocomplete,
          routeName,
        ),
      );
    }
  }, [
    dispatch,
    placesState?.search?.isAutocomplete,
    placesState?.search?.search,
    routeName,
  ]);

  useDebounce(searchCaregiversCb, [], 400);
  useDebounce(searchGooglePlacesCb, [], 400);

  useSignInValidation();

  useEffect(() => {
    if (caregiversState?.searchResult)
      setOptionsCaregiver(
        arrangeCaregiversOptions(
          caregiversState.searchResult,
          dispatch,
          history,
        ),
      );
  }, [caregiversState?.searchResult, dispatch, history]);

  useEffect(() => {
    if (placesState?.searchResult?.data)
      setOptionsPlaces(
        arrangePlacesOptions(placesState?.searchResult, dispatch, history),
      );
  }, [dispatch, history, placesState?.searchResult]);

  useEffect(() => {
    const isAutocomplete = true;
    if (places?.info?.attributes) {
      dispatch(
        googlePlacesActions.searchGooglePlacesChange(
          places.info.attributes.name,
          isAutocomplete,
        ),
      );
    } else if (places?.attributes) {
      dispatch(
        googlePlacesActions.searchGooglePlacesChange(
          places.attributes.name,
          isAutocomplete,
        ),
      );
    }
  }, [dispatch, places]);

  return (
    <Container>
      <StartPageSearchRow>
        <StartPageCaregiverSearch
          isStartPage={isStartPage}
          isCaregiverSearchBar
          leftSearch
          value={caregiversState.search || ""}
          handleSearchChange={handleChangeCaregiversSearch}
          options={optionsCaregiver}
          isLoading={caregiversState.searchLoading}
          placeholder={translations.start_search_c_placeholder}
          validText={translations.start_search_c_valid_text}
        />
        <StartPageGeoSearch
          isStartPage={isStartPage}
          value={placesState.search?.search || ""}
          handleSearchChange={handleChangePlacesSearch}
          options={optionsPlaces}
          isLoading={placesState.searchLoading}
          placeholder={translations.start_search_p_placeholder}
          validText={translations.start_search_p_valid_text}
        />
        <StartPageSearchButton
          onClick={() => history.push("/search")}
          secondary
        >
          <SearchSvg stylingPropClassName="SVG16px" />
        </StartPageSearchButton>
      </StartPageSearchRow>
    </Container>
  );
};

SearchBar.propTypes = {
  places: PropTypes.object,
  isStartPage: PropTypes.bool,
  translations: PropTypes.object,
  routeName: PropTypes.string,
};

export default memo(SearchBar);
