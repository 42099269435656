import { bookingActions as actions } from "./actions";

export const INIT_STATE = {
  loading: false,
  error: "",
  comingBookings: {},
  lastBookings: {},
  invoices: {},
  invoice: {},
  bookingConfirmStatus: {},
  bookingDetails: {},
  lastBookingsPage: 1,
  invoicesPage: 1,
  bookingAttendees: {},
};

export const bookingReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case actions.BOOKINGS_COMING_FETCH:
    case actions.BOOKINGS_LAST_FETCH:
    case actions.BOOKING_INVOICES_FETCH:
    case actions.BOOKING_INVOICE_FETCH_BY_ID:
    case actions.BOOKING_PATIENT_RESPONSE:
    case actions.BOOKING_DETAILS_FETCH:
    case actions.BOOKING_ATTENDEES_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.BOOKINGS_COMING_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        comingBookings: action.payload,
      };
    }
    case actions.BOOKINGS_LAST_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        lastBookings: action.payload,
      };
    }
    case actions.BOOKINGS_LAST_CHANGE_PAGE: {
      return {
        ...state,
        lastBookingsPage: action.payload,
      };
    }
    case actions.BOOKING_INVOICES_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        invoices: action.payload,
      };
    }
    case actions.BOOKING_INVOICES_CHANGE_PAGE: {
      return {
        ...state,
        invoicesPage: action.payload,
      };
    }
    case actions.BOOKING_INVOICE_FETCH_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        invoice: action.payload,
      };
    }
    case actions.BOOKING_PATIENT_RESPONSE_SUCCESS: {
      return {
        ...state,
        bookingConfirmStatus: action.payload,
        loading: false,
      };
    }
    case actions.BOOKING_DETAILS_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        bookingDetails: action.payload,
      };
    }
    case actions.BOOKING_ATTENDEES_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        bookingAttendees: action.payload,
      };
    }
    case actions.BOOKINGS_COMING_FETCH_ERROR:
    case actions.BOOKINGS_LAST_FETCH_ERROR:
    case actions.BOOKING_INVOICES_FETCH_ERROR:
    case actions.BOOKING_INVOICE_FETCH_BY_ID_ERROR:
    case actions.BOOKING_PATIENT_RESPONSE_ERROR:
    case actions.BOOKING_DETAILS_FETCH_ERROR:
    case actions.BOOKING_ATTENDEES_FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default bookingReducer;
