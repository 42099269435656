import styled from "styled-components";

export const RadioWrapper = styled.div`
  display: inline-block;
`;

export const Mark = styled.span`
  border-radius: 50%;
  border: 1px solid #777777;
  display: inline-block;
  flex-shrink: 0;
  height: 14px;
  left: 0;
  margin-right: 5px;
  position: relative;
  vertical-align: middle;
  width: 14px;
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: #1890ff;
    opacity: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transition: all 110ms;
  }
`;

export const Input = styled.input`
  position: absolute;
  visibility: hidden;
  display: none;
  &:checked + ${Mark} {
    &::after {
      width: 8px;
      height: 8px;
      opacity: 1;
      left: 2px;
      top: 2px;
    }
  }
`;

export const Label = styled.label`
  display: flex;
  cursor: pointer;
  padding: 5px 10px 5px 0;
  position: relative;
  align-items: center;
  ${(props) =>
    props.disabled &&
    `
        cursor: not-allowed;
        opacity: 0.4;
    `}
`;
