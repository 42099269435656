import React from "react";

const CloseIcon = () => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    className="closeIconSvg"
  >
    <line x1="0" y1="16" x2="16" y2="0" strokeWidth="2" />
    <line x1="0" y1="0" x2="16" y2="16" strokeWidth="2" />
  </svg>
);

export default CloseIcon;
