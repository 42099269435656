const prefix = "[roles]";

const TYPES = {
  ROLE_CLEAN: `${prefix} ROLE_CLEAN`,
  ROLE_FETCH: `${prefix} ROLE_FETCH`,
  ROLE_FETCH_SUCCESS: `${prefix} ROLE_FETCH_SUCCESS`,
  ROLE_GET: `${prefix} ROLE_GET`,
  ROLE_SET: `${prefix} ROLE_SET`,
  ROLES_CHANGE_SEARCH: `${prefix} ROLES_CHANGE_SEARCH`,
  ROLES_CLEAN_UP: `${prefix} ROLES_CLEAN_UP`,
  ROLES_FETCH: `${prefix} ROLES_FETCH`,
  ROLES_FETCH_ERROR: `${prefix} ROLES_FETCH_ERROR`,
  ROLES_FETCH_SUCCESS: `${prefix} ROLES_FETCH_SUCCESS`,
  ROLES_SEARCH: `${prefix} ROLES_SEARCH`,
  ROLES_SEARCH_SUCCESS: `${prefix} ROLES_SEARCH_SUCCESS`,
  ROLES_SET_SELECTED: `${prefix} ROLES_SET_SELECTED`,
};

export const rolesActions = {
  ...TYPES,
  getRole: (params) => ({
    type: TYPES.ROLE_GET,
    payload: params,
  }),
  setRole: (role) => ({
    type: TYPES.ROLE_SET,
    payload: role,
  }),
  fetchRole: (params) => ({
    type: TYPES.ROLE_FETCH,
    payload: params,
  }),
  fetchRoleSuccess: (role) => ({
    type: TYPES.ROLE_FETCH_SUCCESS,
    payload: role,
  }),
  cleanRole: () => ({
    type: TYPES.ROLE_CLEAN,
  }),
  fetchRoles: (params) => ({
    type: TYPES.ROLES_FETCH,
    payload: params,
  }),
  fetchRolesSuccess: (roles) => ({
    type: TYPES.ROLES_FETCH_SUCCESS,
    payload: roles,
  }),
  fetchRolesError: (error) => ({
    type: TYPES.ROLES_FETCH_ERROR,
    payload: error,
  }),
  searchRoles: (search) => ({
    type: TYPES.ROLES_SEARCH,
    payload: search,
  }),
  searchRolesSuccess: (roles) => ({
    type: TYPES.ROLES_SEARCH_SUCCESS,
    payload: roles,
  }),
  searchRolesChange: (search) => ({
    type: TYPES.ROLES_CHANGE_SEARCH,
    payload: search,
  }),
  setSelectedRole: (data) => ({
    type: TYPES.ROLES_SET_SELECTED,
    payload: data,
  }),
  cleanUpRoles: (data) => ({
    type: TYPES.ROLES_CLEAN_UP,
    payload: data,
  }),
};
