import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as PropTypes from "prop-types";

import { authActions as actions } from "redux/auth/actions";

import { Modal } from "../../common/modal";
import SendBookingContent from "./send-booking-content";

const SendBookingModal = ({
  caregiver,
  clinicData,
  clinicId,
  defaultProcedure,
  isSearch,
  onClose,
  slotsId,
  translations,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(actions.authErrorRefresh({}));
  }, [dispatch]);

  return (
    <Modal onClose={onClose} style={{ maxWidth: 570, width: "100%" }}>
      <SendBookingContent
        caregiver={caregiver}
        clinicData={clinicData}
        clinicId={clinicId}
        defaultProcedure={defaultProcedure}
        isSearch={isSearch}
        onClose={onClose}
        slotsId={slotsId}
        translations={translations}
      />
    </Modal>
  );
};

SendBookingModal.propTypes = {
  caregiver: PropTypes.object,
  clinicData: PropTypes.object,
  clinicId: PropTypes.string,
  defaultProcedure: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  isSearch: PropTypes.bool,
  onClose: PropTypes.func,
  slotsId: PropTypes.string,
  translations: PropTypes.object,
};

export default memo(SendBookingModal);
