export const paramsToQuery = (params) => {
  let queryString = "?";
  Object.entries(params).forEach(([paramKey, paramValue]) => {
    if (paramValue && paramValue !== "undefined") {
      queryString = queryString.concat(`${paramKey}=${paramValue}&`);
    }
  });
  queryString = queryString.slice(0, -1);
  return queryString;
};
