import styled from "styled-components";

import { StyledContent, Text } from "components/common.styled";
import Rate from "components/common/stars";

export const Content = styled(StyledContent)`
  max-width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  width: 100%;
  margin-top: 0;
  padding: 0;
  box-shadow: none;
  padding: 20px 50px;
`;

export const P = styled(Text)`
  margin-bottom: 0;
`;

export const BreadcrumbsContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;

  & a {
    color: #1890ff;

    :hover {
      color: #40a9ff;
    }
  }

  & span {
    margin: 0px 10px;
  }
`;

export const StyledDivider = styled.div`
  border-bottom: 1px solid #00000017;
  margin: 24px 0;
  width: 100%;
  @media (max-width: 993px) {
    margin: 0;
  }
`;

export const ClinicOptionsDivider = styled.div`
  border-bottom: 1px solid #00000017;
  margin: 32px 0;
  width: 100%;
  @media (min-width: 994px) {
    margin: 24px 0;
  }
`;

export const StyledRate = styled(Rate)``;

export const ClinicName = styled.h1`
  margin-bottom: 15px;
  font-size: 30px;
  font-weight: bold;
  line-height: 30px;
`;

export const MainRate = styled(Rate)`
  margin: 0;
`;

export const RateTitleCommments = styled.h4`
  font-weight: bold;
  margin-bottom: 0;
  font-size: 18px;
  margin-right: 10px;
`;

export const ConditionTitle = styled.div`
  font-weight: bold;
  margin-bottom: 24px;
  font-size: 18px;
`;

export const RoleText = styled.p`
  margin-top: 10px;
  color: rgb(39, 39, 47);
  font-size: 15px;

  @media (max-width: 320px) {
    max-width: 180px;
  }
`;
