const prefix = "[translations]";

const TYPES = {
  GET_TRANSLATIONS: `${prefix} GET_TRANSLATIONS`,
  GET_TRANSLATIONS_ERROR: `${prefix} GET_TRANSLATIONS_ERROR`,
  TRANSLATIONS_REFRESH: `${prefix} TRANSLATIONS_REFRESH`,
};

export const translationsActions = {
  ...TYPES,
  getTranslations: (payload) => ({
    type: TYPES.GET_TRANSLATIONS,
    payload,
  }),
  translationsRefresh: (translations) => ({
    type: TYPES.TRANSLATIONS_REFRESH,
    payload: translations,
  }),
  getTranslationsError: (error) => ({
    type: TYPES.GET_TRANSLATIONS_ERROR,
    payload: error,
  }),
};
