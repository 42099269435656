import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { addDays, endOfDay, subDays } from "date-fns";
import * as PropTypes from "prop-types";

import { toDate } from "helpers/helper-functions";
import { useDebounce } from "hooks/use-debounce";
import { caregiversActions } from "redux/caregivers/actions";
import { googlePlacesActions } from "redux/google-places/actions";
import { proceduresActions } from "redux/procedures/actions";
import { rolesActions } from "redux/roles/actions";

import Wrapper from "../calendar/wrapper";
import { Col, ContentContainer, MobileSection, Row } from "../common.styled";
import Pagination from "../common/pagination";
import Dropdown from "../common/search-dropdown/dropdown-multiple";
import Header from "../header";
import CaregiverCard from "./caregiver-card";
import ErrorMessage from "./error";
import SearchRow from "./search-row";

import {
  MainCol,
  SearchContent,
  SearchHeader,
  StyledRow,
  WrapperPosition,
} from "./index.styled";

const Search = ({
  isPlaces,
  roleId,
  onPageChange,
  placeName,
  role,
  roleProcedureName,
  translations,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    searchPage,
    searchSlotsLoading,
    searchSlots,
    error,
    caregiversDataForAvatar,
    slotsLoadBegin,
  } = useSelector((state) => state.caregivers);
  const { data: places, errorPlaces } = useSelector(
    (state) => state.googlePlaces,
  );

  const placesProps = useMemo(() => {
    return places?.attributes
      ? {
          lat: places.attributes.location_lat,
          lng: places.attributes.location_lng,
          vNLat: places.attributes.viewport_northeast_lat,
          vNLng: places.attributes.viewport_northeast_lng,
          vSLat: places.attributes.viewport_southwest_lat,
          vSLng: places.attributes.viewport_southwest_lng,
        }
      : {
          lat: localStorage.getItem("muntra-latitude"),
          lng: localStorage.getItem("muntra-longitude"),
        };
  }, [places]);

  const proceduresState = useSelector((state) => state.procedures);
  const rolesState = useSelector((state) => state.roles);

  const timeStep = useMemo(() => (global.innerWidth < 994 ? 1 : 3), []);
  const endDateInterval = useMemo(() => (global.innerWidth < 994 ? 0 : 2), []);

  const [startDate, setStartDate] = useState(new Date());
  const [debouncedStartDate, setDebouncedStartDate] = useState(startDate);

  const [mounted, setMounted] = useState(false);
  const [scrollItemsHeight, setScrollItemsHeight] = useState("100%");
  const [scrollFontSize, setScrollFontSize] = useState("14px");

  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [scrollTimer, setScrollTimer] = useState(new Date());

  const middleDate = useMemo(
    () => addDays(endOfDay(startDate), 1),
    [startDate],
  );
  const endDate = useMemo(
    () => addDays(endOfDay(startDate), endDateInterval),
    [endDateInterval, startDate],
  );

  const debouncedEndDate = useMemo(
    () => addDays(endOfDay(debouncedStartDate), 1),
    [debouncedStartDate],
  );

  const goToDate = useCallback((date) => {
    toDate(date, setStartDate);
  }, []);

  const goBack = useCallback(
    () => goToDate(subDays(startDate, timeStep)),
    [goToDate, startDate, timeStep],
  );
  const goNext = useCallback(
    () => goToDate(addDays(startDate, timeStep)),
    [goToDate, startDate, timeStep],
  );

  const handleScroll = useCallback(() => {
    if (global.innerWidth >= 994) {
      if (scrollItemsHeight !== "100%") {
        setScrollItemsHeight("100%");
        setScrollFontSize("14px");
      }
    } else {
      const secondsPassed = Math.abs(
        (new Date().getTime() - scrollTimer.getTime()) / 1000,
      );
      if (document.documentElement.scrollTop === 0 && secondsPassed > 0.2) {
        setScrollTimer(new Date());
        setScrollItemsHeight("100%");
        setScrollFontSize("14px");
      } else {
        setScrollItemsHeight("0px");
        setScrollFontSize("0px");
      }
    }
  }, [scrollItemsHeight, scrollTimer]);

  const resetPageURL = useCallback((inputURL) => {
    const url = new URL(inputURL);
    const { searchParams } = url;

    searchParams.set("page", "1");
    url.search = searchParams.toString();

    return `${url.pathname}${url.search}`;
  }, []);

  const handleChangeProceduresSearch = useCallback(
    (value) => {
      dispatch(proceduresActions.searchProceduresChange(value));
    },
    [dispatch],
  );

  const handleChangeRolesSearch = useCallback(
    (value) => {
      dispatch(rolesActions.searchRolesChange(value));
    },
    [dispatch],
  );

  const arrangeProceduresOptions = useMemo(() => {
    if (proceduresState?.searchResult?.data?.length) {
      return proceduresState.searchResult.data.map((item) => ({
        id: item.id,
        title: item.attributes.name,
      }));
    }
  }, [proceduresState.searchResult.data]);

  const arrangeRolesOptions = useMemo(() => {
    if (rolesState?.searchResult?.data?.length) {
      return rolesState.searchResult.data
        .filter((item) => {
          return item.attributes.name
            ?.toLowerCase()
            .includes(rolesState.search?.toLowerCase());
        })
        .map((item) => ({
          id: item.id,
          title: item.attributes.name,
        }));
    }
  }, [rolesState.search, rolesState.searchResult?.data]);

  const toggleItemProcedures = useCallback(
    (selected) => {
      let tmp = [];
      if (selectedProcedures?.find((item) => item.id === selected.id))
        tmp = selectedProcedures?.filter((item) => item.id !== selected.id);
      else {
        tmp = [...selectedProcedures, selected];
      }
      setSelectedProcedures(tmp);
      dispatch(proceduresActions.setSelectedProcedure(tmp, false));
      dispatch(caregiversActions.changePageCaregiverSlotsSearch(1));
      history.push(location.pathname, resetPageURL(window.location.href));
    },
    [dispatch, history, location.pathname, resetPageURL, selectedProcedures],
  );

  const toggleItemRoles = useCallback(
    (selected) => {
      let tmp = [];
      if (selectedRoles?.find((item) => item.id === selected.id))
        tmp = selectedRoles?.filter((item) => item.id !== selected.id);
      else {
        tmp = [...selectedRoles, selected];
      }
      setSelectedRoles(tmp);
      dispatch(rolesActions.setSelectedRole(tmp));
      dispatch(caregiversActions.changePageCaregiverSlotsSearch(1));
      history.push(location.pathname, resetPageURL(window.location.href));
    },
    [dispatch, history, location.pathname, resetPageURL, selectedRoles],
  );

  const handleRadioChange = useCallback(
    (item, isDropdown) => {
      if (isDropdown) {
        setSelectedProcedures([
          {
            id: item.procedure.id,
            title: item.procedure.attributes.name,
          },
        ]);
        dispatch(
          proceduresActions.setSelectedProcedure(
            [
              {
                id: item.procedure.id,
                title: item.procedure.attributes.name,
              },
            ],
            false,
          ),
        );
        dispatch(caregiversActions.changePageCaregiverSlotsSearch(1));
        history.push(location.pathname, resetPageURL(window.location.href));
      } else {
        setSelectedProcedures([
          {
            id: item.id,
            title: item.attributes.name,
          },
        ]);
        dispatch(
          proceduresActions.setSelectedProcedure(
            [
              {
                id: item.id,
                title: item.attributes.name,
              },
            ],
            false,
          ),
        );
        dispatch(caregiversActions.changePageCaregiverSlotsSearch(1));
        history.push(location.pathname, resetPageURL(window.location.href));
      }
    },
    [dispatch, history, location.pathname, resetPageURL],
  );

  const debounceDateCb = useCallback(() => {
    if (startDate !== debouncedStartDate) {
      setDebouncedStartDate(startDate);
    }
  }, [debouncedStartDate, startDate]);

  const searchProceduresCb = useCallback(() => {
    if (proceduresState.search) {
      dispatch(proceduresActions.searchProcedures(proceduresState.search));
    }
  }, [dispatch, proceduresState.search]);

  const searchRolesCb = useCallback(() => {
    if (rolesState.search && !rolesState.searchResult.data) {
      dispatch(rolesActions.searchRoles(rolesState.search));
    }
  }, [dispatch, rolesState.search, rolesState.searchResult.data]);

  useDebounce(debounceDateCb, [], 400);
  useDebounce(searchProceduresCb, [], 400);
  useDebounce(searchRolesCb, [], 400);

  useEffect(() => {
    const url = new URL(window.location.href);
    const page = url.searchParams.get("page");

    if (page && +page !== +searchPage) {
      dispatch(caregiversActions.changePageCaregiverSlotsSearch(+page));
    } else if (page === null) {
      dispatch(caregiversActions.changePageCaregiverSlotsSearch(1));
    }
  }, [dispatch, searchPage]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (isPlaces) dispatch(proceduresActions.setIsProcedure(false));
  }, [dispatch, isPlaces]);

  useEffect(() => {
    if (roleId) {
      dispatch(proceduresActions.setIsProcedure(false));
      if (rolesState?.role?.attributes) {
        if (rolesState?.role?.default_procedure) {
          setSelectedProcedures([
            {
              id: rolesState.role.default_procedure.id,
              title: rolesState.role.default_procedure.attributes.name,
            },
          ]);
          dispatch(
            proceduresActions.setSelectedProcedure(
              [
                {
                  id: rolesState.role.default_procedure.id,
                  title: rolesState.role.default_procedure.attributes.name,
                },
              ],
              false,
            ),
          );
        }
        setSelectedRoles([
          {
            id: rolesState.role.id,
            title: rolesState.role.attributes.name,
          },
        ]);
        dispatch(
          rolesActions.setSelectedRole([
            {
              id: rolesState.role.id,
              title: rolesState.role.attributes.name,
            },
          ]),
        );
      }
    }
  }, [
    dispatch,
    roleId,
    rolesState.role.attributes,
    rolesState.role.default_procedure,
    rolesState.role.id,
  ]);

  useEffect(() => {
    if (!roleId && !isPlaces) {
      if (!rolesState?.currentRole?.role || !rolesState?.currentRole?.procedure)
        return;

      const roles = rolesState?.currentRole?.role?.map((item) => ({
        id: item.id,
        title: item.attributes.name,
      }));
      const procedures = rolesState?.currentRole?.procedure?.map((item) => ({
        id: item.id,
        title: item.attributes.name,
      }));

      if (roles) {
        setSelectedRoles(roles);
        dispatch(rolesActions.setSelectedRole(roles));
        dispatch(caregiversActions.changePageCaregiverSlotsSearch(1));
      }
      if (procedures) {
        setSelectedProcedures(procedures);
        dispatch(proceduresActions.setSelectedProcedure(procedures, false));
        dispatch(caregiversActions.changePageCaregiverSlotsSearch(1));
      }
    }
  }, [
    dispatch,
    isPlaces,
    roleId,
    rolesState?.currentRole?.procedure,
    rolesState?.currentRole?.role,
  ]);

  const fetchCaregivers = useCallback(
    (procedureIds, roleIds) => {
      dispatch(
        caregiversActions.fetchCaregiverSlotsSearch({
          dateEnd: debouncedEndDate,
          dateStart: debouncedStartDate,
          page: searchPage,
          procedureIds,
          roleIds,
          ...placesProps,
        }),
      );
    },
    [debouncedEndDate, debouncedStartDate, dispatch, placesProps, searchPage],
  );

  useEffect(() => {
    if (
      searchPage &&
      !proceduresState.isProcedure &&
      roleId &&
      !rolesState.loading
    ) {
      const procedureIds = rolesState.role?.default_procedure?.id || "";
      fetchCaregivers(procedureIds, roleId);
    }
  }, [
    searchPage,
    proceduresState.isProcedure,
    roleId,
    rolesState.loading,
    fetchCaregivers,
    rolesState.role?.default_procedure?.id,
  ]);

  useEffect(() => {
    if (
      searchPage &&
      ((!proceduresState.isProcedure &&
        !isPlaces &&
        !roleId &&
        !rolesState.loading) ||
        (!proceduresState.isProcedure &&
          isPlaces &&
          places?.attributes &&
          !rolesState.loading) ||
        (proceduresState.isProcedure &&
          proceduresState.selectedProcedures?.length > 0))
    ) {
      const procedureIds =
        proceduresState?.selectedProcedures?.map((item) => item.id).join(",") ||
        "";
      const roleIds =
        rolesState?.selectedRoles?.map((item) => item.id).join(",") || "";
      fetchCaregivers(procedureIds, roleIds);
    }
  }, [
    fetchCaregivers,
    isPlaces,
    roleId,
    places?.attributes,
    proceduresState.isProcedure,
    proceduresState.selectedProcedures,
    rolesState.loading,
    rolesState?.selectedRoles,
    searchPage,
  ]);

  useEffect(() => {
    if (selectedProcedures.length === 0 && proceduresState.selectedProcedures)
      setSelectedProcedures(proceduresState.selectedProcedures);
  }, [proceduresState.selectedProcedures, selectedProcedures.length]);

  useEffect(() => {
    if (selectedRoles.length === 0 && rolesState.selectedRoles)
      setSelectedRoles(rolesState.selectedRoles);
  }, [rolesState.selectedRoles, selectedRoles.length]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(proceduresActions.setProcedure([]));
      dispatch(rolesActions.setRole({}));
      dispatch(googlePlacesActions.fetchGooglePlacesSuccess({}));
    };
  }, [dispatch]);

  return (
    <>
      <Header
        isSearch
        isStartPage={false}
        isPlaces={isPlaces}
        withoutBorder
        places={places}
        translations={translations}
      >
        <SearchHeader>
          <div>
            <StyledRow>
              <Col xs={24} md={10}>
                {global.innerWidth >= 994 && (
                  <Dropdown
                    disable={searchSlotsLoading}
                    searchable={[
                      `${translations.choose_treatments}`,
                      <React.Fragment key="no-results-1">
                        <p>{translations.no_results_search}</p>
                        <p>{translations.check_search_input}</p>
                      </React.Fragment>,
                    ]}
                    list={arrangeProceduresOptions || []}
                    toggleItem={toggleItemProcedures}
                    onSearch={handleChangeProceduresSearch}
                    value={proceduresState.search}
                    isLoading={proceduresState.searchLoading}
                    selectedItems={selectedProcedures}
                  />
                )}
              </Col>

              <Col xs={24} md={10}>
                {global.innerWidth >= 994 && (
                  <Dropdown
                    disable={searchSlotsLoading}
                    searchable={[
                      `${translations.choose_roles}`,
                      <React.Fragment key="no-results-2">
                        <p>{translations.no_results_search}</p>
                        <p>{translations.check_search_input}</p>
                      </React.Fragment>,
                    ]}
                    list={arrangeRolesOptions || []}
                    toggleItem={toggleItemRoles}
                    onSearch={handleChangeRolesSearch}
                    value={rolesState.search}
                    isLoading={rolesState.searchLoading}
                    selectedItems={selectedRoles}
                  />
                )}
              </Col>
            </StyledRow>
          </div>
          {mounted && (
            <div>
              <Row>
                {!roleId && !roleProcedureName && !places?.attributes && (
                  <MainCol />
                )}
                {!roleId && roleProcedureName && (
                  <SearchRow
                    scrollItemsHeight={scrollItemsHeight}
                    scrollFontSize={scrollFontSize}
                  >
                    {translations.book} {roleProcedureName?.toLowerCase() || ""}{" "}
                    {translations.direct_online_lower}
                  </SearchRow>
                )}
                {!!roleId && role?.attributes?.name && (
                  <SearchRow
                    scrollItemsHeight={scrollItemsHeight}
                    scrollFontSize={scrollFontSize}
                  >
                    {translations.book_time_with} {role?.attributes?.name}
                  </SearchRow>
                )}
                {places?.attributes && (
                  <SearchRow
                    scrollItemsHeight={scrollItemsHeight}
                    scrollFontSize={scrollFontSize}
                  >
                    {translations.dentist_and_dental_hygienists_within}{" "}
                    {placeName}
                  </SearchRow>
                )}
                {global.innerWidth >= 994 && (
                  <WrapperPosition
                    style={{
                      maxWidth: "39.5%",
                      width: "100%",
                      minWidth: "400px",
                      padding: "0 50px",
                    }}
                  >
                    <div>
                      <Wrapper
                        style={{
                          maxWidth: "39.5%",
                          width: "100%",
                          minWidth: "400px",
                          padding: "0 50px",
                        }}
                        goBack={goBack}
                        endDate={endDate}
                        startDate={startDate}
                        middleDate={middleDate}
                        goNext={goNext}
                        loading={searchSlotsLoading}
                      />
                    </div>
                  </WrapperPosition>
                )}
                {global.innerWidth < 994 && (
                  <div style={{ width: "100%", marginTop: "10px" }}>
                    <Wrapper
                      goBack={goBack}
                      startDate={startDate}
                      goNext={goNext}
                      loading={searchSlotsLoading}
                    />
                  </div>
                )}
              </Row>
            </div>
          )}
        </SearchHeader>
      </Header>
      <ContentContainer>
        {error && (
          <ErrorMessage
            error={error}
            errorPlaces={errorPlaces}
            translations={translations}
          />
        )}
        {!error && (
          <SearchContent>
            <Col>
              {!!searchSlots.results?.length &&
                searchSlots.results.map((item, index) => (
                  <CaregiverCard
                    isSearch
                    caregiversDataForAvatar={caregiversDataForAvatar}
                    goToDate={goToDate}
                    handleRadioChange={handleRadioChange}
                    item={item}
                    key={item?.id || index}
                    selectedProcedures={selectedProcedures}
                    slotsLoadBegin={slotsLoadBegin}
                    slotsLoadings={searchSlotsLoading}
                    startDate={startDate}
                    translations={translations}
                  />
                ))}
              {searchSlots.results &&
                searchSlots.results.length === 0 &&
                !searchSlotsLoading && (
                  <div>
                    <p>{translations.no_results_search}</p>
                    <p>{translations.check_search_input}</p>
                  </div>
                )}

              <MobileSection>
                {searchSlots?.meta && (
                  <Pagination
                    limit={10}
                    current={searchPage}
                    total={searchSlots.meta.pagination.total}
                    onPageChanged={onPageChange}
                  />
                )}
              </MobileSection>
            </Col>
          </SearchContent>
        )}
      </ContentContainer>
    </>
  );
};

Search.propTypes = {
  isPlaces: PropTypes.bool,
  roleId: PropTypes.string,
  onPageChange: PropTypes.func,
  placeName: PropTypes.string,
  role: PropTypes.object,
  roleProcedureName: PropTypes.string,
  translations: PropTypes.object,
};

export default memo(Search);
