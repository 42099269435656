import React, { memo } from "react";
import * as PropTypes from "prop-types";

import { ErrorContainer } from "../../container/index.styled";

const ErrorMessage = ({ error, translations }) => {
  if (error === "Network Error")
    return (
      <ErrorContainer>
        <b> {translations.generic_error_start} </b>{" "}
      </ErrorContainer>
    );
  if (error === "No caregivers")
    return (
      <ErrorContainer>
        <b> {translations.no_caregivers_found_error} </b>{" "}
      </ErrorContainer>
    );
  return (
    <ErrorContainer>
      <b> {translations.generic_error_start} </b>
    </ErrorContainer>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.string,
  translations: PropTypes.object,
};

export default memo(ErrorMessage);
