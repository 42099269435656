const prefix = "[bookings]";

const TYPES = {
  BOOKING_ATTENDEES_FETCH: `${prefix} BOOKING_ATTENDEES_FETCH`,
  BOOKING_ATTENDEES_FETCH_ERROR: `${prefix} BOOKING_ATTENDEES_FETCH_ERROR`,
  BOOKING_ATTENDEES_FETCH_SUCCESS: `${prefix} BOOKING_ATTENDEES_FETCH_SUCCESS`,
  BOOKING_DETAILS_FETCH: `${prefix} BOOKING_DETAILS_FETCH`,
  BOOKING_DETAILS_FETCH_ERROR: `${prefix} BOOKING_DETAILS_FETCH_ERROR`,
  BOOKING_DETAILS_FETCH_SUCCESS: `${prefix} BOOKING_DETAILS_FETCH_SUCCESS`,
  BOOKING_INVOICE_FETCH_BY_ID: `${prefix} BOOKING_INVOICE_FETCH_BY_ID`,
  BOOKING_INVOICE_FETCH_BY_ID_ERROR: `${prefix} BOOKING_INVOICE_FETCH_BY_ID_ERROR`,
  BOOKING_INVOICE_FETCH_BY_ID_SUCCESS: `${prefix} BOOKING_INVOICE_FETCH_BY_ID_SUCCESS`,
  BOOKING_INVOICES_CHANGE_PAGE: `${prefix} BOOKING_INVOICES_CHANGE_PAGE`,
  BOOKING_INVOICES_FETCH: `${prefix} BOOKING_INVOICES_FETCH`,
  BOOKING_INVOICES_FETCH_ERROR: `${prefix} BOOKING_INVOICES_FETCH_ERROR`,
  BOOKING_INVOICES_FETCH_SUCCESS: `${prefix} BOOKING_INVOICES_FETCH_SUCCESS`,
  BOOKING_PATIENT_RESPONSE: `${prefix} BOOKING_PATIENT_RESPONSE`,
  BOOKING_PATIENT_RESPONSE_ERROR: `${prefix} BOOKING_PATIENT_RESPONSE_ERROR`,
  BOOKING_PATIENT_RESPONSE_SUCCESS: `${prefix} BOOKING_PATIENT_RESPONSE_SUCCESS`,
  BOOKINGS_COMING_FETCH: `${prefix} BOOKINGS_COMING_FETCH`,
  BOOKINGS_COMING_FETCH_ERROR: `${prefix} BOOKINGS_COMING_FETCH_ERROR`,
  BOOKINGS_COMING_FETCH_SUCCESS: `${prefix} BOOKINGS_COMING_FETCH_SUCCESS`,
  BOOKINGS_LAST_CHANGE_PAGE: `${prefix} BOOKINGS_LAST_CHANGE_PAGE`,
  BOOKINGS_LAST_FETCH: `${prefix} BOOKINGS_LAST_FETCH`,
  BOOKINGS_LAST_FETCH_ERROR: `${prefix} BOOKINGS_LAST_FETCH_ERROR`,
  BOOKINGS_LAST_FETCH_SUCCESS: `${prefix} BOOKINGS_LAST_FETCH_SUCCESS`,
};

export const bookingActions = {
  ...TYPES,
  fetchComingBookingsList: () => ({
    type: TYPES.BOOKINGS_COMING_FETCH,
  }),
  fetchComingBookingsListSuccess: (data) => ({
    type: TYPES.BOOKINGS_COMING_FETCH_SUCCESS,
    payload: data,
  }),
  fetchComingBookingsListError: (error) => ({
    type: TYPES.BOOKINGS_COMING_FETCH_ERROR,
    payload: error,
  }),
  fetchLastBookingsList: (page) => ({
    type: TYPES.BOOKINGS_LAST_FETCH,
    payload: page,
  }),
  fetchLastBookingsListSuccess: (data) => ({
    type: TYPES.BOOKINGS_LAST_FETCH_SUCCESS,
    payload: data,
  }),
  fetchLastBookingsListError: (error) => ({
    type: TYPES.BOOKINGS_LAST_FETCH_ERROR,
    payload: error,
  }),
  changeLastBookingsPage: (page) => ({
    type: TYPES.BOOKINGS_LAST_CHANGE_PAGE,
    payload: page,
  }),
  fetchInvoicesList: (page) => ({
    type: TYPES.BOOKING_INVOICES_FETCH,
    payload: page,
  }),
  fetchInvoicesListSuccess: (data) => ({
    type: TYPES.BOOKING_INVOICES_FETCH_SUCCESS,
    payload: data,
  }),
  fetchInvoicesListError: (error) => ({
    type: TYPES.BOOKING_INVOICES_FETCH_ERROR,
    payload: error,
  }),
  changeInvoicesPage: (page) => ({
    type: TYPES.BOOKING_INVOICES_CHANGE_PAGE,
    payload: page,
  }),
  fetchInvoiceById: (id) => ({
    type: TYPES.BOOKING_INVOICE_FETCH_BY_ID,
    payload: id,
  }),
  fetchInvoiceByIdSuccess: (data) => ({
    type: TYPES.BOOKING_INVOICE_FETCH_BY_ID_SUCCESS,
    payload: data,
  }),
  fetchInvoiceByIdError: (error) => ({
    type: TYPES.BOOKING_INVOICE_FETCH_BY_ID_ERROR,
    payload: error,
  }),
  fetchBookingPatientResponse: (data) => ({
    type: TYPES.BOOKING_PATIENT_RESPONSE,
    payload: data,
  }),
  fetchBookingPatientResponseSuccess: (data) => ({
    type: TYPES.BOOKING_PATIENT_RESPONSE_SUCCESS,
    payload: data,
  }),
  fetchBookingPatientResponseError: (error) => ({
    type: TYPES.BOOKING_PATIENT_RESPONSE_ERROR,
    payload: error,
  }),
  fetchBookingDetails: (data) => ({
    type: TYPES.BOOKING_DETAILS_FETCH,
    payload: data,
  }),
  fetchBookingDetailsSuccess: (data) => ({
    type: TYPES.BOOKING_DETAILS_FETCH_SUCCESS,
    payload: data,
  }),
  fetchBookingDetailsError: (error) => ({
    type: TYPES.BOOKING_DETAILS_FETCH_ERROR,
    payload: error,
  }),
  fetchBookingAttendees: (data) => ({
    type: TYPES.BOOKING_ATTENDEES_FETCH,
    payload: data,
  }),
  fetchBookingAttendeesSuccess: (data) => ({
    type: TYPES.BOOKING_ATTENDEES_FETCH_SUCCESS,
    payload: data,
  }),
  fetchBookingAttendeesError: (error) => ({
    type: TYPES.BOOKING_ATTENDEES_FETCH_ERROR,
    payload: error,
  }),
};
