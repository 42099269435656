import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { addHours, startOfDay } from "date-fns";
import * as PropTypes from "prop-types";

import { formatDate, formatHours } from "helpers/helper-functions";
import { selectTag } from "redux/app/selectors";

import { Row, Text } from "../../common.styled";
import {
  CenterRow,
  DateTitle,
  HoursButton,
  Inner,
  NextButton,
} from "./reschedule.styled";

import { Spinner } from "../../common/spinner/index.styled";

const RescheduleSlotsMobile = ({
  goToDate,
  translations,
  setSelectedSlot,
  setStep,
  slots,
  slotsLoading,
  startDate,
}) => {
  const locale = useSelector(selectTag);

  const emptyRow = (index) => (
    <DateTitle key={index}>
      <span>-</span>
    </DateTitle>
  );

  const getActiveButton = useCallback(
    (hours, slotsData) => (
      <span>
        <HoursButton
          style={{ display: "inline" }}
          onClick={() => {
            setSelectedSlot(slotsData);
            setStep(1);
          }}
          primary
        >
          {hours}
        </HoursButton>
      </span>
    ),
    [setSelectedSlot, setStep],
  );

  const renderDateRow = useCallback(() => {
    const sortedArray = [...(slots?.free_bookable_slots || [])].sort(
      (a, b) => b.attributes.dtstart - a.attributes.dtstart,
    );
    const startDateSlots = sortedArray?.filter(
      (item) =>
        formatDate(item.attributes.dtstart, "dd/MM/uuuu") ===
        formatDate(startDate, "dd/MM/uuuu"),
    );

    return (
      <Row>
        {startDateSlots.map((item) => (
          <DateTitle style={{ padding: "5px 5px", margin: 0 }} key={item.id}>
            {startDate
              ? getActiveButton(
                  formatHours(item.attributes.dtstart, "H:mm"),
                  item,
                )
              : emptyRow}
          </DateTitle>
        ))}
      </Row>
    );
  }, [getActiveButton, slots?.free_bookable_slots, startDate]);

  return (
    <div style={{ position: "relative", width: "100%", height: "100vh" }}>
      {!slotsLoading &&
        slots?.free_bookable_slots?.length > 0 &&
        renderDateRow()}
      {!slotsLoading &&
        slots?.free_bookable_slots?.length === 0 &&
        slots?.next_free_bookable_slot && (
          <CenterRow style={{ margin: 0 }}>
            <NextButton
              style={{ margin: "32px 0 0" }}
              onClick={() =>
                goToDate(
                  addHours(
                    startOfDay(
                      new Date(
                        slots?.next_free_bookable_slot?.attributes?.dtstart,
                      ),
                    ),
                    2,
                  ),
                )
              }
            >
              <Inner style={{ margin: "auto" }}>
                {`${translations.r_next_available_time}: ${formatDate(
                  slots.next_free_bookable_slot.attributes.dtstart,
                  "d MMM",
                  locale,
                ).replace(".", "")}`}
              </Inner>
            </NextButton>
          </CenterRow>
        )}

      {!slotsLoading &&
        (!slots ||
          (slots &&
            slots.free_bookable_slots.length === 0 &&
            !slots.next_free_bookable_slot)) && (
          <CenterRow style={{ margin: 0 }}>
            <Text>No available times to book</Text>
          </CenterRow>
        )}

      {slotsLoading && (
        <div>
          <CenterRow style={{ margin: 0 }}>
            <Spinner />{" "}
          </CenterRow>
          <CenterRow style={{ marginTop: "19.5px", fontSize: "13px" }}>
            {translations.r_checking_available_times}
          </CenterRow>
        </div>
      )}
    </div>
  );
};

RescheduleSlotsMobile.propTypes = {
  goToDate: PropTypes.func,
  translations: PropTypes.object,
  setSelectedSlot: PropTypes.func,
  setStep: PropTypes.func,
  slots: PropTypes.object,
  slotsLoading: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
};

export default memo(RescheduleSlotsMobile);
