import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as PropTypes from "prop-types";
import {
  FakeSearchContainer,
  FakeSearchDiv,
  FakeSearchSpan,
} from "style/global-styled-components.styled";

import GeoLocationSvgMobile from "assets/svg-components/GeoLocationMobile";
import LeftArrowSVG from "assets/svg-components/LeftArrow";
import SearchSvg from "assets/svg-components/Search";
import { arrangeCaregiversOptions } from "helpers/arrange-caregivers-options";
import { arrangePlacesOptions } from "helpers/arrange-places-options";
import { useDebounce } from "hooks/use-debounce";
import { useSignInValidation } from "hooks/use-sign-in-validation";
import { caregiversActions } from "redux/caregivers/actions";
import { googlePlacesActions } from "redux/google-places/actions";

import { Modal } from "../common/modal";

import { LeftSearch, RightSearch } from "./index.styled";

const SearchModal = ({ onClose, translations }) => {
  const caregiversState = useSelector((state) => state.caregivers);
  const placesState = useSelector((state) => state.googlePlaces);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();

  const [optionsCaregiver, setOptionsCaregiver] = useState([]);
  const [optionsPlaces, setOptionsPlaces] = useState([]);

  const [isCaregiverSearchSelected, setIsCaregiverSearchSelected] =
    useState(false);
  const [isLocationSearchSelected, setIsLocationSearchSelected] =
    useState(false);

  const toggleCaregiverSearchBar = () =>
    setIsCaregiverSearchSelected(!isCaregiverSearchSelected);
  const toggleLocationSearchBar = () =>
    setIsLocationSearchSelected(!isLocationSearchSelected);

  const pageName = useMemo(() => {
    if (pathname.includes("clinics")) return "clinics";
    if (pathname.includes("caregivers")) return "caregivers";
    if (pathname.includes("clinic-directory")) return "clinic-directory";
    if (pathname.includes("clinic")) return "clinic";
    if (pathname.includes("caregiver")) return "caregiver";
    if (pathname.includes("place")) return "place";
    if (pathname.includes("role")) return "role";
    return pathname.split("/")[1];
  }, [pathname]);

  const handleChangeCaregiversSearch = useCallback(
    (value) => {
      dispatch(caregiversActions.searchCaregiversChange(value));
    },
    [dispatch],
  );

  const handleChangePlacesSearch = useCallback(
    (value) => {
      dispatch(googlePlacesActions.searchGooglePlacesChange(value));
    },
    [dispatch],
  );

  const searchCaregiversCb = useCallback(() => {
    if (caregiversState?.search?.length >= 3) {
      dispatch(
        caregiversActions.searchCaregivers(caregiversState?.search, pageName),
      );
    }
  }, [caregiversState?.search, dispatch, pageName]);

  const searchGooglePlacesCb = useCallback(() => {
    if (placesState?.search?.search?.length >= 3) {
      dispatch(
        googlePlacesActions.searchGooglePlaces(
          placesState?.search?.search,
          placesState?.search?.isAutocomplete,
          pageName,
        ),
      );
    }
  }, [
    dispatch,
    placesState?.search?.isAutocomplete,
    placesState?.search?.search,
    pageName,
  ]);

  useDebounce(searchCaregiversCb, [], 400);
  useDebounce(searchGooglePlacesCb, [], 400);

  useSignInValidation();

  useEffect(() => {
    if (caregiversState?.searchResult)
      setOptionsCaregiver(
        arrangeCaregiversOptions(
          caregiversState.searchResult,
          dispatch,
          history,
        ),
      );
  }, [caregiversState?.searchResult, dispatch, history]);

  useEffect(() => {
    if (placesState?.searchResult?.data)
      setOptionsPlaces(
        arrangePlacesOptions(placesState?.searchResult, dispatch, history),
      );
  }, [dispatch, history, placesState, placesState?.searchResult]);

  return (
    <Modal isMobileSearchModal onClose={onClose}>
      <FakeSearchContainer style={{ padding: "0 20px" }}>
        {!isCaregiverSearchSelected && !isLocationSearchSelected && (
          <>
            <div className="searchModalHeader flexWidth100">
              <button className="ghostButton NoPadding" onClick={onClose}>
                <LeftArrowSVG />
              </button>
              <h1 className="MobileSearchModalHeader">Sök</h1>
            </div>

            <FakeSearchDiv
              style={{ marginTop: "0px" }}
              onClick={toggleCaregiverSearchBar}
            >
              <div className="SVGContainer">
                <SearchSvg stylingPropClassName="GreyFill" />
              </div>
              <FakeSearchSpan>
                {translations.header_search_care_placeholder}
              </FakeSearchSpan>
            </FakeSearchDiv>
            <FakeSearchDiv onClick={toggleLocationSearchBar}>
              <div className="SVGContainer">
                <GeoLocationSvgMobile stylingPropClassName="GreyFill" />
              </div>
              <FakeSearchSpan>
                {translations.header_search_placeholder_text}
              </FakeSearchSpan>
            </FakeSearchDiv>
          </>
        )}
        {isCaregiverSearchSelected && (
          <>
            <div className="searchModalHeader flexWidth100">
              <button className="ghostButton NoPadding" onClick={onClose}>
                <LeftArrowSVG />
              </button>
              <h1 className="MobileSearchModalHeader">Sök</h1>
            </div>

            <LeftSearch
              autoFocus
              className="MobileModalSearchBar NoPadding"
              leftSearch
              value={caregiversState.search || ""}
              handleSearchChange={handleChangeCaregiversSearch}
              options={optionsCaregiver}
              isCaregiverSearchBar
              isLoading={caregiversState.searchLoading}
              placeholder={translations.header_search_care_placeholder}
              validText={translations.header_search_care_validText}
              onClose={onClose}
            />
          </>
        )}
        {isLocationSearchSelected && (
          <>
            <div className="searchModalHeader flexWidth100">
              <button className="ghostButton NoPadding" onClick={onClose}>
                <LeftArrowSVG />
              </button>
              <h1 className="MobileSearchModalHeader">
                {translations.header_search_area_text}
              </h1>
            </div>

            <RightSearch
              autoFocus
              className="MobileModalSearchBar NoPadding"
              value={placesState.search?.search || ""}
              handleSearchChange={handleChangePlacesSearch}
              options={optionsPlaces}
              isLoading={placesState.searchLoading}
              placeholder={translations.header_search_placeholder_text}
              validText={translations.header_search_valid_text}
            />
          </>
        )}
      </FakeSearchContainer>
    </Modal>
  );
};

SearchModal.propTypes = {
  onClose: PropTypes.func,
  translations: PropTypes.object,
};

export default memo(SearchModal);
