import styled from "styled-components";

import { Col, Row } from "components/common.styled";

export const ColMobile = styled.div`
  margin-bottom: 20px;
`;

export const TransitionWrapper = styled.div`
  opacity: 1;
  transition: 150ms opacity, 150ms transform;
  width: 100%;
  ${({ referralSourceEqualStatus }) =>
    referralSourceEqualStatus && `transform: translateY(0px);`};
  &.hided {
    opacity: 0;
    position: absolute;
    transform: translateY(-100%);
    z-index: -1;
  }
`;

export const InnerContainer = styled.div`
  margin: 0 auto 200px;
  max-width: 1120px !important;
`;

export const RatingRow = styled(Row)`
  padding: 12px 0;
  @media (max-width: 993px) {
    padding: 0;
    ${({ padding }) => padding && `padding-top: 76px;`}
  }
`;

export const StyledMapCol = styled(Col)`
  margin-top: 32px;
  @media (max-width: 993px) {
    margin-top: 24px;
  }
`;
