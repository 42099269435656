import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addDays, differenceInMinutes, endOfDay, parseISO } from "date-fns";
import * as PropTypes from "prop-types";
import { BookingAttendeesService } from "services/booking-attendees-service";
import { BookingsService } from "services/bookings-service";

import defaultUserImage from "assets/images/defaultUserIcon.png";
import {
  formatDate,
  formatHours,
  getCaregiverFullName,
  toDate,
} from "helpers/helper-functions";
import { useDebounce } from "hooks/use-debounce";
import { selectTag } from "redux/app/selectors";
import { caregiversActions } from "redux/caregivers/actions";

import {
  Avatar,
  MobileSection,
  Row,
  StyledText,
  Text,
  ThirdHeading,
  TransitionStep,
} from "../../common.styled";
import {
  ButtonCompleteContainer,
  ButtonConfirmContainer,
  RescheduleErrorContainer,
  StyledHeading,
  StyledSpinner,
  StyledStretchButton,
} from "./reschedule.styled";
import RescheduleContent from "./reschedule-content";

const Reschedule = ({
  bookingData = {},
  caregiverData = {},
  onComplete,
  translations,
  authenticateUser,
  authenticationId,
}) => {
  const WIDTH_BREAK = 994;

  const dispatch = useDispatch();

  const { loading, error, slots } = useSelector((state) => state.caregivers);

  const [rescheduleLoader, setRescheduleLoader] = useState(false);
  const [rescheduleComplete, setRescheduleComplete] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [currentClinic, setCurrentClinic] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [debouncedStartDate, setDebouncedStartDate] = useState(startDate);
  const locale = useSelector(selectTag);

  const fullName = getCaregiverFullName(caregiverData);

  const caregiverId = caregiverData?.user?.id;
  const role = caregiverData?.user?.role?.attributes?.name;
  const clinicId = bookingData?.relationships?.clinic?.data?.id;
  const {
    clinic_address_1: clinicAddress1 = "",
    clinic_name: clinicName = "",
    clinic_postal_code: clinicPostalCode = "",
    clinic_city: clinicCity = "",
  } = currentClinic?.attributes || {};

  const endDateInterval = global.innerWidth < WIDTH_BREAK ? 0 : 2;
  const timeStep = global.innerWidth < WIDTH_BREAK ? 1 : 3;

  const newFormatedDate = useMemo(
    () =>
      selectedSlot?.attributes &&
      `${formatHours(
        selectedSlot?.attributes?.dtstart,
        "H:mm EEEE 'den' d MMMM",
        locale,
      )}`,
    [locale, selectedSlot?.attributes],
  );

  const slotDuration = useMemo(
    () =>
      differenceInMinutes(
        parseISO(bookingData?.attributes?.dtend || ""),
        parseISO(bookingData?.attributes?.dtstart || ""),
      ),
    [bookingData?.attributes],
  );

  const goToDate = useCallback((date) => {
    toDate(date, setStartDate);
  }, []);

  const getEndDate = useCallback(
    () => addDays(endOfDay(debouncedStartDate), endDateInterval),
    [endDateInterval, debouncedStartDate],
  );

  const avatarProps = useMemo(
    () => ({
      size: global.innerWidth < WIDTH_BREAK ? 44 : 64,
      alt: fullName,
    }),
    [fullName],
  );

  const getClassName = useCallback(
    (i) => {
      if (step === i) return "";
      return step > i ? "previous" : "next";
    },
    [step],
  );

  const handleClickReschedule = useCallback(async () => {
    setRescheduleLoader(true);
    if (authenticateUser) {
      await BookingsService.rescheduleBooking(bookingData.id, {
        params: {
          new_dtstart: formatDate(
            formatHours(selectedSlot?.attributes?.dtstart),
          ),
        },
      });
    } else {
      await BookingAttendeesService.rescheduleBookingAttendees(
        authenticationId,
        {
          params: {
            new_dtstart: formatDate(
              formatHours(selectedSlot?.attributes?.dtstart),
            ),
          },
        },
      );
    }
    setRescheduleLoader(false);
    setRescheduleComplete(true);
  }, [
    authenticateUser,
    authenticationId,
    bookingData.id,
    selectedSlot?.attributes?.dtstart,
  ]);

  const debounceDateCb = useCallback(() => {
    if (startDate !== debouncedStartDate) {
      setDebouncedStartDate(startDate);
    }
  }, [debouncedStartDate, startDate]);

  useDebounce(debounceDateCb, [], 400);

  useEffect(() => {
    if (caregiverId && clinicId) {
      dispatch(
        caregiversActions.fetchCaregiverSlots({
          clinicId,
          id: caregiverId,
          procedureId: "",
          isNewPatient: false,
          dateStart: formatDate(debouncedStartDate),
          dateEnd: formatDate(getEndDate()),
          durationInMinutes: slotDuration,
        }),
      );
    }
  }, [
    caregiverId,
    clinicId,
    debouncedStartDate,
    dispatch,
    getEndDate,
    slotDuration,
  ]);

  useEffect(() => {
    return () => {
      dispatch(caregiversActions.clearCaregiverError());
    };
  }, [dispatch]);

  return (
    <div>
      {!error && !rescheduleLoader && !rescheduleComplete && (
        <>
          <StyledHeading>{translations.reschedule_new_time}</StyledHeading>
          <Row style={{ margin: "20px 0" }}>
            {!caregiverData?.user?.default_user_image?.attributes
              ?.small_thumbnail && (
              <Avatar
                loading="lazy"
                style={{ margin: 0 }}
                {...avatarProps}
                src={defaultUserImage}
              />
            )}
            {caregiverData?.user?.default_user_image?.attributes
              ?.small_thumbnail && (
              <Avatar
                loading="lazy"
                style={{ margin: 0 }}
                {...avatarProps}
                src={`${caregiverData.user.default_user_image.attributes.small_thumbnail}`}
              />
            )}
            <div style={{ marginLeft: "10px" }}>
              <ThirdHeading style={{ marginBottom: "5px" }}>
                {" "}
                {fullName}{" "}
              </ThirdHeading>
              <StyledText> {role} </StyledText>
            </div>
          </Row>
          {currentClinic?.attributes && (
            <MobileSection style={{ padding: "0 0 16px" }}>
              <ThirdHeading style={{ marginTop: "20px" }}>
                {translations.reschedule_place}
              </ThirdHeading>
              <Text style={{ margin: 0 }}>{clinicName}</Text>
              <Text
                style={{ margin: 0 }}
                itemScope
                itemType="https://schema.org/PostalAddress"
              >
                {`${clinicAddress1}`}
              </Text>
              <Text style={{ margin: 0 }}>
                {" "}
                {`${clinicPostalCode} ${clinicCity}`}
              </Text>
            </MobileSection>
          )}
          {!loading && (
            <TransitionStep className={getClassName(0)}>
              {slots?.caregiver && (
                <RescheduleContent
                  bookingData={bookingData}
                  caregiverId={caregiverId}
                  clinicId={clinicId}
                  goToDate={goToDate}
                  setCurrentClinic={setCurrentClinic}
                  setSelectedSlot={setSelectedSlot}
                  setStep={setStep}
                  slots={slots}
                  startDate={startDate}
                  timeStep={timeStep}
                  translations={translations}
                />
              )}
            </TransitionStep>
          )}
          {!loading && !rescheduleComplete && (
            <TransitionStep className={getClassName(1)}>
              <ThirdHeading>
                {translations.reschedule_confirm_time}
              </ThirdHeading>
              <Text> {newFormatedDate} </Text>
              <ButtonConfirmContainer>
                {global.innerWidth >= WIDTH_BREAK && (
                  <>
                    <StyledStretchButton
                      style={{ border: "none" }}
                      secondary
                      onClick={() => setStep(0)}
                    >
                      {" "}
                      <span style={{ margin: "auto" }}>
                        {translations.reschedule_back}
                      </span>
                    </StyledStretchButton>
                    <StyledStretchButton onClick={handleClickReschedule}>
                      <span style={{ margin: "auto" }}>
                        {translations.reschedule_confirm_new_time}
                      </span>
                    </StyledStretchButton>
                  </>
                )}
                {global.innerWidth < WIDTH_BREAK && (
                  <>
                    <StyledStretchButton
                      style={{ order: 0, marginTop: "30px" }}
                      onClick={handleClickReschedule}
                    >
                      <span style={{ margin: "auto" }}>
                        {translations.reschedule_confirm_new_time}
                      </span>
                    </StyledStretchButton>
                    <StyledStretchButton
                      style={{ order: 1, marginTop: "10px", border: "none" }}
                      secondary
                      onClick={() => setStep(0)}
                    >
                      <span style={{ margin: "auto" }}>
                        {translations.reschedule_back}
                      </span>
                    </StyledStretchButton>
                  </>
                )}
              </ButtonConfirmContainer>
            </TransitionStep>
          )}
          {loading && (
            <div style={{ width: "100%", height: "100%" }}>
              {" "}
              <StyledSpinner />{" "}
            </div>
          )}
        </>
      )}
      {!error && rescheduleLoader && !rescheduleComplete && (
        <StyledSpinner style={{ margin: "100px auto" }} />
      )}
      {!error && !rescheduleLoader && rescheduleComplete && (
        <>
          <StyledHeading
            style={{ fontSize: "28px", lineHeight: "30px", textAlign: "left" }}
          >
            {translations.reschedule_your_time_is_rebooked}
          </StyledHeading>
          <br />
          <p>{`${translations.reschedule_welcome_to} ${clinicName} ${translations.reschedule_time}. ${newFormatedDate}. ${translations.reschedule_no_confirmation_needed}`}</p>
          <ButtonCompleteContainer>
            <StyledStretchButton onClick={onComplete}>
              <span style={{ margin: "0 auto" }}>
                {translations.reschedule_close}
              </span>
            </StyledStretchButton>
          </ButtonCompleteContainer>
        </>
      )}
      {error && (
        <RescheduleErrorContainer>
          <b>{translations.reschedule_render_error}</b>
        </RescheduleErrorContainer>
      )}
    </div>
  );
};

Reschedule.propTypes = {
  bookingData: PropTypes.object,
  caregiverData: PropTypes.object,
  onComplete: PropTypes.func,
  translations: PropTypes.object,
  authenticateUser: PropTypes.bool,
  authenticationId: PropTypes.string,
};

Reschedule.defaultProps = {
  bookingData: {},
  caregiverData: {},
};

export default memo(Reschedule);
