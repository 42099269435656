import React, { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";

import { slugifyer } from "helpers/helper-functions";
import { caregiversActions } from "redux/caregivers/actions";

import Calendar from "components/calendar";
import { Inner, NextButton } from "components/calendar/index.styled";
import { Col, MobileSection, Text } from "components/common.styled";

import { CalendarContainer, ClinicButton } from "../schedule/index.styled";

const ScheduleItem = ({
  caregiver,
  defaultProcedure,
  location,
  slotsObject,
  dateObject,
  translations,
}) => {
  const {
    clinic_address_1: clinicAddress,
    clinic_name: clinicName,
    clinic_postal_code: clinicPostalCode,
    clinic_city: clinicCity,
  } = location.clinic?.attributes || {};
  const { slots, slotsLoadBegin, slotsLoading, getSlotsForPeriod } =
    slotsObject || {};
  const history = useHistory();
  const dispatch = useDispatch();

  const { nextSlotPeriod } = useSelector((state) => state.caregivers);

  const getNextPeriodValue = useCallback(() => {
    if (nextSlotPeriod === 2) return 6;
    if (nextSlotPeriod === 6) return 12;
    if (nextSlotPeriod === 12) return 24;
  }, [nextSlotPeriod]);

  const handleClick = useCallback(() => {
    dispatch(caregiversActions.incrementPeriod(getNextPeriodValue()));
    getSlotsForPeriod();
  }, [dispatch, getNextPeriodValue, getSlotsForPeriod]);

  const noSlots = useMemo(
    () =>
      nextSlotPeriod !== 24 ? (
        <>
          <div style={{ marginBottom: 15, maxWidth: 150, textAlign: "center" }}>
            {`${translations.schedule_item_no_times_coming} ${nextSlotPeriod} ${translations.schedule_items_months}.`}
          </div>
          <NextButton onClick={() => handleClick()}>
            <Inner>{`${
              translations.schedule_item_check_coming_times
            } ${getNextPeriodValue()} ${
              translations.schedule_items_months
            }`}</Inner>
          </NextButton>
        </>
      ) : (
        <div>{translations.schedule_item_no_bookable_times}</div>
      ),
    [getNextPeriodValue, handleClick, nextSlotPeriod, translations],
  );

  return (
    <Col>
      <MobileSection style={{ padding: "0 0 16px" }}>
        <ClinicButton
          onClick={() =>
            history.push(`/${slugifyer(clinicName)}/c/${location.clinic.id}`)
          }
          link
        >
          <Link to={`/${slugifyer(clinicName)}/c/${location.clinic.id}`}>
            {clinicName}
          </Link>
        </ClinicButton>
        <Text
          itemScope
          itemType="https://schema.org/PostalAddress"
        >{`${clinicAddress}, ${clinicPostalCode} ${clinicCity}`}</Text>
      </MobileSection>
      <CalendarContainer>
        <Calendar
          caregiver={caregiver}
          clinic={location.clinic}
          defaultProcedure={defaultProcedure}
          location={location}
          translations={translations}
          dateObject={dateObject}
          caregiverAtLocationObject={{
            caregiverAtLocation: slots,
            caregiverAtLocationLoadBegin: slotsLoadBegin,
            caregiverAtLocationLoading: slotsLoading,
            noCaregiverAtLocation: noSlots,
          }}
        />
      </CalendarContainer>
    </Col>
  );
};

ScheduleItem.propTypes = {
  caregiver: PropTypes.object,
  defaultProcedure: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  location: PropTypes.object,
  slotsObject: PropTypes.object,
  dateObject: PropTypes.object,
  translations: PropTypes.object,
};

export default memo(ScheduleItem);
