import React, { memo } from "react";
import * as PropTypes from "prop-types";

import CelebrationIcon from "assets/svg-components/CelebrationIcon";

const HealthDeclarationHeader = ({
  headerTitle,
  headerHeight = "",
  subHeader = "",
  showCelebrationIcon = false,
}) => {
  return (
    <div
      className="FlexColJustCenter"
      style={{
        height: headerHeight,
        alignItems: showCelebrationIcon ? "center" : "",
        width: showCelebrationIcon ? "350px" : "",
      }}
    >
      {showCelebrationIcon && <CelebrationIcon />}
      <h2
        className={`bookingHeaderTextStyle textCenter bookingHeader noMargin ${
          showCelebrationIcon ? "" : "FlexColJustCenterBookingHeader"
        }`}
      >
        {headerTitle}
      </h2>
      {subHeader && (
        <p style={{ marginTop: "15px", width: "300px" }}>{subHeader}</p>
      )}
    </div>
  );
};

HealthDeclarationHeader.propTypes = {
  headerTitle: PropTypes.string,
  headerHeight: PropTypes.string,
  subHeader: PropTypes.string,
  showCelebrationIcon: PropTypes.bool,
};

HealthDeclarationHeader.defaultProps = {
  headerHeight: "",
  subHeader: "",
};

export default memo(HealthDeclarationHeader);
