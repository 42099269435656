import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import config from "config";
import { useTranslations } from "hooks/use-translations";

import { StyledContent, StyledLayout } from "components/common.styled";
import Footer from "components/footer";
import Header from "components/header";

import BotoxContent from "../components/botox-content";

const ROUTE_NAME = "botox";

const Botox = () => {
  const location = useLocation();
  const translations = useTranslations(ROUTE_NAME);

  return (
    <StyledLayout>
      <Header translations={translations} isStartPage={false} />
      <Helmet>
        <title>Botox: Att veta innan du bokar behandling</title>
        <meta
          id="meta-description"
          name="description"
          content="Konkret och praktisk guide för botox och fillers."
        />
        <link
          id="canonical-link"
          rel="canonical"
          href={config.url + location.pathname.replace(/\?.*$/gm, "")}
        />
      </Helmet>
      <StyledContent>
        <BotoxContent />
      </StyledContent>
      <Footer translations={translations} />
    </StyledLayout>
  );
};

export default Botox;
