import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as PropTypes from "prop-types";

import defaultUserImage from "assets/images/defaultUserIcon.png";
import { COUNTRY_CODES } from "constants/countries";
import { formatHours, slugifyer } from "helpers/helper-functions";
import { validateBookingField } from "helpers/validators";
import { selectTag } from "redux/app/selectors";
import { selectPersonalId } from "redux/auth/selectors";
import { caregiversActions } from "redux/caregivers/actions";

import { FIELD_ERROR_EXCEPTIONS } from "../../../../constants/booking";
import {
  Avatar,
  Col,
  Heading,
  InputContainer,
  MainSpinner,
  Row,
  SecondHeading,
  StyledInput,
  ThirdHeading,
} from "../../../common.styled";
import { StretchButton } from "../../../common/button";
import MissingBookingFields from "../components/MissingBookingFields";

import { Spinner } from "../../../common/spinner/index.styled";
import { NextButton } from "../../index.styled";
import {
  CaregiverCardMobileStyled,
  CaregiverCardStyled,
  LocalHeader,
  TextStyled,
} from "./index.styled";

const ReviewContent = ({
  bookingErrors,
  bookingIsTentative,
  bookingLoader,
  bookingRequiresTwoFactorAuthentication,
  bookSlot,
  defaultProcedure,
  translations,
}) => {
  const [errors, setErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
    personalId: "",
    phoneNumber: "",
  });
  const [missedValues, setMissedValues] = useState({});
  const [personalId, setPersonalId] = useState("");

  const {
    caregiverDataForBooking = {},
    loading,
    selectedSlot = {},
  } = useSelector((state) => state.caregivers);
  const auth = useSelector((state) => state.auth);
  const { country } = useSelector((state) => state.clinics.clinic || {});
  const authPersonalId = useSelector(selectPersonalId);
  const locale = useSelector(selectTag);

  const dispatch = useDispatch();
  const history = useHistory();

  const { caregiver = {} } = caregiverDataForBooking;
  const {
    calling_code: callingCode,
    code: countryCode,
    personal_id_placeholder: personalIdPlaceholder,
    personal_id_regex: personalIdValidator,
  } = country?.attributes || {};
  const currentYear = useMemo(() => new Date().getFullYear().toString(), []);
  const pastCenturyYear = useMemo(() => new Date().getFullYear() - 100, []);

  useEffect(() => {
    return () => {
      dispatch(caregiversActions.clearCaregiverForBooking());
    };
  }, [dispatch]);

  const errorMessage = useMemo(() => {
    const uniqueErrors = new Set(Object.values(errors));
    return Array.from(uniqueErrors)
      .filter((err) => err !== "")
      .join("\n")
      .trim();
  }, [errors]);

  const bookingErrorDetails = useMemo(() => {
    return bookingErrors.reduce((acumm, item) => {
      const field = item?.source?.pointer?.split("/").pop() || "";
      if (!FIELD_ERROR_EXCEPTIONS.includes(field)) {
        acumm.push(item.detail);
      }
      return acumm;
    }, []);
  }, [bookingErrors]);

  const mapOutBookingErrorDetails = useCallback((bookingErrorDetails) => {
    return bookingErrorDetails.map((element) => {
      return (
        <p key={element} style={{ color: "red" }}>
          {element}
        </p>
      );
    });
  }, []);

  const validateField = useCallback(
    ({ field, value }) => {
      return validateBookingField({
        field,
        setError: (error) =>
          setErrors((state) => ({ ...state, [field]: error })),
        translations,
        value,
        ...(bookingRequiresTwoFactorAuthentication
          ? {}
          : { personalIdValidator }),
      });
    },
    [bookingRequiresTwoFactorAuthentication, personalIdValidator, translations],
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const formFields = Object.keys(missedValues);
      if (!bookingRequiresTwoFactorAuthentication && !authPersonalId) {
        formFields.push("personalId");
      }

      const isValid = formFields.every((field) => {
        const value = field === "personalId" ? personalId : missedValues[field];
        return validateField({ field, value });
      });

      if (isValid) {
        bookSlot({ personalId: personalId || authPersonalId, ...missedValues });
      }
    },
    [
      authPersonalId,
      bookSlot,
      bookingRequiresTwoFactorAuthentication,
      missedValues,
      personalId,
      validateField,
    ],
  );

  const handleBlur = useCallback(
    ({ target }) => {
      const { name } = target;
      const value = name === "personalId" ? personalId : missedValues[name];
      validateField({ field: name, value });
    },
    [personalId, missedValues, validateField],
  );

  const handleChangePersonalId = useCallback(
    (e) => {
      const { value } = e.target;
      const valueStartsWithRelevantCurrentCenturyDecadeDigit =
        value[0] <= currentYear.charAt(2);
      const valueStartsWithRelevantMilleniumDigit =
        value[0] === pastCenturyYear.toString().charAt(0) ||
        value[0] === currentYear.charAt(0);

      setPersonalId(value.replace(/\D/g, ``));
      if (
        !valueStartsWithRelevantMilleniumDigit &&
        countryCode === COUNTRY_CODES.sweden
      ) {
        if (valueStartsWithRelevantCurrentCenturyDecadeDigit) {
          setPersonalId(currentYear.charAt(0) + currentYear.charAt(1) + value);
        } else if (value !== "") {
          setPersonalId(
            pastCenturyYear.toString().charAt(0) +
              pastCenturyYear.toString().charAt(1) +
              value,
          );
        }
      }
    },
    [countryCode, currentYear, pastCenturyYear],
  );

  const renderBook = () => {
    const { slot } = selectedSlot;

    const { clinic = {} } = caregiverDataForBooking;
    const isAutoApprove =
      caregiverDataForBooking?.attributes?.auto_approve_patient_booking;

    const firstName = caregiver.attributes?.first_name;
    const lastName = caregiver.attributes?.last_name;
    const fullName = `${firstName} ${lastName}`;
    const fullNamelink = `${firstName}-${lastName}`;
    const caregiverRole = `${caregiver.role?.attributes?.name}`;
    const clinicAddress1 =
      clinic &&
      `${clinic.attributes?.clinic_address_1} ${clinic.attributes?.clinic_address_2}`;
    const clinicAddress2 =
      clinic &&
      `${clinic.attributes?.clinic_postal_code} ${clinic.attributes?.clinic_city}`;
    const slotDate = `${formatHours(
      slot.attributes?.dtstart,
      "EEEE d MMMM uuuu 'kl.' H:mm",
      locale,
    )}-${formatHours(slot.attributes?.dtend, "H:mm")}`;
    const parseSlotDate =
      slotDate && slotDate[0].toUpperCase() + slotDate.slice(1);
    const patientName = `${
      auth?.authUserPersonalId?.data?.attributes?.first_name || ""
    } ${auth?.authUserPersonalId?.data?.attributes?.last_name || ""}`;
    const link = `/${slugifyer(caregiverRole)}/${slugifyer(fullNamelink)}/p/${
      caregiver.id
    }`;

    const avatarProps = {
      size: global.innerWidth < 994 ? 44 : 64,
      alt: fullName,
    };

    const buttonText =
      !bookingIsTentative && isAutoApprove
        ? `${translations.review_content_book_time}`
        : `${translations.review_content_confirm_booking_req}`;

    if (global.innerWidth >= 994 || !bookingRequiresTwoFactorAuthentication)
      return (
        <>
          <Heading
            style={{ fontSize: "28px", lineHeight: "30px", textAlign: "left" }}
          >
            {buttonText}
          </Heading>
          <Row style={{ maxWidth: "490px" }}>
            <Col style={{ maxWidth: "290px", margin: "50px auto" }}>
              <form id="formElem" onSubmit={handleSubmit}>
                {Boolean(errorMessage) && (
                  <p
                    style={{
                      color: "red",
                      whiteSpace: "pre-line",
                      textAlign: "left",
                    }}
                  >
                    {errorMessage}
                  </p>
                )}
                {bookingErrorDetails &&
                  mapOutBookingErrorDetails(bookingErrorDetails)}
                {bookingRequiresTwoFactorAuthentication || authPersonalId ? (
                  <TextStyled
                    style={{
                      border: "solid 1px lightgrey",
                      padding: 10,
                      textAlign: "start",
                      marginBottom: "10px",
                    }}
                  >
                    {parseSlotDate}
                  </TextStyled>
                ) : (
                  <>
                    <InputContainer style={{ maxWidth: "300px" }}>
                      <StyledInput
                        autoComplete="off"
                        maxLength="12"
                        name="personalId"
                        placeholder={personalIdPlaceholder}
                        onBlur={handleBlur}
                        onChange={handleChangePersonalId}
                        type="tel"
                        value={personalId}
                      />
                    </InputContainer>
                    <br />
                  </>
                )}
                <MissingBookingFields
                  bookingErrors={bookingErrors}
                  callingCode={callingCode}
                  handleBlur={handleBlur}
                  missedValues={missedValues}
                  setMissedValues={setMissedValues}
                  translations={translations}
                />
                <NextButton
                  disabled={bookingLoader}
                  onClick={handleSubmit}
                  style={{
                    padding: "0 20px",
                    minHeight: "45px",
                    width: "100%",
                  }}
                  type="submit"
                >
                  {bookingLoader && (
                    <Spinner
                      style={{
                        width: "1.3rem",
                        height: "1.3rem",
                        position: "absolute",
                      }}
                    />
                  )}
                  <b style={{ margin: "0 auto" }}>{buttonText}</b>
                </NextButton>
              </form>
            </Col>
            <Col style={{ width: "100%", textAlign: "start" }} xs={24} md={12}>
              <CaregiverCardStyled onClick={() => history.push(link)}>
                <ThirdHeading style={{ marginBottom: 15 }}>
                  <b>{translations.review_content_started_booking}</b>
                </ThirdHeading>
                <div style={{ display: "flex", marginBottom: "2em" }}>
                  <Avatar
                    src={
                      caregiver.default_user_image
                        ? `${caregiver.default_user_image.attributes?.original}?h=64&w=64&fit=crop`
                        : defaultUserImage
                    }
                    style={{ margin: 0 }}
                    {...avatarProps}
                  />
                  <div style={{ margin: "auto 10px" }}>
                    <TextStyled style={{ fontSize: 18, marginBottom: "5px" }}>
                      <b>{fullName}</b>
                    </TextStyled>
                    <LocalHeader style={{ fontSize: 15, margin: 0 }}>
                      <b>{caregiverRole}</b>
                    </LocalHeader>
                  </div>
                </div>
                {(defaultProcedure?.title ||
                  (defaultProcedure?.length > 0 &&
                    defaultProcedure[0]?.title)) && (
                  <>
                    <LocalHeader>
                      {translations.review_content_treatment}
                    </LocalHeader>
                    <TextStyled>
                      {" "}
                      {defaultProcedure.title || defaultProcedure[0].title}{" "}
                    </TextStyled>
                  </>
                )}
                <LocalHeader>{translations.review_content_place}</LocalHeader>
                <TextStyled>
                  {" "}
                  {clinicAddress1} <br /> {clinicAddress2}{" "}
                </TextStyled>
                <LocalHeader>{translations.review_content_visit}</LocalHeader>
                <TextStyled> {parseSlotDate} </TextStyled>
                {(bookingRequiresTwoFactorAuthentication || authPersonalId) && (
                  <>
                    <LocalHeader>{translations.review_content_pat}</LocalHeader>
                    <TextStyled> {patientName} </TextStyled>
                  </>
                )}
              </CaregiverCardStyled>
            </Col>
          </Row>
        </>
      );

    return (
      <div>
        <CaregiverCardMobileStyled onClick={() => history.push(link)}>
          <Avatar
            src={
              caregiver.default_user_image
                ? `${caregiver.default_user_image.attributes?.original}?h=64&w=64&fit=crop`
                : defaultUserImage
            }
            style={{ margin: 0 }}
            {...avatarProps}
          />
          <div style={{ marginLeft: 20, textAlign: "start" }}>
            <TextStyled style={{ fontSize: 18, marginBottom: 5 }}>
              <b>{fullName}</b>
            </TextStyled>
            <LocalHeader> {parseSlotDate} </LocalHeader>
          </div>
        </CaregiverCardMobileStyled>
        <div style={{ margin: "0 20px" }}>
          <SecondHeading style={{ textAlign: "start" }}>
            {buttonText}
          </SecondHeading>
          {bookingErrorDetails &&
            mapOutBookingErrorDetails(bookingErrorDetails)}
          <TextStyled
            style={{
              border: "solid 1px lightgrey",
              padding: 10,
              textAlign: "start",
              marginBottom: "10px",
            }}
          >
            {parseSlotDate}
          </TextStyled>
          <StretchButton
            onClick={handleSubmit}
            style={{
              padding: "0 20px",
              minHeight: "45px",
              height: "100%",
              width: "100%",
            }}
          >
            <b style={{ margin: "0 auto" }}>{buttonText}</b>
          </StretchButton>
        </div>
      </div>
    );
  };

  return (
    <>
      {auth.loader && <MainSpinner />}
      {loading && <MainSpinner style={{ margin: "50px auto" }} />}
      {!auth.loader &&
        caregiver.attributes &&
        selectedSlot.slot &&
        renderBook()}
    </>
  );
};

ReviewContent.propTypes = {
  bookingErrors: PropTypes.array,
  bookingIsTentative: PropTypes.bool,
  bookingLoader: PropTypes.bool,
  bookingRequiresTwoFactorAuthentication: PropTypes.bool,
  bookSlot: PropTypes.func,
  defaultProcedure: PropTypes.object,
  translations: PropTypes.object,
};

ReviewContent.defaultProps = {
  bookingIsTentative: false,
};

export default memo(ReviewContent);
