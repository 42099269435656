import { addMinutes } from "date-fns";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { AuthService } from "services/auth-service";
import { PatientsService } from "services/patients-service";

import config from "config";
import { formatDate, getItemFields } from "helpers/helper-functions";
import { Notifications } from "helpers/notifications";
import { createPatientData } from "helpers/request-creators";

import { authActions as actions } from "./actions";

const auth = {
  createUserWithEmailAndPassword: () => {},
  signInWithEmailAndPassword: () => {},
  signOut: () => {},
};

const createUserWithEmailPasswordRequest = async (email, password) =>
  auth
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  auth
    .signInWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

function* signInUserQrCode({ payload: formData }) {
  try {
    const autoStartTokenResult = yield call(AuthService.getAutoStartToken);
    if (!autoStartTokenResult) {
      return;
    }

    const {
      data: { relationships, ...rest },
      included,
    } = autoStartTokenResult.data;
    const results = getItemFields(relationships, included);
    const autoStartToken = { ...results, ...rest };
    yield put(actions.autoStartTokenRefresh(autoStartToken));

    formData.append("autostarttoken", autoStartToken.id);

    yield put(actions.getUserTokenAndSignIn(formData));
  } catch (error) {
    yield put(actions.authErrorRefresh(error));
  }
}

function* userSignIn({ payload: formData }) {
  try {
    const authUser = yield call(AuthService.userSignIn, formData);
    if (authUser.data) {
      localStorage.setItem(
        "user_token_pat_frontend",
        authUser.data.access_token,
      );
      localStorage.setItem(
        "expired_time",
        formatDate(addMinutes(new Date(), 15)),
      );
      const patient = yield call(PatientsService.getPatient);
      yield put(actions.authUserRefresh(patient));
    } else if (authUser.response) {
      yield put(actions.authErrorRefresh(authUser.response));
    } else {
      yield put(actions.authErrorRefresh(authUser));
    }
  } catch (error) {
    yield put(actions.authErrorRefresh(error));
  }
}

function* getCurrentPatient() {
  try {
    const patient = yield call(PatientsService.getPatient);
    if (patient.data) yield put(actions.authUserRefresh(patient));
    else {
      yield put(actions.authErrorRefresh(patient.response));
    }
  } catch (error) {
    yield put(actions.authErrorRefresh(error));
  }
}

function* updateCurrentPatient({ payload }) {
  const id = yield select(({ auth }) => auth.authUserPersonalId?.data?.id);
  const requestData = createPatientData(id, payload.values);

  const response = yield call(PatientsService.updatePatient, id, requestData);
  if (!response) {
    yield put(actions.updateCurrentUserError());
    return;
  }

  Notifications.message({ message: payload.message });
  yield put(actions.authUserRefresh({ data: response.data }));
}

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password,
    );
    if (signUpUser.message) {
      yield put(actions.showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(actions.userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(actions.showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password,
    );
    if (signInUser.message) {
      yield put(actions.showAuthMessage(signInUser.message));
    } else {
      localStorage.setItem("user_id", signInUser.user.uid);
      yield put(actions.userSignInSuccess(signInUser.user.uid));
    }
  } catch (error) {
    yield put(actions.showAuthMessage(error));
  }
}

function* signInMobile({
  payload: {
    bookingIsTentative,
    clinicId,
    comment,
    defProcedure,
    isRegister,
    pathName,
    selectedSlot,
    slotsId,
  },
}) {
  try {
    const token = yield call(AuthService.getAutoStartToken);
    if (!token.data) {
      yield put(actions.userSignInMobileError(token.response || token));
    }

    const params = { autostarttoken: token.data.data?.id };

    const referralSource = sessionStorage.getItem("referral_source");
    if (referralSource) {
      params.referral_source = encodeURIComponent(referralSource);
    }
    if (defProcedure?.id) {
      params.defaultProcedureId = defProcedure?.id;
    }
    if (defProcedure?.title) {
      params.defaultProcedureTitle = encodeURIComponent(defProcedure?.title);
    }
    if (comment) {
      params.comment = encodeURIComponent(comment);
    }
    if (bookingIsTentative) {
      params.booking_is_tentative = bookingIsTentative;
    }
    if (slotsId) {
      params.caregiver_id = slotsId;
    }
    if (clinicId) {
      params.clinic_id = clinicId;
    }
    if (isRegister) {
      params.isRegister = isRegister;
    }
    if (selectedSlot?.attributes?.dtend) {
      params.dtend = encodeURIComponent(selectedSlot.attributes.dtend);
    }
    if (selectedSlot?.attributes?.dtstart) {
      params.dtstart = encodeURIComponent(selectedSlot.attributes.dtstart);
    }

    const redirectUrl = encodeURIComponent(
      `${config.url}${pathName}?${Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&")}`,
    );

    window.location.href = `https://app.bankid.com/?autostarttoken=${params.autostarttoken}&redirect=${redirectUrl}`;

    yield put(actions.userSignInMobileSuccess());
  } catch (error) {
    yield put(actions.userSignInMobileError(error));
  }
}

function* signOut() {
  try {
    localStorage.removeItem("user_id");
    localStorage.removeItem("expired_time");
    localStorage.removeItem("user_token_pat_frontend");
    sessionStorage.removeItem("referral_source");
    sessionStorage.removeItem("facebook_pixel_id");
    yield put(actions.userSignOutSuccess());
  } catch (error) {
    yield put(actions.showAuthMessage(error));
  }
}

export default function* authSagas() {
  yield all([
    takeEvery(actions.SIGNUP_USER, createUserWithEmailPassword),
    takeEvery(actions.SIGNIN_USER, signInUserWithEmailPassword),
    takeEvery(actions.SIGNIN_USER_QR_CODE, signInUserQrCode),
    takeEvery(actions.GET_USER_TOKEN_AND_SIGN_IN, userSignIn),
    takeEvery(actions.GET_CURRENT_USER, getCurrentPatient),
    takeEvery(actions.UPDATE_CURRENT_USER, updateCurrentPatient),
    takeEvery(actions.SIGNIN_MOBILE, signInMobile),
    takeEvery(actions.SIGNOUT_USER, signOut),
  ]);
}
