import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: white;
`;

export const InnerContainer = styled.div`
  height: 50%;
`;
