import React, { memo } from "react";
import { useSelector } from "react-redux";
import * as PropTypes from "prop-types";

import { formatDate } from "helpers/helper-functions";
import { selectTag } from "redux/app/selectors";

import { MobileSection } from "components/common.styled";

import {
  CommentFooter,
  CommentRateRow,
  LocalStyledDivider,
  P,
  RateTitle,
  StyledRate,
} from "./index.styled";

const ReviewItem = ({
  translations,
  patientName,
  itemReviewed,
  overallRating,
  reviewComment,
  createdDate,
}) => {
  const locale = useSelector(selectTag);

  return (
    <div>
      <MobileSection>
        <div itemProp="review" itemScope itemType="https://schema.org/Review">
          {itemReviewed && (
            <div
              itemProp="itemReviewed"
              itemScope
              itemType="https://schema.org/MedicalOrganization"
            >
              <meta content={itemReviewed} itemProp="name" />
            </div>
          )}
          <span
            itemProp="reviewRating"
            itemScope
            itemType="https://schema.org/Rating"
          >
            <meta content={overallRating} itemProp="ratingValue" />
            <meta content="1" itemProp="worstRating" />
            <meta content="5" itemProp="bestRating" />
          </span>
          <CommentRateRow>
            <RateTitle style={{ color: "#484848", fontSize: "16px" }}>
              {translations.review_grade}
            </RateTitle>
            <StyledRate
              margin="0 2px 0 0"
              size={20}
              defaultValue={overallRating}
            />
          </CommentRateRow>
          <P>{reviewComment}</P>
          <CommentFooter>
            <span itemProp="datePublished">
              {formatDate(createdDate, "d MMMM uuuu", locale)}
            </span>
            <span>∙</span>
            <span
              itemProp="author"
              itemScope
              itemType="http://schema.org/Person"
            >
              <span itemProp="name" style={{ margin: "0px" }}>
                {patientName}, {translations.review_confirmed_patient}
              </span>
            </span>
          </CommentFooter>
        </div>
      </MobileSection>
      <LocalStyledDivider />
    </div>
  );
};

ReviewItem.propTypes = {
  translations: PropTypes.object,
  patientName: PropTypes.string,
  itemReviewed: PropTypes.string,
  overallRating: PropTypes.number,
  reviewComment: PropTypes.string,
  createdDate: PropTypes.string,
};

export default memo(ReviewItem);
