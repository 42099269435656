import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class PatientInvoicesService extends BaseService {
  constructor(url) {
    super(url);
    this.getInvoices = this.getInvoices.bind(this);
    this.getInvoice = this.getInvoice.bind(this);
    this.getInvoicePdf = this.getInvoicePdf.bind(this);
  }

  async getInvoices(params) {
    try {
      const response = await this.agent.get("", params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async getInvoice(id, params) {
    try {
      const response = await this.agent.get(`/${id}`, params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async getInvoicePdf(id) {
    try {
      const response = await this.agent.get(`/${id}/download`, {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new PatientInvoicesService(API_ROUTES.patientInvoices);

export default ServiceInstance;
export { ServiceInstance as PatientInvoicesService };
