import styled from "styled-components";

import {
  Col,
  Heading,
  MobileSection,
  Row,
  StyledContent,
  Text,
} from "components/common.styled";
import Button from "components/common/button";
import Rate from "components/common/stars";

export const Content = styled(StyledContent)`
  max-width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  width: 100%;
  padding: 0;
  box-shadow: none;
  max-width: 1120px !important;
  margin: 0 auto 200px;
`;

export const CalendarContainer = styled(Col)`
  max-width: 600px;

  @media (max-width: 900px) {
    margin: 0 auto;
  }
`;

export const P = styled(Text)`
  margin-bottom: 0;
`;

export const StyledRate = styled(Rate)``;

export const MainRate = styled(StyledRate)`
  margin-bottom: 15px;
`;

export const AvatarSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NameTitle = styled(Heading)`
  text-align: center;
  margin: 10px 0;
  font-size: 40px;
  line-height: 44px;
  font-weight: bold;
`;

export const TabRow = styled(Row)`
  border-bottom: 3px solid #1890ff52;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 15px;
  @media (max-width: 740px) {
    display: none;
  }
`;

export const ReviewsButton = styled(Button)`
  border-radius: 5px 5px 0px 0px;
  padding: 10px 40px;
  height: auto;
  border: 1px solid #1890ff52;
  border-bottom: 3px solid #1890ff52;
  margin-bottom: -3px;
`;

export const TabHeader = styled.h3`
  padding: 10px 40px;
  margin: auto 0;
  color: #484848;
`;

export const StyledBreadcrumb = styled.div`
  & a {
    color: #1890ff;

    :hover {
      color: #40a9ff;
    }
  }

  & span {
    margin: 0 10px;
  }
`;

export const MainSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 65%;
  width: 65%;
  padding: 70px 30px 30px 50px;

  @media (max-width: 900px) {
    width: 100%;
    padding: 70px 20px 30px 20px;
  }

  @media (max-width: 388px) {
    padding: 10px 0px 30px 0px;
  }
`;

export const BioTitle = styled.h4`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const ScheduleContainer = styled.div`
  flex-grow: 1;

  display: flex;
  align-items: flex-start;
`;

export const ScheduleContent = styled.div`
  padding: 0px 15px;

  @media (max-width: 993px) {
    padding: 0px;
  }
`;

export const StickyWrapper = styled.div`
  display: block;
  position: relative;
  bottom: 2em;
  align-self: flex-start;
  max-width: 100%;
  width: 100%;
  min-width: 420px;

  @media (max-width: 993px) {
    padding: 32px 0 0;
    max-width: 100%;
    min-width: 100%;
  }
  @media (min-width: 994px) {
    margin-top: 29px;
  }
`;

export const StyledDivider = styled.div`
  border-bottom: 1px solid #00000017;
  margin: 15px 0;
  width: 100%;
  @media (max-width: 993px) {
    margin: 0;
  }
  @media (min-width: 994px) {
    margin: 50px 0;
  }
`;

export const LocalStyledDivider = styled(StyledDivider)`
  @media (max-width: 993px) {
    margin: 0;
  }
  @media (min-width: 994px) {
    margin: 15px 0;
  }
`;

export const MobileSectionForRender = styled(MobileSection)`
  @media (min-width: 994px) {
    margin: 50px 0;
  }
`;

export const FixedContainer = styled.div`
  width: 100%;
`;
