export const COUNTRIES = {
  sweden: "se",
  norway: "no",
  denmark: "dk",
};

export const COUNTRY_CODES = {
  sweden: "SE",
  norway: "NO",
  denmark: "DE",
};

export const COUNTRIES_TITLE = {
  sweden: "Sweden",
  norway: "Norway",
  denmark: "Denmark / Danmark",
};
