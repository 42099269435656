import React, { memo, useContext } from "react";
import { Link } from "react-router-dom";
import * as PropTypes from "prop-types";

import DjurfarmaciaLogo from "assets/images/logoDjurfarmacia.png";
import {
  getCountry,
  getLanguage,
} from "helpers/internationalization-functions";
import { InternationalizationContext } from "providers/internationalization-provider";

import versionPackage from "../../../package.json";
import { StyledFooter, Text } from "../common.styled";

import {
  BottomFooter,
  FooterLogo,
  InternationalizationFooter,
  VersionContainer,
} from "./index.styled";

const Footer = ({
  referralSourceEqualStatus,
  withoutBottomMargin,
  translations,
  showAnimalPharmacy = false,
}) => {
  const {
    languages = [],
    countries = [],
    setCountry,
    setLanguage,
  } = useContext(InternationalizationContext);

  return (
    <StyledFooter withoutBottomMargin={withoutBottomMargin}>
      <InternationalizationFooter>
        <select value={getLanguage()} onChange={setLanguage}>
          {!!languages.length &&
            languages?.map((languageObject) => {
              return (
                <option
                  value={languageObject.language}
                  key={languageObject.language}
                >
                  {languageObject.text}
                </option>
              );
            })}
        </select>
        <select onChange={setCountry} defaultValue={getCountry()}>
          {!!countries.length &&
            countries?.map((countryObject) => {
              return (
                <option
                  value={countryObject.country}
                  key={countryObject.country}
                >
                  {countryObject.text}
                </option>
              );
            })}
        </select>
      </InternationalizationFooter>
      {!referralSourceEqualStatus && showAnimalPharmacy && (
        <>
          <hr />
          <Text>{translations.in_coop_with}</Text>

          <a style={{ height: "49px" }} href="https://www.djurfarmacia.se">
            <FooterLogo
              alt="Djurfarmacia Apoteket Trollet"
              height="24.74"
              loading="lazy"
              src={DjurfarmaciaLogo}
              title="Djurfarmacia Apoteket Trollet"
              width="100"
            />
          </a>
        </>
      )}
      <BottomFooter>
        <div>
          <b>
            <Link to="/terms">{translations.common_terms}</Link>
          </b>
          &nbsp;&amp;&nbsp;
          <b>
            <Link to="/cookies-info">{translations.cookies_footer}</Link>
          </b>
        </div>
        <span>
          © {new Date().getFullYear().toString()}{" "}
          <a href="https://about.muntra.se">Muntra AB</a>
          <VersionContainer>&nbsp;(v{versionPackage.version})</VersionContainer>
        </span>
      </BottomFooter>
    </StyledFooter>
  );
};

Footer.propTypes = {
  referralSourceEqualStatus: PropTypes.bool,
  withoutBottomMargin: PropTypes.bool,
  translations: PropTypes.object,
  showAnimalPharmacy: PropTypes.bool,
};

export default memo(Footer);
