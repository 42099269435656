const prefix = "[clinics]";

const TYPES = {
  CLINIC_FETCH_DATA_COMPLETED: `${prefix} CLINIC_FETCH_DATA_COMPLETED`,
  CLINIC_GET_BY_ID: `${prefix} CLINIC_GET_BY_ID`,
  CLINIC_GET_BY_ID_FOR_BOOKINGS: `${prefix} CLINIC_GET_BY_ID_FOR_BOOKINGS`,
  CLINIC_GET_BY_ID_FOR_BOOKINGS_SUCCESS: `${prefix} CLINIC_GET_BY_ID_FOR_BOOKINGS_SUCCESS`,
  CLINIC_GET_BY_ID_FULL: `${prefix} CLINIC_GET_BY_ID_FULL`,
  CLINIC_GET_BY_ID_FULL_SUCCESS: `${prefix} CLINIC_GET_BY_ID_FULL_SUCCESS`,
  CLINIC_GET_BY_ID_SUCCESS: `${prefix} CLINIC_GET_BY_ID_SUCCESS`,
  CLINIC_GET_CAREGIVERS_BY_CLINIC_ID: `${prefix} CLINIC_GET_CAREGIVERS_BY_CLINIC_ID`,
  CLINIC_GET_CAREGIVERS_BY_CLINIC_ID_SUCCESS: `${prefix} CLINIC_GET_CAREGIVERS_BY_CLINIC_ID_SUCCESS`,
  CLINIC_GET_CAREGIVERS_FETCH_ERROR: `${prefix} CLINIC_GET_CAREGIVERS_FETCH_ERROR`,
  CLINIC_GET_PROCEDURE_BY_CLINIC_ID: `${prefix} CLINIC_GET_PROCEDURE_BY_CLINIC_ID`,
  CLINIC_GET_PROCEDURE_BY_CLINIC_ID_SUCCESS: `${prefix} CLINIC_GET_PROCEDURE_BY_CLINIC_ID_SUCCESS`,
  CLINIC_REVIEWS_CHANGE_PAGE: `${prefix} CLINIC_REVIEWS_CHANGE_PAGE`,
  CLINIC_REVIEWS_FETCH: `${prefix} CLINIC_REVIEWS_FETCH`,
  CLINIC_REVIEWS_FETCH_SUCCESS: `${prefix} CLINIC_REVIEWS_FETCH_SUCCESS`,
  CLINIC_SLOTS_FETCH: `${prefix} CLINIC_SLOTS_FETCH`,
  CLINIC_SLOTS_FETCH_SUCCESS: `${prefix} CLINIC_SLOTS_FETCH_SUCCESS`,
  CLINICS_CHANGE_PAGE: `${prefix} CLINICS_CHANGE_PAGE`,
  CLINICS_FETCH: `${prefix} CLINICS_FETCH`,
  CLINICS_FETCH_ERROR: `${prefix} CLINICS_FETCH_ERROR`,
  CLINICS_FETCH_SUCCESS: `${prefix} CLINICS_FETCH_SUCCESS`,
};

export const clinicsActions = {
  ...TYPES,
  fetchClinics: (page) => ({
    type: TYPES.CLINICS_FETCH,
    payload: page,
  }),
  changePageClinics: (page) => ({
    type: TYPES.CLINICS_CHANGE_PAGE,
    payload: page,
  }),
  fetchClinicsSuccess: (places) => ({
    type: TYPES.CLINICS_FETCH_SUCCESS,
    payload: places,
  }),
  fetchClinicsError: (error) => ({
    type: TYPES.CLINICS_FETCH_ERROR,
    payload: error,
  }),
  getClinicByIdFull: ({ clinicId, dateStart, dateEnd, defaultProcedure }) => ({
    type: TYPES.CLINIC_GET_BY_ID_FULL,
    payload: { clinic_id: clinicId, dateStart, dateEnd, defaultProcedure },
  }),
  getClinicByIdFullSuccess: (clinic) => ({
    type: TYPES.CLINIC_GET_BY_ID_FULL_SUCCESS,
    payload: clinic,
  }),
  getClinicById: (clinicId, defaultProcedure) => ({
    type: TYPES.CLINIC_GET_BY_ID,
    payload: { clinic_id: clinicId, defaultProcedure },
  }),
  getClinicByIdSuccess: (clinic) => ({
    type: TYPES.CLINIC_GET_BY_ID_SUCCESS,
    payload: clinic,
  }),
  getClinicForBookings: (clinicId, defaultProcedure) => ({
    type: TYPES.CLINIC_GET_BY_ID_FOR_BOOKINGS,
    payload: { clinic_id: clinicId, defaultProcedure },
  }),
  getClinicForBookingsSuccess: (clinic) => ({
    type: TYPES.CLINIC_GET_BY_ID_FOR_BOOKINGS_SUCCESS,
    payload: clinic,
  }),
  getClinicProcedureById: (clinicId) => ({
    type: TYPES.CLINIC_GET_PROCEDURE_BY_CLINIC_ID,
    payload: clinicId,
  }),
  getClinicProcedureByIdSuccess: (clinic) => ({
    type: TYPES.CLINIC_GET_PROCEDURE_BY_CLINIC_ID_SUCCESS,
    payload: clinic,
  }),
  getClinicCaregiversById: (clinicId) => ({
    type: TYPES.CLINIC_GET_CAREGIVERS_BY_CLINIC_ID,
    payload: clinicId,
  }),
  getClinicCaregiversByIdSuccess: (caregivers) => ({
    type: TYPES.CLINIC_GET_CAREGIVERS_BY_CLINIC_ID_SUCCESS,
    payload: caregivers,
  }),
  getClinicCaregiversByIdError: (error) => ({
    type: TYPES.CLINIC_GET_CAREGIVERS_FETCH_ERROR,
    payload: error,
  }),
  fetchClinicReviews: ({ id, page }) => ({
    type: TYPES.CLINIC_REVIEWS_FETCH,
    payload: { id, page },
  }),
  changePageClinicReviews: (page) => ({
    type: TYPES.CLINIC_REVIEWS_CHANGE_PAGE,
    payload: page,
  }),
  fetchClinicReviewsSuccess: (reviews) => ({
    type: TYPES.CLINIC_REVIEWS_FETCH_SUCCESS,
    payload: reviews,
  }),
  fetchClinicSlots: (payload) => ({
    type: TYPES.CLINIC_SLOTS_FETCH,
    payload,
  }),
  fetchClinicSlotsSuccess: (slots) => ({
    type: TYPES.CLINIC_SLOTS_FETCH_SUCCESS,
    payload: slots,
  }),
  fetchClinicDataCompleted: (status) => ({
    type: TYPES.CLINIC_FETCH_DATA_COMPLETED,
    payload: status,
  }),
};
