import React, { memo } from "react";
import * as PropTypes from "prop-types";

const ErrorMessage = ({ error, errorPlaces, translations = {} }) => {
  if (!errorPlaces) {
    if (error === "Network Error")
      return (
        <div style={{ margin: "30vh auto 0", textAlign: "center" }}>
          {" "}
          <b> {translations.generic_error_start} </b>{" "}
        </div>
      );
    if (error === "No caregivers")
      return (
        <div style={{ margin: "30vh auto 0", textAlign: "center" }}>
          {" "}
          <b> {translations.no_caregivers_found_error}</b>{" "}
        </div>
      );
  } else {
    if (
      errorPlaces ===
      "Muntra Google Place Detail Address Component with Id not found"
    )
      return (
        <div style={{ margin: "30vh auto 0", textAlign: "center" }}>
          {" "}
          <b> {translations.no_caregivers_found_in_area_error}</b>
        </div>
      );
    return (
      <div style={{ margin: "30vh auto 0", textAlign: "center" }}>
        <b> {translations.generic_error_start}</b>
      </div>
    );
  }
};

ErrorMessage.propTypes = {
  error: PropTypes.string,
  errorPlaces: PropTypes.string,
  translations: PropTypes.object,
};

export default memo(ErrorMessage);
