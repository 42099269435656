import React, { memo, useCallback } from "react";
import * as PropTypes from "prop-types";

import { CloseButton, Container } from "./index.styled";

const ImageModal = ({ setIsActive, SrcImg, setSrcImg }) => {
  const handleClick = useCallback(() => {
    setSrcImg("");
    setIsActive(false);
  }, [setIsActive, setSrcImg]);

  return (
    <Container>
      <CloseButton onClick={handleClick}>x</CloseButton>
      {SrcImg && (
        <img
          loading="lazy"
          style={{ width: "100%", marginTop: "30vh" }}
          src={SrcImg}
          alt="Clinic default images"
        />
      )}
    </Container>
  );
};

ImageModal.propTypes = {
  setIsActive: PropTypes.func,
  SrcImg: PropTypes.string,
  setSrcImg: PropTypes.func,
};

export default memo(ImageModal);
