import { googlePlacesActions as actions } from "./actions";

export const INIT_STATE = {
  data: {},
  startData: {},
  errorPlaces: "",
  loading: false,
  search: null,
  searchResult: {},
  searchLoading: false,
  placesTerms: "",
};

export const googlePlacesReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case actions.GOOGLE_PLACES_FETCH:
    case actions.GOOGLE_PLACES_MAIN_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.GOOGLE_PLACES_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    }
    case actions.GOOGLE_PLACES_FETCH_ERROR: {
      return {
        ...state,
        errorPlaces: action.payload,
        loading: false,
        searchLoading: false,
      };
    }
    case actions.GOOGLE_PLACES_MAIN_FETCH_SUCCESS: {
      return {
        ...state,
        startData: action.payload,
        loading: false,
      };
    }
    case actions.GOOGLE_PLACES_SEARCH: {
      return {
        ...state,
        searchResult: {},
        searchLoading: true,
      };
    }
    case actions.GOOGLE_PLACES_SEARCH_SUCCESS: {
      return {
        ...state,
        searchResult: action.payload,
        searchLoading: false,
      };
    }
    case actions.GOOGLE_PLACES_CHANGE_SEARCH: {
      return {
        ...state,
        search: action.payload,
      };
    }
    case actions.GOOGLE_PLACES_TERMS_SUCCESS: {
      return {
        ...state,
        placesTerms: action.payload,
      };
    }
    case actions.GOOGLE_PLACES_CLEAR_ERROR: {
      return {
        ...state,
        errorPlaces: "",
      };
    }
    default:
      return state;
  }
};
