import React from "react";
import * as PropTypes from "prop-types";

import { Modal } from "components/common/modal";

import ClinicImagesDesktopContent from "./clinic-images-desktop-content";
import ClinicImagesMobileContent from "./clinic-images-mobile-content";

const ClinicImagesModal = ({ onClose, images, pickedImage }) => {
  return (
    <Modal isClinicImages onClose={onClose} style={{ width: "100%" }}>
      {global.innerWidth >= 994 && (
        <ClinicImagesDesktopContent images={images} pickedImage={pickedImage} />
      )}
      {global.innerWidth < 994 && <ClinicImagesMobileContent images={images} />}
    </Modal>
  );
};

ClinicImagesModal.propTypes = {
  onClose: PropTypes.func,
  images: PropTypes.object,
  pickedImage: PropTypes.number,
};

export default ClinicImagesModal;
