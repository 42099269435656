import { all, call, put, takeEvery } from "redux-saga/effects";
import { AcceptsReviewEmailService } from "services/accepts-review-email-service";
import { ErrorNotificationsService } from "services/error-notifications-service";
import { LocalesService } from "services/locales-service";

import { DEFAULT_LOCALE as defaultLocale } from "constants/defaults";

import { getItemFields } from "../../helpers/helper-functions";
import { appActions as actions } from "./actions";

function* getCurrentLocale() {
  const locale = yield call(LocalesService.getLocale);

  if (locale.data?.data?.length) {
    const results = locale.data.data.map((item) => {
      const { relationships, ...rest } = item;
      const result = getItemFields(relationships, locale.data.included);
      return { ...result, ...rest };
    });

    yield put(actions.getCurrentLocaleSuccess(results));
  } else {
    yield put(actions.getCurrentLocaleSuccess(defaultLocale));
    yield put(actions.getCurrentLocaleError(locale.response));
  }
}

function* getSpecificLocale({ payload: data }) {
  const dataConfig = {
    params: {
      query: data,
      include: "language",
    },
  };
  const locale = yield call(LocalesService.getSpecificLocale, dataConfig);
  if (locale.data) {
    const results = locale.data.data.map((item) => {
      const { relationships, ...rest } = item;
      const result = getItemFields(relationships, locale.data.included);
      return { ...result, ...rest };
    });

    yield put(actions.getSpecificLocaleSuccess(results));
  } else {
    yield put(actions.getSpecificLocaleError(locale.response));
  }
}

function* sendErrorReport({ payload }) {
  const result = yield call(ErrorNotificationsService.sendErrorReport, payload);
  if (!result) {
    yield put(actions.sendErrorReportError());
  }

  yield put(actions.sendErrorReportSuccess(result.data));
}

function* sendReviewRequestEmailSettings({ payload }) {
  const dataConfig = {
    data: {
      attributes: {
        accepts_review_request_emails: payload.accepts_review_request_emails,
        changed_date: payload.currentDate,
        external_id: payload.external_id,
      },
      type: "muntra-accepts-review-requests-email-settings",
    },
  };
  const result = yield call(AcceptsReviewEmailService.sendReview, dataConfig);
  if (!result) {
    yield put(actions.sendReviewRequestEmailSettingsError());
    return;
  }

  yield put(actions.sendReviewRequestEmailSettingsSuccess(result.data.data));
}

export default function* appSagas() {
  yield all([
    takeEvery(actions.SEND_ERROR_REPORT, sendErrorReport),
    takeEvery(
      actions.SEND_REVIEW_REQUEST_EMAIL_SETTINGS,
      sendReviewRequestEmailSettings,
    ),
    takeEvery(actions.GET_CURRENT_LOCALE, getCurrentLocale),
    takeEvery(actions.GET_SPECIFIC_LOCALE, getSpecificLocale),
  ]);
}
