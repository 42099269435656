import React from "react";
import { Link } from "react-router-dom";

import Infographic from "assets/images/tandblekningInfographicMin.png";

import {
  Col,
  Heading,
  Image,
  Row,
  SecondHeading,
  SmallText,
  Text,
  ThirdHeading,
} from "components/common.styled";

const ToothWhiteningContent = () => {
  const headings = [
    "Allmänt om tandblekning",
    "Vem ska inte bleka tänderna?",
    "Vilka alternativ finns för att bleka tänderna?",
    "Enklare blekningsprodukter såsom tandkräm, strips och pennor (100-300 kr)",
    "Bleka tänderna hemma med gel och blekningsskena (1000-2000 kr)",
    "Bleka tänderna hos en tandläkare eller tandhygienist (från 2500 kr och uppåt)",
    "Hur mycket kostar tandblekningen?",
    "Att tänka på efter tandblekningen",
  ];

  return (
    <Row>
      <Col>
        <Heading>Tandblekning i praktiken: Allt du behöver veta</Heading>
        <Text>Funderar du på att bleka tänderna?</Text>
        <Text>Då har du kommit till rätt ställe.</Text>
        <Text>
          I denna guide hjälper vid dig att{" "}
          <b>
            hitta rätt lösning för tandblekning, i linje med vad som passar din
            plånbok
          </b>
          .
        </Text>
        <Text>Här är direktlänkar till innehållet i guiden:</Text>
        <ul>
          {headings.map((item, index) => (
            <li key={index}>
              <a href={`#heading${index}`}>{`${index + 1}. ${item}`}</a>
            </li>
          ))}
        </ul>
        <SecondHeading id="heading0">1. Allmänt om tandblekning</SecondHeading>
        <Image
          alt="Bleka tänder"
          loading="lazy"
          src="https://muntra-public.s3.eu-north-1.amazonaws.com/treatmentContent/bleka-tander.jpeg"
        />
        <Text>
          För dig som vill bleka tänderna finns det en rad olika alternativ.
        </Text>
        <Text>
          Du kan bleka tänderna själv hemma eller ta professionell hjälp hos en
          tandläkare eller tandhygienist.
        </Text>
        <Text>
          Tandblekning syftar till att få bort missfärgningar på tänderna.{" "}
        </Text>
        <Text>
          Missfärgningar kan uppstå på olika sätt. Generellt särskiljs mellan
          s.k. <b>&quot;yttre&quot;</b> och <b>&quot;inre&quot;</b> orsaker.
        </Text>
        <Text>
          De yttre och mest vanliga orsakerna är t.ex. tobak och kaffe. Medans
          de inre kan vara åldrande, sjukdom eller trauman i form av skador från
          slag på tänderna.{" "}
        </Text>
        <Text>
          Vi föds dessutom olika, somliga föds med vitare tänder än andra.{" "}
        </Text>
        <Text>
          Munhälsa och tändernas utseende påverkar mångas självförtroende och
          mentala hälsa.
        </Text>
        <Text>
          Därför är tandblekning och andra kosmetiska ingrepp idag vanliga. Allt
          fler väljer att bleka tänderna för att förbättra sin självkänsla.
        </Text>

        <SecondHeading id="heading1">
          2. Vem ska inte bleka tänderna?
        </SecondHeading>
        <Text>Det rekommenderas inte att bleka tänderna om...</Text>
        <ul>
          <li>
            <b>du är gravid eller ammar</b> (finns inga undersökningar som visar
            hur barnet påverkas)
          </li>
          <li>du har tunn emalj</li>
          <li>du ofta har problem med ilningar i tänderna</li>
          <li>du har karies (hål i tänderna)</li>
          <li>
            du har borstskador (skador som uppstår på tänderna om de borstas
            hårt)
          </li>
        </ul>
        <Image
          alt="Snabbguide: Välj rätt tandblekningsmetod"
          loading="lazy"
          src={Infographic}
        />

        <SecondHeading id="heading2">
          3. Vilka alternativ finns för att bleka tänderna?
        </SecondHeading>
        <Text>
          Det finns flera olika alternativ för att bleka tänderna. Somliga
          genomför t.o.m. huskurer med vanliga matvaror som återfinns skafferiet
          hemma.
        </Text>
        <Text>
          Du har kanske hört om olika råd och rön som att du får ett vitare
          leende genom att borsta tänderna med bakpulver blandat med vatten.
        </Text>
        <Text>I Sverige är det vanligaste att:</Text>
        <ul>
          <li>
            bleka tänderna <b>hemma med gelskenor</b>
          </li>
          <li>
            boka en <b>behandling hos en tandläkare eller tandhygienist</b>
          </li>
        </ul>
        <Text>
          Viktigt är att redan nu fundera vilket alternativ som passar bäst för
          dig!
        </Text>
        <Text>
          Är dina tänder endast milt missfärgade kan det vara tillräckligt att
          köpa <b>blekningsstrips</b> som du fäster på tänderna eller{" "}
          <b>tandkräm med blekande effekt</b>.
        </Text>
        <Text>
          Detta hjälper ofta mot missfärgning som orsakats av yttre faktorer
          såsom kaffe.
        </Text>
        <Text>
          Är missfärgningen mer omfattande än så, väljer många att bleka
          tänderna hemma.
        </Text>
        <Text>
          Det görs oftast med hjälp av en <b>gel och blekningsskena</b>.
        </Text>
        <Text>
          För den med grov missfärgning, eller den som vill få riktigt vita
          tänder, kan <b>behandling på tandklinik</b> vara det bästa
          alternativet.
        </Text>
        <ThirdHeading>Röker eller snusar du?</ThirdHeading>
        <Text>
          I så fall rekommenderar vi att du rådfrågar en tandläkare eller
          hygienist.
        </Text>
        <Text>
          Resultatet av blekning för tobaksbrukare kan ge motsatt effekt. Här
          gäller det att veta vad man gör!
        </Text>

        <SecondHeading id="heading3">
          4. Enklare blekningsprodukter såsom tandkräm, strips och pennor
          (100-300 kr)
        </SecondHeading>
        <Text>
          Det erbjuds idag många produkter för blekning av tänder. Många
          produkter är receptfria och går att köpa i vanliga affärer.
        </Text>
        <Text>Vi rekommenderar att vara källkritisk mot marknadsföring.</Text>
        <Text>Vissa produkter har inte någon bevisad blekande effekt.</Text>
        <Text>
          Detta kan innefatta alla typer av produkter. Vilseledande
          marknadsföring är ett problem.
        </Text>
        <Text>
          <b>Blekningsstrips, även kallat whitening-strips</b>, är som en ”tejp”
          med blekningsmedel som fästs på tänderna, vanligen några timmar under
          dagen.
        </Text>
        <Text>
          Var noga med att köpa strips som täcker hela tänderna och inte enbart
          delar av tänderna.
        </Text>
        <Text>
          Strips som inte täcker hela tänderna riskerar att ge ett fläckigt
          resultat.
        </Text>
        <Text>
          <b>Tandkrämer och munskölj med blekande effekt </b>
          används ofta för att bevara resultatet av redan blekta tänder. De kan
          även användas för att bleka mildare missfärgningar.
        </Text>
        <Text>
          Tandkrämer och munskölj hjälper alltså inte mot missfärgning som
          orsakas av skador, sjukdom eller åldrande.
        </Text>
        <Text>
          Det är viktigt att vara medveten om somliga risker innan du köper och
          beställer blekningsprodukter. Fel typ av produkt kan innebära ett
          oönskat resultat.
        </Text>
        <ThirdHeading>Kolla produktens CE-märkning</ThirdHeading>
        <Text>
          En tumregel är att alltid kontrollera att produkten är CE-märkt.{" "}
        </Text>
        <Text>
          Denna märkning innebär att blekmedlet följer EU-kommissionens krav och
          direktiv.
        </Text>
        <ThirdHeading>Halt av väteperoxid</ThirdHeading>
        <Text>
          Nyligen har kravet och bestämmelserna kring den tillåtna halten av
          väteperoxid tillkommit.
        </Text>
        <Text>
          Koncentrationen får numera maximalt vara 6% i Sverige och Europa.{" "}
        </Text>
        <Text>
          Väteperoxid är den vanligaste substansen som används för blekning och
          bryter ned missfärgningar på tanden utan att det påverkar tandens
          emalj eller tandkött.
        </Text>
        <ThirdHeading>Blekmedles PH-värde</ThirdHeading>
        <Text>Kontrollera även produktens PH-värde.</Text>
        <Text>
          Ibland kan det vara svårt att få information om detta, varför det är
          viktigt att noga kontrollera produkterna innan köp.
        </Text>
        <Text>
          En blekningsprodukt som inte har ett neutralt PH-värde, och t.ex. har
          ett surt värde, fräter på emaljen och kan orsaka skador på tänderna.
        </Text>
        <ThirdHeading>Mat och dryck under blekningsperioden</ThirdHeading>
        <Text>
          Du bör även kontrollera vad du kan äta och dricka under
          blekningsperioden.
        </Text>
        <Text>
          Att t.ex. dricka vin och kaffe i samband med olika blekningsprodukter
          kan ge ytterligare missfärgningar eller ett oväntat resultat.
        </Text>
        <Text>
          Köper du varor från utlandet behöver du kontrollera allt detta extra
          noggrant.
        </Text>
        <Text>
          Vi rekommenderar att du undersöker produkter noga före köp och
          användning, oavsett varifrån de kommer.
        </Text>
        <Text>
          Är du osäker på produkten bör du rådfråga en tandläkare eller
          tandhygienist.
        </Text>
        <ThirdHeading>Populära blekmedel</ThirdHeading>
        <Text>
          Nedan har vi gjort en lista på några av de populäraste produkterna i
          Sverige.
        </Text>
        <ul>
          <li>
            <a href="https://www.opalescence.com">Opalescence</a>
          </li>
          <li>
            <a href="https://www.brilliantsmile.se">Brilliantsmile</a>
          </li>
          <li>
            <a href="https://www.dentway.se">Dentway</a>
          </li>
        </ul>
        <ThirdHeading>
          Checklista med frågor att ställa vid köp av blekningsprodukter:{" "}
        </ThirdHeading>
        <ul>
          <li>Är produkten CE-märkt?</li>
          <li>Har produkten bevisad effekt?</li>
          <li>Kan produkten hjälpa min typ av/orsak till missfärgning?</li>
          <li>
            Sök på produkten/tillverkarens namn på Google (leta efter
            recensioner)
          </li>
        </ul>

        <SecondHeading id="heading4">
          5. Bleka tänderna hemma med gel och blekningsskena (1000-2000 kr)
        </SecondHeading>
        <Text>
          För dig som vill bleka tänderna över tid hemma är det vanligt att
          använda sig av en bettskena som fylls med blekande gel.
        </Text>
        <Text>
          Själva gelen, som tänderna bleks med, säljs av många olika
          tillverkare. De går ofta att köpa direkt av din tandhygienist eller
          tandläkare.
        </Text>
        <Text>
          Gel med högre halter av väteperoxid får enbart säljas av tandläkare
          och tandhygienister.
        </Text>
        <Text>
          Blekningen kan utföras på både dag- och kvällstid. Många väljer att
          göra det på kvällstid.
        </Text>
        <ThirdHeading>Anpassad bettskena</ThirdHeading>
        <Text>
          Bettskenan ska för optimalt resultat vara formad efter dina tänder.
        </Text>
        <Text>
          En självklar fördel med en bettskena som är anpassad för dig är att
          den blir bekvämare att använda.
        </Text>
        <Text>
          Att bleka tänderna hemma tar tid. En obekväm bettskena kan göra
          processen mer omständlig än nödvändigt.
        </Text>
        <Text>
          Följ anvisningarna för produkten eller av tandläkaren noggrant.{" "}
        </Text>
        <Text>
          Har du besökt en tandläkare eller hygienist och fått rekommendationer
          muntligt - be om att få dessa också skriftligt!
        </Text>
        <Text>
          Det finns många olika typer av material. Därför kan det vara lämpligt
          att använda materialet som din hygienist eller tandläkare känner till
          och rekommenderar.
        </Text>
        <ThirdHeading>
          Vad kan man förvänta sig av att bleka tänderna hemma?
        </ThirdHeading>
        <Text>
          Generellt är det svårt att förutse exakta resultat. Använder du dock
          produkterna enligt anvisningarna så ska ett märkbart resultat vara
          synligt.{" "}
        </Text>
        <Text>
          Om inte bör du undersöka varför. Ska du exempelvis kräva ersättning?
          Många produkter har s.k. nöjdhetsgaranti.
        </Text>
        <Text>
          Har du redan väldigt vita tänder kanske däremot resultatet inte blir
          lika påtagligt.
        </Text>
        <Text>
          Detta kan vara ytterligare en anledning att söka konsultation inom
          tandvården för att skapa rimliga förväntningar.
        </Text>
        <Text>
          Ilningar i tänderna är vanligt förekommande hos alla typer av
          tandblekningsmetoder.
        </Text>
        <Text>
          Du behöver inte oroa dig - det går över! Men som vanligt för att
          minimera ilningar bör du undvika t.ex. kall och varm dryck.
        </Text>
        <SecondHeading>
          Tips för dig som ska använda blekningsskena!
        </SecondHeading>
        <ThirdHeading>
          Få tänderna blästrade eller putsade hos en tandhygienist eller
          tandläkare
        </ThirdHeading>
        <Text>
          Detta ger i sig självt lite effekt mot missfärgning och kan även ge
          blekningsgelén ökad effekt.
        </Text>
        <Text>
          Du bör aldrig blästra eller putsa tänderna mer än två gånger per år.
          Det är skadligt för emaljen att blästra och putsa tänderna för ofta.
        </Text>
        <ThirdHeading>Använd en tandkräm med högre halt av fluor</ThirdHeading>
        <Text>Fluor hjälper mot ilningar i tänderna.</Text>
        <Text>
          Köp tandkräm med högre halt av fluor eller ytterligare tillskott i
          form av tabletter eller fluorsköljning.
        </Text>
        <Text>
          Det hjälper emaljen att återhämta sig och motverkar symtom av
          ilningar.
        </Text>
        <ThirdHeading>Ta före- och efterbilder</ThirdHeading>
        <Text>Följ upp processen med före- och efterbilder.</Text>
        <Text>Gör det själv framför spegeln!</Text>
        <Text>
          Eller be din tandhygienist eller tandläkare att göra det åt dig.
        </Text>
        <Text>
          Du kan själv se tydligt resultatet av blekningen och bättre bedöma
          kvaliteten.
        </Text>

        <SecondHeading id="heading5">
          6. Bleka tänderna hos en tandläkare eller tandhygienist (från 2500 kr
          och uppåt)
        </SecondHeading>
        <Text>
          Slutligen finns det ett snabbare alternativ än blekningsskenor,
          nämligen att anlita en tandläkare eller tandhygienist.
        </Text>
        <Text>
          Vårdgivaren använder överlag samma typ av medel för behandlingen
          (väteperoxid). De använder någon form av ljus för att göra processen
          snabbare.
        </Text>
        <Text>Det finns idag tre populära typer av ljusbehandlingar:</Text>
        <ul>
          <li>UV-ljus</li>
          <li>Laser</li>
          <li>Plasma</li>
        </ul>
        <Text>
          Olika behandlingar passar olika bra för dina tänder och munhälsa. Din
          tandläkare eller tandhygienist kan hjälpa dig att hitta den bästa
          lösningen.
        </Text>
        <Text>
          Det är en god idé att vara väl påläst innan besöket och vända sig till
          en klinik som kan möta dina förväntningar och önskemål.
        </Text>
        <Text>
          En vårdgivare ska alltid innan en tandblekning utföra en
          basundersökning. Röntgenbilder bör vara en del av undersökningen.
        </Text>
        <Text>
          I övrigt utför tandläkaren eller hygienisten en sedvanlig undersökning
          och letar efter hål i tänderna m.m. Orsaken till tändernas
          missfärgning ska även fastställas.
        </Text>
        <ThirdHeading>Tandblekning med UV-ljus</ThirdHeading>
        <Text>
          <b>UV-ljus </b>
          innehåller skadliga, cancerframkallande våglängder.
        </Text>
        <Text>
          Därför måste vissa försiktighetsåtgärder innan behandlingen vidtas
          genom att vårdgivaren skyddar dina läppar, tandkött och tunga.
        </Text>
        <Text>
          En behandling med UV-ljus tar vanligtvis inte mer än två timmar.{" "}
        </Text>
        <Text>
          Blekningsgelén läggs på dina tänder och utsätts för UV-ljus i
          omgångar.
        </Text>
        <Text>
          Antalet omgångar varierar men kan t.ex. vara tre omgångar om 15
          minuter vardera med pauser i mellan.
        </Text>
        <ThirdHeading>Bleka tänder med laserblekning</ThirdHeading>
        <Text>
          Vid
          <b> laserblekning </b>
          skyddar vårdgivaren tandköttet med ett plastmaterial.
        </Text>
        <Text>
          Både patienten och vårdgivaren har också skyddsglasögon under
          blekningen.
        </Text>
        <Text>
          Behandlingen är ofarlig men laserblekning är inte lämplig för alla.
        </Text>
        <Text>
          Värmen från lasern kan exempelvis skada tandpulpan (mjukvävnaden inuti
          tanden, t.ex. nerver och blodkärl).
        </Text>
        <Text>
          Både UV-ljus och blekning med laser kan uppfattas som obehagligt men
          är däremot effektivt.
        </Text>
        <ThirdHeading>Blekning med plasma</ThirdHeading>
        <Text>
          <b>Plasmaljusblekning</b> förespråkas allt oftare pga. att dess
          våglängder är helt ofarliga.
        </Text>
        <Text>Skydd för läppar, tunga och tandkött är inte nödvändigt.</Text>
        <Text>
          Man brukar heller inte behöva besöka vårdgivare upprepade gånger för
          att få önskat resultat.
        </Text>

        <SecondHeading id="heading6">
          7. Hur mycket kostar tandblekningen?
        </SecondHeading>
        <ThirdHeading>Professionell tandblekning</ThirdHeading>
        <Text>Tandblekning klassificeras som ett estetiskt ingrepp.</Text>
        <Text>
          Det omfattas därmed{" "}
          <b>
            <i>inte</i>
          </b>{" "}
          av{" "}
          <a href="https://www.forsakringskassan.se/tandvard/statligt_tandvardsstod">
            Försäkringskassans tandvårdsstöd
          </a>
          .
        </Text>
        <Text>
          Det finns heller inga referenspriser vad gäller tandblekning.
        </Text>
        <Text>Prisbilden kan därför variera mycket.</Text>
        <Text>
          Att blästra eller putsa tänderna ingår ofta i priser för en vanlig
          basundersökning (ca 825 kr/besök).
        </Text>
        <Text>
          Att bleka tänderna på en tandklinik med UV-, laser- eller plasmaljus
          kostar mer än att bleka tänderna hemma. Men det är mycket snabbare och
          effektivare.
        </Text>
        <Text>
          Räkna däremot med att det kostar ungefär 3500 kronor eller mer
          beroende på vårdgivarens kompetens och utrustning.
        </Text>
        <Text>
          Ibland ger tillverkaren av blekningsmaterialet ett rekommenderat pris
          till den vårdpersonal som använder deras produkter.
        </Text>
        <Text>
          Besök om möjligt tillverkarens hemsida för att bilda dig en bättre
          förståelse.
        </Text>
        <ThirdHeading>
          Besök om möjligt tillverkarens hemsida för att bilda dig en bättre
          förståelse.
        </ThirdHeading>
        <Text>
          Tandblekning hemma med blekskena kostar ca 1000-2000 kronor.{" "}
        </Text>
        <Text>
          Exakt pris beror på vilken tillverkares produkter som används.{" "}
        </Text>
        <Text>
          Det kan finnas billigare alternativ vars resultat på tidigare
          användare inte är lika väl dokumenterade.
        </Text>
        <ThirdHeading>Andra hemmaprodukter för tandblekning</ThirdHeading>
        <Text>
          Allmänt är det billigaste alternativet att köpa produkter såsom strips
          eller tandkrämer för att bleka tänderna.
        </Text>
        <Text>
          Men som tidigare nämnt är detta inte alltid lämpligt för alla typer av
          missfärgningar på tänderna.
        </Text>
        <Text>
          I slutändan är det du som bestämmer vad din tandblekning ska få kosta.{" "}
        </Text>
        <Text>
          Men, precis som med allting annat, är kvalitet ofta något som man får
          betala extra för.
        </Text>

        <SecondHeading id="heading7">
          8. Att tänka på efter tandblekningen
        </SecondHeading>
        <Text>
          Hur lång effekten från blekningen blir är till stor del beroende av
          hur tänderna sköts i efterhand.
        </Text>
        <ThirdHeading>Tandborstning</ThirdHeading>
        <Text>
          Om kostnaden för blekningen var hög kan det vara klokt att investera
          en ytterligare slant på en bra tandborste som sköter om tänderna. Då
          hålls de vita längre.
        </Text>
        <Text>
          Vi rekommenderar att använda <b>eltandborste</b>. En eltandborste tar
          generellt bort mer plack och kommer ofta åt bättre.
        </Text>
        <Text>
          I och med att borsthuvudet ofta är mindre än en vanlig tandborste
          rekommenderar vi att först applicera en centimeter tandkräm på
          eltandborsten.
        </Text>
        <Text>Borsta först antingen över eller underkäken.</Text>
        <Text>
          Ta sedan en centimeter ytterligare tandkräm och borsta den andra
          käken.
        </Text>
        <Text>
          För dig som inte har en eltandborste handlar mycket om hur du borstar
          tänderna.
        </Text>
        <Text>
          Ett alltför vanligt misstag är att man borstar för hårt i tron att det
          blir renare. Så är dock inte fallet!
        </Text>
        <Text>
          Köp en tandborste med <b>mjukt borsthuvud</b>.
        </Text>
        <Text>
          Tänk också på att en liten tandborste enklare kommer åt på
          svåråtkomliga platser i munnen.
        </Text>
        <ThirdHeading>Val av tandkräm</ThirdHeading>
        <Text>
          Använd tandkräm med <b>hög halt av fluor</b> efter blekningen.
        </Text>
        <Text>
          Det finns särskilda tandkrämer för missfärgning mot t.ex. kaffe.
        </Text>
        <Text>
          Med jämna mellanrum kan du även köpa och använda något dyrare{" "}
          <b>tandkräm med blekande egenskaper</b>.
        </Text>
        <Text>
          Du behöver inte nödvändigtvis alltid använda denna typ av tandkräm.
        </Text>
        <ThirdHeading>Tuggummi</ThirdHeading>
        <Text>Småäter du ofta?</Text>
        <Text>
          <b>Tuggummi </b>
          utan socker och med xylitol motverkar syraattacker i munnen.
        </Text>
        <Text>Detta genom att mer saliv bildas i munnen än vanligt.</Text>
        <Text>
          Saliv kan man säga är naturens egna rengöringsmedel för munnen.
        </Text>
        <br />
        <Text>Så, det var vår lista än så länge!</Text>
        <Text>
          Vill du boka en tandblekning? Boka tandläkare eller tandhygienist
          online på{" "}
          <Link to="https://www.muntra.com/tandblekning/rp/11">muntra.com</Link>
          !
        </Text>
        <br />
        <SmallText>
          Muntra tillhandahåller ej medicinsk rådgivning, diagnos eller
          behandling. Vänligen hänvisa medicinska frågor och problem till din
          vårdgivare.
        </SmallText>
      </Col>
    </Row>
  );
};

export default ToothWhiteningContent;
