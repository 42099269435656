import React, { memo } from "react";
import * as PropTypes from "prop-types";

import { Modal } from "components/common/modal";

import { ImgStyled } from "./index.styled";

const AvatarModal = ({ onClose, avatar }) => {
  return (
    <Modal onClose={onClose} isAvatar>
      <ImgStyled lazy src={avatar} />
    </Modal>
  );
};

AvatarModal.propTypes = {
  onClose: PropTypes.func,
  avatar: PropTypes.string,
};

export default memo(AvatarModal);
