import { add } from "date-fns";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { CaregiversAtLocationsService } from "services/caregivers-at-locations-service";
import { ClinicsService } from "services/clinics-service";
import { PatientsReviewsService } from "services/patient-reviews-service";

import config from "config";

import { formatDate, getItemFields } from "../../helpers/helper-functions";
import { clinicsActions as actions } from "./actions";

function* fetchClinics({ payload: page }) {
  const dataConfig = {
    params: {
      include: "caregivers.caregiver_locations.clinic,caregivers.role",
      include_administrative_roles: false,
      sort_by: "clinic_name",
      per_page: 20,
      page,
    },
  };
  const result = yield call(ClinicsService.getClinics, dataConfig);
  if (!result) {
    yield put(actions.fetchClinicsError());
    return;
  }

  yield put(actions.fetchClinicsSuccess(result.data));
}

function* fetchClinicProcedure({ payload: id }) {
  yield put(actions.fetchClinicDataCompleted(false));

  const dataConfig = {
    params: {
      include_administrative_roles: false,
      include: [
        "google_place_detail.parent_place",
        "default_clinic_image",
        "clinic_images",
        "logotype",
        "country",
      ].join(","),
    },
  };
  const clinic = yield call(ClinicsService.getClinicById, id, dataConfig);
  if (!clinic?.data?.included) {
    yield put(actions.fetchClinicsError());
  } else {
    const relationships = getItemFields(
      clinic?.data?.data?.relationships,
      clinic?.data?.included,
    );
    const data = { ...clinic?.data?.data, ...relationships };
    yield put(actions.getClinicProcedureByIdSuccess(data));
  }
}

function* fetchClinicCaregivers({ payload: id }) {
  const dataConfig = {
    params: {
      include_administrative_roles: false,
      include: [
        "caregiver_locations.default_procedure.procedure",
        "caregiver_locations.caregivers.role",
        "caregiver_locations.caregivers.default_user_image",
      ].join(","),
    },
  };
  const clinic = yield call(ClinicsService.getClinicById, id, dataConfig);
  if (!clinic?.data?.data) {
    yield put(actions.getClinicCaregiversByIdError());
  } else {
    const relationships = getItemFields(
      clinic?.data?.data?.relationships,
      clinic?.data?.included,
    );
    const data = { ...clinic?.data?.data, ...relationships };
    yield put(
      actions.getClinicCaregiversByIdSuccess(data.caregiver_locations || []),
    );
  }
}

function* getClinicById({ payload }) {
  const dataConfig = {
    params: {
      include: [
        "caregiver_locations.default_procedure.procedure",
        "caregiver_locations.caregivers.role",
        "caregiver_locations.caregivers.default_user_image",
        "caregiver_locations.procedures.procedure",
        "google_place_detail.parent_place",
        "logotype",
        "country",
      ].join(","),
      include_administrative_roles: false,
      include_caregiver_locations_without_procedure_id_matches: true,
      new_patient: true,
      procedure_ids: payload?.defaultProcedure?.id || 1,
    },
  };
  const result = yield call(
    ClinicsService.getClinicById,
    payload?.clinic_id,
    dataConfig,
  );
  if (!result) {
    yield put(actions.fetchClinicsError());
    return;
  }

  const {
    data: {
      data: { relationships, ...rest },
      included,
    },
  } = result || {};

  const results = getItemFields(relationships, included);
  const clinic = { ...results, ...rest };
  yield put(actions.getClinicByIdSuccess(clinic));
}

function* getClinicForBookings({ payload }) {
  const dataConfig = {
    params: {
      dtend: formatDate(add(new Date(), { months: 1 })),
      dtstart: formatDate(new Date()),
      include: ["caregiver_locations.free_bookable_slots,country"].join(","),
      include_administrative_roles: false,
      include_caregiver_locations_without_procedure_id_matches: true,
      procedure_ids: payload.defaultProcedure?.id || config.defaultProcedureId,
    },
  };
  const result = yield call(
    ClinicsService.getClinicById,
    payload.clinic_id,
    dataConfig,
  );
  if (!result) {
    yield put(actions.fetchClinicsError());
    return;
  }

  const {
    data: {
      data: { relationships, ...rest },
      included,
    },
  } = result;
  const results = getItemFields(relationships, included);
  const clinic = { ...results, ...rest };
  yield put(actions.getClinicByIdSuccess(clinic));
}

function* getClinicByIdFull({ payload }) {
  try {
    const dataConfig = {
      params: {
        dtend: payload.dateEnd ? payload.dateEnd : formatDate(new Date()),
        dtstart: payload.dateStart ? payload.dateStart : formatDate(new Date()),
        include: [
          "caregiver_locations.default_procedure.procedure",
          "caregiver_locations.free_bookable_slots",
          "caregiver_locations.caregivers.role",
          "caregiver_locations.caregivers.default_user_image",
          "caregiver_locations.procedures.procedure",
          "google_place_detail.parent_place",
          "logotype",
          "country",
        ].join(","),
        include_administrative_roles: false,
        include_caregiver_locations_without_procedure_id_matches: true,
        new_patient: true,
        procedure_ids: payload?.defaultProcedure?.id
          ? payload?.defaultProcedure?.id
          : payload.procedure,
      },
    };
    const { data: res1 } = yield call(
      ClinicsService.getClinicById,
      payload.clinic_id,
      dataConfig,
    );
    const results1 = getItemFields(res1?.data?.relationships, res1?.included);
    const clinic1 = { ...res1?.data, ...results1 };

    yield put(actions.getClinicByIdFullSuccess(clinic1));

    dataConfig.params.include = `${dataConfig.params.include},caregiver_locations.next_free_bookable_slot`;
    const { data: res2 } = yield call(
      ClinicsService.getClinicById,
      payload.clinic_id,
      dataConfig,
    );
    const results2 = getItemFields(res2?.data?.relationships, res2?.included);
    const clinic2 = { ...res2?.data, ...results2, loadComplete: true };
    yield put(actions.getClinicByIdFullSuccess(clinic2));
    yield put(actions.fetchClinicDataCompleted(true));
  } catch {
    yield put(actions.fetchClinicsError());
  }
}

function* fetchClinicReviews({ payload: { id, page } }) {
  const dataConfig = {
    params: {
      order: "desc",
      page,
      per_page: 10,
      clinic_id: id,
      sort_by: "has_review_comment,created_date",
    },
  };
  const result = yield call(PatientsReviewsService.getReviews, dataConfig);
  if (!result) {
    yield put(actions.fetchClinicsError());
    return;
  }

  yield put(actions.fetchClinicReviewsSuccess(result.data));
}

function* fetchClinicSlots({ payload }) {
  const result = yield call(CaregiversAtLocationsService.getCaregiver, payload);
  if (!result) {
    yield put(actions.fetchClinicsError());
    return;
  }

  const {
    data: { data, included },
  } = result || {};
  const results = data?.map((item) => {
    const { relationships, ...rest } = item;
    const result = getItemFields(relationships, included);
    return { ...result, ...rest };
  });

  yield put(actions.fetchClinicSlotsSuccess(results));
}

export default function* clinicsSagas() {
  yield all([
    takeEvery(actions.CLINICS_FETCH, fetchClinics),
    takeEvery(actions.CLINIC_GET_PROCEDURE_BY_CLINIC_ID, fetchClinicProcedure),
    takeEvery(
      actions.CLINIC_GET_CAREGIVERS_BY_CLINIC_ID,
      fetchClinicCaregivers,
    ),
    takeEvery(actions.CLINIC_GET_BY_ID_FULL, getClinicByIdFull),
    takeEvery(actions.CLINIC_GET_BY_ID, getClinicById),
    takeEvery(actions.CLINIC_GET_BY_ID_FOR_BOOKINGS, getClinicForBookings),
    takeEvery(actions.CLINIC_REVIEWS_FETCH, fetchClinicReviews),
    takeEvery(actions.CLINIC_SLOTS_FETCH, fetchClinicSlots),
  ]);
}
