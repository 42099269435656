import styled, { css } from "styled-components";

import { Col } from "../../common.styled";
import Button from "../button";

import { Spinner } from "../spinner/index.styled";

export const Input = styled.input`
  font-size: 15px;
  background: white;
  border: 0;
  border-bottom: 1px solid #d2d2d2;
  padding: 7px 15px;
  &:focus {
    outline: 0;
  }
`;

export const MainInput = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
  position: relative;

  font-size: 15px;
  border: none;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  padding: 0px 15px;

  border-radius: 2px;

  & svg {
    height: 10px;
    width: 20px;
  }

  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

  color: rgba(0, 0, 0, 0.65);
  & svg {
    fill: rgba(0, 0, 0, 0.65);
  }
  & a {
    color: rgba(0, 0, 0, 0.65);
  }

  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.016) 0px 2px 0px;
  border-color: rgb(217, 217, 217);

  &:hover {
    background: rgb(255, 255, 255);
    color: rgb(64, 169, 255);
    & svg {
      fill: rgb(64, 169, 255);
    }
    & a {
      color: rgb(64, 169, 255);
    }

    border-color: rgb(64, 169, 255);
  }

  ${(props) => {
    if (props.disable)
      return css`
        & svg {
          display: none;
        }
        &:hover {
          color: rgba(0, 0, 0, 0.65);
          & svg {
            fill: rgba(0, 0, 0, 0.65);
          }
          & a {
            color: rgba(0, 0, 0, 0.65);
          }
          border-color: rgb(217, 217, 217);
        }
      `;
  }}
`;

export const OptionBadge = styled.div`
  background-color: rgb(24, 144, 255);
  color: white;
  margin: 2px 3px 2px 0px;
  padding: 4px 12px;
  border-radius: 5px;
  cursor: auto;

  ${(props) => {
    if (props.disable)
      return css`
        background-color: #cecece;
      `;
  }}
`;

export const CloseButton = styled.button`
  background: transparent;
  color: white;
  border: 0;
  padding: 0px 10px 0px 0px;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`;

export const ItemButton = styled(Button)`
  transition: all 0.3s;
  padding: 5px 10px;
  border: 0;
  &:hover {
    background: #f1f1f1;
  }
`;

export const ItemButtonFit = styled(ItemButton)`
  font-size: 16px;
  height: fit-content;
  line-height: 16px;
  padding: 8px 10px;
`;

export const MainButton = styled(Button)`
  width: 100%;
  height: fit-content;
  min-height: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
  position: relative;

  & svg {
    height: 10px;
  }
`;

export const ScrollList = styled(Col)`
  max-height: 200px;
  overflow: auto;
`;

export const List = styled(Col)`
  position: absolute;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 3px 3px;
  background: white;
  z-index: 10;
`;

export const StyledSpin = styled(Spinner)`
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: 10px;
`;

export const NoResult = styled.div`
  padding: 10px 15px;

  & p {
    margin-bottom: 0;
  }
`;
