const prefix = "[google-places]";

const TYPES = {
  GOOGLE_PLACES_CHANGE_SEARCH: `${prefix} GOOGLE_PLACES_CHANGE_SEARCH`,
  GOOGLE_PLACES_CLEAR_ERROR: `${prefix} GOOGLE_PLACES_CLEAR_ERROR`,
  GOOGLE_PLACES_FETCH: `${prefix} GOOGLE_PLACES_FETCH`,
  GOOGLE_PLACES_FETCH_ERROR: `${prefix} GOOGLE_PLACES_FETCH_ERROR`,
  GOOGLE_PLACES_FETCH_SUCCESS: `${prefix} GOOGLE_PLACES_FETCH_SUCCESS`,
  GOOGLE_PLACES_MAIN_FETCH: `${prefix} GOOGLE_PLACES_MAIN_FETCH`,
  GOOGLE_PLACES_MAIN_FETCH_SUCCESS: `${prefix} GOOGLE_PLACES_MAIN_FETCH_SUCCESS`,
  GOOGLE_PLACES_PATCH_SEARCH_TERMS: `${prefix} GOOGLE_PLACES_PATCH_SEARCH_TERMS`,
  GOOGLE_PLACES_PATCH_SEARCH_TERMS_ERROR: `${prefix} GOOGLE_PLACES_PATCH_SEARCH_TERMS_ERROR`,
  GOOGLE_PLACES_PATCH_SEARCH_TERMS_SUCCESS: `${prefix} GOOGLE_PLACES_PATCH_SEARCH_TERMS_SUCCESS`,
  GOOGLE_PLACES_SEARCH: `${prefix} GOOGLE_PLACES_SEARCH`,
  GOOGLE_PLACES_SEARCH_SUCCESS: `${prefix} GOOGLE_PLACES_SEARCH_SUCCESS`,
  GOOGLE_PLACES_TERMS_SUCCESS: `${prefix} GOOGLE_PLACES_TERMS_SUCCESS`,
};

export const googlePlacesActions = {
  ...TYPES,
  fetchGooglePlaces: (params) => ({
    type: TYPES.GOOGLE_PLACES_FETCH,
    payload: params,
  }),
  fetchGooglePlacesSuccess: (places) => ({
    type: TYPES.GOOGLE_PLACES_FETCH_SUCCESS,
    payload: places,
  }),
  fetchGooglePlacesMain: (params) => ({
    type: TYPES.GOOGLE_PLACES_MAIN_FETCH,
    payload: params,
  }),
  fetchGooglePlacesMainSuccess: (places) => ({
    type: TYPES.GOOGLE_PLACES_MAIN_FETCH_SUCCESS,
    payload: places,
  }),
  fetchGooglePlacesError: (error) => ({
    type: TYPES.GOOGLE_PLACES_FETCH_ERROR,
    payload: error,
  }),
  searchGooglePlaces: (search, isAutocomplete, page) => ({
    type: TYPES.GOOGLE_PLACES_SEARCH,
    payload: { search, isAutocomplete, page },
  }),
  searchGooglePlacesSuccess: (places) => ({
    type: TYPES.GOOGLE_PLACES_SEARCH_SUCCESS,
    payload: places,
  }),
  searchGooglePlacesChange: (search, isAutocomplete) => ({
    type: TYPES.GOOGLE_PLACES_CHANGE_SEARCH,
    payload: { search, isAutocomplete },
  }),
  postGooglePlacesSearchTermsSuccess: (data) => ({
    type: TYPES.GOOGLE_PLACES_TERMS_SUCCESS,
    payload: data,
  }),
  patchSelectedSearchGooglePlaceTerms: (data) => ({
    type: TYPES.GOOGLE_PLACES_PATCH_SEARCH_TERMS,
    payload: data,
  }),
  patchSelectedSearchGooglePlaceTermsSuccess: (data) => ({
    type: TYPES.GOOGLE_PLACES_PATCH_SEARCH_TERMS_SUCCESS,
    payload: data,
  }),
  patchSelectedSearchGooglePlaceTermsError: (error) => ({
    type: TYPES.GOOGLE_PLACES_PATCH_SEARCH_TERMS_ERROR,
    payload: error,
  }),
  clearPlacesError: () => ({
    type: TYPES.GOOGLE_PLACES_CLEAR_ERROR,
  }),
};
