const prefix = "[review]";

const TYPES = {
  REVIEW_CHANGE: `${prefix} REVIEW_CHANGE`,
  REVIEW_FETCH_CAREGIVER: `${prefix} REVIEW_FETCH_CAREGIVER`,
  REVIEW_FETCH_CAREGIVER_SUCCESS: `${prefix} REVIEW_FETCH_CAREGIVER_SUCCESS`,
  REVIEW_FETCH_ERROR: `${prefix} REVIEW_FETCH_ERROR`,
};

export const reviewActions = {
  ...TYPES,
  reviewFetchError: (error) => ({
    type: TYPES.REVIEW_FETCH_ERROR,
    payload: error,
  }),
  reviewFetchCaregiver: (id) => ({
    type: TYPES.REVIEW_FETCH_CAREGIVER,
    payload: id,
  }),
  reviewFetchCaregiverSuccess: (caregiver) => ({
    type: TYPES.REVIEW_FETCH_CAREGIVER_SUCCESS,
    payload: caregiver,
  }),
  reviewChange: (state) => ({
    type: TYPES.REVIEW_CHANGE,
    payload: state,
  }),
};
