import { proceduresActions as actions } from "./actions";

export const INIT_STATE = {
  searchLoading: false,
  searchResult: {},
  search: "",
  error: "",
  procedure: [],
  loading: false,
  selectedProcedures: [],
  isProcedure: false,
};

export const proceduresReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case actions.PROCEDURE_GET: {
      return {
        ...state,
        procedure: [],
        loading: true,
      };
    }
    case actions.PROCEDURE_SET: {
      return {
        ...state,
        procedure: action.payload,
        loading: false,
      };
    }
    case actions.PROCEDURES_SEARCH: {
      return {
        ...state,
        searchResult: {},
        searchLoading: true,
      };
    }
    case actions.PROCEDURES_SEARCH_SUCCESS: {
      return {
        ...state,
        searchResult: action.payload,
        searchLoading: false,
      };
    }
    case actions.PROCEDURES_CHANGE_SEARCH: {
      return {
        ...state,
        search: action.payload,
      };
    }
    case actions.PROCEDURES_FETCH_ERROR: {
      return {
        ...state,
        error: action.payload,
        searchLoading: false,
      };
    }
    case actions.PROCEDURES_SET_SELECTED: {
      const newState = { ...state, selectedProcedures: action.payload.data };
      if (action.payload.isClean) return { ...newState, isProcedure: true };
      if (action.payload.data.length === 0)
        return { ...newState, isProcedure: false };
      return newState;
    }
    case actions.PROCEDURES_SET_IS_PROCEDURES: {
      return {
        ...state,
        isProcedure: action.payload,
      };
    }
    default:
      return state;
  }
};
