import React from "react";

const PlusIcon = () => (
  <svg
    style={{ margin: "auto", width: "24px", height: "24px" }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path d="M426.667 170.667h-85.333V85.333C341.333 38.202 303.131 0 256 0s-85.333 38.202-85.333 85.333v85.333H85.333C38.202 170.667 0 208.869 0 256s38.202 85.333 85.333 85.333h85.333v85.333C170.667 473.798 208.869 512 256 512s85.333-38.202 85.333-85.333v-85.333h85.333C473.798 341.333 512 303.131 512 256s-38.202-85.333-85.333-85.333zm0 128H320c-11.782 0-21.333 9.551-21.333 21.333v106.667c0 23.567-19.099 42.667-42.667 42.667s-42.667-19.099-42.667-42.667V320c0-11.782-9.551-21.333-21.333-21.333H85.333c-23.567 0-42.667-19.099-42.667-42.667s19.099-42.667 42.667-42.667H192c11.782 0 21.333-9.551 21.333-21.333V85.333c0-23.567 19.099-42.667 42.667-42.667s42.667 19.099 42.667 42.667V192c0 11.782 9.551 21.333 21.333 21.333h106.667c23.567 0 42.667 19.099 42.667 42.667s-19.1 42.667-42.667 42.667z" />
  </svg>
);

export default PlusIcon;
