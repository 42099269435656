import { all, call, put, takeEvery } from "redux-saga/effects";
import { CaregiversService } from "services/caregivers-service";

import { getItemFields } from "../../helpers/helper-functions";
import { reviewActions as actions } from "./actions";

function* fetchCaregiver({ payload: id }) {
  const dataConfig = {
    params: { include: "role" },
  };
  const result = yield call(CaregiversService.getCaregiverById, id, dataConfig);
  if (!result) {
    yield put(actions.reviewFetchError());
  }

  const {
    data: {
      data: { relationships, ...rest },
      included,
    },
  } = result;
  const results = getItemFields(relationships, included);
  const caregiver = { ...results, ...rest };
  yield put(actions.reviewFetchCaregiverSuccess(caregiver));
}

export default function* reviewSagas() {
  yield all([takeEvery(actions.REVIEW_FETCH_CAREGIVER, fetchCaregiver)]);
}
