import React, { memo } from "react";
import * as PropTypes from "prop-types";

import Reschedule from ".";
import { RescheduleModalContainer } from "./reschedule.styled";

const RescheduleModal = ({
  bookingData,
  caregiver,
  onClose,
  onComplete,
  translations,
  authenticateUser,
  authenticationId,
}) => {
  return (
    <RescheduleModalContainer hideCloseArrow onClose={onClose}>
      <Reschedule
        bookingData={bookingData}
        caregiverData={caregiver}
        onComplete={onComplete}
        translations={translations}
        authenticateUser={authenticateUser}
        authenticationId={authenticationId}
      />
    </RescheduleModalContainer>
  );
};

RescheduleModal.propTypes = {
  bookingData: PropTypes.object,
  caregiver: PropTypes.object,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  translations: PropTypes.object,
  authenticateUser: PropTypes.bool,
  authenticationId: PropTypes.string,
};

export default memo(RescheduleModal);
