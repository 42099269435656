import styled from "styled-components";

export const StyledText = styled.div`
  font-size: 16px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem 1rem 12rem 1rem;
  font-family: Arial, sans-serif;
`;

export const Content = styled.div`
  margin: 50px 0;
  cursor: pointer;
  padding-bottom: 20px;
`;

export const PositionText = styled(StyledText)`
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 700;
`;

export const FirstHeader = styled.h1`
  font-size: 36px;
  font-weight: 700;
  line-height: 32px;
`;

export const SecondHeader = styled.h2`
  font-size: 26px;
  font-weight: 700;
  margin: 80px 0 20px 0;
`;
