import React, { memo } from "react";
import { Link } from "react-router-dom";
import * as PropTypes from "prop-types";

import { slugifyer } from "helpers/helper-functions";

import { MobileSection } from "components/common.styled";

import { StyledBreadcrumb } from "../../container/index.styled";

const Breadcrumbs = ({ fullName, translations, roleName, roleId }) => (
  <MobileSection style={{ paddingTop: 0 }}>
    <StyledBreadcrumb>
      <span style={{ margin: "0 10px 0 0" }}>
        <Link to="/start">{translations.start_page}</Link>
      </span>
      {">"}
      <span>
        <Link to={`/${slugifyer(roleName || "")}/r/${roleId || ""}`}>
          {roleName || ""}
        </Link>
      </span>
      {">"}
      <span style={{ margin: "0 0 0 10px" }}>{fullName || ""}</span>
    </StyledBreadcrumb>
  </MobileSection>
);

Breadcrumbs.propTypes = {
  fullName: PropTypes.string,
  translations: PropTypes.object,
  roleName: PropTypes.string,
  roleId: PropTypes.string,
};

export default memo(Breadcrumbs);
