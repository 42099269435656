import { all, fork } from "redux-saga/effects";

import appSagas from "./app/saga";
import authSagas from "./auth/saga";
import bookingSagas from "./booking/saga";
import caregiversSagas from "./caregivers/saga";
import clinicsSagas from "./clinics/saga";
import socketsSagas from "./event-bus/saga";
import googlePlacesSagas from "./google-places/saga";
import proceduresSagas from "./procedures/saga";
import reviewSagas from "./review/saga";
import rolesSagas from "./roles/saga";
import translationsSaga from "./translations/saga";

export default function* rootSaga() {
  yield all([
    fork(appSagas),
    fork(authSagas),
    fork(rolesSagas),
    fork(googlePlacesSagas),
    fork(caregiversSagas),
    fork(clinicsSagas),
    fork(proceduresSagas),
    fork(bookingSagas),
    fork(reviewSagas),
    fork(socketsSagas),
    fork(translationsSaga),
  ]);
}
