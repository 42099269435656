import { Link } from "react-router-dom";
import styled from "styled-components";

import { StyledLayout } from "../components/common.styled";

export const Layout = styled(StyledLayout)`
  background: white;
`;

export const Alink = styled(Link)`
  font-weight: 700;
`;

export const FakeSearchContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const FakeSearchDiv = styled.div`
  background: white;
  border-radius: 8px;
  border: 1px solid #d6dae2;
  display: flex;
  flex-grow: 2;
  height: 44px;
  margin-top: 6px;
  width: 100%;
`;

export const FakeSearchSpan = styled.span`
  flex-grow: 1;
  line-height: 43px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LocalCaregiversHeader = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 34px;
  margin: 40px 0 16px;
  @media (max-width: 993px) {
    padding: 0;
    font-size: 22px;
    line-height: 24px;
    margin-top: 16px;
  }
`;
