const prefix = "[procedures]";

const TYPES = {
  PROCEDURE_GET: `${prefix} PROCEDURE_GET`,
  PROCEDURE_SET: `${prefix} PROCEDURE_SET`,
  PROCEDURES_CHANGE_SEARCH: `${prefix} PROCEDURES_CHANGE_SEARCH`,
  PROCEDURES_FETCH_ERROR: `${prefix} PROCEDURES_FETCH_ERROR`,
  PROCEDURES_SEARCH: `${prefix} PROCEDURES_SEARCH`,
  PROCEDURES_SEARCH_SUCCESS: `${prefix} PROCEDURES_SEARCH_SUCCESS`,
  PROCEDURES_SET_IS_PROCEDURES: `${prefix} PROCEDURES_SET_IS_PROCEDURES`,
  PROCEDURES_SET_SELECTED: `${prefix} PROCEDURES_SET_SELECTED`,
};

export const proceduresActions = {
  ...TYPES,
  searchProcedures: (search) => ({
    type: TYPES.PROCEDURES_SEARCH,
    payload: search,
  }),
  searchProceduresSuccess: (procedures) => ({
    type: TYPES.PROCEDURES_SEARCH_SUCCESS,
    payload: procedures,
  }),
  getProcedure: (params) => ({
    type: TYPES.PROCEDURE_GET,
    payload: params,
  }),
  setProcedure: (procedure) => ({
    type: TYPES.PROCEDURE_SET,
    payload: procedure,
  }),
  searchProceduresChange: (search) => ({
    type: TYPES.PROCEDURES_CHANGE_SEARCH,
    payload: search,
  }),
  fetchProceduresError: (error) => ({
    type: TYPES.PROCEDURES_FETCH_ERROR,
    payload: error,
  }),
  setSelectedProcedure: (data, isClean) => ({
    type: TYPES.PROCEDURES_SET_SELECTED,
    payload: { data, isClean },
  }),
  setIsProcedure: (data) => ({
    type: TYPES.PROCEDURES_SET_IS_PROCEDURES,
    payload: data,
  }),
};
