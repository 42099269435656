import React from "react";
import * as PropTypes from "prop-types";

const ErrorMessage = ({ error, translations }) => {
  if (error === "Muntra Clinic with Id not found")
    return (
      <div style={{ margin: "30vh auto 0", textAlign: "center" }}>
        <b> {translations.clinic_removed} </b>{" "}
      </div>
    );

  if (error === "Network Error")
    return (
      <div style={{ margin: "30vh auto 0", textAlign: "center" }}>
        <b> {translations.generic_error_start} </b>{" "}
      </div>
    );

  return (
    <div style={{ margin: "30vh auto 0", textAlign: "center" }}>
      <b>{translations.generic_error_start}</b>
    </div>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.string,
  translations: PropTypes.object,
};

export default ErrorMessage;
