export const illnesses = [
  { checkBoxPropertyName: "asthma", checkBoxText: "Astma" },
  {
    checkBoxPropertyName: "osteoporosis",
    checkBoxText: "Benskörhet",
  },
  {
    checkBoxPropertyName: "hemophilia",
    checkBoxText: "Blödarsjuka",
  },
  {
    checkBoxPropertyName: "blood_infection",
    checkBoxText: "Blodsmitta",
  },
  {
    checkBoxPropertyName: "cancer",
    checkBoxText: "Cancer",
  },
  {
    checkBoxPropertyName: "diabetes",
    checkBoxText: "Diabetes",
  },
  {
    checkBoxPropertyName: "epilepsy",
    checkBoxText: "Epilepsi",
  },
  {
    checkBoxPropertyName: "hiv_aids",
    checkBoxText: "HIV/AIDS",
  },
  {
    checkBoxPropertyName: "cerebral_haemorrhage",
    checkBoxText: "Hjärnblödning",
  },
  {
    checkBoxPropertyName: "cardiovascular_diseases",
    checkBoxText: "Hjärt-/kärlsjukdom",
  },
  {
    checkBoxPropertyName: "high_blood_pressure",
    checkBoxText: "Högt blodtryck",
  },
  {
    checkBoxPropertyName: "immunological_disease",
    checkBoxText: "Immunitetssjukdom",
  },
  {
    checkBoxPropertyName: "supplement_for_dental_treatment",
    checkBoxText: "Komplikation efter tandbehandling",
  },
  {
    checkBoxPropertyName: "nutrition_diet",
    checkBoxText: "Kost/diet",
  },
  {
    checkBoxPropertyName: "respiratory_disease",
    checkBoxText: "Lungsjukdom",
  },
  {
    checkBoxPropertyName: "pacemaker",
    checkBoxText: "Pacemaker",
  },
  {
    checkBoxPropertyName: "parkinsons",
    checkBoxText: "Parkinsons",
  },
  {
    checkBoxPropertyName: "mental_health_problems",
    checkBoxText: "Psykiska hälsoproblem",
  },
  {
    checkBoxPropertyName: "rheumatic_disease",
    checkBoxText: "Reumatisk sjukdom",
  },
  {
    checkBoxPropertyName: "smokes_takes_snuff",
    checkBoxText: "Röker/Snusar",
  },
  {
    checkBoxPropertyName: "gets_chemo_therapy",
    checkBoxText: "Strålbehandlad",
  },
  {
    checkBoxPropertyName: "eating_disorder",
    checkBoxText: "Ätstörningar",
  },
  {
    checkBoxPropertyName: "ear_nose_throat",
    checkBoxText: "Öron/nästa/hals",
  },
];

export const allergies = [
  {
    checkBoxPropertyName: "allergy_latex",
    checkBoxText: "Latex",
  },
  {
    checkBoxPropertyName: "allergy_local_anesthesia",
    checkBoxText: "Lokalbedövning",
  },
  {
    checkBoxPropertyName: "allergy_edibles",
    checkBoxText: "Matvaror",
  },
  {
    checkBoxPropertyName: "allergy_nickel",
    checkBoxText: "Nickel",
  },
  {
    checkBoxPropertyName: "allergy_penicillin",
    checkBoxText: "Penicillin",
  },
  {
    checkBoxPropertyName: "allergy_pollen",
    checkBoxText: "Pollen",
  },
];

export const bookingReasons = [
  {
    checkBoxPropertyName: "mouth_bleeding_gums",
    checkBoxText: "Blödande tandkött",
  },
  {
    checkBoxPropertyName: "mouth_bruxism",
    checkBoxText: "Bruxism (gnissla tänder)",
  },
  {
    checkBoxPropertyName: "mouth_halitosis",
    checkBoxText: "Dålig andedräkt",
  },
  {
    checkBoxPropertyName: "mouth_finger_sucker",
    checkBoxText: "Fingersugning",
  },
  {
    checkBoxPropertyName: "mouth_mouth_breather",
    checkBoxText: "Munandning",
  },
  {
    checkBoxPropertyName: "mouth_mouth_ulceration",
    checkBoxText: "Munsår",
  },
  {
    checkBoxPropertyName: "mouth_xerostomia",
    checkBoxText: "Muntorrhet",
  },
  {
    checkBoxPropertyName: "mouth_sore_masticatory_muscles",
    checkBoxText: "Ömma tuggmuskler",
  },
];
