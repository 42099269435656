import styled, { css } from "styled-components";

import Button from "../common/button";
import Search from "../common/search";

export const Logo = styled.img`
  cursor: pointer;
  height: 81px;
  margin: auto 0;

  @media (max-width: 993px) {
    height: 64px;
    width: 128px;
  }
`;

export const LogoMobile = styled.img`
  cursor: pointer;
  height: 35px;
  margin: auto;
  @media (min-width: 740px) {
    height: 40px;
    margin: 8px;
  }
`;

export const StyledButton = styled(Button)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 5px;
  height: 42px;
  margin: auto 0;
  padding: 0 10px;
  @media (max-width: 500px) {
    display: none;
  }
`;

export const StyledSearch = styled(Search)`
  background: white;
  margin-right: 10px;
  transition: width 0.3s;
`;

export const LeftSearch = styled(StyledSearch)`
  margin: 0;
  width: 100%;

  @media (max-width: 740px) {
    flex-grow: 1;
    align-self: center;
  }

  @media (max-width: 500px) {
    margin-right: 0;
  }
  @media (max-width: 740px) {
    border-radius: 5px;
    width: 90%;
  }
`;

export const RightSearch = styled(StyledSearch)`
  border-bottom-left-radius: 5px;
  border-right: none;
  border-top-left-radius: 5px;
  margin: 0;
  width: 100%;

  @media (max-width: 500px) {
    margin-right: 0;
  }

  @media (max-width: 740px) {
    flex-grow: 1;
    align-self: center;
    width: 90%;
    border-radius: 5px;
  }
`;

export const SearchResultName = styled.div`
  font-weight: 500;
  & em {
    font-style: normal;
    color: #1f9dff;
    font-weight: bold;
  }
`;

export const SearchResultText = styled.p`
  font-size: 12px;
  color: #0000004f;
  margin-bottom: 0px;
`;

export const StyledOptionContainer = styled.div`
  ${({ active }) => active && `background-color: #00000012`};
  display: flex;
  flex-direction: row;
  padding-bottom: 14px;
  justify-content: space-between;
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 1px solid #f4f4f6;

  &:hover {
    background-color: #00000008;
  }
`;

export const Person = styled.img`
  max-width: 70px;
  width: 100%;
  margin-right: 10px;
  border-radius: 50%;
`;

export const MobileMenu = styled.div`
  display: none;
  padding: 0 5px;
  margin: 0 10px 0 0;

  @media (max-width: 993px) {
    display: flex;
  }

  &:hover {
    background: #00000017;
  }
`;

export const DesktopMenu = styled.div`
  display: flex;

  @media (max-width: 993px) {
    display: none;
  }
`;

export const MobileMenuLogo = styled.div``;

export const SearchRow = styled.div`
  margin-left: 25px;
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 740px) {
    display: contents;
  }
`;

export const BottomMenuContainer = styled.div`
  z-index: 100;
  border-top: 1px solid #00000036;
  height: 65px;
  width: 100%;
  background-color: white;
  position: fixed;
  bottom: 0;
  right: 0;
`;

export const MainHeaderDiv = styled.div`
  z-index: 100;
  width: 100%;
  top: 0;
`;
export const MainContainer = styled.div`
  display: flex;
  margin: auto 0;
  width: 95%;

  @media (max-width: 993px) {
    width: 100% !important;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  white-space: nowrap;

  & > div {
    justify-content: center;
  }

  & > div > div {
    justify-content: center;
  }
`;

export const ButtonContainer = styled.div`
  & svg {
    fill: #767676;
  }
  &:hover {
    & svg {
      fill: #1890ff;
    }
    & span {
      color: #1890ff;
    }
  }
  ${(props) => {
    if (props.linkStatus)
      return css`
        & svg {
          fill: #1890ff;
        }
        & span {
          color: #1890ff;
        }
      `;
  }}
`;

export const Icon = styled.div`
  margin: 0 auto;
`;
