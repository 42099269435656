import styled from "styled-components";

import { Spinner } from "../spinner/index.styled";

export const SearchInputContainer = styled.div`
  min-height: 28px;
  border: 1px solid #00000036;
  border-radius: 2px;
  display: flex;
  transition: all 0.3s;
  height: 42px;

  &:hover {
    @media (max-width: 740px) {
    }
    border: 1px solid #1890ff;
    & svg {
      fill: #1890ff;
    }
  }

  &:focus-within {
    box-shadow: 0px 0px 1px 3px rgba(24, 143, 255, 0.31);
    border: 1px solid #1890ff;
    @media (max-width: 740px) {
    }
    & svg {
      fill: #1890ff;
    }
  }

  & input[type="search"] {
    border: none;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 20px;
    background-color: transparent;

    &:focus {
      outline: 0;
    }
  }

  & svg {
    width: 16px;
    height: 16px;
    align-self: center;
    fill: #00000036;
    transition: all 0.3s;
  }

  @media (max-width: 740px) {
    border: none;
    min-height: 44px;
  }
  @media (max-width: 993px) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #d6dae2 !important;
  }
`;

export const LeftSearchInputContainer = styled(SearchInputContainer)`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
  @media (max-width: 993px) {
    border-right: 1px solid #d6dae2 !important;
    border: 1px solid #d6dae2;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const StartPageSearchLeftInputContainer = styled(
  LeftSearchInputContainer,
)`
  height: 52px !important;
  align-items: center;
`;

export const StartPageSearchRightInputContainer = styled(SearchInputContainer)`
  height: 52px !important;
  align-items: center;
`;

export const OptionsContainer = styled.div`
  opacity: ${({ options }) => (options ? "1" : "0")};
  visibility: ${({ options }) => (options ? "visible" : "hidden")};
  line-height: 20px;
  max-height: 250px;
  background: white;
  border-radius: 0px 0px 3px 3px;
  width: 100%;
  z-index: 6;
  @media (min-width: 994px) {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
    overflow: auto;
    position: absolute;
  }
`;

export const SearchContainer = styled.div`
  position: relative;
  @media (max-width: 740px) {
    margin: 13px 0 8px 0;
  }
`;

export const SearchSpin = styled(Spinner)`
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: 10px;
`;

export const NoResults = styled.div`
  padding: 10px 20px;
`;
