import React, { useState } from "react";
import * as PropTypes from "prop-types";

import { Container, NumberOfSlide, Slide, Slider } from "./index.styled";

const ClinicImagesMobile = ({
  images,
  toggleModal,
  isLoaded,
  referralSourceEqualStatus,
}) => {
  const [selectedImage, setSelectedImage] = useState(1);

  const slider = document?.getElementById("slider");
  slider?.addEventListener("scroll", () => {
    let ticking = false;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        if (slider.scrollLeft === 0) setSelectedImage(1);
        else if (slider.scrollLeft !== 0)
          setSelectedImage(
            Math.round(slider.scrollLeft / global.innerWidth + 1),
          );
        ticking = false;
      });
      ticking = true;
    }
  });

  return (
    <Container
      referralSourceEqualStatus={referralSourceEqualStatus}
      width={global.innerWidth}
    >
      <Slider id="slider">
        {images?.clinicDefaultImage && (
          <Slide id="0">
            {images?.clinicDefaultImage?.attributes && (
              <img
                loading="lazy"
                id="defIMG"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                onClick={toggleModal}
                src={`${images.clinicDefaultImage.attributes.original}?w=${global.innerWidth}`}
                alt="Clinic default images"
              />
            )}
          </Slide>
        )}
        {isLoaded &&
          images?.clinicImages.length !== 0 &&
          images?.clinicImages?.map((item, index) => {
            return (
              <Slide
                id={images?.clinicDefaultImage?.attributes ? index + 1 : index}
                key={images?.clinicDefaultImage?.attributes ? index + 1 : index}
              >
                {item?.attributes?.original && (
                  <img
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    onClick={toggleModal}
                    src={`${item.attributes.original}?w=${global.innerWidth}`}
                    alt="Clinic images"
                  />
                )}
              </Slide>
            );
          })}
      </Slider>
      {isLoaded && (
        <NumberOfSlide>
          <div>
            {" "}
            {`${selectedImage} / ${
              (images?.clinicDefaultImage?.attributes ? 1 : 0) +
              images.clinicImages.length
            }`}{" "}
          </div>
        </NumberOfSlide>
      )}
    </Container>
  );
};

ClinicImagesMobile.propTypes = {
  images: PropTypes.object,
  toggleModal: PropTypes.func,
  isLoaded: PropTypes.bool,
  referralSourceEqualStatus: PropTypes.bool,
};

ClinicImagesMobile.defaultProps = {
  images: {},
};

export default ClinicImagesMobile;
