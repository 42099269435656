import styled from "styled-components";

import {
  ContentContainer,
  Heading,
  StyledContent,
  StyledLayout,
} from "components/common.styled";

export const MainHeading = styled(Heading)`
  font-size: 80px;
  font-weight: 600;
  text-align: center;
  line-height: normal;
  margin: 60px 0;
  color: #031b4e;
  @media (max-width: 1024px) {
    font-size: 30px;
    margin: 0;
  }
`;

export const Layout = styled(StyledLayout)`
  background: white;
`;

export const Content = styled(StyledContent)`
  box-shadow: none;
  width: 100%;
  margin-bottom: 400px;
  max-width: 1120px !important;
  margin: 0 auto;
  padding: 2rem 0 0;
  @media (max-width: 740px) {
    max-width: 100%;
    padding: 0;
    margin: 3rem auto 0;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1260px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 916px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 768px) {
    display: block;
  }
`;

export const CategoryImg = styled.img`
  height: 200px;
  max-width: 100%;
  object-fit: cover;
  width: 498px;
  border-radius: 12px;
  @media (max-width: 993px) {
    height: 80px;
  }
`;

export const CaregiverTypeCards = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0px;
  @media (max-width: 993px) {
    padding-top: 0;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 7px;
  }
`;

export const CategoryContainer = styled.div`
  border-radius: 12px !important;
  color: #2a2e43;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  min-width: 100px;
  position: relative;
  width: 100%;
  width: fit-content;
  text-align: left;
  && p {
    margin-top: 5px;
    color: black;
    @media (max-width: 993px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export const ImageContainer = styled.div`
  display: block;
  position: relative;
`;

export const CaregiverTypeCardDeck = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const InnerContainer = styled.div`
  margin: 0 auto;
  max-width: 1120px !important;
`;

export const UpperContainer = styled(ContentContainer)`
  margin: 0 auto;
  max-width: 1120px !important;
  padding-top: 100px;

  @media (max-width: 993px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 40px;
  }
`;

export const LowerContainer = styled(ContentContainer)`
  background-color: #f2f2f2;
`;

export const FakeSearchButton = styled.button`
  background-color: rgb(0, 85, 255);
  border-radius: 12px;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  height: 52px;
  line-height: 21px;
  margin-top: 12px;
  width: 100%;
`;

export const PlacesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0 250px;
`;

export const ErrorContainer = styled.div`
  margin: 30vh auto 0;
  text-align: center;
`;

export const SearchBarHeaderHeading = styled.div`
  margin: 60px 0 60px 0;
  height: 92px;
`;

export const SearchBarHeaderHeadingMobile = styled.div`
  height: 34px;
`;
export const LocalCaregiverList = styled.ul`
  padding-left: 20px;
  margin: 0;
  list-style-type: none;
`;
