import React, { memo } from "react";
import * as PropTypes from "prop-types";

import defaultUserImage from "assets/images/defaultUserIcon.png";
import clinicPlaceholderImage from "assets/images/placeholder.jpeg";

import Calendar from "components/calendar";
import { Avatar, StyledText, Text } from "components/common.styled";

import {
  ClinicDefaultImage,
  Container,
  ImageContainer,
  LocalHeader,
  TextContainer,
} from "./index.styled";

const NearestCaregiverCard = ({
  defaultProcedure,
  slotDetails,
  translations,
  noSlots = <Text>{translations.r_no_available_times_to_book}</Text>,
  searchSlotsLoading,
  dateObject,
}) => {
  const { caregiver = {}, clinic = {} } = slotDetails || {};
  const firstName = caregiver?.attributes?.first_name || "";
  const lastName = caregiver?.attributes?.last_name || "";
  const role = caregiver?.role?.attributes?.name || "";

  const clinicAdress = clinic.attributes?.clinic_address_1 || "";
  const clinicPostalCode = clinic.attributes?.clinic_postal_code || "";
  const clinicCity = clinic.attributes?.clinic_city || "";

  const fullName = `${firstName} ${lastName}`;
  const locationAdress = `${clinicAdress}, ${clinicPostalCode} ${clinicCity}`;

  const avatarProps = {
    alt: fullName,
  };

  return (
    <div>
      <Container>
        <ImageContainer>
          {!clinic.default_clinic_image?.attributes && (
            <ClinicDefaultImage loading="lazy" src={clinicPlaceholderImage} />
          )}
          {clinic.default_clinic_image?.attributes?.original && (
            <ClinicDefaultImage
              loading="lazy"
              src={`${clinic.default_clinic_image.attributes.original}?w=600`}
              alt="Clinic default images"
            />
          )}
          {caregiver && (
            <div>
              {caregiver.default_user_image && (
                <Avatar
                  {...avatarProps}
                  loading="lazy"
                  src={caregiver.default_user_image.attributes?.small_thumbnail}
                />
              )}
              {!caregiver.default_user_image && (
                <Avatar
                  {...avatarProps}
                  loading="lazy"
                  src={defaultUserImage}
                />
              )}
            </div>
          )}
        </ImageContainer>
        <TextContainer>
          <LocalHeader>
            {" "}
            {role} {fullName}{" "}
          </LocalHeader>
          <StyledText> {locationAdress} </StyledText>
        </TextContainer>
        <div style={{ padding: "0 15px 20px" }}>
          <Calendar
            caregiver={caregiver}
            clinic={clinic}
            defaultProcedure={defaultProcedure}
            location={slotDetails}
            translations={translations}
            dateObject={dateObject}
            caregiverAtLocationObject={{
              caregiverAtLocation: slotDetails,
              noCaregiverAtLocation: noSlots,
              caregiverAtLocationLoading: searchSlotsLoading,
            }}
          />
        </div>
      </Container>
    </div>
  );
};

NearestCaregiverCard.propTypes = {
  defaultProcedure: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  slotDetails: PropTypes.object,
  translations: PropTypes.object,
  noSlots: PropTypes.element,
  searchSlotsLoading: PropTypes.bool,
  dateObject: PropTypes.object,
};

NearestCaregiverCard.defaultProps = {
  defaultProcedure: {},
};

export default memo(NearestCaregiverCard);
