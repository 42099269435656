import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Alink,
  LocalCaregiversHeader,
} from "style/global-styled-components.styled";

import config from "config";
import { slugifyer } from "helpers/helper-functions";
import { useTranslations } from "hooks/use-translations";
import { caregiversActions } from "redux/caregivers/actions";
import { googlePlacesActions } from "redux/google-places/actions";

import Footer from "components/footer";
import Search from "components/search";
import { Layout } from "components/search/index.styled";

const GooglePlace = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { google_place_name: googlePlaceName, google_place_id: googlePlaceId } =
    useParams();

  const routeName = "[first_param].g.[google_place_id]";

  const { error } = useSelector((state) => state.caregivers);
  const {
    placesError,
    startData,
    data: places,
  } = useSelector((state) => state.googlePlaces);

  const translations = useTranslations(routeName);

  const onPageChange = (page) => {
    history.push(
      {
        pathname: location.pathname,
        search: `?page=${page}`,
      },
      {
        pathname: `/${googlePlaceName}/g/${googlePlaceId}`,
        search: `?page=${page}`,
      },
    );
    dispatch(caregiversActions.changePageCaregiverSlotsSearch(+page));
  };

  useEffect(() => {
    if (googlePlaceId)
      dispatch(
        googlePlacesActions.fetchGooglePlacesMain({
          id: googlePlaceId,
          include: "child_places",
        }),
      );
  }, [dispatch, googlePlaceId]);

  useEffect(() => {
    if (error) dispatch(caregiversActions.clearCaregiverError());
  }, [dispatch, error]);

  useEffect(() => {
    if (placesError) dispatch(googlePlacesActions.clearPlacesError());
  }, [dispatch, placesError]);

  useEffect(() => {
    if (googlePlaceId)
      dispatch(
        googlePlacesActions.fetchGooglePlaces({
          id: googlePlaceId,
          include: "parent_place",
        }),
      );
  }, [dispatch, googlePlaceId]);

  return (
    <Layout>
      {places?.info?.attributes?.name && (
        <Helmet>
          <title>{`${translations.dental_care_close_to} ${places?.info?.attributes?.name} | ${translations.meta_title_booking}`}</title>
          <meta
            id="meta-description"
            name="description"
            content={`${translations.dental_care_close_to} ${places?.info?.attributes?.name}${translations.meta_content_places}`}
          />
          <link
            id="canonical-link"
            rel="canonical"
            href={config.url + location.pathname.replace(/\?.*$/gm, "")}
          />
        </Helmet>
      )}
      <Search
        firstParam={googlePlaceName}
        isPlaces
        onPageChange={onPageChange}
        placeName={places?.attributes?.name}
        translations={translations}
      />
      <div style={{ display: "flex", margin: "100px 133px 250px" }}>
        <div>
          <LocalCaregiversHeader>
            {translations.find_caregiver_location}
          </LocalCaregiversHeader>
          <ul style={{ paddingLeft: "20px", margin: "0" }}>
            {startData?.child_places?.map((item) => {
              const { name, place_id: placeId } = item.attributes;
              return (
                <li key={placeId}>
                  <Alink to={`/${slugifyer(name)}/g/${placeId}`}>{name}</Alink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <Footer translations={translations} />
    </Layout>
  );
};

export default GooglePlace;
