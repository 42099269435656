export function readablePhoneNumber([phoneNumber]) {
  if (!phoneNumber) return phoneNumber;
  const trimmedPhoneNumber = phoneNumber.replace(/\s/g, ``);
  if (trimmedPhoneNumber.substring(0, 3) === "+46") {
    const trimmedPhoneNumberWithoutSwedishCountryCode =
      trimmedPhoneNumber.replace(/^\+46/, `0`);
    if (trimmedPhoneNumberWithoutSwedishCountryCode.substring(0, 2) === "07") {
      return trimmedPhoneNumberWithoutSwedishCountryCode.replace(
        /(^.{3})(.{3})(.{2})(.{2}$)/,
        `$1‑$2 $3 $4`,
      );
    }
    if (trimmedPhoneNumberWithoutSwedishCountryCode.substring(0, 2) === "08") {
      switch (trimmedPhoneNumberWithoutSwedishCountryCode.length) {
        case 10:
          return trimmedPhoneNumberWithoutSwedishCountryCode.replace(
            /(^.{2})(.{3})(.{3})(.{2}$)/,
            `$1‑$2 $3 $4`,
          );
        case 9:
          return trimmedPhoneNumberWithoutSwedishCountryCode.replace(
            /(^.{2})(.{3})(.{2})(.{2}$)/,
            `$1‑$2 $3 $4`,
          );
        case 8:
          return trimmedPhoneNumberWithoutSwedishCountryCode.replace(
            /(^.{2})(.{2})(.{2})(.{2}$)/,
            `$1‑$2 $3 $4`,
          );
        default:
          return trimmedPhoneNumberWithoutSwedishCountryCode;
      }
    }
    const threeDigitLengthAreaCode = [
      "011",
      "013",
      "016",
      "018",
      "019",
      "021",
      "023",
      "026",
      "031",
      "033",
      "035",
      "036",
      "040",
      "042",
      "044",
      "046",
      "054",
      "060",
      "063",
      "090",
    ];
    if (
      threeDigitLengthAreaCode.includes(
        trimmedPhoneNumberWithoutSwedishCountryCode.substring(0, 3),
      )
    ) {
      switch (trimmedPhoneNumberWithoutSwedishCountryCode.length) {
        case 10:
          return trimmedPhoneNumberWithoutSwedishCountryCode.replace(
            /(^.{3})(.{3})(.{2})(.{2}$)/,
            `$1‑$2 $3 $4`,
          );
        case 9:
          return trimmedPhoneNumberWithoutSwedishCountryCode.replace(
            /(^.{3})(.{2})(.{2})(.{2}$)/,
            `$1‑$2 $3 $4`,
          );
        case 8:
          return trimmedPhoneNumberWithoutSwedishCountryCode.replace(
            /(^.{3})(.{3})(.{2}$)/,
            `$1‑$2 $3`,
          );

        default:
          return trimmedPhoneNumberWithoutSwedishCountryCode;
      }
    }
    if (trimmedPhoneNumberWithoutSwedishCountryCode.length === 10) {
      return trimmedPhoneNumberWithoutSwedishCountryCode.replace(
        /(^.{4})(.{2})(.{2})(.{2}$)/,
        `$1‑$2 $3 $4`,
      );
    }
    if (trimmedPhoneNumberWithoutSwedishCountryCode.length === 9) {
      return trimmedPhoneNumberWithoutSwedishCountryCode.replace(
        /(^.{4})(.{3})(.{2}$)/,
        `$1‑$2 $3`,
      );
    }
    return trimmedPhoneNumberWithoutSwedishCountryCode;
  }
  if (trimmedPhoneNumber.substring(0, 3) === "+47") {
    const trimmedPhoneNumberWithoutNorwegianCountryCode =
      trimmedPhoneNumber.replace(/^\+47/, ``);
    return trimmedPhoneNumberWithoutNorwegianCountryCode.replace(
      /(^.{2})(.{2})(.{2})(.*$)/,
      `$1 $2 $3 $4`,
    );
  }
  return trimmedPhoneNumber;
}
