import React from "react";
import * as PropTypes from "prop-types";
import styled, { css } from "styled-components";

const StyledDivButton = styled.div`
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  border: none;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;

  padding: ${(props) => {
    if (props.circle) return "10px";
    return "0px 15px";
  }};

  border-radius: ${(props) => {
    if (props.circle) return "16px";
    return "2px";
  }};

  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

  color: rgb(255, 255, 255);
  & a {
    color: rgb(255, 255, 255);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }
  & svg {
    fill: rgb(255, 255, 255);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }
  & span {
    margin-left: 8px;
  }

  background-color: rgb(24, 144, 255);
  box-shadow: rgba(0, 0, 0, 0.043) 0px 2px 0px;
  border-color: rgb(24, 144, 255);

  &:hover {
    color: rgb(255, 255, 255);
    & a {
      color: rgb(255, 255, 255);
    }
    & svg {
      fill: rgb(255, 255, 255);
    }

    background-color: rgb(64, 169, 255);
    border-color: rgb(64, 169, 255);
  }

  ${(props) => {
    if (props.secondary)
      return css`
        color: rgba(0, 0, 0, 0.65);
        & svg {
          fill: rgba(0, 0, 0, 0.65);
        }
        & a {
          color: rgba(0, 0, 0, 0.65);
        }

        background-color: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.016) 0px 2px 0px;
        border-color: rgb(217, 217, 217);

        &:hover {
          background: rgb(255, 255, 255);
          color: rgb(64, 169, 255);
          & svg {
            fill: rgb(64, 169, 255);
          }
          & a {
            color: rgb(64, 169, 255);
          }

          border-color: rgb(64, 169, 255);
        }
      `;
  }}

  ${(props) => {
    if (props.link)
      return css`
        border: 0;
        color: rgb(24, 144, 255);
        & svg {
          fill: rgb(24, 144, 255);
        }
        & a {
          color: rgb(24, 144, 255);
        }

        background-color: transparent;
        box-shadow: none;

        &:hover {
          background: transparent;
          color: rgb(64, 169, 255);
          & svg {
            fill: rgb(64, 169, 255);
          }
          & a {
            color: rgb(64, 169, 255);
          }
        }
      `;
  }}

  height: 32px;
  display: flex;
  align-items: center;

  & svg {
    height: 100%;
    width: auto;
  }
`;

const ButtonDiv = ({ secondary, primary, circle, ...props }) => {
  return (
    <StyledDivButton
      secondary={secondary}
      primary={primary}
      circle={circle}
      {...props}
    />
  );
};

ButtonDiv.propTypes = {
  secondary: PropTypes.bool,
  primary: PropTypes.bool,
  circle: PropTypes.bool,
};

export default ButtonDiv;
