import React from "react";

const BankIDSvg = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 317 300">
    <g fillRule="nonzero" fill="none">
      <path
        d="M99.9 156.6l13.2-83.2H99c-6.6 0-15.1-3.7-17.6-10.5-.8-2.3-2.7-10.2 8.2-17.9 3.9-2.7 6.4-5.7 6.9-8 .5-2.4-.1-4.5-1.8-6.1-2.4-2.3-7.1-3.6-13.1-3.6-10.1 0-17.2 5.8-17.9 10-.5 3.1 1.9 5.6 4 7.2 6.3 4.7 7.8 11.5 3.9 17.9-4 6.6-12.7 10.9-22 11H35.2C34 81.5 14.4 205.7 12.9 215.5H91c.7-4.4 4.3-27.9 9.2-58.9h-.3z"
        fill="#479CBE"
      />
      <path
        d="M204.5.6H125l-10.6 67.3h13.5c7.4 0 14.4-3.4 17.4-8.3 1-1.6 1.4-3 1.4-4.3 0-2.8-1.9-4.9-3.8-6.3-5.2-3.9-6.3-8-6.3-10.9 0-.6 0-1.1.1-1.6 1.1-7.1 10.7-14.8 23.4-14.8 7.6 0 13.4 1.8 16.9 5.1 3.1 2.9 4.3 7 3.4 11.3-1.1 5.1-6.2 9.3-9.1 11.4-7.7 5.4-6.7 10.1-6.2 11.5 1.6 4.2 7.7 6.9 12.4 6.9H198v.1c28 .2 43 13.1 38.3 43.1-4.4 27.9-25.8 39.9-51.3 40.1l-10.1 64.4h14.9c62.9 0 114.3-40.4 124.4-104.2C326.7 32.2 276.5.6 204.5.6z"
        fill="#00A5C3"
      />
      <path
        d="M204.5.6H125l-10.6 67.3h13.5c7.4 0 14.4-3.4 17.4-8.3 1-1.6 1.4-3 1.4-4.3 0-2.8-1.9-4.9-3.8-6.3-5.2-3.9-6.3-8-6.3-10.9 0-.6 0-1.1.1-1.6 1.1-7.1 10.7-14.8 23.4-14.8 7.6 0 13.4 1.8 16.9 5.1 3.1 2.9 4.3 7 3.4 11.3-1.1 5.1-6.2 9.3-9.1 11.4-7.7 5.4-6.7 10.1-6.2 11.5 1.6 4.2 7.7 6.9 12.4 6.9H198v.1c28 .2 43 13.1 38.3 43.1-4.4 27.9-25.8 39.9-51.3 40.1l-10.1 64.4h14.9c62.9 0 114.3-40.4 124.4-104.2C326.7 32.2 276.5.6 204.5.6z"
        fill="#235971"
      />
      <g fill="#235971">
        <path d="M8.7 244.2h31.9c13.6 0 16.9 6.9 15.9 13.2-.8 5.1-4.3 8.9-10.3 11.4 7.6 2.9 10.6 7.4 9.5 14.5-1.4 8.9-9.1 15.5-19.2 15.5H.2l8.5-54.6zm21.1 22.6c6.2 0 9.1-3.3 9.7-7.2.6-4.2-1.3-7.1-7.5-7.1h-5.5l-2.2 14.3h5.5zm-3.4 23.6c6.4 0 10.1-2.6 11-7.9.7-4.6-1.9-7.3-8.1-7.3H23l-2.4 15.3h5.8v-.1zM100.4 299.2c-8.3.6-12.3-.3-14.3-3.9-4.4 2.7-9.3 4.1-14.5 4.1-9.4 0-12.7-4.9-11.8-10.3.4-2.6 1.9-5.1 4.3-7.2 5.2-4.5 18-5.1 23-8.5.4-3.8-1.1-5.2-5.8-5.2-5.5 0-10.1 1.8-18 7.2l1.9-12.4c6.8-4.9 13.4-7.2 21-7.2 9.7 0 18.3 4 16.7 14.6l-1.9 12c-.7 4.2-.5 5.5 4.2 5.6l-4.8 11.2zM86 280.4c-4.4 2.8-12.6 2.3-13.5 8.1-.4 2.7 1.3 4.7 4 4.7 2.6 0 5.8-1.1 8.4-2.9-.2-1-.1-2 .2-3.9l.9-6zM115.9 256.5h16.6l-.9 5.5c5.3-4.5 9.3-6.2 14.5-6.2 9.3 0 13.6 5.7 12.1 15l-4.3 27.9h-16.6l3.6-23.1c.7-4.2-.6-6.2-3.8-6.2-2.6 0-5 1.4-7.3 4.5l-3.8 24.7h-16.6l6.5-42.1zM171.1 244.2h16.6l-4.2 26.8 15.9-14.5h20.5l-20.4 18 16.4 24.2H195l-12.6-19.5h-.2l-3 19.5h-16.6z" />
      </g>
      <g fill="#479CBE">
        <path d="M229.9 244.2H249l-8.4 54.5h-19.1zM258.3 244.2h27.3c21.1 0 27.2 15.3 25.2 28-1.9 12.4-11.7 26.5-30.2 26.5h-30.8l8.5-54.5zm17.7 41.5c9.3 0 14.4-4.6 15.9-14.3 1.1-7.2-1.1-14.3-11.4-14.3h-5.1l-4.4 28.6h5z" />
      </g>
    </g>
  </svg>
);

export default BankIDSvg;
