import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import config from "config";
import { useTranslations } from "hooks/use-translations";
import { caregiversActions } from "redux/caregivers/actions";
import { rolesActions } from "redux/roles/actions";

import Footer from "components/footer";
import Search from "components/search";
import { Layout } from "components/search/index.styled";

const Role = () => {
  const { role } = useSelector((state) => state.roles);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { role_name: roleName, role_id: roleId } = useParams();

  const routeName = "[first_param].r.[role_id]";
  const translations = useTranslations(routeName);

  useEffect(() => {
    if (roleId) {
      dispatch(
        rolesActions.fetchRole({ id: roleId, include: "default_procedure" }),
      );
    }
  }, [dispatch, roleId]);

  useEffect(() => {
    return () => dispatch(rolesActions.cleanRole());
  }, [dispatch]);

  const onPageChange = (e) => {
    history.push(
      {
        pathname: location.pathname,
        search: `?page=${e}`,
      },
      {
        pathname: `/${roleName}/g/${roleId}`,
        search: `?page=${e}`,
      },
    );
    dispatch(caregiversActions.changePageCaregiverSlotsSearch(+e));
  };

  return (
    <Layout>
      {role?.attributes && (
        <Helmet>
          <title>{`${role?.attributes?.name} | ${translations.meta_title_booking}`}</title>
          <meta
            id="meta-description"
            name="description"
            content={translations.meta_content_role_search}
          />
          <link
            id="canonical-link"
            rel="canonical"
            href={config.url + location.pathname.replace(/\?.*$/gm, "")}
          />
        </Helmet>
      )}
      <Search
        firstParam={roleName}
        roleId={roleId}
        onPageChange={onPageChange}
        role={role}
        translations={translations}
      />

      <Footer translations={translations} />
    </Layout>
  );
};

export default Role;
