import React, { memo, useCallback } from "react";
import * as PropTypes from "prop-types";

import SmileyHappy from "assets/svg-components/smileys/SmileyHappy";
import SmileyNeutral from "assets/svg-components/smileys/SmileyNeutral";
import SmileySad from "assets/svg-components/smileys/SmileySad";

const HealthDeclarationPreview = ({
  allergiesCheckBoxesUsed,
  hasAllergies,
  hasIllness,
  hasSpecialReasonForBooking,
  healthDeclarationObject,
  illnessesCheckBoxesUsed,
  reasonForBookingCheckBoxesUsed,
  translations,
}) => {
  const otherTextBool =
    healthDeclarationObject.special_notes !== "" ||
    healthDeclarationObject.medications_free_text !== "";

  const renderAllergyPreview = useCallback(
    () => (
      <div>
        <h2 className="bookingHeaderTextStyle  HealthDeclarationPreviewH2">
          {translations.health_declaration_allergy_heading}
        </h2>
        <div style={{ marginTop: "20px" }}>
          {healthDeclarationObject.allergy_latex && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_allergy_latex}
            </p>
          )}
          {healthDeclarationObject.allergy_local_anesthesia && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_allergy_local_anesthesia}
            </p>
          )}
          {healthDeclarationObject.allergy_edibles && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_allergy_edibles}
            </p>
          )}
          {healthDeclarationObject.allergy_nickel && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_allergy_nickel}
            </p>
          )}
          {healthDeclarationObject.allergy_penicillin && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_allergy_penicillin}
            </p>
          )}
          {healthDeclarationObject.allergy_pollen && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_allergy_pollen}
            </p>
          )}
        </div>
      </div>
    ),
    [healthDeclarationObject, translations],
  );

  const renderIllnessPreview = useCallback(
    () => (
      <div>
        <h2 className="bookingHeaderTextStyle  HealthDeclarationPreviewH2">
          {translations.health_declaration_illness_heading}
        </h2>
        <div style={{ marginTop: "20px" }}>
          {healthDeclarationObject.asthma && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_asthma}
            </p>
          )}
          {healthDeclarationObject.osteoporosis && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_osteoporosis}
            </p>
          )}
          {healthDeclarationObject.hemophilia && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_hemophilia}
            </p>
          )}
          {healthDeclarationObject.blood_infection && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_blood_infection}
            </p>
          )}
          {healthDeclarationObject.cancer && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_cancer}
            </p>
          )}
          {healthDeclarationObject.diabetes && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_diabetes}
            </p>
          )}
          {healthDeclarationObject.epilepsy && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_epilepsy}
            </p>
          )}
          {healthDeclarationObject.hiv_aids && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_hiv_aids}
            </p>
          )}
          {healthDeclarationObject.cerebral_haemorrhage && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_cerebral_haemorrhage}
            </p>
          )}
          {healthDeclarationObject.cardiovascular_diseases && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_cardiovascular_diseases}
            </p>
          )}
          {healthDeclarationObject.high_blood_pressure && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_high_blood_pressure}
            </p>
          )}
          {healthDeclarationObject.immunological_disease && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_immunological_disease}
            </p>
          )}
          {healthDeclarationObject.supplement_for_dental_treatment && (
            <p style={{ textAlign: "left" }}>
              {
                translations.health_declaration_illness_supplement_for_dental_treatment
              }
            </p>
          )}
          {healthDeclarationObject.nutrition_diet && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_nutrition_diet}
            </p>
          )}
          {healthDeclarationObject.respiratory_disease && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_respiratory_disease}
            </p>
          )}
          {healthDeclarationObject.pacemaker && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_pacemaker}
            </p>
          )}
          {healthDeclarationObject.parkinsons && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_parkinsons}
            </p>
          )}
          {healthDeclarationObject.mental_health_problems && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_mental_health_problems}
            </p>
          )}
          {healthDeclarationObject.rheumatic_disease && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_rheumatic_disease}
            </p>
          )}
          {healthDeclarationObject.smokes_takes_snuff && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_smokes_takes_snuff}
            </p>
          )}
          {healthDeclarationObject.gets_chemo_therapy && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_gets_chemo_therapy}
            </p>
          )}
          {healthDeclarationObject.eating_disorder && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_eating_disorder}
            </p>
          )}
          {healthDeclarationObject.ear_nose_throat && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_illness_ear_nose_throat}
            </p>
          )}
        </div>
      </div>
    ),
    [healthDeclarationObject, translations],
  );

  const renderBookingReasonPreview = useCallback(
    () => (
      <div>
        <h2 className="bookingHeaderTextStyle  HealthDeclarationPreviewH2">
          {translations.health_declaration_booking_reason_header}
        </h2>
        <div style={{ marginTop: "20px" }}>
          {healthDeclarationObject.mouth_bleeding_gums && (
            <p style={{ textAlign: "left" }}>
              {
                translations.health_declaration_booking_reason_mouth_bleeding_gums
              }
            </p>
          )}
          {healthDeclarationObject.mouth_bruxism && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_booking_reason_mouth_bruxism}
            </p>
          )}
          {healthDeclarationObject.mouth_halitosis && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_booking_reason_mouth_halitosis}
            </p>
          )}
          {healthDeclarationObject.mouth_finger_sucker && (
            <p style={{ textAlign: "left" }}>
              {
                translations.health_declaration_booking_reason_mouth_finger_sucker
              }
            </p>
          )}
          {healthDeclarationObject.mouth_mouth_breather && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_booking_reason_mouth_breather}
            </p>
          )}
          {healthDeclarationObject.mouth_mouth_ulceration && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_booking_reason_mouth_ulceration}
            </p>
          )}
          {healthDeclarationObject.mouth_xerostomia && (
            <p style={{ textAlign: "left" }}>
              {translations.health_declaration_booking_reason_mouth_xerostomia}
            </p>
          )}
          {healthDeclarationObject.mouth_sore_masticatory_muscles && (
            <p style={{ textAlign: "left" }}>
              {
                translations.health_declaration_booking_reason_mouth_sore_masticatory_muscles
              }
            </p>
          )}
        </div>
      </div>
    ),
    [healthDeclarationObject, translations],
  );

  return (
    <div className="FlexColJustCenter" style={{ width: "300px" }}>
      <h1
        className="bookingHeader"
        style={{ width: "min-content", marginTop: "40px" }}
      >
        {translations.health_declaration_header_confirm}
      </h1>
      <div
        className="HealthDeclarationSmileyDiv"
        style={{ cursor: "default", paddingTop: "0px" }}
      >
        {hasIllness && illnessesCheckBoxesUsed && renderIllnessPreview()}
        {hasAllergies && allergiesCheckBoxesUsed && renderAllergyPreview()}
        {hasSpecialReasonForBooking &&
          reasonForBookingCheckBoxesUsed &&
          renderBookingReasonPreview()}

        <h2 className="bookingHeaderTextStyle  HealthDeclarationPreviewH2">
          {translations.health_declaration_patient_health_header}
        </h2>
        {healthDeclarationObject.patient_health_evaluation ===
          translations.health_declaration_patient_health_bad && (
          <div className="flexRowCenter" style={{ marginTop: "20px" }}>
            <SmileySad classProp="flexStartSelf" />
            <label
              className="HealthDeclarationSmileyLabel"
              style={{ fontSize: "14px", fontWeight: "0", marginLeft: "10px" }}
            >
              {translations.health_declaration_patient_health_bad_label}
            </label>
          </div>
        )}
        {healthDeclarationObject.patient_health_evaluation ===
          translations.health_declaration_patient_health_average && (
          <div className="flexRowCenter" style={{ marginTop: "20px" }}>
            <SmileyNeutral classProp="flexStartSelf" />
            <label
              className="HealthDeclarationSmileyLabel"
              style={{ fontSize: "14px", fontWeight: "0", marginLeft: "10px" }}
            >
              {translations.health_declaration_patient_health_average_label}
            </label>
          </div>
        )}
        {healthDeclarationObject.patient_health_evaluation ===
          translations.health_declaration_patient_health_good && (
          <div className="flexRowCenter" style={{ marginTop: "20px" }}>
            <SmileyHappy classProp="flexStartSelf" />
            <label
              className="HealthDeclarationSmileyLabel"
              style={{ fontSize: "14px", fontWeight: "0", marginLeft: "10px" }}
            >
              {translations.health_declaration_patient_health_good_label}
            </label>
          </div>
        )}
        {otherTextBool && (
          <h2
            className="bookingHeaderTextStyle  HealthDeclarationPreviewH2"
            style={{ marginBottom: "20px" }}
          >
            {translations.health_declaration_heading_other}
          </h2>
        )}
        {healthDeclarationObject.medications_free_text !== "" ? (
          <p
            className="noMargin"
            style={{ textAlign: "left", whiteSpace: "normal !important" }}
          >
            {healthDeclarationObject.medications_free_text}
          </p>
        ) : (
          ""
        )}
        {healthDeclarationObject.special_notes !== "" ? (
          <p style={{ textAlign: "left", whiteSpace: "normal !important" }}>
            {healthDeclarationObject.special_notes}
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

HealthDeclarationPreview.propTypes = {
  allergiesCheckBoxesUsed: PropTypes.bool,
  hasAllergies: PropTypes.bool,
  hasIllness: PropTypes.bool,
  hasSpecialReasonForBooking: PropTypes.bool,
  healthDeclarationObject: PropTypes.object,
  illnessesCheckBoxesUsed: PropTypes.bool,
  reasonForBookingCheckBoxesUsed: PropTypes.bool,
  translations: PropTypes.object,
};

export default memo(HealthDeclarationPreview);
