import React, { useState } from "react";
import * as PropTypes from "prop-types";

import ImageModal from "components/common/image-modal";

const ClinicImagesMobileContent = ({ images }) => {
  const [isActive, setIsActive] = useState(false);
  const [SrcImg, setSrcImg] = useState("");

  const handleClick = (e) => {
    setIsActive(true);
    setSrcImg(e.target.src);
  };

  return (
    <div>
      <div style={{ display: `${isActive ? "none" : "block"}` }}>
        {images?.clinicDefaultImage?.attributes && (
          <img
            loading="lazy"
            style={{ width: "100%", paddingBottom: "5 px" }}
            id={0}
            onClick={handleClick}
            src={`${images?.clinicDefaultImage.attributes.original}?w=${global.innerWidth}`}
            alt="Clinic default images"
          />
        )}
        {images?.clinicImages?.map((item, index) => {
          return (
            <div style={{ marginTop: index === 0 ? "5px" : 0 }} key={index}>
              {item?.attributes?.original && (
                <img
                  loading="lazy"
                  style={{
                    display: "none",
                    width: "100%",
                    paddingBottom: "5px",
                  }}
                  id={index + 1}
                  onClick={handleClick}
                  src={`${item.attributes.original}?w=${global.innerWidth}`}
                  alt="Clinic default images"
                />
              )}
            </div>
          );
        })}
      </div>
      {isActive && (
        <ImageModal
          setSrcImg={setSrcImg}
          SrcImg={SrcImg}
          isActive={isActive}
          setIsActive={setIsActive}
        />
      )}
    </div>
  );
};

ClinicImagesMobileContent.propTypes = {
  images: PropTypes.object,
};

export default ClinicImagesMobileContent;
