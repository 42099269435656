import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import * as PropTypes from "prop-types";

import {
  ScheduleContainer,
  ScheduleContent,
  StickyWrapper,
} from "./reschedule.styled";
import RescheduleItem from "./reschedule-item";

const RescheduleContent = ({
  goToDate,
  setCurrentClinic,
  setSelectedSlot,
  setStep,
  slots,
  startDate,
  timeStep,
  translations,
}) => {
  const { slotsLoading } = useSelector((state) => state.caregivers);

  useEffect(() => {
    if (slots.clinic?.id) setCurrentClinic(slots.clinic);
  }, [setCurrentClinic, slots.clinic]);

  return (
    <ScheduleContainer>
      <StickyWrapper>
        {slots?.caregiver && (
          <ScheduleContent style={{ padding: 0 }}>
            {slots.clinic && (
              <RescheduleItem
                goToDate={goToDate}
                id={slots.caregiver?.id}
                key={0}
                setSelectedSlot={setSelectedSlot}
                setStep={setStep}
                slots={slots}
                slotsLoading={slotsLoading}
                startDate={startDate}
                timeStep={timeStep}
                translations={translations}
              />
            )}
          </ScheduleContent>
        )}
      </StickyWrapper>
    </ScheduleContainer>
  );
};

RescheduleContent.propTypes = {
  goToDate: PropTypes.func,
  setCurrentClinic: PropTypes.func,
  setSelectedSlot: PropTypes.func,
  setStep: PropTypes.func,
  slots: PropTypes.object,
  startDate: PropTypes.instanceOf(Date),
  timeStep: PropTypes.number,
  translations: PropTypes.object,
};

RescheduleContent.defaultProps = {
  slots: {},
};

export default memo(RescheduleContent);
