import React, { memo } from "react";
import { Link } from "react-router-dom";
import * as PropTypes from "prop-types";

import { slugifyer } from "helpers/helper-functions";

import {
  CategoryContainer,
  CategoryImg,
  ImageContainer,
} from "../../container/index.styled";

const TreatmentContent = ({
  treatmentContentName,
  treatmentParam,
  imageUrl,
}) => (
  <CategoryContainer key={treatmentParam}>
    <Link to={`/${slugifyer(treatmentParam)}`}>
      <ImageContainer>
        <CategoryImg
          loading="lazy"
          src={imageUrl}
          alt={`${treatmentContentName}`}
        />
      </ImageContainer>
      <p>{treatmentContentName}</p>
    </Link>
  </CategoryContainer>
);

TreatmentContent.propTypes = {
  treatmentContentName: PropTypes.string,
  treatmentParam: PropTypes.string,
  imageUrl: PropTypes.string,
};

export default memo(TreatmentContent);
