import { useQuery } from "./use-query";

export const useReferralSource = () => {
  const query = useQuery();
  const referralSourceGet = query.get("referral_source");

  if (
    typeof referralSourceGet === "object" &&
    !Array.isArray(referralSourceGet) &&
    referralSourceGet !== null
  )
    return "";

  return referralSourceGet;
};
