import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useTranslations } from "hooks/use-translations";

import Footer from "components/footer";
import Header from "components/header";
import { Layout } from "components/search/index.styled";

import InvoicesContent from "../components/invoices-content";

const Invoices = () => {
  const authState = useSelector((state) => state.auth);

  const history = useHistory();

  const routeName = "invoices";
  const translations = useTranslations(routeName);

  useEffect(() => {
    if (!localStorage.getItem("user_token_pat_frontend"))
      history.push("/login");
  }, [authState, history]);

  return (
    <Layout style={{ backgroundColor: "white" }}>
      <Helmet>
        <title>{`${translations.meta_title_invoices} | ${translations.meta_title_default}`}</title>
      </Helmet>
      <Header translations={translations} isStartPage={false} />
      <InvoicesContent />
      <Footer translations={translations} />
    </Layout>
  );
};

export default Invoices;
