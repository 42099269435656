const {
  NODE_ENV,
  REACT_APP_API_HOST,
  REACT_APP_ERROR_NOTIFICATION_SERVICE_URL,
  REACT_APP_URL_HOST,
  REACT_APP_DEFAULT_PROCEDURE_ID,
  REACT_APP_GA_TRACKING_ID,
  REACT_APP_MPMS_TOKEN,
} = process.env;

const config = {
  api: "https://api.testing.muntra.com/api",
  defaultProcedureId: 2,
  defaultProcedureTitle: "Basundersökning av tandläkare",
  env: "development",
  errorNotificationApi:
    "https://app.testing.muntra.com/log-api/error-notifications",
  gaTrackingId: "UA-88721215-7",
  mpmsToken: "YZmwhJ9UU7LiTL6dsixP91HlG57TYtXpSwrldupmjKykX5a82qy6Tza1WGn9i1we",
  url: "http://localhost:8080",
};

const api = REACT_APP_API_HOST || window.ENV?.REACT_APP_API_HOST || config.api;

const defaultProcedureId =
  REACT_APP_DEFAULT_PROCEDURE_ID ||
  window.ENV?.REACT_APP_DEFAULT_PROCEDURE_ID ||
  config.defaultProcedureId;
const env = NODE_ENV || config.env;
const errorNotificationApi =
  REACT_APP_ERROR_NOTIFICATION_SERVICE_URL ||
  window.ENV?.REACT_APP_ERROR_NOTIFICATION_SERVICE_URL ||
  config.errorNotificationApi;
const gaTrackingId =
  REACT_APP_GA_TRACKING_ID ||
  window.ENV?.REACT_APP_GA_TRACKING_ID ||
  config.gaTrackingId;
const mpmsToken =
  REACT_APP_MPMS_TOKEN || window.ENV?.REACT_APP_MPMS_TOKEN || config.mpmsToken;
const url = REACT_APP_URL_HOST || window.ENV?.REACT_APP_URL_HOST || config.url;

export default {
  api,
  defaultProcedureId,
  defaultProcedureTitle: config.defaultProcedureTitle,
  env,
  errorNotificationApi,
  gaTrackingId,
  mpmsToken,
  url,
};
