import { reviewActions as actions } from "./actions";

export const INIT_STATE = {
  error: "",
  loading: false,
  caregiver: {},
  clinic: {},
  review: {},
};

export const reviewReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case actions.REVIEW_FETCH_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case actions.REVIEW_FETCH_CAREGIVER: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.REVIEW_FETCH_CAREGIVER_SUCCESS: {
      return {
        ...state,
        caregiver: action.payload,
        loading: false,
      };
    }
    case actions.REVIEW_CHANGE: {
      return {
        ...state,
        review: { ...state.review, ...action.payload },
      };
    }
    default:
      return state;
  }
};
