import styled from "styled-components";

export const BookingsInnerContainer = styled.div`
  margin: 0 10%;
  padding: 2rem 2rem 12rem 2rem;
  font-family: Arial, sans-serif;
  @media (max-width: 740px) {
    margin: 0 0 240px;
    padding: 3rem 0 0;
  }
`;

export const BookingHeader = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: 34px;
  margin: 10px 0 32px;
  @media (max-width: 993px) {
    padding: 0 32px 0;
  }
`;

export const BookingsGrid = styled.div`
  padding: 50px 0;
  display: grid;
  grid-gap: 5em;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  @media (max-width: 993px) {
    display: block;
    padding: 0px 32px;
  }
`;

export const PastBookingsGrid = styled(BookingsGrid)`
  margin-bottom: 0;
`;
