import React, { memo } from "react";
import { addDays, subDays } from "date-fns";
import * as PropTypes from "prop-types";

import Wrapper from "../wrapper";
import RescheduleSlots from "./reschedule-slots";
import RescheduleSlotsMobile from "./reschedule-slots-mobile";

const RescheduleCalendar = ({
  goToDate,
  id,
  translations,
  setSelectedSlot,
  setStep,
  slots,
  slotsLoading,
  startDate,
  timeStep,
}) => {
  const middleDate = addDays(startDate, 1);
  const endDate = addDays(startDate, 2);

  const goBack = () => goToDate(subDays(startDate, timeStep));
  const goNext = () => goToDate(addDays(startDate, timeStep));

  if (global.innerWidth < 994)
    return (
      <Wrapper
        goBack={goBack}
        goNext={goNext}
        slotsLoadings={slotsLoading}
        startDate={startDate}
      >
        <RescheduleSlotsMobile
          goToDate={goToDate}
          id={id}
          setSelectedSlot={setSelectedSlot}
          setStep={setStep}
          slots={slots}
          slotsLoading={slotsLoading}
          startDate={startDate}
          translations={translations}
        />
      </Wrapper>
    );

  return (
    <Wrapper
      endDate={endDate}
      goBack={goBack}
      goNext={goNext}
      middleDate={middleDate}
      slotsLoadings={slotsLoading}
      startDate={startDate}
    >
      <RescheduleSlots
        goToDate={goToDate}
        id={id}
        setSelectedSlot={setSelectedSlot}
        setStep={setStep}
        slots={slots}
        slotsLoading={slotsLoading}
        startDate={startDate}
        translations={translations}
      />
    </Wrapper>
  );
};

RescheduleCalendar.propTypes = {
  goToDate: PropTypes.func,
  id: PropTypes.string,
  translations: PropTypes.object,
  setSelectedSlot: PropTypes.func,
  setStep: PropTypes.func,
  slots: PropTypes.object,
  slotsLoading: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  timeStep: PropTypes.number,
};

export default memo(RescheduleCalendar);
