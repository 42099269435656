import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router";

import CloseIcon from "assets/svg-components/CloseIcon";
import LeftArrowSVG from "assets/svg-components/LeftArrow";
import config from "config";
import {
  allergies,
  bookingReasons,
  illnesses,
} from "constants/health-declaration-info";
import { mobileValidator } from "helpers/helper-functions";
import { Notifications } from "helpers/notifications";
import { useTranslations } from "hooks/use-translations";

import Header from "components/header";
import CheckBoxesAndTextAreaContent from "components/health-declaration/check-boxes-and-text-area-content";
import HealthDeclarationPreview from "components/health-declaration/health-declaration-preview";
import HealthStateStep from "components/health-declaration/health-state-step";
import NextButton from "components/health-declaration/next-button";
import TwoAlternativesButtons from "components/health-declaration/two-alternatives-buttons";

import HealthDeclarationPageHeader from "../components/health-declaration-header";

const HealthDeclarationPage = () => {
  const history = useHistory();
  const { booking_external_binding_id: bookingExternalBindingId } = useParams();

  const routeName = "h.n.[external_id]";
  const translations = useTranslations(routeName);

  const [healthDeclarationObject, setHealthDeclarationObject] = useState({
    allergy_edibles: false,
    allergy_latex: false,
    allergy_local_anesthesia: false,
    allergy_nickel: false,
    allergy_penicillin: false,
    allergy_pollen: false,
    allergy_other: false,
    asthma: false,
    blood_infection: false,
    cancer: false,
    cardiovascular_diseases: false,
    cerebral_haemorrhage: false,
    diabetes: false,
    ear_nose_throat: false,
    eating_disorder: false,
    epilepsy: false,
    gets_chemo_therapy: false,
    hemophilia: false,
    high_blood_pressure: false,
    hiv_aids: false,
    immunological_disease: false,
    medications_free_text: "",
    mental_health_problems: false,
    mouth_bleeding_gums: false,
    mouth_bruxism: false,
    mouth_halitosis: false,
    mouth_finger_sucker: false,
    mouth_mouth_breather: false,
    mouth_mouth_ulceration: false,
    mouth_xerostomia: false,
    mouth_sore_masticatory_muscles: false,
    mouth_other: false,
    nutrition_diet: false,
    osteoporosis: false,
    other: false,
    pacemaker: false,
    parkinsons: false,
    patient_health_evaluation: "",
    respiratory_disease: false,
    rheumatic_disease: false,
    smokes_takes_snuff: false,
    special_notes: "",
    supplement_for_dental_treatment: false,
  });

  const [allergyOtherText, setAllergyOtherText] = useState("");
  const [hasAllergies, setHasAllergies] = useState(false);
  const [hasIllness, setHasIllness] = useState(false);
  const [hasSpecialReasonForBooking, setHasSpecialReasonForBooking] =
    useState(false);
  const [illnessOtherText, setIllnessOtherText] = useState("");
  const [medicineOtherText, setMedicineOtherText] = useState("");
  const [
    specialReasonForBookingOtherText,
    setSpecialReasonForBookingOtherText,
  ] = useState("");
  const [step, setStep] = useState(1);
  const [takesMedicine, setTakesMedicine] = useState(false);
  const [wantsToShareMoreInfo, setWantsToShareMoreInfo] = useState(false);
  const [wantsToShareMoreInfoOtherText, setWantsToShareMoreInfoOtherText] =
    useState("");
  const [illnessesCheckBoxesUsed, setIllnessesCheckBoxesUsed] = useState(false);
  const [allergiesCheckBoxesUsed, setAllergiesCheckBoxesUsed] = useState(false);
  const [reasonForBookingCheckBoxesUsed, setReasonForBookingCheckBoxesUsed] =
    useState(false);

  const isMobile = mobileValidator.test(window.navigator.userAgent);

  const getBackButtonClassName = () => {
    if (step === 1) {
      return "transparentSVG";
    }
    if (step === 8) {
      return "transparentSVG";
    }
    return "";
  };

  const getClassName = (i) => {
    if (step === i) return "";
    return step > i ? "previous" : "next";
  };

  const scrollToTop = useCallback(() => {
    window.scrollY.toFixed(0);
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [scrollToTop, step]);

  const specialNotes = useMemo(() => {
    return `${
      illnessOtherText !== ""
        ? `${translations.hn_illness_other_text}: ${illnessOtherText}\n\n`
        : ""
    }${
      allergyOtherText !== ""
        ? `${translations.hn_allergies}: ${allergyOtherText}\n\n`
        : ""
    }${
      specialReasonForBookingOtherText !== ""
        ? `${translations.hn_special_reason_other_text}: ${specialReasonForBookingOtherText}\n\n`
        : ""
    }${
      wantsToShareMoreInfoOtherText !== ""
        ? `${translations.hn_other_info}: ${wantsToShareMoreInfoOtherText}\n\n`
        : ""
    }`;
  }, [
    illnessOtherText,
    allergyOtherText,
    specialReasonForBookingOtherText,
    wantsToShareMoreInfoOtherText,
    translations,
  ]);

  const medicationsFreeText = useMemo(() => {
    return `${
      medicineOtherText !== ""
        ? `${translations.hn_medicines}: ${medicineOtherText}\n\n`
        : ""
    }`;
  }, [medicineOtherText, translations]);

  useEffect(() => {
    setHealthDeclarationObject((prev) => ({
      ...prev,
      special_notes: specialNotes,
    }));
  }, [specialNotes]);

  useEffect(() => {
    setHealthDeclarationObject((prev) => ({
      ...prev,
      medications_free_text: medicationsFreeText,
    }));
  }, [medicationsFreeText]);

  const stepSetter = useCallback(async () => {
    if (step === 7) {
      const healthDeclarationOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data: {
            type: "muntra_declaration",
            attributes: healthDeclarationObject,
          },
        }),
      };
      await fetch(
        `${config.api}/muntra-declarations/?booking_attendee_external_binding_id=${bookingExternalBindingId}`,
        healthDeclarationOptions,
      )
        .then((response) => {
          if (response.status !== 200) {
            response.json().then((data) => {
              Notifications.message({ message: data?.errors[0]?.detail });
            });
          } else {
            setStep(step + 1);
            scrollToTop();
          }
        })
        .catch(() => {
          Notifications.message({ message: "TypeError" });
        });
    } else {
      setStep(step + 1);
      scrollToTop();
    }
  }, [bookingExternalBindingId, healthDeclarationObject, scrollToTop, step]);

  const onGoBackStep = () => {
    if (step > 1) {
      setStep(step - 1);
      scrollToTop();
    }
    switch (step) {
      case 2:
        setTakesMedicine(false);
        setHasIllness(false);
        break;
      case 3:
        setHasIllness(false);
        setHasAllergies(false);
        break;
      case 4:
        setHasAllergies(false);
        setHasSpecialReasonForBooking(false);
        break;
      case 5:
        setHasSpecialReasonForBooking(false);
        break;
      case 6:
        setHasSpecialReasonForBooking(false);
        setWantsToShareMoreInfo(false);
        break;
      case 7:
        setWantsToShareMoreInfo(false);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${translations.hn_submit_health_dec_digitally}`}</title>
      </Helmet>
      <section className="layout">
        <div
          className={`flexSpaceBetween StickyMobileHeader bottomBorderGrey
        }`}
          style={{ background: "white" }}
        >
          <button
            className="ghostButton"
            id="backButton"
            onClick={onGoBackStep}
            style={{ marginLeft: "20px", cursor: "pointer" }}
          >
            <LeftArrowSVG classProp={getBackButtonClassName()} />
          </button>
          <Header
            centeredLogo
            flexWidth100
            hideMenuOptions
            isModal
            isStartPage
            bottomBorder={false}
            translations={translations}
          />
          <button
            className="ghostButton"
            onClick={() => history.push("/")}
            style={{ marginRight: "20px" }}
          >
            <CloseIcon />
          </button>
        </div>

        <div
          className="FlexCol healthDeclarationLayout"
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className={`transitionStep ${getClassName(1)}`}>
            {step === 1 && (
              <div>
                {!takesMedicine && (
                  <>
                    <HealthDeclarationPageHeader
                      headerTitle={translations.hn_do_you_take_meds}
                    />
                    <TwoAlternativesButtons
                      containerClass=""
                      leftButtonText={translations.hn_yes}
                      leftOnClickValue
                      onClickLeft={setTakesMedicine}
                      onClickRight={stepSetter}
                      rightButtonText={translations.hn_no}
                    />
                  </>
                )}
                {takesMedicine && (
                  <>
                    <CheckBoxesAndTextAreaContent
                      header={translations.hn_medicines}
                      healthDeclarationObject={healthDeclarationObject}
                      healthDeclarationTextProperty="medications_free_text"
                      otherText={medicineOtherText}
                      setHealthDeclarationObject={setHealthDeclarationObject}
                      setOtherText={setMedicineOtherText}
                      showCheckBoxes={false}
                      textAreaLabel={translations.hn_med_label}
                      textAreaPlaceholder="Namn och typ"
                    />
                    <NextButton
                      buttonText={translations.hn_next}
                      disabled={medicineOtherText === ""}
                      distanceFromScreenBottom="25px"
                      noMargin
                      positionFixed
                      stepSetter={stepSetter}
                    />
                  </>
                )}
              </div>
            )}
          </div>

          <div className={`transitionStep ${getClassName(2)}`}>
            {step === 2 && (
              <div>
                {!hasIllness && (
                  <>
                    <HealthDeclarationPageHeader
                      headerTitle={translations.hn_do_you_have_illnesses}
                    />
                    <TwoAlternativesButtons
                      containerClass=""
                      leftButtonText={translations.hn_yes}
                      leftOnClickValue
                      onClickLeft={setHasIllness}
                      onClickRight={stepSetter}
                      rightButtonText={translations.hn_no}
                    />
                  </>
                )}
                {hasIllness && (
                  <>
                    <CheckBoxesAndTextAreaContent
                      checkBoxPropertiesArray={illnesses}
                      header={translations.hn_illness_other_text}
                      healthDeclarationObject={healthDeclarationObject}
                      healthDeclarationTextProperty="special_notes"
                      otherText={illnessOtherText}
                      setCheckBoxesUsed={setIllnessesCheckBoxesUsed}
                      setHealthDeclarationObject={setHealthDeclarationObject}
                      setOtherText={setIllnessOtherText}
                      showCheckBoxes
                      textAreaLabel={translations.hn_illness_label}
                      textAreaPlaceholder={translations.hn_name_desc_severity}
                    />
                    <NextButton
                      buttonText={translations.hn_next}
                      stepSetter={stepSetter}
                    />
                  </>
                )}
              </div>
            )}
          </div>

          <div className={`transitionStep ${getClassName(3)}`}>
            {step === 3 && (
              <div>
                {!hasAllergies && (
                  <>
                    <HealthDeclarationPageHeader
                      headerTitle={translations.hn_do_you_have_allergies}
                    />
                    <TwoAlternativesButtons
                      containerClass=""
                      leftButtonText={translations.hn_yes}
                      leftOnClickValue
                      onClickLeft={setHasAllergies}
                      onClickRight={stepSetter}
                      rightButtonText={translations.hn_no}
                    />
                  </>
                )}
                {hasAllergies && (
                  <>
                    <CheckBoxesAndTextAreaContent
                      checkBoxPropertiesArray={allergies}
                      header={translations.hn_allergies}
                      healthDeclarationObject={healthDeclarationObject}
                      healthDeclarationTextProperty="special_notes"
                      otherText={allergyOtherText}
                      setCheckBoxesUsed={setAllergiesCheckBoxesUsed}
                      setHealthDeclarationObject={setHealthDeclarationObject}
                      setOtherText={setAllergyOtherText}
                      showCheckBoxes
                      textAreaLabel={translations.hn_allergies_label}
                      textAreaPlaceholder={translations.hn_name_desc_severity}
                    />
                    <NextButton
                      buttonText={translations.hn_next}
                      stepSetter={stepSetter}
                    />
                  </>
                )}
              </div>
            )}
          </div>
          <div
            className={`transitionStep FlexColJustCenter90vh ${getClassName(
              4,
            )}`}
            style={{ height: "70vh" }}
          >
            {step === 4 && (
              <HealthStateStep
                healthDeclarationObject={healthDeclarationObject}
                setHealthDeclarationObject={setHealthDeclarationObject}
                stepSetter={stepSetter}
                noMargin
                translations={translations}
              />
            )}
          </div>
          <div className={`transitionStep ${getClassName(5)}`}>
            {step === 5 && (
              <>
                {!hasSpecialReasonForBooking && (
                  <>
                    <HealthDeclarationPageHeader
                      headerTitle={translations.hn_special_reason_header}
                    />
                    <TwoAlternativesButtons
                      containerClass=""
                      leftButtonText={translations.hn_yes}
                      leftOnClickValue
                      onClickLeft={setHasSpecialReasonForBooking}
                      onClickRight={stepSetter}
                      rightButtonText={translations.hn_no}
                    />
                  </>
                )}
                {hasSpecialReasonForBooking && (
                  <div>
                    <CheckBoxesAndTextAreaContent
                      checkBoxPropertiesArray={bookingReasons}
                      header={translations.hn_more_info_regarding_visit}
                      healthDeclarationObject={healthDeclarationObject}
                      healthDeclarationTextProperty="special_notes"
                      otherText={specialReasonForBookingOtherText}
                      setCheckBoxesUsed={setReasonForBookingCheckBoxesUsed}
                      setHealthDeclarationObject={setHealthDeclarationObject}
                      setOtherText={setSpecialReasonForBookingOtherText}
                      showCheckBoxes
                      textAreaLabel={translations.hn_free_text}
                      textAreaPlaceholder={
                        translations.hn_more_info_regarding_visit
                      }
                    />
                    <NextButton
                      buttonText={translations.hn_next}
                      stepSetter={stepSetter}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div className={`transitionStep ${getClassName(6)}`}>
            {step === 6 && (
              <>
                {!wantsToShareMoreInfo && (
                  <>
                    <HealthDeclarationPageHeader
                      headerTitle={translations.hn_want_to_share_more_info}
                    />
                    <TwoAlternativesButtons
                      containerClass=""
                      leftButtonText={translations.hn_yes}
                      leftOnClickValue
                      onClickLeft={setWantsToShareMoreInfo}
                      onClickRight={stepSetter}
                      rightButtonText={translations.hn_no}
                    />
                  </>
                )}

                {wantsToShareMoreInfo && (
                  <>
                    <CheckBoxesAndTextAreaContent
                      header={translations.hn_what_is_important_for_visit}
                      healthDeclarationObject={healthDeclarationObject}
                      healthDeclarationTextProperty="special_notes"
                      otherText={wantsToShareMoreInfoOtherText}
                      setHealthDeclarationObject={setHealthDeclarationObject}
                      setOtherText={setWantsToShareMoreInfoOtherText}
                      showCheckBoxes={false}
                      textAreaLabel={translations.hn_share_more_info_label}
                      textAreaPlaceholder=""
                    />
                    <NextButton
                      noMargin
                      buttonText={translations.hn_next}
                      disabled={wantsToShareMoreInfoOtherText === ""}
                      distanceFromScreenBottom="25px"
                      positionFixed
                      stepSetter={stepSetter}
                    />
                  </>
                )}
              </>
            )}
          </div>
          <div className={`transitionStep ${getClassName(7)}`}>
            {step === 7 && (
              <div>
                <HealthDeclarationPreview
                  allergiesCheckBoxesUsed={allergiesCheckBoxesUsed}
                  hasAllergies={hasAllergies}
                  hasIllness={hasIllness}
                  hasSpecialReasonForBooking={hasSpecialReasonForBooking}
                  healthDeclarationObject={healthDeclarationObject}
                  illnessesCheckBoxesUsed={illnessesCheckBoxesUsed}
                  reasonForBookingCheckBoxesUsed={
                    reasonForBookingCheckBoxesUsed
                  }
                  translations={translations}
                />
                <NextButton
                  buttonText={translations.hn_finish_and_send}
                  placeAtScreenBottom="25px"
                  stepSetter={stepSetter}
                />
              </div>
            )}
          </div>
          <div className={`transitionStep ${getClassName(8)}`}>
            {step === 8 && (
              <div>
                <HealthDeclarationPageHeader
                  headerHeight="70vh"
                  headerTitle={translations.hn_declaration_done}
                  isMobile={isMobile}
                  showCelebrationIcon
                />
                <NextButton
                  noMargin
                  buttonText={translations.hn_close}
                  placeAtScreenBottom="25px"
                  stepSetter={() => history.push("/start")}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default HealthDeclarationPage;
