import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as PropTypes from "prop-types";

import CloseIcon from "assets/svg-components/CloseIcon";
import LeftArrowSVG from "assets/svg-components/LeftArrow";
import config from "config";
import {
  allergies,
  bookingReasons,
  illnesses,
} from "constants/health-declaration-info";
import { formatHours, mobileValidator } from "helpers/helper-functions";
import { Notifications } from "helpers/notifications";
import { selectTag } from "redux/app/selectors";

import CheckBoxesAndTextAreaContent from "./check-boxes-and-text-area-content";
import HealthDeclarationHeader from "./health-declaration-header";
import HealthDeclarationPreview from "./health-declaration-preview";
import HealthStateStep from "./health-state-step";
import NextButton from "./next-button";
import TwoAlternativesButtons from "./two-alternatives-buttons";

const HealthDeclaration = ({
  bookingExternalBindingId,
  onClose,
  translations,
}) => {
  const { selectedSlot, caregiverDataForBooking = {} } = useSelector(
    (state) => state.caregivers,
  );
  const locale = useSelector(selectTag);
  const { clinic } = caregiverDataForBooking;
  const { slot } = selectedSlot;

  const [healthDeclarationObject, setHealthDeclarationObject] = useState({
    allergy_edibles: false,
    allergy_latex: false,
    allergy_local_anesthesia: false,
    allergy_nickel: false,
    allergy_penicillin: false,
    allergy_pollen: false,
    allergy_other: false,
    asthma: false,
    blood_infection: false,
    cancer: false,
    cardiovascular_diseases: false,
    cerebral_haemorrhage: false,
    diabetes: false,
    ear_nose_throat: false,
    eating_disorder: false,
    epilepsy: false,
    gets_chemo_therapy: false,
    hemophilia: false,
    high_blood_pressure: false,
    hiv_aids: false,
    immunological_disease: false,
    medications_free_text: "",
    mental_health_problems: false,
    mouth_bleeding_gums: false,
    mouth_bruxism: false,
    mouth_halitosis: false,
    mouth_finger_sucker: false,
    mouth_mouth_breather: false,
    mouth_mouth_ulceration: false,
    mouth_xerostomia: false,
    mouth_sore_masticatory_muscles: false,
    mouth_other: false,
    nutrition_diet: false,
    osteoporosis: false,
    other: false,
    pacemaker: false,
    parkinsons: false,
    patient_health_evaluation: "",
    respiratory_disease: false,
    rheumatic_disease: false,
    smokes_takes_snuff: false,
    special_notes: "",
    supplement_for_dental_treatment: false,
  });

  const [healthDeclarationJsonObject, setHealthDeclarationJsonObject] =
    useState(null);

  const [allergyOtherText, setAllergyOtherText] = useState("");
  const [hasAllergies, setHasAllergies] = useState(false);
  const [hasIllness, setHasIllness] = useState(false);
  const [hasSpecialReasonForBooking, setHasSpecialReasonForBooking] =
    useState(false);
  const [illnessOtherText, setIllnessOtherText] = useState("");
  const [medicineOtherText, setMedicineOtherText] = useState("");
  const [
    specialReasonForBookingOtherText,
    setSpecialReasonForBookingOtherText,
  ] = useState("");
  const [step, setStep] = useState(1);
  const [takesMedicine, setTakesMedicine] = useState(false);
  const [wantsToShareMoreInfo, setWantsToShareMoreInfo] = useState(false);
  const [wantsToShareMoreInfoOtherText, setWantsToShareMoreInfoOtherText] =
    useState("");
  const [illnessesCheckBoxesUsed, setIllnessesCheckBoxesUsed] = useState(false);
  const [allergiesCheckBoxesUsed, setAllergiesCheckBoxesUsed] = useState(false);
  const [reasonForBookingCheckBoxesUsed, setReasonForBookingCheckBoxesUsed] =
    useState(false);

  const isMobile = mobileValidator.test(window.navigator.userAgent);

  const getBackButtonClassName = useCallback(() => {
    if (step === 1 || step === 8) {
      return "transparentSVG";
    }
    return "";
  }, [step]);

  const getClassName = useCallback(
    (i) => {
      if (step === i) return "";
      return step > i ? "previous" : "next";
    },
    [step],
  );

  const scrollToTop = useCallback(() => {
    document.getElementById("healthDeclarationTop").scrollIntoView();
  }, []);

  useEffect(() => {
    setHealthDeclarationJsonObject({
      data: {
        type: "muntra_declaration",
        attributes: healthDeclarationObject,
      },
    });
  }, [healthDeclarationObject]);

  useEffect(() => {
    setHealthDeclarationObject((prev) => ({
      ...prev,
      special_notes: `${
        illnessOtherText !== ""
          ? `${translations.hn_illness_other_text}: ${illnessOtherText}\n\n`
          : ""
      }${
        allergyOtherText !== ""
          ? `${translations.hn_allergies}: ${allergyOtherText}\n\n`
          : ""
      }${
        specialReasonForBookingOtherText !== ""
          ? `${translations.hn_special_reason_other_text}: ${specialReasonForBookingOtherText}\n\n`
          : ""
      }${
        wantsToShareMoreInfoOtherText !== ""
          ? `${translations.hn_other_info}: ${wantsToShareMoreInfoOtherText}\n\n`
          : ""
      }`,
    }));
  }, [
    illnessOtherText,
    allergyOtherText,
    specialReasonForBookingOtherText,
    wantsToShareMoreInfoOtherText,
    translations,
  ]);

  useEffect(() => {
    setHealthDeclarationObject((prev) => ({
      ...prev,
      medications_free_text: `${
        medicineOtherText !== ""
          ? `${translations.hn_medicines}: ${medicineOtherText}\n\n`
          : ""
      }`,
    }));
  }, [medicineOtherText, translations]);

  const stepSetter = useCallback(async () => {
    if (step === 7) {
      const healthDeclarationOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(healthDeclarationJsonObject),
      };
      await fetch(
        `${config.api}/muntra-declarations/?booking_attendee_external_binding_id=${bookingExternalBindingId}`,
        healthDeclarationOptions,
      )
        .then((response) => {
          if (response.status !== 200) {
            response.json().then((data) => {
              Notifications.message({ message: data?.errors[0]?.detail });
            });
          } else {
            setStep(step + 1);
            scrollToTop();
          }
        })
        .catch(() => {
          Notifications.message({ message: "TypeError" });
        });
    } else {
      setStep(step + 1);
      scrollToTop();
    }
  }, [
    bookingExternalBindingId,
    healthDeclarationJsonObject,
    scrollToTop,
    step,
  ]);

  const onGoBackStep = useCallback(() => {
    if (step > 1) {
      setStep(step - 1);
      scrollToTop();
    }
    switch (step) {
      case 2:
        setTakesMedicine(false);
        setHasIllness(false);
        break;
      case 3:
        setHasIllness(false);
        setHasAllergies(false);
        break;
      case 4:
        setHasAllergies(false);
        setHasSpecialReasonForBooking(false);
        break;
      case 5:
        setHasSpecialReasonForBooking(false);
        break;
      case 6:
        setHasSpecialReasonForBooking(false);
        setWantsToShareMoreInfo(false);
        break;
      case 7:
        setWantsToShareMoreInfo(false);
        break;

      default:
        break;
    }
  }, [scrollToTop, step]);

  return (
    <>
      <div
        id="healthDeclarationTop"
        style={{ position: "fixed", top: "0px" }}
      />

      <div
        className={`flexSpaceBetween StickyMobileHeader ${
          isMobile ? "bottomBorderGrey" : ""
        }`}
        style={{ background: "white" }}
      >
        <button
          className="ghostButton"
          id="backButton"
          onClick={onGoBackStep}
          style={{ marginLeft: "10px", cursor: "pointer" }}
        >
          <LeftArrowSVG classProp={getBackButtonClassName()} />
        </button>
        <button
          className="ghostButton"
          onClick={onClose}
          style={{ marginRight: "10px" }}
        >
          <CloseIcon />
        </button>
      </div>

      <div
        className="FlexCol"
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={`transitionStep ${getClassName(1)}`}>
          <div>
            {!takesMedicine && (
              <>
                <HealthDeclarationHeader
                  headerTitle={translations.hn_do_you_take_meds}
                />
                <TwoAlternativesButtons
                  leftButtonText={translations.hn_yes}
                  leftOnClickValue
                  onClickLeft={setTakesMedicine}
                  onClickRight={stepSetter}
                  rightButtonText={translations.hn_no}
                />
              </>
            )}
            {takesMedicine && (
              <>
                <CheckBoxesAndTextAreaContent
                  header={translations.medication_heading}
                  healthDeclarationObject={healthDeclarationObject}
                  healthDeclarationTextProperty="medications_free_text"
                  otherText={medicineOtherText}
                  setHealthDeclarationObject={setHealthDeclarationObject}
                  setOtherText={setMedicineOtherText}
                  showCheckBoxes={false}
                  textAreaLabel={translations.hn_med_label}
                  textAreaPlaceholder="Namn och typ"
                />
                <NextButton
                  buttonText={translations.hn_next}
                  disabled={medicineOtherText === ""}
                  distanceFromScreenBottom="25px"
                  positionFixed
                  stepSetter={stepSetter}
                />
              </>
            )}
          </div>
        </div>
        <div className={`transitionStep ${getClassName(2)}`}>
          <div>
            {!hasIllness && (
              <>
                <HealthDeclarationHeader
                  headerTitle={translations.hn_do_you_have_illnesses}
                />
                <TwoAlternativesButtons
                  leftButtonText={translations.hn_yes}
                  leftOnClickValue
                  onClickLeft={setHasIllness}
                  onClickRight={stepSetter}
                  rightButtonText={translations.hn_no}
                />
              </>
            )}
            {hasIllness && (
              <>
                <CheckBoxesAndTextAreaContent
                  checkBoxPropertiesArray={illnesses}
                  header={translations.hn_illness_other_text}
                  healthDeclarationObject={healthDeclarationObject}
                  healthDeclarationTextProperty="special_notes"
                  otherText={illnessOtherText}
                  setCheckBoxesUsed={setIllnessesCheckBoxesUsed}
                  setHealthDeclarationObject={setHealthDeclarationObject}
                  setOtherText={setIllnessOtherText}
                  showCheckBoxes
                  textAreaLabel={translations.hn_illness_label}
                  textAreaPlaceholder={translations.hn_name_desc_severity}
                />
                <NextButton
                  buttonText={translations.hn_next}
                  stepSetter={stepSetter}
                />
              </>
            )}
          </div>
        </div>
        <div className={`transitionStep ${getClassName(3)}`}>
          <div>
            {!hasAllergies && (
              <>
                <HealthDeclarationHeader
                  headerTitle={translations.hn_do_you_have_allergies}
                />
                <TwoAlternativesButtons
                  leftButtonText={translations.hn_yes}
                  leftOnClickValue
                  onClickLeft={setHasAllergies}
                  onClickRight={stepSetter}
                  rightButtonText={translations.hn_no}
                />
              </>
            )}
            {hasAllergies && (
              <>
                <CheckBoxesAndTextAreaContent
                  checkBoxPropertiesArray={allergies}
                  header={translations.hn_allergies}
                  healthDeclarationObject={healthDeclarationObject}
                  healthDeclarationTextProperty="special_notes"
                  otherText={allergyOtherText}
                  setCheckBoxesUsed={setAllergiesCheckBoxesUsed}
                  setHealthDeclarationObject={setHealthDeclarationObject}
                  setOtherText={setAllergyOtherText}
                  showCheckBoxes
                  textAreaLabel={translations.hn_allergies_label}
                  textAreaPlaceholder={translations.hn_name_desc_severity}
                />
                <NextButton
                  buttonText={translations.hn_name_desc_severity}
                  stepSetter={stepSetter}
                />
              </>
            )}
          </div>
        </div>
        <div
          className={`transitionStep FlexColJustCenter90vh ${getClassName(4)}`}
          style={{ height: "70vh" }}
        >
          <HealthStateStep
            healthDeclarationObject={healthDeclarationObject}
            setHealthDeclarationObject={setHealthDeclarationObject}
            stepSetter={stepSetter}
            translations={translations}
          />
        </div>
        <div className={`transitionStep ${getClassName(5)}`}>
          {!hasSpecialReasonForBooking && (
            <>
              <HealthDeclarationHeader
                headerTitle={translations.hn_special_reason_header}
              />
              <TwoAlternativesButtons
                leftButtonText={translations.hn_yes}
                leftOnClickValue
                onClickLeft={setHasSpecialReasonForBooking}
                onClickRight={stepSetter}
                rightButtonText={translations.hn_no}
              />
            </>
          )}
          {hasSpecialReasonForBooking && (
            <div style={{ marginRight: "30px", marginLeft: "30px" }}>
              <CheckBoxesAndTextAreaContent
                checkBoxPropertiesArray={bookingReasons}
                header={translations.hn_more_info_regarding_visit}
                healthDeclarationObject={healthDeclarationObject}
                healthDeclarationTextProperty="special_notes"
                otherText={specialReasonForBookingOtherText}
                setCheckBoxesUsed={setReasonForBookingCheckBoxesUsed}
                setHealthDeclarationObject={setHealthDeclarationObject}
                setOtherText={setSpecialReasonForBookingOtherText}
                showCheckBoxes
                textAreaLabel={translations.hn_free_text}
                textAreaPlaceholder={translations.hn_more_info_regarding_visit}
              />
              <NextButton
                buttonText={translations.hn_next}
                stepSetter={stepSetter}
              />
            </div>
          )}
        </div>
        <div className={`transitionStep ${getClassName(6)}`}>
          {!wantsToShareMoreInfo && (
            <>
              <HealthDeclarationHeader
                headerTitle={translations.hn_want_to_share_more_info}
              />
              <TwoAlternativesButtons
                leftButtonText={translations.hn_yes}
                leftOnClickValue
                onClickLeft={setWantsToShareMoreInfo}
                onClickRight={stepSetter}
                rightButtonText={translations.hn_no}
              />
            </>
          )}

          {wantsToShareMoreInfo && (
            <>
              <CheckBoxesAndTextAreaContent
                header={translations.hn_what_is_important_for_visit}
                healthDeclarationObject={healthDeclarationObject}
                healthDeclarationTextProperty="special_notes"
                otherText={wantsToShareMoreInfoOtherText}
                setHealthDeclarationObject={setHealthDeclarationObject}
                setOtherText={setWantsToShareMoreInfoOtherText}
                showCheckBoxes={false}
                textAreaLabel={translations.hn_share_more_info_label}
                textAreaPlaceholder=""
              />
              <NextButton
                buttonText={translations.hn_next}
                disabled={wantsToShareMoreInfoOtherText === ""}
                distanceFromScreenBottom="25px"
                positionFixed
                stepSetter={stepSetter}
              />
            </>
          )}
        </div>
        <div className={`transitionStep ${getClassName(7)}`}>
          <div>
            <HealthDeclarationPreview
              allergiesCheckBoxesUsed={allergiesCheckBoxesUsed}
              hasAllergies={hasAllergies}
              hasIllness={hasIllness}
              hasSpecialReasonForBooking={hasSpecialReasonForBooking}
              healthDeclarationObject={healthDeclarationObject}
              illnessesCheckBoxesUsed={illnessesCheckBoxesUsed}
              reasonForBookingCheckBoxesUsed={reasonForBookingCheckBoxesUsed}
              translations={translations}
            />
            <NextButton
              buttonText={translations.hn_finish_and_send}
              placeAtScreenBottom="25px"
              stepSetter={stepSetter}
            />
          </div>
        </div>
        <div className={`transitionStep ${getClassName(8)}`}>
          <div>
            <HealthDeclarationHeader
              headerHeight="70vh"
              headerTitle={translations.hn_declaration_done}
              isMobile={isMobile}
              showCelebrationIcon
              subHeader={`${
                translations.health_declaration_subheading_welcome
              } ${clinic.attributes.clinic_name} \n ${
                translations.health_declaration_subheading_at
              } ${formatHours(slot.attributes.dtstart, "H:mm")}-${formatHours(
                slot.attributes.dtend,
                "H:mm EEEE d MMMM",
                locale,
              )}`}
            />
            <NextButton
              buttonText={translations.hn_close}
              placeAtScreenBottom="25px"
              stepSetter={onClose}
            />
          </div>
        </div>
      </div>
    </>
  );
};

HealthDeclaration.propTypes = {
  bookingExternalBindingId: PropTypes.string,
  onClose: PropTypes.func,
  translations: PropTypes.object,
};

export default memo(HealthDeclaration);
