import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto 2rem;
  padding: 3rem 2rem 12rem 2rem;
  font-family: Arial, sans-serif;
  @media (max-width: 993px) {
    margin-bottom: 60px;
  }
`;

export const FirstHeader = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: 34px;
  margin: 10px 0 32px;
`;
