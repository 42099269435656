export const DEFAULT_LOCALE = [
  {
    type: "locale",
    attributes: {
      tag: "sv-se",
    },
    language: {
      attributes: {
        iso_code: "sv",
        name: "Svenska",
      },
    },
  },
];

export const DEFAULT_ERROR_MESSAGE = "Something went wrong";

export const PERSONAL_ID_VALIDATOR = /^\d{10,12}$/;
