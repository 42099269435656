import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addDays, endOfDay } from "date-fns";
import * as PropTypes from "prop-types";

import { formatDate, toDate } from "helpers/helper-functions";
import { useDebounce } from "hooks/use-debounce";
import { caregiversActions } from "redux/caregivers/actions";

import {
  Col,
  MainSpinner,
  Text,
  VerticalCheckbox,
  VerticalRadio,
} from "components/common.styled";
import Dropdown from "components/common/search-dropdown";

import ScheduleItem from "../schedule-item";

import {
  LocationHeader,
  ScheduleContainer,
  ScheduleContent,
  StickyWrapper,
} from "./index.styled";

const Schedule = ({ translations }) => {
  const {
    caregiver,
    slots,
    slotsLoading,
    slotsLoadBegin,
    defProcedure,
    loadingCaregiverProcedures,
  } = useSelector((state) => state.caregivers);

  const dispatch = useDispatch();
  const { caregiver_id: caregiverId } = useParams();

  const [defaultProcedure, setDefaultProcedure] = useState({});
  const [getDefaultProceduresStatus, setGetDefaultProceduresStatus] =
    useState(false);
  const [isNewPatient, setIsNewPatient] = useState(true);
  const [procedures, setProcedures] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [debouncedStartDate, setDebouncedStartDate] = useState(startDate);

  const { innerWidth } = window;

  const timeStep = innerWidth < 994 ? 1 : 3;
  const endDateInterval = innerWidth < 994 ? 0 : 2;

  const getEndDate = useCallback(
    () => addDays(endOfDay(debouncedStartDate), endDateInterval),
    [endDateInterval, debouncedStartDate],
  );

  const getCaregiversAtLocationForPeriod = useCallback(() => {
    if (caregiverId && defaultProcedure?.id)
      dispatch(
        caregiversActions.fetchCaregiverSlots({
          id: caregiverId,
          procedureId: defaultProcedure.id,
          isNewPatient,
          dateStart: formatDate(debouncedStartDate),
          dateEnd: formatDate(getEndDate()),
        }),
      );
  }, [
    caregiverId,
    debouncedStartDate,
    defaultProcedure.id,
    dispatch,
    getEndDate,
    isNewPatient,
  ]);

  const getProcedures = useCallback(() => {
    const procedures = [];

    if (caregiver.caregiver_locations)
      caregiver.caregiver_locations?.forEach((item) => {
        if (item.procedures)
          item.procedures?.forEach((procedure) => {
            if (!procedures?.find((el) => el.id === procedure?.procedure?.id))
              procedures.push({
                id: procedure.procedure?.id,
                title: procedure.procedure.attributes?.name,
                key: `procedure-${procedure.procedure?.id}`,
              });
          });
      });

    return procedures;
  }, [caregiver.caregiver_locations]);

  const filterCaregiversAtLocation = useCallback(
    (location) => {
      if (Array.isArray(slots) && slots.length) {
        return slots?.find((item) => location.id === item.id);
      }
      return [];
    },
    [slots],
  );

  const onChangeRadio = useCallback(
    ({ target }) => {
      const result = procedures?.find((item) => item.id === target.value);
      setDefaultProcedure(result);
    },
    [procedures],
  );

  const onChangeCheckBox = useCallback(
    ({ target }) => setIsNewPatient(target.checked),
    [],
  );

  const goToDate = useCallback((date) => {
    toDate(date, setStartDate);
  }, []);

  const debounceDateCb = useCallback(() => {
    if (startDate !== debouncedStartDate) {
      setDebouncedStartDate(startDate);
    }
  }, [debouncedStartDate, startDate]);

  useDebounce(debounceDateCb, [], 400);

  useEffect(() => {
    if (defProcedure?.id) {
      const procedure = {
        id: defProcedure.id,
        title: defProcedure.attributes.name,
      };
      setDefaultProcedure(procedure);
    }
  }, [defProcedure]);

  useEffect(() => {
    getCaregiversAtLocationForPeriod();
  }, [getCaregiversAtLocationForPeriod]);

  useEffect(() => {
    if (caregiver.attributes && !getDefaultProceduresStatus) {
      const results = getProcedures();
      if (results.length > 0) {
        setProcedures(results);
        setGetDefaultProceduresStatus(true);
      }
    }
  }, [caregiver.attributes, getDefaultProceduresStatus, getProcedures]);

  return (
    <ScheduleContainer>
      <StickyWrapper>
        {(!!caregiver.caregiver_locations?.length ||
          loadingCaregiverProcedures) && (
          <>
            <LocationHeader>
              <h3>{translations.schedule_book}</h3>
            </LocationHeader>
            {loadingCaregiverProcedures && (
              <MainSpinner style={{ margin: "50px auto" }} />
            )}
            <ScheduleContent>
              {procedures.length > 0 && defProcedure?.id && (
                <>
                  <Text>
                    <b>{translations.schedule_what_is_reason_for_booking}</b>
                  </Text>
                  {global.innerWidth >= 994 && (
                    <Col>
                      {procedures.length > 6 && (
                        <Dropdown
                          title={translations.schedule_search_treatment}
                          searchable={[
                            `${translations.schedule_search_treatment}`,
                            `${translations.schedule_no_match_procedure}`,
                          ]}
                          list={procedures}
                          resetThenSet={(item) => setDefaultProcedure(item)}
                          defaultProcedure={defaultProcedure}
                        />
                      )}
                      {procedures.length <= 6 && (
                        <Col>
                          {procedures.map((item) => (
                            <VerticalRadio
                              name="procedure"
                              key={item.key}
                              value={item.id}
                              onChange={onChangeRadio}
                              checkedValue={defaultProcedure.id}
                            >
                              {item.title}
                            </VerticalRadio>
                          ))}{" "}
                        </Col>
                      )}
                    </Col>
                  )}
                  {innerWidth < 944 && (
                    <Col>
                      {procedures && (
                        <Dropdown
                          title={translations.schedule_search_treatment}
                          searchable={[
                            `${translations.schedule_search_treatment}`,
                            `${translations.schedule_no_match_procedure}`,
                          ]}
                          list={procedures}
                          resetThenSet={(item) => setDefaultProcedure(item)}
                          defaultProcedure={defaultProcedure}
                        />
                      )}{" "}
                    </Col>
                  )}
                  <VerticalCheckbox
                    checked={isNewPatient}
                    onChange={onChangeCheckBox}
                    label={translations.schedule_new_patient}
                  />
                </>
              )}

              {caregiver.caregiver_locations?.map(
                (caregiverLocation, index) => (
                  <ScheduleItem
                    caregiver={caregiver}
                    defaultProcedure={defaultProcedure}
                    key={index}
                    location={caregiverLocation}
                    translations={translations}
                    slotsObject={{
                      slots: filterCaregiversAtLocation(caregiverLocation),
                      getSlotsForPeriod: getCaregiversAtLocationForPeriod,
                      slotsLoadBegin,
                      slotsLoading,
                    }}
                    dateObject={{ startDate, timeStep, goToDate }}
                  />
                ),
              )}
            </ScheduleContent>
          </>
        )}
      </StickyWrapper>
    </ScheduleContainer>
  );
};

Schedule.propTypes = {
  translations: PropTypes.object,
};

export default memo(Schedule);
