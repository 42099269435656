import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { bookingActions } from "redux/booking/actions";

import { ContentContainer, MainSpinner } from "components/common.styled";
import Pagination from "components/common/pagination";

import InvoiceCard from "../invoice-card";

import { Container, FirstHeader } from "./index.styled";

const InvoicesContent = () => {
  const invoicesContent = useRef(null);

  const {
    invoices = {},
    invoicesPage,
    loading,
  } = useSelector((state) => state.booking);

  const dispatch = useDispatch();
  const onPageChange = (e) => {
    dispatch(bookingActions.changeInvoicesPage(e));
    if (invoicesContent.current) invoicesContent.current?.scrollIntoView();
  };

  useEffect(() => {
    return () => {
      dispatch(bookingActions.changeInvoicesPage(1));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(bookingActions.fetchInvoicesList(invoicesPage));
  }, [dispatch, invoicesPage]);

  return (
    <ContentContainer ref={invoicesContent}>
      <Container>
        <FirstHeader> Fakturor </FirstHeader>
        {loading && <MainSpinner style={{ margin: "40% auto" }} />}
        {!loading && (
          <div>
            {!invoices?.data?.length && <div> Du har inga fakturor. </div>}
            {invoices?.data?.length &&
              invoices?.data?.map((item) => (
                <InvoiceCard
                  key={item.id}
                  item={item}
                  invoices={invoices.included}
                />
              ))}
            {invoices?.meta && (
              <Pagination
                limit={10}
                current={invoicesPage}
                total={invoices.meta.pagination.total}
                onPageChanged={onPageChange}
              />
            )}
          </div>
        )}
      </Container>
    </ContentContainer>
  );
};

export default InvoicesContent;
