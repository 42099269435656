import styled, { css } from "styled-components";

export const Container = styled.div`
  border-radius: 10px;
  position: relative;
  height: auto;
  width: 100%;
`;

export const Album = styled.div`
  display: flex;

  border-radius: 20px;
  position: relative;
  width: 100%;
  overflow: hidden;

  height: 100% !important;
  width: 100% !important;
  max-height: calc(100vh - 144px) !important;
  min-height: 300px !important;

  @media (min-width: 1128px) {
    max-height: calc(60vh - 64px) !important;
  }
`;

export const GridContainer = styled.div`
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  width: 51%;
`;

export const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 50%;
`;

export const DefImg = styled.div`
  cursor: pointer;
  width: 50%;
  ${({ onlyDef, isLoaded }) => {
    if (onlyDef || !isLoaded) {
      return css`
        width: 100%;
        height: 100%;
      `;
    }
  }}
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Images = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${({ count }) => {
    if (count === 2) {
      return css`
        height: 50%;
      `;
    }
  }}
  ${({ count, allWidth }) => {
    if (count === 3 && !allWidth) {
      return css`
        height: 100%;
        width: 50%;
      `;
    }
    if (count === 3 && allWidth) {
      return css`
        height: 50%;
      `;
    }
  }}
  
  ${({ count }) => {
    if (count === 4) {
      return css`
        height: 100%;
        width: 50%;
      `;
    }
  }}
`;
