import React from "react";
import * as PropTypes from "prop-types";

import { Col } from "components/common.styled";

import { ClinicOptionsDivider, ConditionTitle } from "../index.styled";

const Accessibility = ({ handicap, stairs, elevator, translations }) => {
  if (!(handicap || stairs || elevator)) return null;

  return (
    <Col>
      <ConditionTitle>
        <b>{translations.accessibility_heading}</b>
      </ConditionTitle>

      {handicap !== null && (
        <>
          <span>{translations.accessibility_handicap}</span>
          <ClinicOptionsDivider />
        </>
      )}
      {/* TODO recheck this condition */}
      {stairs !== null &&
        (stairs ? (
          <>
            <span>{translations.accessibility_stairs_available}</span>
            <ClinicOptionsDivider />
          </>
        ) : (
          <>
            <span>{translations.accessibility_stairs_lower}</span>
            <ClinicOptionsDivider />
          </>
        ))}
      {/* TODO recheck this condition */}
      {elevator !== null &&
        (elevator ? (
          <>
            <span>{translations.accessibility_elevator_available}</span>
            <ClinicOptionsDivider />
          </>
        ) : (
          <>
            <span>{translations.accessibility_elevator_not_available}</span>
            <ClinicOptionsDivider />
          </>
        ))}
    </Col>
  );
};

Accessibility.propTypes = {
  handicap: PropTypes.bool,
  stairs: PropTypes.bool,
  elevator: PropTypes.bool,
  translations: PropTypes.object,
};

export default Accessibility;
