import styled from "styled-components";

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PaginationContent = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  width: max-content;
  flex-flow: wrap;

  & a {
    padding: 5px 10px;
    min-width: 35px;
    text-align: center;
    box-shadow: none;
    border-color: #ced4da;
    color: rgb(24, 144, 255);
    font-weight: 900;
    font-size: 15px;
    display: block;
    transition: all 0.3s;
  }
  & a:hover {
    background-color: #f4f4f4;
  }
`;

export const PageItem = styled.div`
  ${({ active }) =>
    active &&
    `
  & a {
    background-color: #e3e7eb;
    border-color: #ced4da;
  }
  `};
`;
