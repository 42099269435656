import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  ${({ width }) => width && `height: ${width / 1.46}px;`}
  ${({ referralSourceEqualStatus }) =>
    referralSourceEqualStatus && `margin-top: 0;`}
`;

export const Slider = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
`;

export const Slide = styled.div`
  width: 100%;
  flex-shrink: 0;
  height: 100%;
  scroll-snap-align: start;
  scroll-behavior: smooth;
  margin: auto 0;
`;

export const NumberOfSlide = styled.div`
  color: white;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  position: absolute;
  z-index: 3;
  background-color: rgba(34, 34, 34, 0.66);
  bottom: 25px;
  right: 20px;
  border-radius: 4px;
  & > div {
    font-weight: 500;
    margin: 0;
    padding: 3px 10px;
  }
`;
