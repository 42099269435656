import { caregiversActions as actions } from "./actions";

export const INIT_STATE = {
  data: {},
  caregiver: {},
  reviews: {},
  caregiversDataForAvatar: [],
  caregiverDataForBooking: {},
  defProcedure: {},
  searchSlots: {},
  selectedSlot: {},
  searchResult: [],
  slots: [],
  firstStartSlots: [],
  secondStartSlots: [],
  caregiversTerms: "",
  page: 1,
  startPage: 1,
  loading: false,
  search: null,
  searchLoading: false,
  loadingReviews: false,
  loadingCaregiverProcedures: false,
  id: null,
  pageReviews: 1,
  slotsLoading: false,
  searchPage: null,
  searchSlotsLoading: false,
  fetchCaregiversStatus: false,
  slotsLoadBegin: false,
  error: "",
  nextSlotPeriod: 2,
};

export const caregiversReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case actions.CAREGIVERS_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.CAREGIVERS_CHANGE_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    case actions.CAREGIVERS_CHANGE_START_PAGE: {
      return {
        ...state,
        startPage: action.payload,
      };
    }
    case actions.CAREGIVERS_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    }
    case actions.CAREGIVERS_FETCH_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
        searchLoading: false,
        searchSlotsLoading: false,
        slotsLoadBegin: false,
        loadingCaregiverProcedures: false,
      };
    }
    case actions.CAREGIVERS_SEARCH: {
      return {
        ...state,
        searchResult: [],
        searchLoading: true,
      };
    }
    case actions.CAREGIVERS_SEARCH_SUCCESS: {
      return {
        ...state,
        searchResult: action.payload,
        searchLoading: false,
      };
    }
    case actions.CAREGIVERS_CHANGE_SEARCH: {
      return {
        ...state,
        search: action.payload,
      };
    }
    case actions.CAREGIVER_GET_BY_ID: {
      return {
        ...state,
        loading: true,
        id: action.payload,
      };
    }
    case actions.CAREGIVER_GET_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        caregiver: {
          ...action.payload,
          caregiver_locations: state.caregiver?.caregiver_locations || [],
        },
      };
    }
    case actions.CAREGIVER_FETCH_PROCEDURES: {
      return {
        ...state,
        loadingCaregiverProcedures: true,
      };
    }
    case actions.CAREGIVER_FETCH_PROCEDURES_SUCCESS: {
      return {
        ...state,
        defProcedure: action.payload.defaultProcedure,
        caregiver: {
          ...state.caregiver,
          caregiver_locations: action.payload.caregiverLocations,
        },
        loadingCaregiverProcedures: false,
      };
    }
    case actions.CAREGIVER_REVIEWS_CHANGE_PAGE: {
      return {
        ...state,
        pageReviews: action.payload,
      };
    }
    case actions.CAREGIVER_REVIEWS_FETCH: {
      return {
        ...state,
        loadingReviews: true,
      };
    }
    case actions.CAREGIVER_REVIEWS_FETCH_SUCCESS: {
      return {
        ...state,
        loadingReviews: false,
        reviews: action.payload,
      };
    }
    case actions.CAREGIVER_SLOTS_FETCH: {
      return {
        ...state,
        slotsLoading: true,
        slotsLoadBegin: true,
      };
    }
    case actions.CAREGIVER_SLOTS_FETCH_SUCCESS: {
      return {
        ...state,
        slotsLoading: false,
        slotsLoadBegin: false,
        slots: action.payload,
      };
    }
    case actions.CAREGIVER_SLOTS_SEARCH_CHANGE_PAGE: {
      return {
        ...state,
        searchPage: action.payload,
      };
    }
    case actions.CAREGIVER_SLOTS_SEARCH_FETCH:
    case actions.CAREGIVER_NEAREST_SLOTS_FETCH: {
      return {
        ...state,
        searchSlotsLoading: true,
        slotsLoadBegin: true,
      };
    }
    case actions.CAREGIVER_SLOTS_SEARCH_FETCH_SUCCESS: {
      if (action.payload.secondLoadComplete)
        return {
          ...state,
          slotsLoadBegin: false,
          searchSlotsLoading: false,
          searchSlots: action.payload,
        };
      if (action.payload.loadComplete)
        return {
          ...state,
          searchSlotsLoading: false,
          searchSlots: action.payload,
        };
      return {
        ...state,
        searchSlots: action.payload,
      };
    }
    case actions.CAREGIVER_FIRST_NEAREST_SLOTS_FETCH_SUCCESS: {
      if (action.payload.secondLoadComplete)
        return {
          ...state,
          slotsLoadBegin: false,
          firstStartSlots: action.payload,
        };
      if (action.payload.loadComplete)
        return {
          ...state,
          searchSlotsLoading: false,
          firstStartSlots: action.payload,
        };
      return {
        ...state,
        firstStartSlots: action.payload,
      };
    }
    case actions.CAREGIVER_SECOND_NEAREST_SLOTS_FETCH_SUCCESS: {
      if (action.payload.secondLoadComplete)
        return {
          ...state,
          slotsLoadBegin: false,
          secondStartSlots: action.payload,
        };
      if (action.payload.loadComplete)
        return {
          ...state,
          searchSlotsLoading: false,
          secondStartSlots: action.payload,
        };
      return {
        ...state,
        secondStartSlots: action.payload,
      };
    }
    case actions.CAREGIVER_GET_SELECTED_SLOT: {
      return {
        ...state,
        selectedSlot: action.payload,
      };
    }
    case actions.CAREGIVER_FETCH_STATUS: {
      return {
        ...state,
        fetchCaregiversStatus: action.payload,
      };
    }
    case actions.CAREGIVERS_SEARCH_TERMS_SUCCESS: {
      return {
        ...state,
        caregiversTerms: action.payload,
      };
    }
    case actions.CAREGIVERS_GET_FOR_AVATAR_SUCCESS: {
      return {
        ...state,
        caregiversDataForAvatar: action.payload,
      };
    }
    case actions.CAREGIVER_FOR_BOOKING_REFRESH: {
      return {
        ...state,
        caregiverDataForBooking: action.payload,
        loading: false,
      };
    }
    case actions.CAREGIVER_CLEAR_BOOKING_DATA: {
      return {
        ...state,
        caregiverDataForBooking: {},
      };
    }
    case actions.CAREGIVER_CLEAR_ERROR: {
      return {
        ...state,
        error: "",
      };
    }
    case actions.CAREGIVER_CLEAN_UP: {
      return {
        ...state,
        defProcedure: {},
        caregiver: {},
      };
    }
    case actions.CAREGIVER_INCREMENT_PERIOD: {
      return {
        ...state,
        nextSlotPeriod: action.payload,
      };
    }
    default:
      return state;
  }
};
