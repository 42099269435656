import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import config from "config";
import { slugifyer } from "helpers/helper-functions";
import { useQuery } from "hooks/use-query";
import { useTranslations } from "hooks/use-translations";
import { caregiversActions } from "redux/caregivers/actions";

import {
  Content,
  Heading,
  Layout,
  StyledCol,
  StyledPagination,
  StyledRow,
  StyledSpin,
  StyledText,
} from "components/common.styled";
import Footer from "components/footer";
import Header from "components/header";

const Directory = () => {
  const routeName = "directory";

  const caregiversState = useSelector((state) => state.caregivers);
  const { data, loading, page } = caregiversState;

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const translations = useTranslations(routeName);

  const onPageChange = useCallback(
    (e) => {
      history.push({
        pathname: location.pathname,
        search: `?page=${e}`,
      });
      dispatch(caregiversActions.changePageCaregivers(+e));
    },
    [dispatch, history, location.pathname],
  );

  const getIncludedItem = useCallback(
    (search) =>
      data?.included?.find(
        (item) => item.type === search.type && item.id === search.id,
      ),
    [data?.included],
  );

  useEffect(() => {
    if (query.get("page") && +query.get("page") !== +page)
      dispatch(caregiversActions.changePageCaregivers(+query.get("page")));
  }, [dispatch, page, query]);

  useEffect(() => {
    dispatch(caregiversActions.fetchCaregivers(page));
  }, [dispatch, page]);

  const renderPagination = () => {
    const {
      meta: { pagination },
    } = data;

    return (
      <StyledPagination
        total={pagination.total}
        limit={20}
        current={page}
        onPageChanged={onPageChange}
      />
    );
  };

  return (
    <Layout>
      <Helmet>
        <title>
          {`${translations.find_dental_caregivers_by_name} | ${translations.meta_title_default}`}
        </title>
        <meta
          id="meta-description"
          name="description"
          content={translations.meta_content_clinic_dir}
        />
        <link
          id="canonical-link"
          rel="canonical"
          href={config.url + location.pathname.replace(/\?.*$/gm, "")}
        />
      </Helmet>

      <Header translations={translations} isStartPage={false} />

      <Content>
        {data?.meta && renderPagination()}
        <Heading>{translations.find_dental_caregivers_by_name}</Heading>
        <StyledRow>
          {!loading &&
            (data?.data || []).map((item) => {
              const { first_name: firstName, last_name: lastName } =
                item.attributes;
              const fullName = `${firstName} ${lastName}`;

              const { role, caregiver_locations: caregiverLocations } =
                item.relationships;
              const caregiverRole = role && getIncludedItem(role.data);
              const caregiverLocation =
                caregiverLocations.data[0] &&
                getIncludedItem(caregiverLocations.data[0]);
              const caregiverClinic =
                caregiverLocation &&
                getIncludedItem(caregiverLocation.relationships.clinic.data);

              const address = caregiverClinic?.attributes.clinic_address_1;
              const city = caregiverClinic
                ? caregiverClinic.attributes.clinic_city
                : "";
              const postalCole = caregiverClinic
                ? caregiverClinic.attributes.clinic_postal_code
                : "";

              return (
                <StyledCol xs={24} md={5} key={`caregiver-${item.id}`}>
                  <Link
                    to={`/${slugifyer(
                      caregiverRole.attributes.name,
                    )}/${slugifyer(fullName)}/p/${item.id}`}
                  >
                    {`${caregiverRole.attributes.name} ${fullName}`}
                  </Link>
                  {address && <StyledText>{`${address}`}</StyledText>}
                  <StyledText>{`${city} ${postalCole}`}</StyledText>
                </StyledCol>
              );
            })}
          {loading && <StyledSpin size="large" />}
        </StyledRow>
        {data?.meta && renderPagination()}
      </Content>
      <Footer translations={translations} />
    </Layout>
  );
};

export default Directory;
