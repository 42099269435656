import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import QRCode from "qrcodejs2";

const QrCode = ({
  text,
  width,
  height,
  colorDark,
  colorLight,
  correctLevel,
}) => {
  const qrCodeRootRef = useRef(null);
  const qrCodeRef = useRef(null);

  useEffect(() => {
    if (qrCodeRootRef.current && text) {
      qrCodeRootRef.current.innerHTML = "";
      qrCodeRef.current = new QRCode(qrCodeRootRef.current, {
        colorDark,
        colorLight,
        correctLevel: QRCode.CorrectLevel[correctLevel],
        height,
        text,
        width,
      });

      qrCodeRef.current.makeCode(text);
    }
    return () => {
      if (qrCodeRef.current) {
        qrCodeRef.current = null;
      }
    };
  }, [colorDark, colorLight, correctLevel, height, text, width]);

  return (
    <div
      style={{
        margin: "50px",
        width: "200px",
        height: "200px",
        pointerEvents: "none",
      }}
      ref={qrCodeRootRef}
    />
  );
};

QrCode.defaultProps = {
  width: 200,
  height: 200,
  colorDark: "#000000",
  colorLight: "#ffffff",
  correctLevel: "L",
};

QrCode.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  colorDark: PropTypes.string,
  colorLight: PropTypes.string,
  correctLevel: PropTypes.oneOf(["L", "M", "Q", "H"]),
};

export default QrCode;
