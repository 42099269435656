import { all, call, put, takeEvery } from "redux-saga/effects";
import { ProceduresService } from "services/procedures-service";
import { RolesService } from "services/roles-service";

import { getItemFields } from "../../helpers/helper-functions";
import { rolesActions as actions } from "./actions";

function* getRole({ payload: params }) {
  const procedureDataConfig = {
    params: { "filter[ids]": params.procedure },
  };
  const rolesDataConfig = {
    params: {
      "filter[ids]": params.role,
      is_caregiver_role: true,
    },
  };

  let role = "";
  let procedure = "";

  if (params.role && params.procedure) {
    [role, procedure] = yield all([
      RolesService.getRoles(rolesDataConfig),
      ProceduresService.getProcedures(procedureDataConfig),
    ]);
  } else if (params.role && !params.procedure) {
    role = yield call(RolesService.getRoles, rolesDataConfig);
  } else if (!params.role && params.procedure) {
    procedure = yield call(
      ProceduresService.getProcedures,
      procedureDataConfig,
    );
  }

  if (!role && !procedure) {
    yield put(actions.fetchRolesError());
    return;
  }

  yield put(
    actions.setRole({ procedure: procedure.data?.data, role: role.data?.data }),
  );
}

function* fetchOneRole({ payload }) {
  const { id = "", include, isCaregiverRole } = payload || {};
  const dataConfig = {
    params: {
      include,
      ...(isCaregiverRole && { is_caregiver_role: isCaregiverRole }),
    },
  };
  const result = yield call(RolesService.getRoleById, id, dataConfig);
  if (!result) {
    yield put(actions.fetchRolesError());
    return;
  }

  const {
    data: {
      data: { relationships, ...rest },
      included,
    },
  } = result;
  const results = getItemFields(relationships, included);
  const role = { ...results, ...rest };
  yield put(actions.fetchRoleSuccess(role));
}

function* fetchRoles({ payload }) {
  const { include, isCaregiverRole } = payload || {};
  const dataConfig = {
    params: {
      include,
      ...(isCaregiverRole && { is_caregiver_role: isCaregiverRole }),
    },
  };
  const result = yield call(RolesService.getRoles, dataConfig);
  if (!result) {
    yield put(actions.fetchRolesError());
    return;
  }

  const roles = result.data?.data?.map((item) => ({
    type: item.type,
    id: item.id,
    is_caregiver_role: item.attributes.is_caregiver_role,
    name: item.attributes.name,
  }));
  yield put(actions.fetchRolesSuccess(roles));
}

function* searchRoles({ payload: search }) {
  const dataConfig = {
    params: {
      query: search,
      is_caregiver_role: true,
    },
  };
  const result = yield call(RolesService.getRoles, dataConfig);
  if (!result) {
    yield put(actions.fetchRolesError());
    return;
  }

  yield put(actions.searchRolesSuccess(result.data));
}

export default function* rolesSagas() {
  yield all([
    takeEvery(actions.ROLE_GET, getRole),
    takeEvery(actions.ROLE_FETCH, fetchOneRole),
    takeEvery(actions.ROLES_FETCH, fetchRoles),
    takeEvery(actions.ROLES_SEARCH, searchRoles),
  ]);
}
